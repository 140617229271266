

<template>
  <div>
    <div>
      <template v-if="hasError">
        <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="mb-2" style="vertical-align: bottom;color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
        <b-popover
          :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
          placement="top"
          triggers="hover"
          :content="hasErrorContent">
        </b-popover>
      </template>
      <font-awesome-icon v-if="params && params.existsIcon && params.data && params.data.uuId" :id="`CELL_EXISTS_${params.eGridCell.getAttribute('comp-id')}`" class="exists-icon mb-2" style="vertical-align: bottom;font-size: 1.125em;" :icon="['far', 'arrow-right-arrow-left']"/>
      <b-popover
        v-if="params && params.existsIcon && params.data && params.data.uuId" 
        :target="`CELL_EXISTS_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="$t('exists')">
      </b-popover>
      <span :class="hasError ? 'limitWidth' : ''">{{ label }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ErrorIconCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    this.value = this.params && this.params.value !== -1? this.params.value: null;
  },
  computed: {
    hasErrorContent() {
      const msg = this.params.data[this.params.errorMessage] ? this.params.data[this.params.errorMessage] : typeof this.hasError === 'string' ? this.hasError : this.params.message;
      if (this.$te(msg)) {
        return this.$t(msg);
      }
      return msg;
    },
    label() {
      return this.value;
    },
    hasError() {
      if (this.params.hasError) {
        const result = this.params.hasError(this.params);
        if (typeof result === 'string') {
           return result;
        }
        else if (!result) {
          return result;
        }
        return this.params.message;
      }
      
      return (typeof this.params.errorMessage !== 'undefined' && this.params.data[this.params.errorMessage]) || 
        (!this.params.data.uuId && (!this.value || this.value.length === 0)) || 
        (this.value && this.params.maxLength < this.value.length);
    }
  }
})
</script>
<style lang="scss" scoped>
  .limitWidth {
    min-height: 26px;
    display: inline-block;
    white-space: nowrap;
    vertical-align: bottom;
    margin-left: 2px;
  }
  
  .exists-icon {
    position: absolute;
    top: 5px;
    left: 5px;
  }
</style>