var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.elemId } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "no-close-on-esc": _vm.isCellEditing || _vm.editorShow,
            "modal-class": [_vm.elemId],
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _vm.alertObj.msg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertObj.msg,
                  details: _vm.alertObj.msgDetails.list,
                  detailTitle: _vm.alertObj.msgDetails.title,
                  alertState: _vm.alertObj.state,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _vm.allowManage
            ? _c(
                "div",
                { staticClass: "grid-toolbar border" },
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_EDIT_" + _vm.elemId } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { disabled: _vm.disableEdit },
                          on: {
                            click: function ($event) {
                              return _vm.openEditor(false)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "pen-to-square"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EDIT_" + _vm.elemId,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                  ),
                  _c(
                    "span",
                    { attrs: { id: "BTN_DELETE_" + _vm.elemId } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { disabled: _vm.disableDelete },
                          on: { click: _vm.rowDelete },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "trash-can"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_DELETE_" + _vm.elemId,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass:
              "ag-theme-balham generic-selector selector-grid-height spreadsheet",
            staticStyle: { width: "100%" },
            attrs: {
              id: "rebate-grid",
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              rowMultiSelectWithClick: false,
              rowSelection: "multiple",
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressContextMenu: "",
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: false,
              singleClickEdit: false,
              enableRangeSelection: true,
              suppressMultiSort: "",
              suppressRowClickSelection: "",
              enableFillHandle: false,
              fillOperation: _vm.fillOperation,
              fillHandleDirection: "xy",
              processCellForClipboard: _vm.processCellForClipboard,
              processCellFromClipboard: _vm.processCellFromClipboard,
              navigateToNextCell: _vm.navigateToNextCell,
              tabToNextCell: _vm.tabToNextCell,
              rowData: _vm.rowData,
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
            },
            on: {
              "grid-ready": _vm.onGridReady,
              "cell-key-down": _vm.onCellKeyDown,
              "paste-start": _vm.onPasteStart,
              "paste-end": _vm.onPasteEnd,
              "cell-focused": _vm.cellFocused,
              "cell-editing-started": _vm.onCellEditingStarted,
              "cell-editing-stopped": _vm.onCellEditingStopped,
            },
          }),
        ],
        1
      ),
      _vm.allowManage && _vm.editorShow
        ? _c("SandboxModal", {
            attrs: {
              data: _vm.editData,
              id: _vm.editData.uuId,
              sharing: true,
              showOwner: true,
              show: _vm.editorShow,
              title: _vm.editorTitleLabel,
              sandboxList: _vm.sandboxProfile.sandboxs
                ? _vm.sandboxProfile.sandboxs
                : [],
              appendAfterOptions: _vm.editorAppendAfterOptions,
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              success: _vm.editorSuccess,
            },
          })
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("entity_selector.confirm_deletion_title"),
            "content-class": "entity-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.ecConfirmDeleteShow = false
            },
            ok: _vm.ecConfirmDeleteOk,
            cancel: _vm.ecConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.ecShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.entityCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.entityCol, "applyAll", $$v)
                            },
                            expression: "entityCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.ecConfirmDeleteShow,
            callback: function ($$v) {
              _vm.ecConfirmDeleteShow = $$v
            },
            expression: "ecConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.ecConfirmDeleteStatement))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }