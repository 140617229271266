<template>
  <div :id="`EPOCH_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-form-group :label="$t('epoch.date')" label-for="epoch">
        <b-input-group>
          <b-form-datepicker id="activity-startDate" v-model="epoch" class="mb-2"
            today-button
            reset-button
            close-button
            hide-header
            boundary="viewport"
            :min="minDate"
            :max="maxDate"
            :popper-opts="{ positionFixed: true }"
            :label-today-button="$t('date.today')"
            :label-reset-button="$t('date.reset')"
            :label-close-button="$t('date.close')"
            today-button-variant="primary"
            reset-button-variant="danger" 
            close-button-variant="secondary"
          >
            <template v-slot:button-content="{ }">
              <font-awesome-icon :icon="['far', 'calendar-days']" />
            </template>
          </b-form-datepicker>
        </b-input-group>
      </b-form-group>
        
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { strRandom, EventBus } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { managementService } from '@/services';


export default {
  name: 'EpochSelectorModal',
  components: {
  },
  props: {
    id:        { type: String,   default: `EPOCH_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    visibility: { type: String, default: 'private' },
  },
  data() {
    return {
      permissionName: 'EPOCH',
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false
      },
      epoch: null,
      firstEpoch: null
    }
  },
  created() {
    managementService.configuration({ opts: 'api'}).then((response) => {
      this.firstEpoch = response.data.propertyList['api.time-machine.since'];
    });
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
  },
  computed: {
    maxDate() {
      return new Date();
    },
    minDate() {
      if (this.firstEpoch) {
        return moment(this.firstEpoch).format("YYYY-MM-DD");
      }
      return "2024-01-01";
    },
    isReadOnly() {
      return !this.state.editable && this.readOnly;
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title: this.$t('epoch.select');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        if (this.$store.state.epoch.value) {
          this.epoch = moment(this.$store.state.epoch.value).format('YYYY-MM-DD');
        }
        else {
          this.epoch = null;
        }
      }
    }
  },
  methods: {
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.epochSubmit();
    },
    epochSubmit() {
      let name = '';
      this.$store.state.sandbox.value = null;
      if (this.epoch) {
        this.$store.state.epoch.value = moment(this.epoch).unix() * 1000;
        if (this.$store.state.epoch.value === 0) {
          // add 1 second to be valid
          this.$store.state.epoch.value = 1;
        }
        name = moment(this.epoch).format('YYYY-MM-DD');
      }
      else {
        this.$store.state.epoch.value = null;
        name = this.$t('sidebar.epoch');
      }
      this.$emit('success', { epoch: this.epoch, name: name });
      EventBus.$emit('language-change');
      this.$emit('update:show', false)
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    modalCancel() {
      this.$emit('update:show', false)
    }
  }
}
</script>
