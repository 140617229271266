var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "b-row",
            {
              ref: "management-section",
              staticClass: "section management-row",
            },
            [
              _vm.canList("COMPANY")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.COMPANY)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--managemen-icon-secondary)",
                              "--fa-secondary-color":
                                "var(--management-icon-primary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: {
                              icon: ["fad", "apartment"],
                              "swap-opacity": "",
                            },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.COMPANY)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("company.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.LOCATION)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.LOCATION)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "map-location-dot"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.LOCATION)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("location.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("DEPARTMENT")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.DEPARTMENT)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "sitemap"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(
                                    _vm.entities.DEPARTMENT
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("department.title_selector"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("STAFF")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.STAFF)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "user-hard-hat"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.STAFF)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("staff.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.CUSTOMER)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.CUSTOMER)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "user-tie"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.CUSTOMER)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("customer.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.PROJECT)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.PROJECT)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "chart-network"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.PROJECT)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("project.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.STAGE)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.STAGE)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "signs-post"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.STAGE)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("stage.title_management")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.TASK_TEMPLATE)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(
                                _vm.entities.TASK_TEMPLATE
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "layer-group"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(
                                    _vm.entities.TASK_TEMPLATE
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("template.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.SKILL)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.SKILL)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "head-side-brain"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.SKILL)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("skill.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.RESOURCE)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.RESOURCE)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "cart-flatbed-boxes"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.RESOURCE)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("resource.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.REBATE)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.REBATE)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "badge-percent"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.REBATE)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("rebate.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("STORAGE_FILE")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.state.fileShow = true
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "folders"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.state.fileShow = true
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("file.title.list")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("USER")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.USER)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "user-shield"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.USER)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("user.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("ACCESS_POLICY")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(
                                _vm.entities.ACCESS_POLICY
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "user-lock"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(
                                    _vm.entities.ACCESS_POLICY
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("access_policy.title_selector"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("TAG")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.TAG)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "tags"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.TAG)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("tag.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("ADMIN__ENUMERATION_MANAGEMENT")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.ENUM)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "square-list"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.ENUM)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("enum.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList(_vm.entities.WEBHOOK)
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.WEBHOOK)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "webhook"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.WEBHOOK)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("webhook.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("ADMIN__FIELD_MANAGEMENT")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.FIELD)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "pen-field"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.FIELD)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fields.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canList("ADMIN__SYSTEM_MANAGEMENT")
                ? _c(
                    "b-col",
                    {
                      staticClass: "management-container management-color",
                      attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "management-card card",
                          attrs: { "body-class": "text-center" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openSelector(_vm.entities.LANGUAGE)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mb-2",
                            staticStyle: {
                              "font-size": "80px",
                              "--fa-primary-color":
                                "var(--management-icon-primary)",
                              "--fa-secondary-color":
                                "var(--managemen-icon-secondary)",
                              width: "95%",
                              padding: "5px",
                              "--fa-primary-opacity": "0.8",
                              "--fa-secondary-opacity": "0.8",
                            },
                            attrs: { icon: ["fad", "language"] },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "#",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSelector(_vm.entities.LANGUAGE)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("language.title_selector")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("GenericSelectorModalForAdmin", {
        attrs: {
          show: _vm.state.entitySelectorShow,
          entityService: _vm.currentService,
          entity: _vm.currentEntity,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "entitySelectorShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("CompanySelectorModalForAdmin", {
        attrs: { show: _vm.state.companyShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "companyShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("DepartmentSelectorModalForAdmin", {
        attrs: { show: _vm.state.departmentShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "departmentShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("StaffSelectorModalForAdmin", {
        attrs: { show: _vm.state.staffShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "staffShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("EnumSelectorModalForAdmin", {
        attrs: { show: _vm.state.enumShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "enumShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("FieldSelectorModalForAdmin", {
        attrs: { show: _vm.state.fieldShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "fieldShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("LanguageSelectorModalForAdmin", {
        attrs: { show: _vm.state.languageShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "languageShow", $event)
          },
          cancel: _vm.onCloseModal,
        },
      }),
      _c("FileSelectorModal", {
        attrs: { show: _vm.state.fileShow, mode: "MANAGE", multiple: true },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "fileShow", $event)
          },
          ok: _vm.ok,
          cancel: _vm.onCloseModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }