<template>
  <div class="history-item">
    <div><span>{{ $t('entity') }}: </span><span>{{ entity }}</span></div>
    <div><span>{{ $t('name') }}: </span><span>{{ name }}</span></div>
    <div><span>{{ $t('status') }}: </span><span>{{ status }}</span></div>
    <template v-for="(prop, index) in properties">
      <div :key="index">
        <span>{{ prop.name }}: </span>
        <span>{{ formatValue(prop) }}</span>
      </div>
    </template>
    <div v-if="dependencies">{{ $t('links') }}</div>
    <ul class="audit-detail">
      <template v-for="(dependency, index) in dependencies">
        <li :key="index">
          <div><span>{{ $t('entity') }}: </span><span>{{ dependency.entity }}</span></div>
          <div><span>{{ $t('name') }}: </span><span>{{ dependency.name }}</span></div>
          <div><span>{{ $t('status') }}: </span><span>{{ dependency.status }}</span></div>
          <div><span>{{ $t('modified') }}: </span><span>{{ formatDate(dependency.properties.at) }}</span></div>
        </li>
      </template>
    </ul>
  
  </div>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { convertDurationToDisplay } from '@/helpers/task-duration-process';

export default Vue.extend({
  name: 'AuditDetailCellRenderer'
  , data() {
    return {
      entity: null
      , name: null
      , sttus: null
      , dependencies: null
      , properties: null
    }
  }
  , created() {
    if (this.params.context?.componentParent?.durationConversionOpts != null) {
      this.durationConversionOpts = this.params.context.componentParent.durationConversionOpts;
    }
  }
  , beforeMount() {
    this.entity = this.params.data.entity;
    this.name = this.params.data.name;
    this.status = this.params.data.status;
    this.dependencies = this.params.data.dependencies;
    this.properties = this.params.data.properties;
    if (this.properties) {
      for (const key of Object.keys(this.properties)) {
        this.properties[key]['name'] = key;
      }
    }
  }
  , computed: {
    
  }
  , methods: {
    refresh(params) {
      this.params = params;
      this.dependencies.splice(0, this.dependencies.length, ...this.params.data.dependencies);
      this.properties.splice(0, this.properties.length, ...this.params.data.properties);
      return true;
    }
    , formatDate(value) {
      return moment(value).format('YYYY-MM-DD hh:mm a');
    }
    , formatValue(prop) {
      if (prop.name === 'startTime' ||
          prop.name === 'closeTime' ||
          prop.name === 'beginDate' ||
          prop.name === 'untilDate') {
        return this.formatDate(prop.value);  
      }
      else if (prop.name === 'duration') {
        return convertDurationToDisplay(prop.value / 60000, 'D', this.durationConversionOpts);
      }
      return prop.value;
    }
  }
})
</script>

<style lang="scss" scoped>
.audit-detail {
  list-style-type: disc;
  padding-left: 40px;
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: 0;
}

.audit-detail p {
  margin-bottom: 0;
  line-height: 20px;
}

.audit-detail li {
  margin-bottom: 5px;
  line-height: 20px;
}

.timestamp-container {
  position: relative;
  margin-bottom: 10px;
}

.timestamp-container span {
  padding-left: 20px;
}

.timestamp-icon {
  position: absolute;
  left: 0;
  top: 6px;
}

.author-container {
  width: 100%;
  margin-top: 10px;
}


.author-name {
  position: relative;
  float: right;
}

.author-name span {
  padding-left: 20px;
}

.author-name .author-icon, .timestamp-icon {
  position: absolute;
  left: 0;
  top: 6px;
}

.history-item {
  margin: 0;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  word-break: break-word;
}
</style>