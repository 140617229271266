<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="cancelModal"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <div class="ml-1 mr-1">{{ $t('lock') }}</div>
            <b-form-checkbox switch v-model="activity.readOnly"/>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col v-if="isNameVisible" cols="12" lg="8" class="pr-0">
            <b-form-group :label="$t('activity.field.name')" label-for="name">
              <b-input-group>
                <b-form-input id="activity-name" type="text" class="w-100"
                  :minMatchingChars="0"
                  :maxMatches="100"
                  :data-vv-as="$t('activity.field.name')"
                  data-vv-name="activity.name"
                  :maxlength="maxNameLength"
                  data-vv-delay="500"
                  v-model="activity.name" 
                  v-validate="{ required: true }"
                  :readonly="isNameReadOnly"
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'activity.name')">
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('activity.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
            <b-form-group :label="$t('field.identifier')" label-for="identifier">
              <b-input-group>
                <b-form-input id="identifier" type="text"
                  :data-vv-as="$t('field.identifier')"
                  data-vv-name="activity.identifier"
                  :maxlength="maxIdentifierLength"
                  v-model="activity.identifier" 
                  :disabled="isIdentifierReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['identifier'] != null">
            <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col v-if="isDescriptionVisible" cols="12" class="pr-0">
            <b-form-group :label="$t('activity.field.description')" label-for="activity-description">
              <b-form-textarea id="activity-description" 
                :placeholder="isDescriptionReadOnly? '' : $t('activity.placeholder.description')"
                :data-vv-as="$t('activity.field.description')"
                data-vv-name="activity.description"
                data-vv-delay="500"
                v-model="activity.description"
                :max-rows="6"
                :readonly="isDescriptionReadOnly"
                trim
                :rows="3"/>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('activity.description') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['description'] != null">
            <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" xl="6" class="pr-0" v-if="isStartTimeVisible">
            <b-row class="mb-3">
              <b-col cols="12">
                <b-form-group :label="$t('activity.field.startTime')" label-for="activity-startDate">
                  <b-form-datepicker id="activity-startDate" v-model="duration.startDate" class="mb-2" @input="dateTimeInputHandler($event, 'startDate')"
                    today-button
                    reset-button
                    close-button
                    hide-header
                    :label-today-button="$t('date.today')"
                    :label-reset-button="$t('date.reset')"
                    :label-close-button="$t('date.close')"
                    today-button-variant="primary"
                    reset-button-variant="danger" 
                    close-button-variant="secondary"
                    :disabled="isStartTimeReadOnly"
                  >
                    <template v-slot:button-content="{ }">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                    </template>
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <Timepicker v-model="duration.startTime" locale="en"  @input="dateTimeInputHandler($event, 'startTime')"
                    :disableTime="isStartTimeReadOnly">
                </Timepicker>
              </b-col>
            </b-row>
          </b-col>
          <template v-if="customFieldMap['startTime'] != null">
            <b-col v-for="(field, index) in customFieldMap['startTime']" :key="'startTime'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" xl="6" class="pr-0" v-if="isCloseTimeVisible">
            <b-row class="mb-3">
              <b-col cols="12">
                <b-form-group :label="$t('activity.field.closeTime')" label-for="activity-closeDate">
                  <b-form-datepicker id="activity-closeDate" v-model="duration.closeDate" class="mb-2" @input="dateTimeInputHandler($event, 'closeDate')"
                    today-button
                    reset-button
                    close-button
                    hide-header
                    :label-today-button="$t('date.today')"
                    :label-reset-button="$t('date.reset')"
                    :label-close-button="$t('date.close')"
                    today-button-variant="primary"
                    reset-button-variant="danger" 
                    close-button-variant="secondary"
                    :disabled="isCloseTimeReadOnly"
                  >
                    <template v-slot:button-content="{ }">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                    </template>
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <Timepicker v-model="duration.closeTime" @input="dateTimeInputHandler($event, 'closeTime')" 
                    :disableTime="isCloseTimeReadOnly">
                </Timepicker>
              </b-col>
            </b-row>
          </b-col>
          <template v-if="customFieldMap['closeTime'] != null">
            <b-col v-for="(field, index) in customFieldMap['closeTime']" :key="'closeTime'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
    
          <b-col v-if="isFixedDurationVisible" cols="12" md="6" class="pr-0">
            <b-form-group :label="$t('task.field.fixedDuration')" label-for="task-fixed-duration">
              <b-input-group>
                <b-input-group-prepend v-if="!isFixedDurationReadOnly">
                  <b-button id="FIXED_DURATION_SUBTRACT" @click.prevent="fixedDurationAddMinus(-1)" :disabled="disableFixedDuration">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="FIXED_DURATION_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.duration_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="task-fixed-duration" type="text"
                  @keydown="onFixedDurationKeyDown($event)" 
                  @keyup="onFixedDurationKeyUp($event)"
                  @change="onFixedDurationChange"
                  :disabled="disableFixedDuration"
                  :data-vv-as="$t('task.field.fixedDuration')"
                  data-vv-name="activity.fixedDuration"
                  :class="fixedDurationClass"
                  data-vv-delay="500"
                  v-model="activity.fixedDuration" 
                  :readonly="isFixedDurationReadOnly"
                  >
                </b-form-input>
                <b-input-group-append v-if="!isFixedDurationReadOnly">
                  <b-button id="FIXED_DURATION_ADD" @click.prevent="fixedDurationAddMinus(1)" :disabled="disableFixedDuration">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="FIXED_DURATION_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.duration_add')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
                <b-input-group-append v-if="fixedDurationAlert">
                  <b-input-group-text id="fixed-duration-alert" class="fixed-duration-alert">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                  </b-input-group-text>
                  <b-popover target="fixed-duration-alert" triggers="hover" placement="topleft">
                    {{ fixedDurationAlertMsg }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFixedDurationError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fixedDuration') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['fixedDuration'] != null">
            <b-col v-for="(field, index) in customFieldMap['fixedDuration']" :key="'fixedDuration'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="isDurationVisible" cols="12" md="6" class="pr-0">
            <b-form-group :label="$t('activity.field.duration')" label-for="activity-duration">
              <b-input-group>
                <b-input-group-prepend v-if="!isDurationReadOnly">
                  <b-button id="DURATION_SUBTRACT" @click.prevent="durationAddMinus(-1)">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="DURATION_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('activity.button.duration_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="activity-duration" type="text" @change="dateTimeInputHandler($event, 'duration')"
                  @keydown="onDurationKeyDown($event)" 
                  @keyup="onDurationKeyUp($event)"
                  :data-vv-as="$t('activity.field.duration')"
                  data-vv-name="duration.value"
                  data-vv-delay="500"
                  v-model="duration.value" 
                  :readonly="isDurationReadOnly"
                  >
                </b-form-input>
                <b-input-group-append v-if="!isDurationReadOnly">
                  <b-button id="DURATION_ADD" @click.prevent="durationAddMinus(1)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="DURATION_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('activity.button.duration_add')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDurationError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duration.value') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['estimatedDuration'] != null">
            <b-col v-for="(field, index) in customFieldMap['estimatedDuration']" :key="'estimatedDuration'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" md="6" class="pr-0 mb-3 mb-md-0" v-if="isActualDurationVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.actualDuration`) }}</label>
              <b-input-group>
                <div id="taskActualDurationDiv" :disabled="!disableWorkEffort" class="actual-duration-form-control-fix">
                  <b-form-input id="task-actual-duration" type="text"
                    :disabled="isActualDurationReadOnly || disableWorkEffort"
                    :class="workEffortClass"
                    v-model="totalWorkEffort" 
                    readonly
                    @click="staffWorkEffortToggle"
                    >
                  </b-form-input>
                </div>
                <b-popover target="taskActualDurationDiv" triggers="hover" placement="top" v-if="!isActualDurationReadOnly">
                  {{ $t('task.alert.cannot_edit_work_effort') }}
                </b-popover>
                <b-input-group-append v-if="workEffortAlert">
                  <b-input-group-text id="work-effort-alert" class="work-effort-alert">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                  </b-input-group-text>
                  <b-popover target="work-effort-alert" triggers="hover" placement="topleft">
                    {{ workEffortAlertMsg }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['actualDuration'] != null">
            <b-col v-for="(field, index) in customFieldMap['actualDuration']" :key="'actualDuration'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" sm="6" v-if="isProgressVisible" class="pr-0">
            <b-form-group id="field-progress" :label="$t('task.field.progress')" label-for="task-progress">
              <b-input-group>
                <b-input-group-prepend v-if="!isProgressReadOnly">
                  <b-button id="PROGRESS_SUBTRACT" @click.prevent="progressAddMinus(-1)" :disabled="isProgressReadOnly">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="PROGRESS_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.progress_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="task-progress" v-model="activity.progress" 
                  type="text" @blur="progressFormat" 
                  :readonly="isProgressReadOnly">
                </b-form-input>
                <template v-if="!isProgressReadOnly">
                  <b-input-group-append>
                    <b-button id="PROGRESS_ADD" @click.prevent="progressAddMinus(1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="PROGRESS_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.progress_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append>
                    <span id="BTN_PROGRESS_COMPLETE">
                      <b-button @click.prevent="progressComplete()" :variant="activity.progress == 100? 'success':'secondary'">
                        <font-awesome-icon :icon="['far', 'check']"/>
                      </b-button>
                    </span>
                    <b-popover target="BTN_PROGRESS_COMPLETE" triggers="hover" placement="topleft">
                      {{ $t('task.popover.progressComplete') }}
                    </b-popover>
                  </b-input-group-append>
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['progress'] != null">
            <b-col v-for="(field, index) in customFieldMap['progress']" :key="'progress'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
         
          <b-col cols="12" sm="4" class="pr-0" v-if="isStageVisible">
            <b-form-group id="field-stage" :label="$t('task.field.stage')" label-for="task-stage">
              <b-form-select id="task-stage" v-model="stage" 
                :options="optionStage" 
                :disabled="isStageReadOnly"
                text-field="label" 
                value-field="key"/>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['stage'] != null">
            <b-col v-for="(field, index) in customFieldMap['stage']" :key="'stage'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col v-if="isColorVisible" cols="12" sm="2" class="pr-0">
            <Color v-model="activity.color" :disabled="isColorReadOnly" :update="updatedColor"/>
          </b-col>
          <template v-if="customFieldMap['color'] != null">
            <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
      
          <b-col cols="12" class="pr-0" v-if="isStaffVisible || isResourceVisible">
            <b-form-group v-if="dataMode === 'staff'">
              <label class="mr-1">{{ $t(`activity.field.staffs`) }}</label>
              <button v-if="!isStaffReadOnly" id="STAFF_ADD" class="btn-action" :disabled="isStaffReadOnly" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="STAFF_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('activity.button.staff_add')">
                </b-popover>
              </button>
            
              <BadgeGroup v-model="staffs" :readOnly="isStaffReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                    :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ': ''}${item.utilization? Math.trunc(item.utilization * 100) : 100}%)`" 
                    :variant="item.genericStaff? 'info' : staffUsage[item.name] ? 'warning' : 'primary'"
                    :errorMessage="staffUsage[item.name] || null"
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isStaffReadOnly" />
                  </template>
              </BadgeGroup>
            </b-form-group>
      
            <b-form-group v-if="dataMode === 'resource'">
              <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
              <button v-if="!isResourceReadOnly" id="RESOURCE_ADD" class="btn-action" :disabled="isResourceReadOnly" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="RESOURCE_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.resource_add')">
                </b-popover>
              </button>
              <BadgeGroup v-model="resources" :readOnly="isResourceReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                    :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isResourceReadOnly" />
                  </template>
              </BadgeGroup>
            </b-form-group>

            <template v-if="customFieldMap['staffs'] != null">
              <b-col v-for="(field, index) in customFieldMap['staffs']" :key="'staffs'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['resources'] != null">
              <b-col v-for="(field, index) in customFieldMap['resources']" :key="'resources'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-col>
          
    
          <b-col cols="12" md="6" v-if="isFixedCostNetVisible || isFixedCostVisible" class="pr-0">
            <b-form-select class="title-dropdown mb-1" v-model="fixedCostMode">
              <option v-if="isFixedCostVisible" value="gross">{{ $t('task.field.fixedCost') }}</option>
              <option v-if="isFixedCostNetVisible" value="net">{{ $t('task.field.fixedCostNet') }}</option>
            </b-form-select>
            <b-form-group>
              <b-input-group>
                <b-form-input id="cost" type="number" :min="0.00" :step="1" lazy-formatter
                  v-if="fixedCostMode === 'gross'"
                  :formatter="floatFormatter(null, 0.00)"
                  :data-vv-as="$t('task.field.fixedCost')"
                  data-vv-name="activity.fixedCost"
                  data-vv-delay="500"
                  v-model="activity.fixedCost"
                  :readonly="isFixedCostReadOnly">
                </b-form-input>
                <b-form-input id="costNet" type="number" :min="0.00" :step="1" lazy-formatter
                  v-if="fixedCostMode === 'net'"
                  :formatter="floatFormatter(null, 0.00)"
                  :data-vv-as="$t('task.field.fixedCost')"
                  data-vv-name="activity.fixedCost"
                  data-vv-delay="500"
                  v-model="activity.fixedCostNet"
                  readonly>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['fixedCost'] != null">
            <b-col v-for="(field, index) in customFieldMap['fixedCost']" :key="'fixedCost'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" md="6" v-if="isCurrencyCodeVisible" class="pr-0">
            <b-form-group :label="$t('task.field.currencyCode')">
              <b-input-group>
                <select class="custom-select" v-model="activity.currencyCode" :disabled="isCurrencyCodeReadOnly">
                  <template v-for="(opt, index) in optionCurrency">
                    <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                  </template>
                </select>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showCurrencyError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('activity.currencyCode') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['currencyCode'] != null">
            <b-col v-for="(field, index) in customFieldMap['currencyCode']" :key="'currencyCode'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col v-if="isEstimatedCostVisible || isEstimatedCostNetVisible" cols="12" md="6" class="pr-0">
            <b-form-select class="title-dropdown mb-1" v-model="estimatedCostMode">
              <option v-if="isEstimatedCostVisible" value="gross">{{ $t('task.field.estimatedCost') }}</option>
              <option v-if="isEstimatedCostNetVisible" value="net">{{ $t('task.field.estimatedCostNet') }}</option>
            </b-form-select>
            <b-form-group>
              <b-input-group>
                <b-form-input id="estimatedCost"
                  v-if="estimatedCostMode === 'gross'"
                  :value="estimatedCost"
                  readonly>
                </b-form-input>
                <b-form-input id="estimatedCostNet"
                  v-if="estimatedCostMode === 'net'"
                  :value="estimatedCostNet"
                  readonly>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['estimatedCost'] != null">
            <b-col v-for="(field, index) in customFieldMap['estimatedCost']" :key="'estimatedCost'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" md="6" v-if="isActualCostVisible || isActualCostNetVisible" class="pr-0">
            <b-form-select class="title-dropdown mb-1" v-model="actualCostMode">
              <option v-if="isActualCostVisible" value="gross">{{ $t('task.field.actualCost') }}</option>
              <option v-if="isActualCostNetVisible" value="net">{{ $t('task.field.actualCostNet') }}</option>
            </b-form-select>
            <b-form-group>
              <b-input-group>
                <b-form-input id="actualCost"
                  v-if="actualCostMode === 'gross'"
                  :value="actualCost"
                  :class="actualCostClass"
                  readonly>
                </b-form-input>
                <b-form-input id="actualCostNet"
                  v-if="actualCostMode === 'net'"
                  :value="actualCostNet"
                  :class="actualCostClass"
                  readonly>
                </b-form-input>
                <b-input-group-append v-if="actualCostAlert">
                  <b-input-group-text id="actualCostAlert" class="work-effort-alert">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                  </b-input-group-text>
                  <b-popover target="actualCostAlert" triggers="hover" placement="top">
                    {{ actualCostAlertMsg }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['actualCost'] != null">
            <b-col v-for="(field, index) in customFieldMap['actualCost']" :key="'actualCost'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" class="pr-0" v-if="isRebateVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.rebates`) }}</label>
              <button v-if="!isRebateReadOnly" id="REBATE_ADD" :disabled="isRebateReadOnly" class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="REBATE_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.rebate_add')">
                </b-popover>
              </button>
  
              <b-form-text class="rebate-total mr-1">
                {{ $t(`task.field.total_rebate`, [totalRebate]) }}
              </b-form-text>
  
              <BadgeGroup v-model="rebates" :readOnly="isRebateReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
                    :text="`${item.name} (${formatRebate(item.rebate)}%)`" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isRebateReadOnly" />
                  </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['rebates'] != null">
            <b-col v-for="(field, index) in customFieldMap['rebates']" :key="'rebates'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" class="pr-0" v-if="isNoteVisible">
            <b-form-group>
              <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['notes'] != null">
            <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="activity[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || activity.readOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="canEdit() && canAdd()" :disabled="activity.readOnly" size="sm" variant="secondary" @click="splitActivity" style="margin-right: auto">
          {{ $t('button.split_activity') }}
        </b-button>
        <template v-if="canEdit() || !exists">
          <b-button size="sm" variant="success" disabled v-if="submittedBy == 'ok'">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" @click="handleOkBtnClicked()" v-else>{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        <template v-if="canEdit() || !exists">
          <b-button size="sm" variant="success" disabled v-if="submittedBy == 'apply'">
              <b-spinner small type="grow" />{{ $t('button.saving') }}
            </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" v-else @click="handleApplyBtnClicked()">{{ $t('button.apply') }}</b-button>
        </template>
      </template>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" :entityType="historyEntityType" links="STAGE,REBATE,NOTE,STAFF" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>

    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="state.staffSelectorShow"
      :show.sync="state.staffSelectorShow" 
      nonAdmin
      @ok="staffSelectorOk"
    />

    <StaffWorkEffortModal v-if="state.staffWorkEffortShow" :childTaskEfforts="childTasksActualDuration" :workEfforts="staffs" :show.sync="state.staffWorkEffortShow" @ok="staffWorkEffortOk"/>
    <StaffUtilizationModal 
      v-if="state.staffUtilizationEditShow" 
      :show.sync="state.staffUtilizationEditShow" 
      :uuId="staffUtilizationEdit.uuId" 
      :name="staffUtilizationEdit.name" 
      :utilization="staffUtilizationEdit.utilization" 
      :unit="staffUtilizationEdit.unit" 
      :genericStaff="staffUtilizationEdit.genericStaff"
      :cData="staffUtilizationEdit.data"
      edgeName="ACTIVITY-STAFF" 
       
      @ok="staffUtilizationOk"
    />
    <template v-for="(item, index) in toConfirmStaffs">
      <StaffUtilizationModal 
        v-if="item.show" 
        :key="`staff-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmStaffs.length > 1" 
        :uuId="item.uuId" :name="item.name" 
        :utilization="item.utilization" 
        :unit="item.quantity" 
        :genericStaff="item.genericStaff" 
        :cData="staffUtilizationEdit.data"
        edgeName="ACTIVITY-STAFF" 
        @ok="toConfirmStaffOk" 
        @cancel="toConfirmStaffCancel"
      />
    </template>
    
    <!-- rebate selector -->
    <GenericSelectorModalForAdmin v-if="state.rebateSelectorShow"
      :show.sync="state.rebateSelectorShow" 
      :entityService="rebateUtil" 
      entity="REBATE"
      nonAdmin
      :preselected="rebateEdit.uuId"
      @ok="rebateSelectorOk"
    />

    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="state.resourceSelectorShow"
      :show.sync="state.resourceSelectorShow" 
      :entityService="resourceUtil"
      entity="RESOURCE"
      nonAdmin
      singleSelection
      @ok="resourceSelectorOk"
    />

    <ResourceUnitModal 
      v-if="state.resourceUnitEditShow" 
      :show.sync="state.resourceUnitEditShow" 
      :uuId="resourceUnitEdit.uuId" 
      :name="resourceUnitEdit.name" 
      :unit="resourceUnitEdit.unit" 
      :utilization="parseFloat(resourceUnitEdit.utilization)" 
      :cData="resourceUnitEdit.data"
      edgeName="ACTIVITY-RESOURCE" 
      @ok="resourceUnitOk"
    />
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal 
        v-if="item.show" 
        :key="`resource-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmResources.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="item.unit" 
        :utilization="parseFloat(item.utilization)" 
        :cData="item"
        edgeName="ACTIVITY-RESOURCE" 
        
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel"
      />
    </template>
    
    <TaskDateTimeDurationCalculation :show.sync="state.durationCalculationShow" 
      :activityName="activityName"
      :resizeMode="durationCalculation.resizeMode"
      :defaultActionForNonWorkPrompt="null"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projScheduleFromStart"
      :activityAutoScheduleMode="durationCalculation.activityAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @cancel="durationCalculationCancel"
      @skip="durationCalculationCancel"
    />

    <SplitTaskModal :show.sync="state.splitActivityModalShow"
      :title="$t('button.split_activity')"
      :calendar="calendar"
      :duration="duration"
      @success="splitActivitySuccess"
    />
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateResources } from '@/helpers/resource';
import { getCustomFieldInfo, customFieldValidate, filterCustomFields, compareKeys } from '@/helpers/custom-fields';
import { cloneDeep, debounce } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { strRandom
  , floatFormatter, incrementDuration
  , onDurationKeyDown, onDurationKeyUp 
  , loadViewProfile 
  , processCalendar
  , transformCalendar
  , processRegExp
  , maxDuratioUnit
  , costFormat
  , costFormatAdv
} from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { activityService, activityLinkStaffService, activityLinkResourceService,
         stageService, activityLinkStageService, staffService, activityLinkRebateService
} from '@/services';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { DEFAULT_CALENDAR, TRIGGERS, getEarliestOrLatestWorkHour
  , convertDurationToDisplay, convertDisplayToDuration, analyzeDurationAUM, extractDurationConversionOpts
  , toFixed
} from '@/helpers/task-duration-process';
import { removeDeniedProperties } from '@/views/management/script/common';
import currencies from '@/views/management/script/currencies';
import { updateRebates } from '@/helpers/rebate';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { rebateUtil } from '@/views/management/script/rebate';
import { resourceUtil } from '@/views/management/script/resource';
const TASK_TYPE = {
  TASK: 'Task'
  , SUMMARY_TASK: 'Project'
  , MILESTONE: 'Milestone'
}
Object.freeze(TASK_TYPE);

export default {
  name: 'ActivityModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    StaffUtilizationModal: () => import('@/components/modal/StaffUtilizationModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffWorkEffortModal: () => import('@/components/modal/StaffWorkEffortModal'),
    Timepicker: () => import('@/components/Calendar/Timepicker.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    SplitTaskModal: () => import('@/components/modal/SplitTaskModal'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    id:        { type: String,   default: `TASK_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    mode:      { type: String,   default: 'staff' }
  },
  data() {
    return {
      propShowFlipCount: 0,
      permissionName: "ACTIVITY",
      modelInfo: null,
      alertError: false,
      alertMsg: null,
      alertMsgDetails: [],
      settings: {},
      state: {
        editable:                 false,
        isSubmitting:             false,
        modalShow:                false,
        skillSelectorShow:        false,
        skillLevelEditShow:       false,
        staffSelectorShow:        false,
        staffWorkEffortShow:      false,
        resourceSelectorShow:     false,
        resourceUnitEditShow:     false,
        confirmChangeOnCompleteShow: false,
        staffUtilizationEditShow: false,
        historyShow:              false,
        durationCalculationShow:  false,
        noteShow:                 false,
        rebateSelectorShow:       false,
        splitActivityModalShow:   false
      },
      submittedBy: null,
      activity: {
        uuId:           null,
        name:           null,
        description:    null,
        duration:       null,
        identifier:     null,
        fixedDuration:  null,
        fixedCost:      null,
        fixedCostNet: null,
        currencyCode:   null,
        estimatedCost: 0.0,
        estimatedCostNet: 0.0,
        actualCost: 0.0,
        actualCostNet: 0.0,
        progress:       null,
        color:          null,
        readOnly: false   
        
      },
      stage: null,
      optionStage: [],
      stageList: [],
      childTasksActualDuration: 0,
      constraint: {
        type: null,
        date: null
      },
      optionCurrency: [],
      duration: {
        startDate: null,
        startTime: null,
        closeDate: null,
        closeTime: null,
        value: null
      },
      childStartDate: 0,
      childCloseDate: 0,

      staffs: [
        // { uuId: "1", name: "John", resourceLink: { } }
      ],
      toConfirmStaffs: [],
      staffUtilizationEdit: {
        uuId:  null,
        name:  null,
        utilization: null,
        unit: null,
        genericStaff: false
      },

      rebates: [],
      rebateEdit: {
        uuId: null,
        name: null,
        rebate: null
      },
      actualCostMode: 'gross',
      fixedCostMode: 'gross',
      estimatedCostMode: 'gross',
      
      resources: [
        // { uuId: "1", name: "Desk", unit: 1, utilization: 1.00 }
      ],
      toConfirmResources: [],
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null
      },
      
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      save_content: false,
      
      durationCalculation: {
        trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projScheduleFromStart: true
        , activityAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , skipOutOfProjectDateCheck: false
      },
      staffUsage: {},
      updatedColor: null,
      dataMode: null,
      
      customFields: [],
      customFieldMap: {},

      entityOptions: [],
      calendar: DEFAULT_CALENDAR,
      hasSplit: false,
      durationConversionOpts: {}
    }
  },
  created() {
    this.durationGroup = ['startTime','closeTime','duration']
    this.durationViewGroup = ['startTime','closeTime','estimatedDuration']
    this.getModelInfo();
    this.getEntityOptions();
    this.getDurationConversionOpts();

    this.fieldValidateUtil = fieldValidateUtil;
    this.rebateUtil = rebateUtil;
    this.resourceUtil = resourceUtil;
    this.original = {
      staffs: []
      , resources: []
      , startTime: null
      , closeTime: null
      , description: null
      , activity: null
      , stage: null
    }

    this.dataMode = this.mode;
    this.floatFormatter = floatFormatter;
    this.prevStartDateStr = null;
    this.prevStartTimeStr = null;
    this.prevCloseDateStr = null;
    this.prevCloseTimeStr = null;
    this.originNotes = [];
  },
  mounted() {

  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.rebateUtil = null;
    this.resourceUtil = null;
    this.floatFormatter = null;
    this.original = null;
    this.prevStartDateStr = null;
    this.prevStartTimeStr = null;
    this.prevCloseDateStr = null;
    this.prevCloseTimeStr = null;
    this.originNotes = null;
    this.durationGroup = null;
    this.durationVireGroup = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    isActualDurationVisible() {
      return !this.isTemplate && this.isStaffVisible && this.exists && this.canView(this.permissionName, ['actualDuration'])
    },
    isActualDurationReadOnly() {
      return this.isReadOnly || this.isDurationGroupReadOnly
    },
    disableWorkEffort() {
      return this.staffs.length == 0;
    },
    workEffortClass() {
      return { 
        colorRed: this.workEffortAlert,
        'field-readonly-no-color-filter': !this.disableWorkEffort && !this.isReadOnly
      }
    },
    fixedDurationClass() {
      return { 
        colorRed: this.fixedDurationAlert,
        'field-readonly-no-color-filter': !this.disableFixedDuration && !this.isReadOnly
      }
    },
    componentId() {
      return `ACTIVITY_FORM_${this.id}`;
    },
    isReadOnly() {
      return (!this.state.editable && this.readOnly) || this.activity.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showError() {
      return this.alertMsg != null;
    },
    isProgressVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['progress'])
      && ((!this.exists && this.canAdd(this.permissionName, ['progress'])) || this.exists)
    },
    isProgressReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['progress']))
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'activity.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'activity.description');
    },
    showDurationError() {
      return fieldValidateUtil.hasError(this.errors, 'duration.value');
    },
    exists() {
      return this.id && !this.id.startsWith('ACTIVITY_NEW_');
    },
    labelTitle() {
      return this.title? this.title : (this.exists? this.$t('activity.title_detail') : this.$t('activity.title_new'));
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    historyEntityType() {
      return "ACTIVITY";
    },
    activityName() {
      if (this.activity == null || this.activity.name == null || this.activity.name.trim().length == 0) {
        return '[unnamed]';
      }
      return this.activity.name;
    },
    disableOk() {
      return (this.original.readOnly && this.activity.readOnly) || this.state.isSubmitting || 
      ((this.dataMode === 'staff' && this.staffs.length === 0) ||
        (this.dataMode === 'resource' && this.resources.length === 0));
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    totalRebate() {
      var total = 0;
      for (const rebate of this.rebates) {
        total += rebate.rebate;
      }
      return toFixed(total*100, 2);
    },
    showCurrencyError() {
      return fieldValidateUtil.hasError(this.errors, 'activity.currencyCode');
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['identifier'])) || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['color'])) || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description'])
      && ((!this.exists && this.canAdd(this.permissionName, ['description'])) || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isDurationGroupReadOnly() {
      return this.isReadOnly ||
        (!this.canView(this.permissionName, this.durationViewGroup) 
          || (this.exists && !this.canEdit(this.permissionName, this.durationGroup)) 
          || (!this.exists && !this.canAdd(this.permissionName, this.durationGroup))
        )
    },
    showFixedDurationError() {
      return fieldValidateUtil.hasError(this.errors, 'fixedDuration');
    },
    estimatedCost() {
      return this.activity.currencyCode == null || this.activity.currencyCode.trim().length == 0? `$${costFormat(this.activity.estimatedCost)}` : costFormatAdv(this.activity.estimatedCost, this.activity.currencyCode);
    },
    estimatedCostNet() {
      return this.activity.currencyCode == null || this.activity.currencyCode.trim().length == 0? `$${costFormat(this.activity.estimatedCostNet)}` : costFormatAdv(this.activity.estimatedCostNet, this.activity.currencyCode);
    },
    actualCost() {
      return this.activity.currencyCode == null || this.activity.currencyCode.trim().length == 0? `$${costFormat(this.activity.actualCost)}` : costFormatAdv(this.activity.actualCost, this.activity.currencyCode);
    },
    actualCostNet() {
      return this.activity.currencyCode == null || this.activity.currencyCode.trim().length == 0? `$${costFormat(this.activity.actualCostNet)}` : costFormatAdv(this.activity.actualCostNet, this.activity.currencyCode);
    },
    actualCostAlert() {
      if(this.activity && this.activity.actualCost != null) {
        const fixedCost = this.activity.fixedCost > 0? this.activity.fixedCost : 0;
        if(this.activity.actualCost > fixedCost && fixedCost !== 0) {
          return true;
        } else if(this.activity.estimatedCost && this.activity.actualCost > this.activity.estimatedCost) {
          return true;
        }
      }
      return false;
    },
    actualCostAlertMsg() {
      if(this.activity && this.activity.actualCost != null) {
        const fixedCost = this.activity.fixedCost ? this.activity.fixedCost : 0;
        const isCurrencyCodeValid = this.activity.currencyCode != null && this.activity.currencyCode.trim().length > 0;
        if(this.activity.actualCost > fixedCost && fixedCost !== 0) {
          const diff = isCurrencyCodeValid? costFormatAdv(this.activity.actualCost - fixedCost, this.activity.currencyCode) : `$${costFormat(this.activity.actualCost - fixedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_fixed_cost', [diff]);
        } else if(this.activity.estimatedCost && this.activity.actualCost > this.activity.estimatedCost) {
          const diff = isCurrencyCodeValid? costFormatAdv(this.activity.actualCost - this.activity.estimatedCost, this.activity.currencyCode) :  `$${costFormat(this.activity.actualCost - this.activity.estimatedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_estimated_cost', [diff]);
        }
      }
      return '';
    },
    actualCostClass() {
      if ((this.activity.fixedCost && this.activity.actualCost > this.activity.fixedCost) ||
          (this.activity.estimatedCost < this.activity.actualCost)) {
        return 'colorRed';
      }
      return '';
    },
    fixedDurationAlertMsg() {
      if(this.activity && this.activity.fixedDuration != null) {
        const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
        const { value: fixedDuration } = convertDisplayToDuration(this.activity.fixedDuration, this.durationConversionOpts);
        const { value: actualDuration } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
        if(actualDuration > fixedDuration) {
          const diff = convertDurationToDisplay(actualDuration - fixedDuration, 'D', this.durationConversionOpts);
          return this.$t('task.alert.actual_duration_exceeds_fixed_duration', [diff]);
        } else if(dValue > fixedDuration) {
          const diff = convertDurationToDisplay(dValue - fixedDuration, 'D', this.durationConversionOpts);
          return this.$t('task.alert.estimated_duration_exceeds_fixed_duration', [diff]);
        }
      }
      return '';
    },
    fixedDurationAlert() {
      if (this.activity.fixedDuration && !['0','0Y','0M','0W','0D','0h','0m'].includes(this.activity.fixedDuration)) {
        const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
        const { value: fixedDuration } = convertDisplayToDuration(this.activity.fixedDuration, this.durationConversionOpts);
        const { value: actualDuration } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
        return fixedDuration < dValue || fixedDuration < actualDuration;
      }
      return false;
    },
    isStartTimeVisible() {
      return this.canView(this.permissionName, ['startTime']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['startTime'])) || this.exists)
    },
    isStartTimeReadOnly() {
      return this.isDurationGroupReadOnly
    },
    isCloseTimeVisible() {
      return this.canView(this.permissionName, ['closeTime']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['closeTime'])) || this.exists)
    },
    isCloseTimeReadOnly() {
      return this.isDurationGroupReadOnly
    },
    isDurationVisible() {
      return this.canView(this.permissionName, ['estimatedDuration']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['duration'])) || this.exists)
    },
    isDurationReadOnly() {
      return this.isDurationGroupReadOnly
    },
    disableFixedDuration() {
      return (this.exists && !this.canEdit(this.permissionName, ['fixedDuration']));
    },
    isFixedDurationVisible() {
      return this.canView(this.permissionName, ['fixedDuration']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedDuration'])) || this.exists)
    },
    isFixedDurationReadOnly() {
      return (!this.canView(this.permissionName, ['fixedDuration']) 
          || (this.exists && !this.canEdit(this.permissionName, ['fixedDuration'])) 
          || (!this.exists && !this.canAdd(this.permissionName, ['fixedDuration']))
          || this.activity.readOnly
        )
    },
    isStageVisible() {
      return this.canView('STAGE') && this.canView(this.permissionName, ['STAGE'])
      && ((!this.exists && this.canEdit(this.permissionName, ['STAGE'])) || this.exists)
    },
    isStageReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STAGE'])) 
      || this.optionStage == null || this.optionStage.length == 0
    },
    isStaffVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('STAFF') && this.canView(this.permissionName, ['STAFF']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STAFF'])) || this.exists)
    },
    isStaffReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['STAFF'])
    },
    isResourceVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('RESOURCE') && this.canView(this.permissionName, ['RESOURCE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['RESOURCE'])) || this.exists)
    },
    isResourceReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['RESOURCE'])
    },
    isFixedCostVisible() {
      return this.canView(this.permissionName, ['fixedCost'])
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedCost'])) || this.exists)
    },
    isFixedCostNetVisible() {
      return this.canView(this.permissionName, ['fixedCostNet'])
      && this.exists
    },
    isFixedCostReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['fixedCost']))
    },
    isCurrencyCodeVisible() {
      return this.canView(this.permissionName, ['currencyCode'])
      && ((!this.exists && this.canAdd(this.permissionName, ['currencyCode'])) || this.exists)
    },
    isCurrencyCodeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['currency']))
    },
    isEstimatedCostVisible() {
      return this.canView(this.permissionName, ['estimatedCost'])
      && this.exists
    },
    isEstimatedCostNetVisible() {
      return this.canView(this.permissionName, ['estimatedCostNet'])
      && this.exists
    },
    isActualCostVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['actualCost'])
      && this.exists
    },
    isActualCostNetVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['actualCostNet'])
      && this.exists
    },
    isRebateVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('REBATE') && this.canView(this.permissionName, ['REBATE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['REBATE'])) || this.exists)
    },
    isRebateReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['REBATE'])
    },
    totalWorkEffort() {
      let total = 0;
      let unit = 'D';
      for(const item of this.staffs) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total + this.childTasksActualDuration, unit, this.durationConversionOpts);
    },
    workEffortAlertMsg() {
      return this.$t('task.alert.work_effort_exceeds_estimated_duration');
    },
    workEffortAlert() {
      const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
      const { value: tWorkEffort } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
      return tWorkEffort > dValue;
    },
  },
  watch: {
    async show(newValue) {
      this.propShowFlipCount++;
      if(newValue != this.state.modalShow) {
        if (newValue) {
          await getCustomFieldInfo(this, 'ACTIVITY');
          if (this.customFields.length == 0) {
            this.customFieldMap = {};
          } else {
            this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
          }
        }
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.state.noteShow = false;
        this.originNotes = [];
        this.notes = [];
        this.alertMsg = null;
        this.alertError = false;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.submittedBy = null;
        this.resetProperties();
        this.settings = await loadViewProfile(this.settings, this.$store.state.authentication.user.uuId);
        this.actualCostMode = this.isActualCostVisible? 'gross' : 'net';
        this.fixedCostMode = this.isFixedCostVisible? 'gross' : 'net';
        this.estimatedCostMode = this.isEstimatedCostVisible? 'gross' : 'net';
        
   
        const listParams = { start: 0, limit: -1, ksort: 'name' }
        const canViewTag = this.canView('TAG') && this.canView('STAGE', ['TAG']);
        if (canViewTag) {
          listParams.tagFilter = [['STAGE.TAG.name', 'is', 'ACTIVITY']]
        }
        const list = await stageService.list(JSON.parse(JSON.stringify(listParams))).then((response) => {
          if (response.arg_total == 0 && canViewTag && this.entityOptions.length > 0) {
            const excludedEntities = []
            for (const entity of this.entityOptions) {
              excludedEntities.push(['STAGE.TAG.name', 'is', entity.value]);
            }
            listParams.tagFilter = ['_not_', ['_or_', excludedEntities]]
            return stageService.list(listParams).then(response2 => {
              return response2.data;
            })
            .catch(() => {
              return [];
            });
          } else {
            return response.data;
          }
        }).catch(() => {
          return [];
        });
        this.stageList.splice(0, this.stageList.length, ...list);
        this.populateActivityStage();
        
        this.dataMode = this.mode;
        if(this.exists) {
          //Using setTimeout() to prevent /api/activity/get request call from being stalled. (Haven't figured out why the stalling happened)
          setTimeout(() => {
            this.activityGet(this.id);
          }, 1000);
          
        } else {
          
          //Default startDate, startTime and duration are set. Call initDurationCalculation() to get closeDate and closeTime.
          this.initDurationCalculation({ trigger: TRIGGERS.START_DATE });
        }
      }
    },
    staffs(newValue) {
      //Fix the glitch of popover stuck in displaying state when disabling the popover during displaying state.
      if (newValue != null && newValue.length > 0) {
        this.$root.$emit('bv::hide::popover', 'activityActualDurationDiv')
      }
    }
  },
  methods: {
    staffWorkEffortToggle() {
      if (this.canView(this.permissionName, ['STAFF.duration', 'STAFF.durationAUM'])) {
        this.state.staffWorkEffortShow = true;
      }
    },
    staffWorkEffortOk(workEfforts) {
      for(const workEffort of workEfforts) {
        const index = this.staffs.findIndex(i => i.uuId === workEffort.uuId);
        if(index > -1) {
          this.$set(this.staffs[index], 'duration', workEffort.duration);
          this.$set(this.staffs[index], 'durationAUM', workEffort.durationAUM);
        }
      }
    },
    getTodayLocalDate() {
      //Get the local date time literally and set it as UTC time. (Specification given by Paul)
      const localDateTime = new Date();
      const localYear = localDateTime.getFullYear();
      const localMonth = localDateTime.getMonth() + 1; //Provided month is 0-index based. Add 1 to get actual month value.
      const localDate = localDateTime.getDate();
      const localHour = localDateTime.getHours();
      const localMinutes = localDateTime.getMinutes();
      const localDateInStr = `${localYear}-${localMonth < 10? '0' + localMonth: localMonth}-${localDate < 10? '0' + localDate: localDate}`;
      const localTimeInStr = `${localHour < 10? '0' + localHour : localHour}:${localMinutes < 10? '0' + localMinutes: localMinutes }:00`;
      return { dateStr: localDateInStr, timeStr: localTimeInStr };
    },
    formatRebate(rebate) {
      return toFixed(rebate*100, 2);
    },
    getTodayWeekDay(momentDate=null) {
      const targetLocale = moment.locale();
      moment.locale('en');
      const weekday = momentDate != null? momentDate.locale('en').format('dddd') : moment.utc().locale('en').format('dddd');
      moment.locale(targetLocale);
      return weekday;
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "TASK"}).then(value => {
        self.modelInfo = value.TASK.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    durationChanged() {
      let originDuration = this.original.duration;
      const duration = this.duration;
      
      //Fixed a false dialog prompt due to null origin duration.
      if (originDuration == null && duration != null) {
        originDuration = {}
        for (const prop in duration) {
          originDuration[prop] = null;
        }
      }

      if(originDuration.startDate != duration.startDate ||
          !isTimeEqual(originDuration.startTime, duration.startTime) ||
          originDuration.closeDate != duration.closeDate ||
          !isTimeEqual(originDuration.closeTime, duration.closeTime)) {
        return true;
      }
      return false;

      function isTimeEqual(time1, time2) {
        const t1 = time1? time1.length > 5? time1.substring(0, 5): time1 : '';
        const t2 = time2? time2.length > 5? time2.substring(0, 5): time2 : '';
        return t1 === t2;
      }
    },
    activityGet(id) {
      const service = activityService;
      this.getEnumList();
      service.get([{ uuId: id}], ['TASK', 'STAGE', 'SKILL', 'STAFF',
        'REBATE', 'RESOURCE', 'STORAGE_FILE', 'NOTE', 'TAG']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    dateTimeInputHandler: debounce(function(newValue, trigger) {
      let payload = {
        trigger: trigger != null? trigger : TRIGGERS.duration,
        resizeMode: false
      }
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.START_TIME == trigger) {
        payload.oldDateStr = this.prevStartDateStr;
        payload.oldTimeStr = this.prevStartTimeStr;
      } else if (TRIGGERS.CLOSE_DATE == trigger || TRIGGERS.CLOSE_TIME == trigger) {
        payload.oldDateStr = this.prevCloseDateStr;
        payload.oldTimeStr = this.prevCloseTimeStr;
        payload.resizeMode = true;
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        payload.oldConstraintType = this.prevConstraintType;
        payload.oldConstraintDateStr = this.prevConstraintDateStr;
      } else {
        payload.oldDateStr = null;
        payload.oldTimeStr = null;
      }
      this.initDurationCalculation(payload);

      //Update oldValue;
      if (TRIGGERS.START_DATE == trigger) {
        this.prevStartDateStr = newValue;
      } else if (TRIGGERS.START_TIME == trigger) {
         this.prevStartTimeStr = newValue;
      } else if (TRIGGERS.CLOSE_DATE == trigger) {
        this.prevCloseDateStr = newValue;
      } else if (TRIGGERS.CLOSE_TIME == trigger) {
        this.prevCloseTimeStr = newValue;
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger) {
        this.prevConstraintType = newValue;
      } else if (TRIGGERS.CONSTRAINT_DATE == trigger) {
        this.prevConstraintDateStr = newValue;
      }
    }, 300),
    digestResponse(data) {
      const t = this.activity;
      for (const key of Object.keys(t)) {
        if(data[key]) {
          t[key] = data[key]
        }
      }
      
      if (t.progress === null) {
        t.progress = 0;  // set progress to 0 if it is null so it does not appear blank
      }
      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          t[field.name] = data[field.name];
        }
      }
      
      this.original.description = data.description;

      //Handling edge case when the activity has no valid startTime or closeTime
      if(data.startTime == 0 || data.startTime === 32400000)  {
        data.startTime = null;
      } 
      //8640000000000000 is maximumn value of date. Larger than that is considered invalid.
      if(data.closeTime >= 32503680000000)  {
        data.closeTime = null;
      }

      if (data.duration) {
        this.$set(this.duration, 'value', convertDurationToDisplay(data.duration, 'D', this.durationConversionOpts));
      }
      
      if (data.stage) {
        this.stage = data.stage.uuId;
        this.original.stage = data.stage.uuId;
      }
      
      if (data.startTime) {
        const d = moment(data.startTime).utc();
        this.$set(this.duration, 'startDate', d.format('YYYY-MM-DD'));
        this.$set(this.duration, 'startTime', d.format('HH:mm'));
      } else {
        this.$set(this.duration, 'startDate', null);
        this.$set(this.duration, 'startTime', null);
      }
      if (data.closeTime) {
        const d = moment(data.closeTime).utc();
        this.$set(this.duration, 'closeDate', d.format('YYYY-MM-DD'));
        this.$set(this.duration, 'closeTime', d.format('HH:mm'));
      } else {
        this.$set(this.duration, 'closeDate', null);
        this.$set(this.duration, 'closeTime', null);
      }

      this.prevStartDateStr = this.duration.startDate;
      this.prevStartTimeStr = this.duration.startTime;
      this.prevCloseDateStr = this.duration.closeDate;
      this.prevCloseTimeStr = this.duration.closeTime;

      this.original.startTime = this.constructDateTimeStr(this.duration.startDate, this.duration.startTime);
      this.original.closeTime = this.constructDateTimeStr(this.duration.closeDate, this.duration.closeTime);

      if (data.duration != null) {
        this.$set(this.original, 'duration', cloneDeep(this.duration));
      }

      this.original.fixedDuration = data.fixedDuration;
      this.original.progress = data.progress * 100;

      if (data.progress != null) {
        t.progress = Math.round(data.progress * 100);
      }
      
      if (data.fixedDuration !== null) {
        t.fixedDuration = convertDurationToDisplay(data.fixedDuration, data.durationAUM, this.durationConversionOpts);
      }
      
      //Setup Staff List data
      if (data.staffList && data.staffList.length > 0) {
        this.dataMode = 'staff';
        const list = data.staffList.map(i => { 
          let name = null;
          if(i.firstName && i.lastName) {
            name = `${i.firstName} ${i.lastName}`;
          } else if(i.firstName == null && i.lastName) {
            name = i.lastName;
          } else if (i.firstName && i.lastName == null) {
            name = i.firstName;
          } else {
            name = 'n/a';
          }
          return { 
            uuId: i.uuId, 
            name,
            utilization: i.resourceLink.utilization,
            quantity: i.resourceLink.quantity,
            duration: i.resourceLink.duration,
            durationAUM: i.resourceLink.durationAUM,
            genericStaff: i.genericStaff? i.genericStaff : false,
            ...filterCustomFields(i.resourceLink, ['uuId'])
          }
        });
        this.original.staffs.splice(0, this.original.staffs.length, ...list);
        this.staffs.splice(0, this.staffs.length, ...cloneDeep(list));
      }


      // Setup rebates data
      if (data.rebateList && data.rebateList.length > 0) {
        this.rebates = cloneDeep(data.rebateList);
        this.original.rebates.splice(0, this.original.rebates.length, ...data.rebateList);
      }
      
      //Setup Resource List data
      if (data.resourceList && data.resourceList.length > 0) {
        this.dataMode = 'resource';
        const list = data.resourceList.map(i => { 
         
          return { 
            uuId: i.uuId, 
            name: i.name,
            quantity: i.resourceLink.quantity,
            utilization: i.resourceLink.utilization,
            ...filterCustomFields(i.resourceLink, ['uuId'])
          }
        });
        this.original.resources.splice(0, this.original.resources.length, ...list);
        this.resources.splice(0, this.resources.length, ...cloneDeep(list));
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }

      this.original.activity = {
        uuId: t.uuId
        , name: t.name
        , description: t.description
        , duration: t.duration
        , startTime: moment.utc(this.original.startTime, 'YYYY-MM-DDTHH:mm:ss').valueOf()
        , closeTime: moment.utc(this.original.closeTime, 'YYYY-MM-DDTHH:mm:ss').valueOf()
        , identifier: t.identifier
        , color: t.color
      };
    },
    changeOnCompleteCancel() {
      this.save_content = false;
      this.state.confirmChangeOnCompleteShow = false;
    },
    preOk(isSaveContent = false, callback=null) {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.activity[field.name])) {
          field.showError = true;
          this.state.isSubmitting = false;
          this.submittedBy = null;
          return;  
        }
      }
      
      /** Specification by Paul: When a activity is complete, user makes changes to the following properties:
       *  [start date, end date, duration, work effort, skills, staff, resources, constraints and progress]
       *  , we should prompt the user "This activity has been already completed.  Proceed with changes?" Cancel/Confirm."
       */
      this.save_content = isSaveContent;
      const original = this.original;
      //If original progress is 100 and there is change which will affect activity schedule, show confirmation dialog.
      if(original.progress == 100) {
        const curStartDateTime  = this.constructDateTimeStr(this.duration.startDate, this.duration.startTime);
        if(original.startTime !== curStartDateTime) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const curCloseDateTime  = this.constructDateTimeStr(this.duration.closeDate, this.duration.closeTime);
        if(original.closeTime !== curCloseDateTime) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        if(original.duration !== null && original.duration.value !== this.duration.value) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        
        const staffChanged = ((originStaffs, staffs) => {
          let isChanged = false;
          for(let i = 0, len = staffs.length; i < len; i++) {
            const staff = staffs[i];
            const index = originStaffs.findIndex(j => j.uuId === staff.uuId);
            if(index == -1) {
              isChanged = true;
            } else {
              const originStaff = originStaffs[index];
              if(originStaff.duration !== staff.duration || originStaff.utilization !== staff.utilization) {
                isChanged = true;
              }
            }
            if(isChanged) {
              break;
            }
          }
          return isChanged;
        })(this.original.staffs, cloneDeep(this.staffs));
        if(staffChanged) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const resourceChanged = ((originResources, resources) => {
          let isChanged = false;
          for(let i = 0, len = resources.length; i < len; i++) {
            const resource = resources[i];
            const index = originResources.findIndex(j => j.uuId === resource.uuId);
            if(index == -1) {
              isChanged = true;
            } else {
              const originResource = originResources[index];
              if(originResource.quantity != resource.quantity ||
                 originResource.utilization !== resource.utilization) {
                isChanged = true;
              }
            }
            if(isChanged) {
              break;
            }
          }
          return isChanged;
        })(this.original.resources, cloneDeep(this.resources));
        if(resourceChanged) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
      }
      //Safe to proceed to save activity.
      this.ok(callback);
    },
    ok(callback=null) {
      if(this.state.alertTaskChangedShow) {
        this.state.alertTaskChangedShow = false;
      }
      if(this.state.confirmChangeOnCompleteShow) {
        this.state.confirmChangeOnCompleteShow = false;
      }
      
      if (this.totalRebate > 100) {
        this.alertMsg = this.$t('rebate.error.total');
        this.alertError = true;
        this.state.isSubmitting = false;
        this.submittedBy = null;
        return;
      }
      
      //Apply $nextTick() here to fix an issue caused by a change in duration field is not reflected when user click 'save' button directly right after changing the value in the textbox. 
      //The change event is not triggered. $nextTick() waits for the change event to happen before proceed to activity saving process.
      this.$nextTick(() => {
        this.errors.clear();
        this.alertMsg = null;
        this.alertError = false;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.validateCurrencyCode();
        if(this.dataMode == 'staff' && this.staffs.length === 0) {
          this.errors.add({
            field: `staffs`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('activity.field.staffs')])
          });
        }

        if(this.dataMode == 'resource' && this.resources.length === 0) {
          this.errors.add({
            field: `resources`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.field.resources')])
          });
        }

        this.$validator.validate().then(valid => {
          if (valid && this.errors.items.length < 1) {
            this.activitySubmit(callback);
          } else {
            this.alertMsg = this.$t('error.attention_required');
            this.alertError = true;
            this.submittedBy = null;
            this.scrollToTop();
          }
        });
      });
    },
    populateActivityStage() {
      let options = [{key: null, label: ""}]; // First item is blank
      if (this.stageList) {
        this.stageList.forEach(stage => {
          options.push({key: stage.uuId, label: stage.name});
        });
        this.optionStage.splice(0, this.optionStage.length, ...options); 
      }
    },
    async activitySubmit(callback=null) {
      const data = cloneDeep(this.activity);
      
      delete data.estimatedDuration;
      delete data.fixedCostNet;
      delete data.actualCostNet;
      delete data.actualCost;
      delete data.estimatedCostNet;
      delete data.estimatedCost;
            
      if(data.progress != null) {
        if (isNaN(data.progress)) {
          data.progress = 0;
        } else {
          data.progress = toFixed(data.progress / 100, 2);
        }
        
      }
      
      if (data.fixedDuration !== null) {
        const { unit } = analyzeDurationAUM(data.fixedDuration);
        data.durationAUM = unit;
        const { value } = convertDisplayToDuration(data.fixedDuration, this.durationConversionOpts);
        data.fixedDuration = value;
      }
          
      if (data.fixedCost === '') {
        this.activity.fixedCost = data.fixedCost = '0'; // set a zero value so that it updates
      }
      
      let sDate = this.duration.startDate != null && this.duration.startDate != ''? this.duration.startDate : null;
      if (sDate != null) {
        const sDateTime = moment.utc(sDate, 'YYYY-MM-DD');
        let sTime = this.duration.startTime;
        if (sTime == null || sTime.trim().length == 0) {
          sTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(sDate, 'YYYY-MM-DD'), { isLatest: false, ignoreLeave: false });
        }
        if (sTime != null) {
          const token = sTime.split(':');
          sDateTime.hour(token[0]).minute(token[1]);
        }
        data.startTime = sDateTime.valueOf();
      } else {
        data.startTime = null;
      }

      let cDate = this.duration.closeDate != null && this.duration.closeDate != ''? this.duration.closeDate : null;
      if (cDate != null) {
         const cDateTime = moment.utc(cDate, 'YYYY-MM-DD');
        let cTime = this.duration.closeTime;
        if (cTime == null || cTime.trim().length == 0) {
          cTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(cDate, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
        }
        if (cTime != null) {
          const token = cTime.split(':');
          cDateTime.hour(token[0]).minute(token[1]);
        }
        data.closeTime = cDateTime.valueOf();
      } else {
        data.closeTime = null;
      }

      const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
      data.duration = dValue;
      
      let method = 'update';
      if(!this.exists) {
        delete data['uuId'];
        method = 'create';
      }
      this.activityPost(method, data, this.projectId, callback);
    },
    async activityPost(method, data, projectId, callback=null) {
      this.state.isSubmitting = true;
      let activityId = null;
      
      //Skip updating activity if there is no change in activity properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedTaskProperties(data);
      }

      if (method == 'create' || hasChanged) {
        const result = await this.updateTask(method, data, projectId);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.alertError = true;
          this.state.isSubmitting = false;
          this.submittedBy = null;
          return;
        }
        activityId = result.activityId;
      } else {
        activityId = data.uuId;
      }

      let hasError = false;
      
      // save the color in the profile
      this.updatedColor = data.color;
      
      if(this.dataMode === 'staff' && !this.isStaffReadOnly) {
        const staffResult = await this.updateStaffs(activityId);
        if (staffResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`activity.${method}_partial`);
          if (staffResult.errorCodes.length > 0) {
            if (staffResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('staff.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('staff.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('staff.title').toLowerCase()]))
          }
        }
      }

      if (!this.isRebateReadOnly) {
        const rebateResult = await updateRebates(activityId, activityLinkRebateService, this.original.rebates, this.rebates)
        if (rebateResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (rebateResult.errorCodes.length > 0) {
            if (rebateResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
          }
        }
      }      

      if (!this.isStageReadOnly) {
        const stageResult = await this.updateStage(activityId);
        if (stageResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`activity.${method}_partial`);
          if (stageResult.errorCodes.length > 0) {
            if (stageResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('stage.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('stage.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('stage.title').toLowerCase()]))
          }
        }
      }
      
      if (this.dataMode === 'resource'  && !this.isResourceReadOnly) {
        const resourceResult = await updateResources(activityId, activityLinkResourceService, this.original.resources, this.resources)
        if (resourceResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`activity.${method}_partial`);
          if (resourceResult.errorCodes.length > 0) {
            if (resourceResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('resource.title_selector').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
          }
        }
      }
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(activityId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`activity.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      
      const stageName = this.optionStage.find(s => s.key === this.stage);
      this.state.isSubmitting = false;
      this.submittedBy = null;
      if(hasError) {
        this.alertError = true;
        this.scrollToTop();
      } else if (this.save_content) {
        this.save_content = false;
        this.$emit('success', { 
          id: activityId, 
          msg: this.$t(`activity.${method}`), 
          imageChanged: this.original.activity == null || (this.original.activity.avatarRef !== data.avatarRef), 
          data: { ...this.activity, duration: this.duration, rebates: this.rebates }, 
          type: 'activity',
          staff: this.staffs,
          origStaff: this.original.staffs,
          stage: stageName ? stageName.label : null,
          hasSplit: this.hasSplit
        });
        this.$emit('update:id', activityId);
        this.alertError = false;
        this.alertMsg = this.$t(`activity.${method}`);
        if (callback != null && typeof callback == 'function') {
          callback();
        }
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { 
          id: activityId, 
          msg: this.$t(`activity.${method}`), 
          imageChanged: this.original.activity == null || (this.original.activity.avatarRef !== data.avatarRef), 
          data: { ...this.activity, duration: this.duration, rebates: this.rebates }, 
          type: 'activity',
          staff: this.staffs,
          origStaff: this.original.staffs,
          stage: stageName ? stageName.label : null,
          hasSplit: this.hasSplit
        });
      }
    },
    async updateTask(method, data, projectId) {
      const result = {
        hasError: false,
        msg: this.$t(`activity.${method}`)
      }
      
      const service = activityService;

      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      let activityId = await service[method]([data], projectId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      })
      .catch(e => {
        result.hasError = true;
        const response = e.response;
        let errorMsg = this.$t('error.internal_server');
        if (response && 403 === response.status) {
          errorMsg = this.$t('error.authorize_action');
        } else if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 
              'string_limit_exceeded', 'number_limit_exceeded',
              'date_limit_exceeded', 'day_limit_exceeded',
              'duration_limit_exceeded'].includes(clue)) {
            errorMsg = this.$t('error.attention_required');
            const fieldKey = `activity.${feedback.args[0]}`;
            const args = [this.$t(`activity.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
                break;
              case 'date_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('YYYY-MM-DD'));
                break;
              case 'day_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('HH:mm'));
                break;
              case 'duration_limit_exceeded':
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
        result.msg = errorMsg;
      });
      result.activityId = activityId;
      return result;
    },
    httpAjaxError(e) {
      console.log(e); // eslint-disable-line no-console
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback !== null && feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.errors.add({
            field: `activity.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
          this.alertError = true;
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
        this.alertError = false;
    },
    resetProperties() {
      const keys = Object.keys(this.activity);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId' || keys[i] === 'parent') {
          continue;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.activity[keys[i]] = customField.def;
            continue;
          }
        }
        this.activity[keys[i]] = null;
      }
      
      this.original.description = null;
      this.original.duration = null;
      
      this.original.staffs = [];
      this.staffs = [];
      this.toConfirmStaffs = [];
      this.staffUtilizationEdit.uuId = null;
      this.staffUtilizationEdit.name = null;
      this.staffUtilizationEdit.utilization = null;
      this.staffUtilizationEdit.unit = null;
      this.staffUtilizationEdit.genericStaff = false;

      this.original.rebates = [];
      this.rebates = [];

      this.resources = [];
      this.original.resources = [];
      
      this.duration.closeDate = null;
      this.duration.closeTime = null;

      this.original.startTime = null;
      this.original.closeTime = null;
      
      this.original.stage = null;
      this.stage = null;
      this.original.activity = null;

      const newStartTimeObj = this.getSuggestedStartTimeObj();
      this.$set(this.duration,'startDate', newStartTimeObj.dateStr); 
      this.$set(this.duration,'startTime', newStartTimeObj.timeStr);
      this.$set(this.duration, 'value', null);
    },
    onDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onDurationKeyUp(event) {
      this.duration.value = onDurationKeyUp(event, this.duration.value);
    },
    onFixedDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onFixedDurationKeyUp(event) {
      this.activity.fixedDuration = onDurationKeyUp(event, this.activity.fixedDuration);
    },
    durationAddMinus(delta) {
      this.duration.value = incrementDuration(this.duration.value != null? this.duration.value : '0D', delta);
      this.initDurationCalculation();
    },
    fixedDurationAddMinus(delta) {
      this.activity.fixedDuration = incrementDuration(this.activity.fixedDuration ? this.activity.fixedDuration : '0D', delta);
    },
    calcDurationWhenEtocChanged(newEtoc) {
      const etocValue = newEtoc != null? convertDisplayToDuration(newEtoc, this.durationConversionOpts).value : 0;
      const totalWorkEffort = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts).value;
      this.duration.value = convertDurationToDisplay(etocValue + totalWorkEffort, 'D', this.durationConversionOpts);
      this.initDurationCalculation();
    },
    getSuggestedStartTimeObj() {
      const returnObj = { 
        dateStr: null
        , timeStr: null
        , dateTimeStr: null
      }
      //Get the local date time literally and set it as UTC time. (Specification given by Paul)
      const { dateStr, timeStr } = this.getTodayLocalDate();
      returnObj.dateStr = dateStr;
      const todayWeekDay = this.getTodayWeekDay(moment.utc(dateStr, 'YYYY-MM-DD'));
      //Set the earliest work hour for a new activity's start time.
      const cal = this.calendar;
      if(cal != null && cal[todayWeekDay] != null && cal[todayWeekDay].length > 0) {
        const weekDayHours = cal[todayWeekDay];
        const earliestHour = weekDayHours.reduce((earliestHour, currentValue) => {
          if(earliestHour == null || earliestHour.trim().length < 5) {
            return currentValue.startHour;
          }
          if(earliestHour > currentValue.startHour) {
            return currentValue.startHour;
          } else {
            return earliestHour;
          }
        }, null);
        
        returnObj.timeStr = earliestHour;
        returnObj.dateTimeStr = this.constructDateTimeStr(dateStr, earliestHour)
      } else {
        returnObj.timeStr = timeStr;
        returnObj.dateTimeStr = this.constructDateTimeStr(dateStr, timeStr)
      }
      return returnObj;
    },
    progressFormat() {
      if(isNaN(this.activity.progress)) {
        this.activity.progress = 0;
      }
      const value = parseInt(this.activity.progress);
      if(value < 0) {
        this.activity.progress = 0;
      } else if(value > 100) {
        this.activity.progress = 100;
      } else {
        this.activity.progress = value;
      }
    },
    staffBadgeRemove: function(index) {
      this.staffs.splice(index,1)
    },
    staffBadgeClick(id) {
      const selected = this.staffs.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      edit.unit = selected.quantity;
      edit.genericStaff = selected.genericStaff;
      edit.data = selected;
      this.state.staffUtilizationEditShow = true;
    },
    staffSelectorToggle() {
      this.state.staffSelectorShow = true;
    },
    staffSelectorOk({ details }) {
      const newStaffs = details.map(i => { 
        return { 
          uuId: i.uuId
          , name: i.name
          , genericStaff: i.genericStaff 
        }
      });
      
      this.toConfirmStaffs.splice(0, this.toConfirmStaffs.length, ...newStaffs);
      for(let i = this.toConfirmStaffs.length-1; i > -1; i--) {
        this.toConfirmStaffs[i].toBeShown = true;
      }
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].toBeShown = false;
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].show = true;
    },
    staffUtilizationOk({ id, name, utilization, unit, oldId, genericStaff, customFields, customData }) {
      const oldIdIndex = oldId? this.staffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.staffs[oldIdIndex];
      } else {
        selected = this.staffs.find(i => i.uuId === id);
      }
      if(selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.utilization = utilization;
        selected.quantity = unit;
        for (const field of customFields) {
          selected[field.name] = customData[field.name];
        }
      } else {
        const staff = {
          uuId: id,
          name: name,
          utilization: utilization,
          quantity: unit,
          genericStaff: !!genericStaff
        };
        
        for (const field of customFields) {
          staff[field.name] = customData[field.name];
        }
        this.staffs.push(staff);
      }
    },
    toConfirmStaffOk({ id, name, utilization, unit, oldId, applyToAll, genericStaff, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmStaffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.toConfirmStaffs[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmStaffs.find(i => i.uuId === id);
      }
      
      selected.utilization = utilization;
      selected.quantity = unit;
      selected.customFields = customFields;
      selected.customData = customData;
      
      if(genericStaff != undefined) {
        selected.genericStaff = genericStaff;
      }
      const toBeShown = this.toConfirmStaffs.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmStaffCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.utilization = utilization;
          entry.quantity = unit;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmStaffCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmStaffCancel() {
      this.toConfirmStaffCommit();
    },
    toConfirmStaffCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmStaffs.length; i < len; i ++) {
        this.toConfirmStaffs[i].show = false;
      }
      const confirmedStaffs = this.toConfirmStaffs.filter(i => i.utilization != null);
      const ids = confirmedStaffs.map(i => i.uuId);
      const currentIds = this.staffs.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newStaffs = confirmedStaffs.filter(i => !currentIds.includes(i.uuId));
      
      let staff = null;
      for(let i = 0, len = this.staffs.length; i < len; i++) {
        staff = this.staffs[i];
        if(duplicatedIds.includes(staff.uuId)) {
          const found = confirmedStaffs.find(i => i.uuId === staff.uuId);
          if (found) {
            staff.utilization = found.utilization;
            staff.quantity = found.quantity;
            for (const field of found.customFields) {
              staff[field.name] = found.customData[field.name];
            }
          }
          
        }
      }

      let duration = 0;
      newStaffs.forEach(i => {
        if (i.duration > duration) {
          duration = i.duration;
        }
        const staff = {uuId: i.uuId, name: i.name, utilization: i.utilization, quantity: i.quantity, genericStaff: !!i.genericStaff};
        for (const field of i.customFields) {
          staff[field.name] = i.customData[field.name];
        }
        this.staffs.push( staff );
      });
      
      if (duration !== 0) {
        this.duration.value = `${Math.round(duration / 8 / 60)}D`;
        this.initDurationCalculation({});
      }
    },
    async updateStaffs(activityId) {
      const service = activityLinkStaffService;
      const result = {
        hasError: false,
        errorCodes: []
      }
      const staffs = cloneDeep(this.staffs);
      const originStaffs = this.original.staffs;
      const toAdd = [];
      const toUpdate = [];
      const unchangedIds = [];
      for(let i = 0, len = staffs.length; i < len; i++) {
        const staff = staffs[i];
        const index = originStaffs.findIndex(j => j.uuId === staff.uuId);
        if(index == -1) {
          toAdd.push(staff);
        } else {
          const originStaff = originStaffs[index];
          if(compareKeys(staff, originStaff)) {
            toUpdate.push(staff);
          } else {
            unchangedIds.push(staff.uuId);
          }
        }
      }

      const toAddIds = toAdd.map(i => i.uuId);
      const toUpdateIds = toUpdate.map(i => i.uuId);
      const toRemove = originStaffs.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
      
      //To add new staffs if there is any.
      if(toAdd.length > 0) {
        let { needUpdate=[], hasError=false, errorCode=null } = await service.create(activityId, toAdd)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            return { needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), hasError: failIds.length > 0, errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            return { needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)).length > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        //Collect those staffs require Update (instead of add) and add them to toUpdate list.
        toUpdate.splice(toUpdate.length, 0, ...needUpdate);
      }

      //To update existing staffs if there is any.
      if(toUpdate.length > 0) {
        let{ needAdd=[], hasError=false, errorCode=null } = await service.update(activityId, toUpdate)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
            const failIds = list.filter(i => i.clue !== 'Unknown_relation' && i.clue !== 'OK').map(i => i.args[0]);
            return { needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), hasError: failIds.length > 0, errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
            return { needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), hasError: toUpdate.filter(i => !toAddIds.includes(i.uuId)).lenght > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        
        //To add staffs which require Add operation (instead of Update) if there is any.
        if(needAdd.length > 0) {
          let { hasError=false, errorCode=null } = await service.create(activityId, needAdd)
          .then(response => {
            if(207 == response.status) {
              const list = response.data[response.data.jobCase];
              const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
              return { hasError: needAdd.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 207 };
            }
            return {}
          })
          .catch(e => {
            if(e.response && 422 == e.response.status) {
              const list = e.response.data[e.response.data.jobCase];
              const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
              return { needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)) > 0, errorCode: 422 };
            } else {
              return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
            }
          });
          if(hasError) {
            result.hasError = true;
            result.errorCodes.push(errorCode);
          }
        }
      }

      //Try remove staffs which user wants to remove if there is any
      if(toRemove.length > 0) {
        const clues = ['OK', 'Unknown_relation'];
        let { hasError=false, errorCode=null } = await service.remove(activityId, toRemove)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 207 };
          }
          return { failed: [] };
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }
      return result;
    },
    async updateStage(activityId) {
      const service = activityLinkStageService;
      const result = {
        hasError: false,
        errorCodes: []
      }
      
      // If already linked to a stage and we picked a new one, remove the old link first
      if (this.original.stage && this.stage != this.original.stage) {
        let { hasError=false, errorCode=null } = await service.remove(activityId, {uuId: this.original.stage}).
        then(response => {
          if(207 == response.status) {
            return { hasError: true, errorCode: 207 }
          }
          return {}
        })
        .catch(e => {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        });

        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      // If the chosen stage differs from the old one, we need to create a new link
      // Null stage is allowed (stage is not mandatory). We just skip making a link in this case.
      if (!result.hasError && this.original.stage != this.stage && this.stage != null) {
        let { hasError=false, errorCode=null } = service.create(activityId, {uuId: this.stage}).
        then(response => {
          if(207 == response.status) {
            return { hasError: true, errorCode: 207 }
          }
          return {}
        })
        .catch(e => {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }
      
      if (!result.hasError) {    
        this.original.stage = this.stage;
      }
      return result; 
    },
    durationCalculationOk({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr }) {
      this.duration.startDate = startDateStr;
      this.duration.startTime = startTimeStr;
      this.duration.closeDate = closeDateStr;
      this.duration.closeTime = closeTimeStr;
      this.duration.value = durationDisplay;
      this.constraint.type = constraintType;
      this.constraint.date = constraintDateStr;
      
      this.prevStartDateStr = startDateStr;
      this.prevStartTimeStr = startTimeStr;
      this.prevCloseDateStr = closeDateStr;
      this.prevCloseTimeStr = closeTimeStr;
      this.prevConstraintType = constraintType;
      this.prevConstraintDateStr = constraintDateStr;
    },
    durationCalculationCancel({ trigger, oldDateStr, oldTimeStr, oldConstraintType, oldConstraintDateStr }) {
      //Reset to previous value
      if (trigger == TRIGGERS.START_DATE || trigger == TRIGGERS.START_TIME) {
        this.duration.startDate = oldDateStr;
        this.duration.startTime = oldTimeStr;
        this.prevStartDateStr = oldDateStr;
        this.prevStartTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CLOSE_DATE || trigger == TRIGGERS.CLOSE_TIME) {
        this.duration.closeDate = oldDateStr;
        this.duration.closeTime = oldTimeStr;
        this.prevCloseDateStr = oldDateStr;
        this.prevCloseTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CONSTRAINT_TYPE || trigger == TRIGGERS.CONSTRAINT_DATE) {
        this.constraint.type = oldConstraintType;
        this.constraint.date = oldConstraintDateStr;
      } else if (trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
        //Do nothing.
      }
      //Trigger 'duration' is not possible to emit 'cancel' event.
    },
    async initDurationCalculation({ trigger=TRIGGERS.DURATION, useExistingValues=false, oldDateStr=null, oldTimeStr=null
                                    , oldConstraintType=null, oldConstraintDateStr=null, skipOutOfProjectDateCheck=null,
                                    resizeMode=false } = {}) {
      if (useExistingValues !== true) {
        this.durationCalculation.trigger = trigger;
        this.durationCalculation.startDateStr = this.duration.startDate;
        this.durationCalculation.startTimeStr = this.duration.startTime;
        this.durationCalculation.closeDateStr = this.duration.closeDate;
        this.durationCalculation.closeTimeStr = this.duration.closeTime;
        this.durationCalculation.durationDisplay = this.duration.value;
        this.durationCalculation.resizeMode = resizeMode;
        
        if (this.staffs.length > 0) {
          this.calendar = await staffService.calendar(this.staffs[0].uuId)
          .then((response) => {
            // combine the calendar lists into single lists for each day
            const data = response.data[response.data.jobCase];
            return transformCalendar(processCalendar(data));
          })
          .catch((e) => {
            self.httpAjaxError(e);
            return null;
          });
        }
        
        if (this.calendar == null) {
          this.calendar = cloneDeep(DEFAULT_CALENDAR);
        }
        this.durationCalculation.calendar = this.calendar;
        this.durationCalculation.constraintType = this.constraint.type;
        this.durationCalculation.constraintDateStr = this.constraint.date;
        this.durationCalculation.lockDuration = this.activity.lockDuration;
        this.durationCalculation.oldDateStr = oldDateStr;
        this.durationCalculation.oldTimeStr = oldTimeStr;
        this.durationCalculation.oldConstraintType = oldConstraintType;
        this.durationCalculation.oldConstraintDateStr = oldConstraintDateStr;
      }
      //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
      if (skipOutOfProjectDateCheck != null) {
        this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
      }
      this.state.durationCalculationShow = true; //start the calculation.
    },
    constructDateTimeStr(dateStr, timeStr) {
      if (dateStr != null && dateStr.trim().length > 0) {
        let _timeStr = null;
        if (timeStr && timeStr.trim().length > 0) {
          _timeStr = timeStr.length == 5? timeStr + ':00' : timeStr;
        }
        return `${dateStr}${_timeStr != null? 'T'+_timeStr : ''}`;
      }
      return null;
    },
    cancelModal() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    handleOkBtnClicked() {
      this.submittedBy='ok';
      this.preOk();
    },
    handleApplyBtnClicked(callback=null) {
      this.submittedBy='apply';
      this.preOk(true, callback);
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    resourceBadgeRemove: function(index) {
      this.resources.splice(index,1)
    },
    resourceBadgeClick(id) {
      const selected = this.resources.find(i => i.uuId === id);
      const edit = this.resourceUnitEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.unit = selected.quantity;
      edit.utilization = selected.utilization;
      edit.data = selected;
      this.state.resourceUnitEditShow = true;
    },
    resourceSelectorToggle() {
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, unit: null, utilization: 1, show: false }});
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      for(let i = this.toConfirmResources.length-1; i > -1; i--) {
        this.toConfirmResources[i].toBeShown = true;
      }
      this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
      this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
    },
    resourceUnitOk({ id, name, unit, utilization, oldId, customFields, customData }) {
      const oldIdIndex = oldId? this.resources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.resources[oldIdIndex];
      } else {
        selected = this.resources.find(i => i.uuId === id);
      }
      if (selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.quantity = unit;
        selected.utilization = utilization;
        for (const field of customFields) {
          selected[field.name] = customData[field.name];
        }
      }
      else {
        const res = {uuId: id, name: name, quantity: unit, utilization: utilization}
        for (const field of customFields) {
          res[field.name] = customData[field.name];
        }
        this.resources.push( res );
      }
    },
    toConfirmResourceOk({ id, name, unit, utilization, oldId, applyToAll, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmResources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmResources[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmResources.find(i => i.uuId === id);
      }
      
      selected.unit = unit;
      selected.utilization = utilization;
      selected.customFields = customFields;
      selected.customData = customData;
      
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          entry.utilization = utilization;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmResourceCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    },
    toConfirmResourceCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const confirmedResources = this.toConfirmResources.filter(i => i.unit && i.unit > 0);
      const ids = confirmedResources.map(i => i.uuId);
      const currentIds = this.resources.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newResources = confirmedResources.filter(i => !currentIds.includes(i.uuId));

      let resource = null;
      for(let i = 0, len = this.resources.length; i < len; i++) {
        resource = this.resources[i];
        if(duplicatedIds.includes(resource.uuId)) {
          const confirmed = confirmedResources.find(i => i.uuId === resource.uuId);
          resource.quantity = confirmed.unit;
          resource.utilization = confirmed.utilization;
          
          for (const field of confirmed.customFields) {
            resource[field.name] = confirmed.customData[field.name];
          }
        }
      }

      newResources.forEach(i => {
        const res = {uuId: i.uuId, name: i.name, quantity: i.unit, utilization: i.utilization};
      
        for (const field of i.customFields) {
          res[field.name] = i.customData[field.name];
        }
        this.resources.push( res );
      });
    },
    removeUnchangedTaskProperties(data) {
      //Remove those properties whose value is not changed in provided data against original activity.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalTask = this.original.activity;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (key == 'constraintType' || key == 'constraintTime') { //Backend needs both constraint type and time even when only one of them is changed.
          if (originalTask.constraintType === data.constraintType && originalTask.constraintTime === data.constraintTime) {
            delete data[key];
            continue;
          }
        } else if (originalTask[key] === data[key] ||
                   (typeof originalTask[key] === 'undefined' && data[key] === null)) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    getEntityOptions() {
      this.$store.dispatch('data/info', {type: "holder", object: "STAGE,TAG"}).then(value => {
        this.entityOptions.splice(0, this.entityOptions.length);
        for(const v of value) {
          this.entityOptions.push({ value: v, text: v });
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    async getEnumList() {
      return this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    onFixedDurationChange() {
      if (this.activity.fixedDurationfixedDuration &&
        // eslint-disable-next-line
      !/^\d+[\.,D,m,h,W,M,Y]+$/.test(this.activity.fixedDuration)) {
        this.activity.fixedDuration = `${this.activity.fixedDuration}D`;
      }
    },
    progressAddMinus(delta) {
      if(isNaN(this.activity.progress)) {
        this.activity.progress = 0;
      }
      const value = parseInt(this.activity.progress);
      this.activity.progress = value + delta;
      // round to nearest 10
      if (this.activity.progress % 10 !== 0) {
        if (delta > 0) {
          this.activity.progress = Math.ceil(this.activity.progress / 10) * 10;
        }
        else {
          this.activity.progress = Math.floor(this.activity.progress / 10) * 10;
        }
      }
      this.progressFormat();
    },
    progressComplete() {
      this.activity.progress = 100;
      this.progressFormat();
    },
    rebateSelectorToggle() {
      this.rebateEdit.uuId = null;
      this.state.rebateSelectorShow = true;
    },
    rebateSelectorOk({details}) {
      const rebates = JSON.parse(JSON.stringify(this.rebates));
      if (details.length > 0) {
        const newRebatesToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = rebates.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const rebate = rebates[foundIndex];
            rebate.uuId = r.uuId;
            rebate.name = r.name;
            rebate.rebate = r.rebate;
            newRebatesToReplacePreselected.push(rebate);
            rebates.splice(foundIndex, 1);
          } else {
            newRebatesToReplacePreselected.push({ uuId: r.uuId, name: r.name, rebate: r.rebate })
          }
        }
        
        if (this.rebateEdit.uuId != null) {
          const foundIndex = rebates.findIndex(i => i.uuId == this.rebateEdit.uuId);
          if (foundIndex != -1) {
            rebates.splice(foundIndex, 1, ...newRebatesToReplacePreselected);
          } else {
            rebates.push(...newRebatesToReplacePreselected);
          }
        } else {
          rebates.push(...newRebatesToReplacePreselected);
        }
      } else if (this.rebateEdit.uuId != null) {
        //When no selection is made, remove the preselected one from the existing rebates
        rebates.splice(rebates.findIndex(j => j.uuId === this.rebateEdit.uuId), 1);
      }
      this.rebates.splice(0, this.rebates.length, ...rebates);

      this.rebateEdit.uuId = null;
    },
    rebateEditOk(result) {
      const rebate = result.data;
      const index = this.rebates.findIndex(i => i.uuId === rebate.uuId);
      if (index !== -1) {
        this.rebates.splice(index, 1, rebate);
      }
    },
    rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    },
    rebateBadgeClick(id) {
      this.rebateEdit.uuId = id;
      this.state.rebateSelectorShow = true;
    },
    validateCurrencyCode() {
      if(this.activity.fixedCost != null && !this.activity.currencyCode) {
        this.errors.add({
          field: `activity.currencyCode`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.field.currencyCode')])
        });
        return false;
      } else {
        return true;
      }
    },
    splitActivity() {
      this.state.splitActivityModalShow = true;
    },
    splitActivitySuccess(splits) {
      this.duration.startDate = splits[0].startDate;
      this.duration.startTime = splits[0].startTime;
      this.duration.closeDate = splits[0].closeDate;
      this.duration.closeTime = splits[0].closeTime;
      this.duration.value = splits[0].value;
      
      // clone the activity using the split dates
      for (let i = 1; i < splits.length; i++) {
        const data = {};
        let sDate = splits[i].startDate != null && splits[i].startDate != ''? splits[i].startDate : null;
        if (sDate != null) {
          const sDateTime = moment.utc(sDate, 'YYYY-MM-DD');
          let sTime = splits[i].startTime;
          if (sTime == null || sTime.trim().length == 0) {
            sTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(sDate, 'YYYY-MM-DD'), { isLatest: false, ignoreLeave: false });
          }
          if (sTime != null) {
            const token = sTime.split(':');
            sDateTime.hour(token[0]).minute(token[1]);
          }
          data.startTime = sDateTime.valueOf();
        } else {
          data.startTime = null;
        }
  
        let cDate = splits[i].closeDate != null && splits[i].closeDate != ''? splits[i].closeDate : null;
        if (cDate != null) {
           const cDateTime = moment.utc(cDate, 'YYYY-MM-DD');
          let cTime = splits[i].closeTime;
          if (cTime == null || cTime.trim().length == 0) {
            cTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(cDate, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
          }
          if (cTime != null) {
            const token = cTime.split(':');
            cDateTime.hour(token[0]).minute(token[1]);
          }
          data.closeTime = cDateTime.valueOf();
        } else {
          data.closeTime = null;
        }
  
        const { value: dValue } = convertDisplayToDuration(splits[i].value, this.durationConversionOpts);
        data.duration = dValue;
        
        activityService.clone(
          data, 
          this.id
        )
        .then((response) => {
          return response.data.jobClue.uuId;
        });
      }
      this.hasSplit = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists))
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
    //End of methods
  }
}
</script>

<style lang="scss" scoped>

.cost-block {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.cost-left {
  margin-right: 15px;
}

.cost-right {
  margin-left: 15px;
}

.image-preview-container {
  width: 100%;
  border: 1px var(--form-control-border) solid;
  padding: 5px;
  min-height: 36px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-preview {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 300px;
}

.actual-cost-alert {
  color: var(--status-alt-red);
  position: absolute;
  right: 11px;
  top: 8px;
}
.work-effort-alert {
  color: var(--status-alt-red);
}
.colorRed {
  color: var(--status-alt-red) !important;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

@media (min-width: 1200px) {
  .remove-padding {
    padding-left: 0;
  }
}
</style>

<style lang="scss">
a.list-group-item {
  border: none !important;
  color: var(--form-control);
  background-color: var(--form-control-bg);
}

.actual-duration-form-control-fix {
  flex: 1 1 auto;
  width: 1%;

  &:not(:last-child) {
    input.form-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

@media screen and (max-width: 991px) {
  .identifier-margin {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .color-margin {
    margin-left: 15px;
  }
}
</style>
