import { httpAjax } from '@/helpers';

export const managementService = {
  info,
  status,
  ssoProviders,
  create,
  update,
  whitelist,
  remove,
  getNumberOfUsersInfo,
  configuration,
  health
};

/**
 * Get information about fields
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function info(params) {
  const url = '/api/system/schema';
  const config = {
    params: params,
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

function status() {
  const url = '/management/status';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

function ssoProviders() {
  const url = '/api/system/schema/login';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

/**
 * Create a new field 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = `/api/system/schema?type=field&object=${data.model}.${data.name}&opts=allowAdding`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update a field 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function update(data) {
  const origName = data.origName;
  delete data.origName;
  const url = `/api/system/schema?type=field&object=${data.model}.${origName}&opts=allowModify,allowRename,allowCleanup`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.put(url, data, config);
}

/**
 * Delete a field
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(data) {
  const url = `/api/system/schema?type=field&object=${data.model}.${data.name}&opts=allowDelete,allowCleanup,removeDeleted`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.delete(url, config);
}

function whitelist() {
  const url = '/api/system/schema?type=field&object=MODELS';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

function getNumberOfUsersInfo() {
  const url = '/management/report?opts=brief';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(resp => {
    const janusDbReport = resp.data?.janusDbReport;
    return janusDbReport 
      ? {
        used: janusDbReport['Number of users in system'] ?? -1,
        total: janusDbReport['Number of users by CMS license'] ?? -1
      }
      : {
        used: -1,
        total: -1
      };
  });
}

function configuration(params) {
  const url = '/api/system/configuration';
  const config = {
    params,
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

/**
 * Get information about health
 */
function health() {
  const url = '/management/health';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}
