<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId, 'anti-shift']"
      @hidden="$emit('update:show', false)"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="user.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('user.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('user.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <AvatarBanner 
          v-if="isAvatarBannerVisible" 
          v-model="avatarBanner" 
          :readOnly="isAvatarBannerReadOnly" 
          :baseAvatarIcon="['fad', 'user-tie']" @status="avatarBannerStatus"
        />
        <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isEmailVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('user.field.email')" label-for="email">
                <b-input-group>
                  <b-form-input id="email" type="text"
                    :data-vv-as="$t('user.field.email')"
                    data-vv-name="user.email"
                    data-vv-delay="500"
                    v-model="user.email" 
                    :readonly="isEmailReadOnly"
                    trim />
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showEmailError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.email') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['email'] != null">
              <b-col v-for="(field, index) in customFieldMap['email']" :key="'email'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="user.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="user.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col v-if="isFirstNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('user.field.firstName')" label-for="firstName">
                <b-input-group>
                  <b-form-input id="firstName" type="text"
                    :data-vv-as="$t('user.field.firstName')"
                    data-vv-name="user.firstName"
                    data-vv-delay="500"
                    v-model="user.firstName" 
                    :readonly="isFirstNameReadOnly"
                    trim />
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFistNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.firstName') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['firstName'] != null">
              <b-col v-for="(field, index) in customFieldMap['firstName']" :key="'firstName'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="user.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
          
            <b-col cols="12" class="pr-0" v-if="isLastNameVisible">
              <b-form-group :label="$t('user.field.lastName')" label-for="lastName">
                <b-input-group>
                  <b-form-input id="lastName" type="text"
                    :data-vv-as="$t('user.field.lastName')"
                    data-vv-name="user.lastName"
                    data-vv-delay="500"
                    v-model="user.lastName" 
                    :readonly="isLastNameReadOnly"
                    trim
                  />
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLastNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.lastName') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['lastName'] != null">
              <b-col v-for="(field, index) in customFieldMap['lastName']" :key="'lastName'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isNickNameVisible">
              <b-form-group :label="$t('user.field.nickName')" label-for="nickName">
                <b-input-group>
                  <b-form-input id="nickName" type="text"
                    v-model="user.nickName" 
                    :readonly="isNickNameReadOnly"
                    trim/>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['nickName'] != null">
              <b-col v-for="(field, index) in customFieldMap['nickName']" :key="'nickName'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isMobileVisible">
              <b-form-group :label="$t('user.field.mobile')" label-for="mobile">
                <b-input-group>
                  <b-form-input id="mobile" type="text"
                    v-model="user.mobile"
                    :readonly="isMobileReadOnly"
                    trim/>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['mobile'] != null">
              <b-col v-for="(field, index) in customFieldMap['mobile']" :key="'mobile'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isAccessPolicyVisible">
              <b-form-group :label="$t('user.field.accessPolicy')" label-for="role">
                <b-input-group>
                  <b-form-input type="text" :placeholder="$t('user.placeholder.select_policy')" readonly class="form-label"
                    v-on="!isAccessPolicyReadOnly ? { click: accessPolicySelectorOpen } : {}"
                    v-model="accessPolicySelector.name"
                    data-vv-as="Access Policy"
                    data-vv-name="accessPolicySelector.name"
                    data-vv-delay="500"
                  />
                  <template v-if="!isAccessPolicyReadOnly">
                    <b-input-group-append>
                      <b-button @click="accessPolicySelectorOpen"><span>{{ $t('button.select') }}</span></b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button variant="info" :disabled="accessPolicySelector.name === null" @click="state.permissionShow = true"><span>{{ $t('button.customize') }}</span></b-button>
                    </b-input-group-append>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showAccessPolicyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('accessPolicySelector.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['accessPolicy'] != null">
              <b-col v-for="(field, index) in customFieldMap['accessPolicy']" :key="'accessPolicy'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isEnabledVisible" cols="12" sm="4" class="pr-0">
              <b-form-group :label="isUserEnabled">
                <div id="enabled_checkbox" class="user-checkbox">
                  <b-form-checkbox switch v-model="user.enabled" size="lg" name="enabled" :disabled="isEnabledReadOnly"></b-form-checkbox>
                </div>
                <b-popover target="enabled_checkbox" triggers="hover" placement="topright" boundary="viewport">
                  {{ $t('user.enableTooltip') }}
                </b-popover>
              </b-form-group>
            </b-col>
            <b-col v-if="isSuperUserVisible" cols="12" sm="4" class="pr-0">
              <b-form-group>
                <template #label>
                  <font-awesome-icon :icon="['far', 'shield']"/>&nbsp;&nbsp;{{ $t('user.field.system_admin') }}
                </template>
                <div id="system_admin_chkbox" class="user-checkbox">
                <b-form-checkbox class="checkbox-width" switch v-model="user.superUser" size="lg" name="enabled" :disabled="isSuperUserReadOnly"></b-form-checkbox>
                </div>
                <b-popover target="system_admin_chkbox" triggers="hover" placement="top" boundary="viewport">
                  {{ $t('user.system_admin_tooltip') }}
                </b-popover>
              </b-form-group>
            </b-col>
            <b-col v-if="isLdapEnabledVisible" cols="12" sm="4" class="pr-0">
              <b-form-group :label="$t('user.ldapLoginLabel')">
              <div id="ldap_chkbox" class="user-checkbox">
                <b-form-checkbox class="checkbox-width" switch v-model="user.ldapLogin" size="lg" name="ldap" :disabled="isLdapReadOnly"></b-form-checkbox>
                </div>
                <b-popover target="ldap_chkbox" triggers="hover" placement="top" boundary="viewport">
                  {{ $t('user.ldapLoginTooltip') }}
                </b-popover>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['ldapLogin'] != null">
              <b-col v-for="(field, index) in customFieldMap['ldapLogin']" :key="'ldapLogin'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="isTagVisible">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly"/>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['notes'] != null">
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="user[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="(exists || waiting || (!isUserRegistered && !canAdd())) && !user.ldapLogin" size="sm" variant="secondary" @click="preSendMail" style="margin-right: auto">
          {{ $t(isUserRegistered?'button.reset_password': $store.state.data.status.smtpEnabled ? 'button.send_activation' : 'button.activate') }}
        </b-button>
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" v-else @click="preOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!-- access policy selector -->
    <GenericSelectorModalForAdmin v-if="state.accessPolicySelectorShow"
      :show.sync="state.accessPolicySelectorShow" 
      :entityService="accessPolicyUtil"
      :preselected="accessPolicySelector.uuId"
      entity="ACCESS_POLICY"
      nonAdmin
      singleSelection
      @ok="accessPolicyOk"
    />

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="USER" :customFields="customFields" links="ACCESS_POLICY,NOTE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
    
    <PermissionModal :show.sync="state.permissionShow" :isOwnUser="isOwnUser" :selected="permissions" :permissionProperties="permissionProps" @success="permissionOk" />

    <b-modal :title="$t('user.confirmation.title_email_change')"
        v-model="state.confirmEmailChangeShow"
        @ok="confirmEmailChangeOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('user.confirmation.email_change_line1',[this.originEmail]) }}</p>
        <p>{{ $t('user.confirmation.email_change_line2') }}</p>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.continue') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('user.user_edit')"
        v-model="state.confirmSaveChangeBeforeProceedShow"
        @ok="confirmSaveChangeBeforeProceedOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('user.confirmation.save_change_before_proceed') }}</p>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.save_continue') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <PasswordModal :register="!isUserRegistered" :tokenId="tokenId" :show.sync="passwordShow" @success="passwordSuccess"/>

    <b-modal :title="$t('title_warning')"
        v-model="confirmRevokingCriticalPermissionShow"
        @ok="confirmRevokingCriticalPermissionOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('statement_risk_losing_account_setting_access') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { cloneDeep } from 'lodash';
import { strRandom, processRegExp } from '@/helpers';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { userService, accessPolicyService, userLinkAccessPolicyService
         , userLinkPermissionService, authenticationService,
         userLinkTagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { accessPolicyUtil } from '@/views/management/script/accessPolicy';

export default {
  name: 'UserModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    PermissionModal: () => import('@/components/modal/PermissionModal'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    PasswordModal: () => import('@/components/modal/PasswordModal.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    id:        { type: String,   default: `USER_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    staffData: { type: Object, default: null }
  },
  data() {
    return {
      permissionName: 'USER',
      modelInfo:       null,
      alertMsg:        null,
      alertError:      true,
      alertMsgDetails: [],
      state: {
        editable:                 false,
        isSubmitting:             false,
        modalShow:                false,
        accessPolicySelectorShow: false,
        permissionShow:           false,
        confirmEmailChangeShow:   false,
        historyShow:              false,
        confirmSaveChangeBeforeProceedShow: false,
        noteShow:                 false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      user: {
        uuId:      null,
        email:     null,
        firstName: null,
        lastName:  null,
        nickName:  null,
        mobile:    null,
        enabled:   null,
        registered: null,
        superUser: null,
        identifier: null,
        color:     null,
        ldapLogin: null,
        ldapId: null,
        readOnly: false
      },

      accessPolicySelector: {
        uuId: null,
        name: null,
      },
      permissions: [],
      waiting: false,
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      tags: [],
      updatedColor: null,
      tokenId: null,
      passwordShow: false,
      
      permissionProps: [],
      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},

      confirmRevokingCriticalPermissionShow: false,
      restrictedRequiredField: null
    }
  },
  created() {
    this.accessPolicyUtil = accessPolicyUtil;
    this.getModelInfo();
    if(this.exists) {
      this.userGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originPermissions = [];
    this.originAccessPolicyId = null;
    this.originEmail = null;
    this.originStaffEmail = null;
    this.originUser = null;
    this.callbackAfterSubmit = null;
    this.originNotes = [];
    this.originTags = [];
  },
  mounted() {
    this.init(this.show);
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originPermissions = null;
    this.originAccessPolicyId = null;
    this.originEmail = null;
    this.originStaffEmail = null;
    this.originUser = null;
    this.callbackAfterSubmit = null;
    this.originNotes = null;
    this.originTags = null;
    this.accessPolicyUtil = null;
  },
  computed: {
    isOwnUser() {
      return this.id === this.$store.state.authentication.user.uuId;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `USER_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.user.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showError() {
      return this.alertMsg != null;
    },
    showEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'user.email');
    },
    showFistNameError() {
      return fieldValidateUtil.hasError(this.errors, 'user.firstName');
    },
    showLastNameError() {
      return fieldValidateUtil.hasError(this.errors, 'user.lastName');
    },
    showAccessPolicyError() {
      return fieldValidateUtil.hasError(this.errors, 'accessPolicySelector.name');
    },
    exists() {
      return this.id && !this.id.startsWith('USER_NEW_');
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    isUserEnabled() {
      return this.$t(!this.user.registered ? 'user.enabled_not_activated' : this.user.enabled ? 'user.enabled_on' : 'user.enabled_off');
    },
    isUserRegistered() {
      return this.user && this.user.registered? true: false; 
    },
    labelTitle() {
      return this.title? this.title : this.$t('user.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isAvatarBannerVisible() {
      return this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef']) && 
      ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists);
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']));
    },
    isEmailVisible() {
      //Email is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['email'])
    },
    isEmailReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['email']))
    },
    isFirstNameVisible() {
      //FirstName is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['firstName'])
    },
    isFirstNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['firstName']))
    },
    isLastNameVisible() {
      //LastName is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['lastName'])
    },
    isLastNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['lastName']))
    },
    isNickNameVisible() {
      return this.canView(this.permissionName, ['nickName']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['nickName'])) || this.exists)
    },
    isNickNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['nickName']))
    },
    isMobileVisible() {
      return this.canView(this.permissionName, ['mobile']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['mobile'])) || this.exists)
    },
    isMobileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['mobile']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['identifier'])) || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['color'])) || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isAccessPolicyVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('ACCESS_POLICY') && this.canView(this.permissionName, ['ACCESS_POLICY']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['ACCESS_POLICY'])) || this.exists)
    },
    isAccessPolicyReadOnly() {
      return this.isReadOnly || !(this.canEdit(this.permissionName, ['ACCESS_POLICY']) && this.canView('PERMISSION'))
    },
    isEnabledVisible() {
      return this.canView(this.permissionName, ['enabled']) && this.exists
    },
    isEnabledReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['enabled'])) || (this.user != null && !this.user.registered)
    },
    isSuperUserVisible() {
      return this.canView(this.permissionName, ['superUser']) && this.exists
    },
    isSuperUserReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['superUser'])) || !this.canChangeAdmin()
    },
    isLdapEnabledVisible() {
      return this.exists 
      && this.user != null && this.user.ldapId 
      && this.$store != null
      && this.$store.state != null
      && this.$store.state.data != null
      && this.$store.state.data.status != null
      && this.$store.state.data.status.ldapEnabled 
      && this.canView(this.permissionName, ['ldapLogin'])
    },
    isLdapReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['ldapLogin'])
    },
    disableOk() {
      return (this.original.readOnly && this.user.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.init(newValue);
      }
    }
  },
  methods: {
    async init(isShow) {
      await getCustomFieldInfo(this, 'USER');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      this.state.modalShow = isShow;
      this.state.noteShow = false;
      this.originNotes = [];
      this.notes = [];
      this.alertMsg = null;
      this.alertError = true;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      this.resetUserProperties();
      if(this.exists) {
        this.userGet(this.id);
      } else {
        if (isShow) {
          const requiredFields = ['email','firstName','lastName', 'ACCESS_POLICY']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 
          
          if (result.val && !this.canView('ACCESS_POLICY')) {
            result = { val: false, restrictedProp: 'ACCESS_POLICY' }
          }

          if (result.val && !this.canView('PERMISSION')) {
            result = { val: false, restrictedProp: 'PERMISSION' }
          }

          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        } else {
          this.isAccessDenied = false;
        }

        if (this.staffData) {
          this.user.firstName = this.staffData.firstName;
          this.user.lastName = this.staffData.lastName;
          this.user.email = this.staffData.email;
          this.avatarBanner.avatarId = this.staffData.avatarRef || null;
          this.avatarBanner.bannerId = this.staffData.bannerRef || null;
          this.user.color = this.staffData.color;
        }
      }
      
    },
    getDisplayNameOfProperty(val) {
      if (val == 'ACCESS_POLICY') {
        return this.$t('user.field.accessPolicy');
      } else if (val == 'PERMISSION') {
        return this.$t('permission.permission');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`user.field.${val}`);
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "USER"}).then(value => {
        self.modelInfo = value.USER.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    userGet(id) {
      var links = ['ACCESS_POLICIES', 'NOTE', 'PERMISSION','TAG'];
      userService.get([{ uuId: id }], links).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    canChangeAdmin() {
      const admin = this.$store.state.authentication.user.superUser;
      const enabledNRegistered = this.user.registered && this.user.enabled;
      return admin && enabledNRegistered && this.user.uuId !== this.$store.state.authentication.user.uuId;
    },
    digestResponse(data) {
      // if this is the current user update the state variable
      if (data.uuId === this.$store.state.authentication.user.uuId) {
        let user = this.$store.state.authentication.user;
        for (const key of Object.keys(user)) {
          if (key == 'permissionList' && (data.permissionList == null || data.permissionList.length < 1)) {
            //Skip updating permissionList when it is null or empty. Fix for #1198
            continue;
          }
          user[key] = data[key] || null;
        }
        this.$set(this.$store.state.authentication, 'user', user);
      }

      this.original.readOnly = data.readOnly;
      
      const u = this.user;
      for (const key of Object.keys(u)) {
        u[key] = data[key] || null;
      }
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          u[field.name] = data[field.name];
        }
      }
      
      //Keep a copy of originEmail, used to determine if email has been modified.
      this.originEmail = u.email;
      if(data.staff && data.staff.email) {
        this.originStaffEmail = data.staff.email;
      }
      
      u.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }

      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }
      else {
        this.tags = [];
        this.originTags = [];
      }
      
      //Setup Access Policy data
      if (data.accessPolicyList && data.accessPolicyList.length > 0) {
        const list = data.accessPolicyList.map(i => { return { uuId: i.uuId, name: i.name }})
        const accessPolicy = list.length > 0? list[0] : null;
        this.originAccessPolicyId = accessPolicy? accessPolicy.uuId : null;
        if(accessPolicy) {
          this.accessPolicySelector.uuId = accessPolicy.uuId;
          this.accessPolicySelector.name = accessPolicy.name;
        }
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }

      this.originPermissions = data.permissionList? cloneDeep(data.permissionList) : [];
      for (const perm of this.originPermissions) {
        if (!perm.permissionLink) {
          // find the access policy
          const ap = data.accessPolicyList[0].permissionList.find(a => a.uuId === perm.uuId);
          if (ap && ap.permissionLink &&
              ap.permissionLink.denyRules) {
            perm.permissionLink = ap.permissionLink;   
          }
        }
      }
      
      this.permissionProps = data.permissionList? data.permissionList : [];
      this.permissions = cloneDeep(this.originPermissions);
      this.originUser = cloneDeep(u);
      this.originUser.avatarRef = data.avatarRef;
      this.originUser.bannerRef = data.bannerRef;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.user[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = true;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);

      if(!this.user.email || this.user.email && this.user.email.trim().length < 1) {
        this.errors.add({
          field: 'user.email',
          msg: this.$t('validation.messages.required', [this.$t('user.field.email')])
        });
      }

      if(!this.user.firstName || this.user.firstName && this.user.firstName.trim().length < 1) {
        this.errors.add({
          field: 'user.firstName',
          msg: this.$t('validation.messages.required', [this.$t('user.field.firstName')])
        });
      }

      if(!this.user.lastName || this.user.lastName && this.user.lastName.trim().length < 1) {
        this.errors.add({
          field: 'user.lastName',
          msg: this.$t('validation.messages.required', [this.$t('user.field.lastName')])
        });
      }

      if(this.isAccessPolicyVisible && (!this.accessPolicySelector.name || this.accessPolicySelector.name && this.accessPolicySelector.name.trim().length < 1)) {
        this.errors.add({
          field: 'accessPolicySelector.name',
          msg: this.$t('validation.messages.required', [this.$t('user.field.accessPolicy')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          
          if (this.exists && this.user.uuId == this.$store.state.authentication.user.uuId && this.isAccessPolicyVisible) {
            const criticalPermissions = ['USER__VIEW', 'USER__EDIT', 'ADMIN__APPLICATION_MANAGEMENT'];
            const perms = this.permissions.filter(i => criticalPermissions.includes(i.name));
            if (criticalPermissions.length > perms.length) {
              this.confirmRevokingCriticalPermissionShow = true;
              return;
            }
          }
          this.userSubmit();
        } else {
          this.callbackAfterSubmit = null;
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.scrollToTop();
        }
      });
      
    },
    userSubmit() {
      const data = cloneDeep(this.user);
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }
      let mode = 'update';
      if(!this.exists) {
        delete data['uuId'];
        mode = 'create';
      }
      this.userPost(mode, data);
    },
    async userPost(method, data) {
      this.state.isSubmitting = true;
      let userId = null;
      
      //Skip updating user if there is no change in user properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedUserProperties(data);
      }

      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        let result = await this.updateUser(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.alertError = true;
          this.state.isSubmitting = false;
          return;
        }
        userId = result.userId;
      } else {
        userId = data.uuId;
      }

      let hasError = false;

      // save the color in the profile
      this.updatedColor = data.color;
      
      if (data.superUser) {
        this.$store.state.authentication.user.superUser = false;
      }
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(userId, userLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`user.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isAccessPolicyReadOnly) {
        const accessPolicyResult = await this.updateAccessPolicy(userId);
        if (accessPolicyResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`user.${method}_partial`);
          if (accessPolicyResult.errorCodes.length > 0) {
            if (accessPolicyResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('access_policy.label').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('access_policy.label').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('access_policy.label').toLowerCase()]))
          }
        }
      }
      
      
      const permissionResult = await this.updatePermissions(userId);
      if (permissionResult.hasError) {
        hasError = true;
        this.alertMsg = this.$t(`user.${method}_partial`);
        if (permissionResult.errorCodes.length > 0) {
          if (permissionResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('access_policy.field.permissions').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('access_policy.field.permissions').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('access_policy.field.permissions').toLowerCase()]))
        }
      }

      //Notes
      //Remove uuId of new notes before saving
      const notes = cloneDeep(this.notes);
      for (let i = 0, len = notes.length; i < len; i++) {
        if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
          delete notes[i].uuId;
        }
      }      
      const noteResult = await persistNotes(userId, this.originNotes, notes);
      if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
        hasError = true;
        this.alertMsg = this.$t(`user.${method}_partial`);
        if (noteResult.errorCodes.length > 0) {
          if (noteResult.errorCodes.includes(403)) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        } else {
          this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
        }
      }

      if (hasError) {
        this.state.isSubmitting = false;
        this.scrollToTop();
        return;
      }

      //Sync logon user details to store if changed user is logon user
      if (userId == this.$store.state.authentication.user.uuId) {
        await userService.getDetails()
        .then(response => response.data)
        .then(user => {
            this.$store.commit("authentication/updateUser", user);
        });
      }

      const callback = this.callbackAfterSubmit != null? this.callbackAfterSubmit : null;
      this.callbackAfterSubmit = null;

      this.state.isSubmitting = false;
      
      if (this.user.uuId === this.$store.state.authentication.user.uuId) {
        this.$set(this.$store.state.authentication.user, 'firstName', this.user.firstName);
        this.$set(this.$store.state.authentication.user, 'lastName', this.user.lastName);
        this.$set(this.$store.state.authentication.user, 'nickName', this.user.nickName);
        this.$set(this.$store.state.authentication.user, 'avatarRef', this.avatarBanner.avatarId);
        this.$set(this.$store.state.authentication.user, 'bannerRef', this.avatarBanner.bannerId);
      }
      if (this.tokenId !== null) {
        this.passwordShow = true;
      }
      else if (callback != null) {
        this.$emit('success', { msg: this.$t(`user.${method}`) });
        callback({ msg: this.$t(`user.${method}`) });
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`user.${method}`) });
      }
    },
    passwordSuccess() {
      this.$emit('update:show', false);
      this.$emit('success', { msg: !this.exists ? this.$t(`user.create`) : this.$t('user.password_updated') });
    },
    async updateUser(method, data) {
      const self = this;
      const result = {
        hasError: false,
        msg: this.$t(`user.${method}`)
      }
      let userId = await userService[method]([data])
      .then(response => {
        const data = response.data;
        if (data[data.jobCase][0].clue === 'SMTP_unavailable') {
          self.tokenId = data[data.jobCase][0].tokenId;
        }
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`user.error.failed_to_${method}_user`);
        const response = e.response;
        if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 'not_unique_key',
              'string_limit_exceeded', 'number_limit_exceeded'
              ].includes(clue)) {
            result.msg = this.$t('error.attention_required');
            const fieldKey = `user.${feedback.args[0]}`;
            const args = [this.$t(`user.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
              case 'cannot_be_blank':
                break;
              case 'not_unique_key':
                clueNotHandled = true;
                this.errors.add({
                  field: fieldKey,
                  msg: this.$t('user.error.duplicate_value', args)
                });
                break;
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                result.msg = this.$('error.internal_server'); //reset the msg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          } else if (feedback.spot && feedback.spot.length === 1 && feedback.spot[0] === 'Super user cannot be de-activated') {
            result.msg = this.$t('user.error.super_user_cannot_be_deactivated');
          } else if (clue == 'failed_api' && 'user.licence.limit' === feedback.hint) {
            result.msg = this.$t('user.error.user_limit_exceeded');
          }
        }
      });
      result.userId = userId;
      return result;
    },
    async updateAccessPolicy(userId) {
      const result = {
        hasError: false,
        errorCodes: []
      }
      const originAccessPolicyId = this.originAccessPolicyId;
      const accessPolicyId = this.accessPolicySelector.uuId;
      const clues = ['Unknown_target', 'Unknown_relation']
      if(originAccessPolicyId && originAccessPolicyId !== accessPolicyId) {
        await userLinkAccessPolicyService.remove(userId, [originAccessPolicyId])
        .catch(e => {
          
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            if(!clues.includes(list[0].clue)) {
              result.hasError = true;
              result.errorCodes.push(422);
            }
          } else {
            result.hasError = true;
            result.errorCodes.push(e.response.status);
          }
        });
      }
      
      if(accessPolicyId && originAccessPolicyId !== accessPolicyId) {
        await userLinkAccessPolicyService.create(userId, [accessPolicyId])
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            if('Already_have_edge'!== list[0].clue) {
              result.hasError = true;
              result.errorCodes.push(422);
            }
          } else {
            result.hasError = true
            result.errorCodes.push(e.response.status);
          }
        });
      }

      return result;
    },
    async updatePermissions(userId) {
      const result = {
        hasError: false,
        errorCodes: []
      }
      
      const perms = cloneDeep(this.permissions);
      const originPerms = this.originPermissions;
      
      const permToAdd = [];
      const permToUpdate = [];
      const permToRemove = [];

      perms.forEach(i => {
        const index = originPerms.findIndex(j => j.uuId === i.uuId);
        if(index === -1) {
          permToAdd.push(i);
        }
        else {
          const originDeny = originPerms[index].permissionLink && originPerms[index].permissionLink.denyRules ? originPerms[index].permissionLink.denyRules : [];
          const originData = originPerms[index].permissionLink && originPerms[index].permissionLink.dataRules ? originPerms[index].permissionLink.dataRules : [];
          if ((originDeny.length === 0 && i.denyRules && i.denyRules.length > 0) ||
              (originData.length === 0 && i.dataRules && i.dataRules.length > 0) ||
              (originDeny.length !== 0 && !i.denyRules) ||
              (originData.length !== 0 && !i.dataRules) ||
              (i.denyRules && 
              (i.denyRules.filter(r => originDeny.findIndex(o => o === r) !== -1).length !== originDeny.length || // check for removed rules
              i.denyRules.length !== originDeny.length)) ||
              (i.dataRules && 
              (i.dataRules.filter(r => originData.findIndex(o => o === r) !== -1).length !== originData.length || // check for removed rules
              i.dataRules.length !== originData.length))) { // check for new rules
              permToUpdate.push(i);
          }
        }
      });

      originPerms.forEach(i => {
        if(perms.findIndex(j => j.uuId === i.uuId) == -1) {
          permToRemove.push(i);
        }
      });

      //Try to add link of permission to user
      if(permToAdd.length > 0) {
        let { hasError=false, errorCode=null } = await userLinkPermissionService.create(userId, permToAdd.map(i => { 
          if (i.denyRules &&
              i.name.endsWith('ADD')) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: i.denyRules ? i.denyRules : []
              } 
            }
          }
          else if (i.denyRules ||
              i.dataRules) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: i.denyRules ? i.denyRules : [],
                dataRules: i.dataRules ? i.dataRules : []
              } 
            }
          }
          return { 
            uuId: i.uuId, 
            name: i.name
          }
        }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode:  e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      //Try to update link of permission to user
      if(permToUpdate.length > 0) {
        let { hasError=false, errorCode=null } = await userLinkPermissionService.update(userId, permToUpdate.map(i => 
        { 
          if (i.denyRules !== null &&
              i.name.endsWith('ADD')) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: Array.isArray(i.denyRules)? i.denyRules : []
              } 
            }
          }
          else if (i.denyRules !== null ||
              i.dataRules !== null) {
            return { 
              uuId: i.uuId, 
              name: i.name, 
              permissionLink: { 
                denyRules: Array.isArray(i.denyRules)? i.denyRules : [],
                dataRules: Array.isArray(i.dataRules) ? i.dataRules : []
              } 
            }
          }
          else {
            const index = originPerms.findIndex(j => j.uuId === i.uuId);
            if(index !== -1) {
              const originDeny = originPerms[index].permissionLink ? originPerms[index].permissionLink.denyRules : [];
              const originData = originPerms[index].permissionLink ? originPerms[index].permissionLink.dataRules : [];
              if (originDeny.length !== 0 &&
                  i.name.endsWith('ADD')) {
                return {
                  uuId: i.uuId,
                  name: i.name,
                  permissionLink: {
                    denyRules: []
                  }
                }
              }
              else if (originDeny.length !== 0 ||
                  originData.length !== 0) {
                return {
                  uuId: i.uuId,
                  name: i.name,
                  permissionLink: {
                    denyRules: [],
                    dataRules: []
                  }
                }
              }
            }
          }
          
          return { 
            uuId: i.uuId, 
            name: i.name
          }
        }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null?  e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }
      
      //Remove link of permission to user
      if(permToRemove.length > 0) {
        const clues = ['OK', 'Unknown_relation'];
        let { hasError=false, errorCode=null } = await userLinkPermissionService.remove(userId, permToRemove.map(i => i.uuId))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null?  e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.errors.add({
            field: `user.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
          this.alertError = true;
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetUserProperties() {
      const keys = Object.keys(this.user);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.user[keys[i]] = customField.def;
            continue;
          }
        }
        this.user[keys[i]] = null;
      }

      const company = this.$store.state.company;
      this.avatarBanner.avatarId = company ? company.avatarRef : null;
      this.avatarBanner.bannerId = company ? company.bannerRef : null;

      this.accessPolicySelector.uuId = null;
      this.accessPolicySelector.name = null;

      this.tokenId = null;
      this.originPermissions = [];
      this.permissions = [];
      this.originEmail = null;
      this.originStaffEmail = null;
      this.originAccessPolicyId = null;
      this.originUser = null;
      this.callbackAfterSubmit = null;
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
        this.alertError = true;
      }
    },
    accessPolicySelectorOpen() {
      this.state.accessPolicySelectorShow = true;
    },
    accessPolicyOk({ details }) {
      const selected = details[0];
      this.accessPolicySelector.uuId = selected.uuId;
      this.accessPolicySelector.name = selected.name;

      accessPolicyService.get([{ uuId: selected.uuId}], ['PERMISSION'])
      .then(response => {
        const data = response.data[response.data.jobCase] || [];
        if(data.length > 0) {
          const list = data[0].permissionList? data[0].permissionList:[];
          // rules loaded from the server are in permissionLink.denyRules
          // we need them in denyRules to be edited and updated correctly
          for (const p of list) {
            if (p.permissionLink && p.permissionLink.denyRules) {
              p.denyRules = p.permissionLink.denyRules;
            }
            if (p.permissionLink && p.permissionLink.dataRules) {
              p.dataRules = p.permissionLink.dataRules;
            }
          }
          this.permissionProps.splice(0, this.permissionProps.length, ...list);
          this.permissions.splice(0, this.permissions.length, ...list);
        }
      })
      
    },
    permissionOk(selected) {
      this.permissions.splice(0, this.permissions.length, ...selected);
    },
    preSendMail() {
      if(this.originEmail !== this.user.email) {
        this.state.confirmSaveChangeBeforeProceedShow = true;
      } else {
        this.sendMail();
      }
    },
    sendMail() {
      const self = this;
      this.waiting = true;
      if(this.isUserRegistered) {
        authenticationService.forgotPassword(this.user.email).then((response) => {
          const data = response.data;
          if (data.jobClue && data.jobClue.clue === 'SMTP_unavailable') {
            self.tokenId = data.jobClue.tokenId;
            self.passwordShow = true;
          }
          else {
            self.alertMsg = this.$t('forgot_password.success_manager');
          }
          self.alertError = false;
          self.waiting = false;
        })
        .catch(() => {
          self.alertMsg = this.$t('forgot_password.failure');
          self.alertError = true;
          self.waiting = false;
        })
      } else {
        userService.sendRegistrationEmail(this.user.uuId).then((response) => {
          const data = response.data;
          if (data.jobClue.clue === 'SMTP_unavailable') {
            self.tokenId = data.jobClue.tokenId;
            self.passwordShow = true;
          }
          else {
            self.alertMsg = this.$t('user.activation_sent');
          }
          self.alertError = false;
          self.waiting = false;
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          self.alertMsg = this.$t('user.error.activation_not_sent');
          self.alertError = true;
          self.waiting = false;
        });
      }
    },
    confirmEmailChangeOk() {
      this.ok();
    },
    preOk() {
      if(this.originStaffEmail && this.originStaffEmail === this.originEmail && this.originEmail !== this.user.email) {
        this.state.confirmEmailChangeShow = true;
      } else {
        this.ok();
      }
    },
    removeUnchangedUserProperties(data) {
      //Remove those properties whose value is not changed in provided data against original user.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalUser = this.originUser;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalUser[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    confirmSaveChangeBeforeProceedOk() {
      this.callbackAfterSubmit = () => {
        this.userGet(this.id);
        if (this.$store.state.data.status.smtpEnabled) {
          this.alertMsg = this.$t('user.activation_sent');
        }
        this.alertError = false;
        this.waiting = false;
      }
      this.preOk();
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    confirmRevokingCriticalPermissionOk() {
      this.userSubmit();
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-width {
  width: 50px;
}

.user-checkbox {
  width: 25px;
}
</style>