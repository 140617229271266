<template>
  <div class="animated fadeIn">
    <div class="container">
      <b-row class="section management-row" ref="management-section">
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('COMPANY')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.COMPANY)"
          >
            <font-awesome-icon :icon="['fad', 'apartment']" swap-opacity style="font-size: 80px; --fa-primary-color: var(--managemen-icon-secondary);
      --fa-secondary-color: var(--management-icon-primary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.COMPANY)">{{ $t('company.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.LOCATION)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.LOCATION)"
          >
            <font-awesome-icon :icon="['fad', 'map-location-dot']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"></font-awesome-icon>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.LOCATION)">{{ $t('location.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('DEPARTMENT')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.DEPARTMENT)"
          >
            <font-awesome-icon :icon="['fad','sitemap']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.DEPARTMENT)">{{ $t('department.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('STAFF')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.STAFF)"
          >
            <font-awesome-icon :icon="['fad', 'user-hard-hat']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.STAFF)">{{ $t('staff.title_selector') }}</b-button>
          </div>
        </b-col>      
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.CUSTOMER)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.CUSTOMER)"
          >
            <font-awesome-icon :icon="['fad', 'user-tie']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.CUSTOMER)">{{ $t('customer.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.PROJECT)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.PROJECT)"
          >
            <font-awesome-icon :icon="['fad', 'chart-network']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.PROJECT)">{{ $t('project.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.STAGE)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.STAGE)"
          >
            <font-awesome-icon :icon="['fad', 'signs-post']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.STAGE)">{{ $t('stage.title_management') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.TASK_TEMPLATE)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.TASK_TEMPLATE)"
          >
            <font-awesome-icon :icon="['fad', 'layer-group']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.TASK_TEMPLATE)">{{ $t('template.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.SKILL)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.SKILL)"
          >
            <font-awesome-icon :icon="['fad','head-side-brain']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.SKILL)">{{ $t('skill.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.RESOURCE)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.RESOURCE)"
          >
            <font-awesome-icon :icon="['fad', 'cart-flatbed-boxes']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.RESOURCE)">{{ $t('resource.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.REBATE)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.REBATE)"
          >
            <font-awesome-icon :icon="['fad', 'badge-percent']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.REBATE)">{{ $t('rebate.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('STORAGE_FILE')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="state.fileShow = true"
          >
            <font-awesome-icon :icon="['fad', 'folders']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="state.fileShow = true">{{ $t('file.title.list') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('USER')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.USER)"
          >
            <font-awesome-icon :icon="['fad', 'user-shield']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.USER)">{{ $t('user.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('ACCESS_POLICY')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.ACCESS_POLICY)"
          >
            <font-awesome-icon :icon="['fad', 'user-lock']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.ACCESS_POLICY)">{{ $t('access_policy.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('TAG')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.TAG)"
          >
            <font-awesome-icon :icon="['fad', 'tags']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.TAG)">{{ $t('tag.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('ADMIN__ENUMERATION_MANAGEMENT')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.ENUM)"
          >
            <font-awesome-icon :icon="['fad', 'square-list']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.ENUM)">{{ $t('enum.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList(entities.WEBHOOK)">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.WEBHOOK)"
          >
            <font-awesome-icon :icon="['fad', 'webhook']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.WEBHOOK)">{{ $t('webhook.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('ADMIN__FIELD_MANAGEMENT')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.FIELD)"
          >
            <font-awesome-icon :icon="['fad', 'pen-field']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.FIELD)">{{ $t('fields.title_selector') }}</b-button>
          </div>
        </b-col>
        <b-col class="management-container management-color" xs="12" sm="4" md="4" lg="3" xl="3" v-if="canList('ADMIN__SYSTEM_MANAGEMENT')">
          <div 
            body-class="text-center"
            class="management-card card"
            @click.prevent="openSelector(entities.LANGUAGE)"
          >
            <font-awesome-icon :icon="['fad', 'language']" style="font-size: 80px; --fa-primary-color: var(--management-icon-primary);
      --fa-secondary-color: var(--managemen-icon-secondary);width: 95%;padding: 5px;--fa-primary-opacity: 0.8;--fa-secondary-opacity: 0.8;" class="mb-2"/>
            <b-button href="#" block variant="primary" @click.prevent="openSelector(entities.LANGUAGE)">{{ $t('language.title_selector') }}</b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <GenericSelectorModalForAdmin 
      :show.sync="state.entitySelectorShow" 
      :entityService="currentService" 
      :entity="currentEntity"
      @cancel="onCloseModal"
    />

    <CompanySelectorModalForAdmin
      :show.sync="state.companyShow"
      @cancel="onCloseModal"
    />

    <DepartmentSelectorModalForAdmin
      :show.sync="state.departmentShow"
      @cancel="onCloseModal"
    />

    <StaffSelectorModalForAdmin
      :show.sync="state.staffShow"
      @cancel="onCloseModal"
    />

    <EnumSelectorModalForAdmin
      :show.sync="state.enumShow"
      @cancel="onCloseModal"
    />

    <FieldSelectorModalForAdmin
      :show.sync="state.fieldShow"
      @cancel="onCloseModal"
    />

    <LanguageSelectorModalForAdmin
      :show.sync="state.languageShow"
      @cancel="onCloseModal"
    />

    <FileSelectorModal :show.sync="state.fileShow" mode="MANAGE" @ok="ok" :multiple="true"
      @cancel="onCloseModal" />
  </div>
</template>

<script>
import { debounce } from 'lodash'

import { rebateUtil } from './script/rebate'
import { resourceUtil } from './script/resource'
import { skillUtil } from './script/skill'
import { locationUtil } from './script/location'
import { templateTaskUtil } from './script/taskTemplate'
import { stageUtil } from './script/stage'
import { projectUtil } from './script/project'
import { customerUtil } from './script/customer'
import { userUtil } from './script/user'
import { accessPolicyUtil } from './script/accessPolicy'
import { tagUtil } from './script/tag'
import { webhookUtil } from './script/webhook'


export default {
  name: 'Management',
  components: {
    FileSelectorModal        : () => import('@/components/modal/FileSelectorModal')
    , GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
    , CompanySelectorModalForAdmin: () => import('@/components/modal/CompanySelectorModalForAdmin')
    , DepartmentSelectorModalForAdmin: () => import('@/components/modal/DepartmentSelectorModalForAdmin')
    , StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin')
    , EnumSelectorModalForAdmin: () => import('@/components/modal/EnumSelectorModalForAdmin')
    , FieldSelectorModalForAdmin: () => import('@/components/modal/FieldSelectorModalForAdmin')
    , LanguageSelectorModalForAdmin: () => import('@/components/modal/LanguageSelectorModalForAdmin')
  },
  data() {
    return {
      state: {
        staffShow: false
        , departmentShow: false
        , fileShow: false
        , companyShow: false
        , entitySelectorShow: false
        , enumShow: false
        , fieldShow: false
        , languageShow: false
      }
      , currentService: null
      , currentEntity: null
    }
  },
  mounted() {
    this.screenResize()
    window.addEventListener('resize', this.screenResize)
  },
  created() {
    this.entities = {
      REBATE: 'REBATE'
      , RESOURCE: 'RESOURCE'
      , SKILL: 'SKILL'
      , LOCATION: 'LOCATION'
      , TASK_TEMPLATE: 'TEMPLATE__PROJECT'
      , STAGE: 'STAGE'
      , PROJECT: 'PROJECT'
      , CUSTOMER: 'CUSTOMER'
      , USER: 'USER'
      , ACCESS_POLICY: 'ACCESS_POLICY'
      , TAG: 'TAG'
      , COMPANY: 'COMPANY'
      , DEPARTMENT: 'DEPARTMENT'
      , STAFF: 'STAFF'
      , ENUM: 'ENUM'
      , WEBHOOK: 'WEBHOOK'
      , FIELD: 'FIELD'
      , LANGUAGE: 'LANGUAGE'
    };
    const query = this.$route.query;
    if(query.modal) {
      const modal = query.modal;
      if('templateTask' === modal) {
        // this.state.taskTemplatesShow = true;
        this.openSelector(this.entities.TASK_TEMPLATE)
      }
      else if('companies' === modal) {
        this.openSelector(this.entities.COMPANY)
      }
      else if('rebate' === modal) {
        this.openSelector(this.entities.REBATE)
      }
      else if('resource' === modal) {
        this.openSelector(this.entities.RESOURCE)
      }
      else if('skill' === modal) {
        this.openSelector(this.entities.SKILL)
      }
      else if('location' === modal) {
        this.openSelector(this.entities.LOCATION)
      }
      else if('stage' === modal) {
        this.openSelector(this.entities.STAGE)
      }
      else if('project' === modal) {
        this.openSelector(this.entities.PROJECT)
      }
      else if('customer' === modal) {
        this.openSelector(this.entities.CUSTOMER)
      }
      else if('user' === modal) {
        this.openSelector(this.entities.USER)
      }
      else if('accessPolicy' === modal) {
        this.openSelector(this.entities.ACCESS_POLICY)
      }
      else if('tag' === modal) {
        this.openSelector(this.entities.TAG)
      }
      else if('department' === modal) {
        this.openSelector(this.entities.DEPARTMENT)
      }
      else if('staff' === modal) {
        this.openSelector(this.entities.STAFF)
      }
      else if('enum' === modal) {
        this.openSelector(this.entities.ENUM)
      }
      else if('webhook' === modal) {
        this.openSelector(this.entities.WEBHOOK)
      }
      else if('field' === modal) {
        this.openSelector(this.entities.FIELD)
      }
      else if('language' === modal) {
        this.openSelector(this.entities.LANGUAGE)
      }
    }
  },
  beforeDestroy() {
    this.entities = null
    window.removeEventListener('resize', this.screenResize)
  },
  watch: {
    'state.entitySelectorShow': function(newValue) {
      //Force screen rendering in case user permissions are changed to fix broken css style
      if (!newValue && (this.currentEntity == this.entities.USER || this.currentEntity == this.entities.ACCESS_POLICY)) {
        this.screenResize();
      }
    }
  },
  methods: {
    screenResize: debounce(function(/**event*/) {
      const sectionElem = this.$refs['management-section'];
      let cardElems = sectionElem.querySelectorAll('.management-container');
      if (cardElems == null || cardElems.length == 0) {
        return;
      }
      cardElems = Array.from(cardElems);
      const rows = [];
      let curCard = cardElems.shift();
      let curRow = { first: curCard, last: null, middle: [] };
      const rect = curCard.getBoundingClientRect();
      let curTop = rect.top;
      let prevCard = null;
      while (cardElems.length > 0) {
        prevCard = curCard;
        curCard = cardElems.shift();
        const rect = curCard.getBoundingClientRect();
        if (curTop != rect.top) {
          //detect wrap item
          curRow.last = prevCard;
          if (curRow.middle.length > 0 && curRow.middle[curRow.middle.length - 1] == prevCard) {
            curRow.middle.pop(); //remove last entry of middle card as it is the last card of the row.
          }
          rows.push(curRow);
          curRow = { first: curCard, last: null, middle: [] };
          curTop = rect.top;
        } else if (curRow.first != prevCard) {
          curRow.middle.push(prevCard);
        }
      }

      if (curRow.last == null) {
        curRow.last = curCard;
      }
      rows.push(curRow);

      for (let i = 0, len = rows.length; i < len; i++) {
        const row = rows[i];
        
        if (row.first == row.last) {
          row.first.classList.add('management-container-only-item-of-row');
          row.first.classList.remove('management-container-first-of-row');
          row.first.classList.remove('management-container-last-of-row');
          row.first.classList.remove('round-bottom-radius');
        } else {
          row.first.classList.remove('management-container-only-item-of-row');
          row.first.classList.add('management-container-first-of-row');
          row.first.classList.remove('management-container-last-of-row');
          row.first.classList.remove('round-bottom-radius');
          
          row.last.classList.remove('management-container-only-item-of-row');
          row.last.classList.remove('management-container-first-of-row');
          row.last.classList.add('management-container-last-of-row');
          row.first.classList.remove('round-bottom-radius');
        }
        for (const middle of row.middle) {
          middle.classList.remove('management-container-only-item-of-row');
          middle.classList.remove('management-container-first-of-row');
          middle.classList.remove('management-container-last-of-row');
          middle.classList.remove('first-row');
          middle.classList.remove('last-row');
          middle.classList.remove('round-bottom-radius');
        }
        if (len == 1) {
          row.first.classList.add('first-row');
          row.last.classList.add('first-row');
          row.first.classList.add('last-row');
          row.last.classList.add('last-row');
        } else if (i == 0) {
          row.first.classList.add('first-row');
          row.last.classList.add('first-row');
          row.first.classList.remove('last-row');
          row.last.classList.remove('last-row');
        } else if (i == len - 1) {
          row.first.classList.remove('first-row');
          row.last.classList.remove('first-row');
          row.first.classList.add('last-row');
          row.last.classList.add('last-row');
        } else {
          row.first.classList.remove('first-row');
          row.last.classList.remove('first-row');
          row.first.classList.remove('last-row');
          row.last.classList.remove('last-row');
        }
      }

      if (rows.length > 1) {
        const lastRow = rows[rows.length - 1];
        const rowBeforeLastRow = rows[rows.length - 2];
        const lastRowItemCount = lastRow.middle.length + (lastRow.first != lastRow.last ? 2 : 1);
        const rowBeforeLastRowItemCount = rowBeforeLastRow.middle.length + 2;

        if (lastRowItemCount < rowBeforeLastRowItemCount) {
          rowBeforeLastRow.first.classList.add('round-bottom-radius');
          rowBeforeLastRow.last.classList.add('round-bottom-radius');
        }
      }
      
    }, 100, { leading: false, trailing: true }),
    getColour(idx) {
      return `background: ${this.colours[idx]}`;
    },
    ok(/** payload **/) {
      // console.log(payload) // eslint-disable-line no-console
    },
    onCloseModal() {
      this.$router.replace({'query': null});
    },
    updateUrl(entity) {
      let params = new URL(document.location.toString()).searchParams;
      if (!params.has("modal")) {
        this.$router.push({ query: { modal: entity }})
      }
    },
    openSelector(entity) {
      switch (entity) {
        case this.entities.REBATE:
          this.updateUrl('rebate');
          this.currentService = rebateUtil
          this.currentEntity = this.entities.REBATE
          this.state.entitySelectorShow = true
          break
        case this.entities.RESOURCE:
          this.updateUrl('resource')
          this.currentService = resourceUtil
          this.currentEntity = this.entities.RESOURCE
          this.state.entitySelectorShow = true
          break
        case this.entities.SKILL:
          this.updateUrl('skill')
          this.currentService = skillUtil
          this.currentEntity = this.entities.SKILL
          this.state.entitySelectorShow = true
          break
        case this.entities.LOCATION:
          this.updateUrl('location')
          this.currentService = locationUtil
          this.currentEntity = this.entities.LOCATION
          this.state.entitySelectorShow = true
          break
        case this.entities.TASK_TEMPLATE:
          this.updateUrl('templateTask')
          this.currentService = templateTaskUtil
          this.currentEntity = this.entities.TASK_TEMPLATE
          this.state.entitySelectorShow = true
          break
        case this.entities.STAGE:
          this.updateUrl('stage')
          this.currentService = stageUtil
          this.currentEntity = this.entities.STAGE
          this.state.entitySelectorShow = true
          break
        case this.entities.PROJECT:
          this.updateUrl('project')
          this.currentService = projectUtil
          this.currentEntity = this.entities.PROJECT
          this.state.entitySelectorShow = true
          break
        case this.entities.CUSTOMER:
          this.updateUrl('customer')
          this.currentService = customerUtil
          this.currentEntity = this.entities.CUSTOMER
          this.state.entitySelectorShow = true
          break
        case this.entities.USER:
          this.updateUrl('user')
          this.currentService = userUtil
          this.currentEntity = this.entities.USER
          this.state.entitySelectorShow = true
          break
        case this.entities.ACCESS_POLICY:
          this.updateUrl('accessPolicy')
          this.currentService = accessPolicyUtil
          this.currentEntity = this.entities.ACCESS_POLICY
          this.state.entitySelectorShow = true
          break
        case this.entities.TAG:
          this.updateUrl('tag')
          this.currentService = tagUtil
          this.currentEntity = this.entities.TAG
          this.state.entitySelectorShow = true
          break
        case this.entities.WEBHOOK:
          this.updateUrl('webhook')
          this.currentService = webhookUtil
          this.currentEntity = this.entities.WEBHOOK
          this.state.entitySelectorShow = true
          break
        case this.entities.COMPANY:
          this.updateUrl('companies')
          this.state.companyShow = true
          break
        case this.entities.DEPARTMENT:
          this.updateUrl('department')
          this.state.departmentShow = true
          break
        case this.entities.STAFF:
          this.updateUrl('staff')
          this.state.staffShow = true
          break
        case this.entities.ENUM:
          this.updateUrl('enum')
          this.state.enumShow = true
          break
        case this.entities.FIELD:
          this.updateUrl('field')
          this.state.fieldShow = true
          break
        case this.entities.LANGUAGE:
          this.updateUrl('language')
          this.state.languageShow = true
          break
        default:
          console.error(`Invalid entity, ${entity}, is provided`) //eslint-disable-line no-console
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-block {
  padding-left: 4px;
  padding-right: 4px;
}

.card-body {
  padding: 1.0rem;
}

.card {
  min-width: 150px;
  cursor: pointer;
}

.section {
  border-radius: 10px;
  justify-content: center;
}

.management-container {
  padding: 20px;
  max-width: 250px;
}

.management-color {
  background-color: var(--backdrop);
}


.card-padding-left {
  display: none;
  height: 100%;
  width: 10px;
  position: absolute;
  left: -10px;
  border-bottom-left-radius: 10px;
}

.card-padding-right {
  display: none;
  height: 100%;
  width: 10px;
  position: absolute;
  right: -10px;
  border-bottom-right-radius: 10px;
}

.management-container {
  &.management-container-first-of-row {
    &.first-row {
      border-top-left-radius: 10px;
    }
    &.last-row, &.round-bottom-radius {
      border-bottom-left-radius: 10px;
    }
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 20px;
  }

  &.management-container-last-of-row {
    &.first-row {
      border-top-right-radius: 10px;
    }
    &.last-row, &.round-bottom-radius {
      border-bottom-right-radius: 10px;
    }
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }

  &.management-container-only-item-of-row {
    &.first-row {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &.last-row {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.left, .left4 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-right: 10px;
}

.right, .right4 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
}

.middle, .middle4 {
    padding-right: 10px;
    padding-left: 10px;
}

.middle-right {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .right4 {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    padding-top: 0px;
  }
    
    
  .middle-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 20px;
  }

  .right4 {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  }
    
  .card-padding-left {
    display: block;
  }
    
  .card-padding-right {
    display: block;
  }
  
  .right4 .management-card {
    width: calc(100% + 20px);
    left: -10px;
    position: relative;
  }
  
}

@media only screen and (max-width: 575px) {
  .left {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }
  
  .left4 {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  
  .middle {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 20px;
  }
   
  .middle4 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }
   
  .right {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    padding-top: 0px;
  }
  
  .right4 {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    padding-top: 0px;
    padding-left: 10px;
  }
  
  .card-padding-left {
    display: none;
  }
    
  .card-padding-right {
    display: none;
  }
  
  .right4 .management-card {
    width: auto;
    left: 0;
    position: relative;
  }
  
  .middle-right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    padding-top: 0px;
    padding-right: 10px;
  }
    
}

@media only screen and (max-width: 515px) {
  .left, .left4 {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
    
  .middle {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 10px;
    padding-left: 20px;
  }
   
  .middle4 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 10px;
    padding-left: 20px;
  }
   
  .right, .right4 {
    border-top-right-radius: 00px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
  }
  
  .middle-right {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }
    
}

.management-card {
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 0;
}

.management-card .btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
