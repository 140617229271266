var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label &&
    typeof _vm.label === "string" &&
    (_vm.label.startsWith("https://") || _vm.label.startsWith("http://"))
      ? _c("a", { attrs: { href: _vm.label, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _c("span", [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }