<template>
  <div>
    <ol class="breadcrumb">
      <template v-for="(routeObject, index) in routeRecords">
        <template v-if="hasParam(routeObject) && isLast(index)">
          <template v-if="hasMetaLabel(routeObject)">
            <li class="breadcrumb-item" :key="index">
              <router-link :to="getRecordRoutePath(routeObject)">{{ contextName }}</router-link>
            </li>
            <li class="breadcrumb-item" :key="`${index}_1`">
              <span class="active">{{ getName(routeObject) }}</span>
            </li>
          </template>
          <template v-else>
            <li class="breadcrumb-item" :key="index">
              <span class="active">{{ contextName }}</span>
            </li>
          </template>
        </template>
        <li class="breadcrumb-item" v-else-if="getName(routeObject) !== ''" :key="index">
          <span class="active" v-if="hasParam(routeObject) && isLast(index)">{{ getName(routeObject) }}</span>
  
          <span class="active" v-else-if="isLast(index)">{{ getName(routeObject) }}</span>
          <router-link :to="'/'" v-else-if="'' === routeObject.path">{{ getName(routeObject) }}</router-link>
          <router-link :to="getRecordRoutePathCustom(routeObject)" v-else>{{ getName(routeObject) }}</router-link>
        </li>
      </template>
      <span v-if="viewName" class="ml-1">/ {{viewName}}</span>
    </ol>
    <b-dropdown class="mode-button" :class="getButtonClass()">
      <template #button-content>
        <span id="SELECTED_STATE_NAME"><font-awesome-icon class="mr-2 fa-fw" :icon="['far', selectedState.icon]"/>{{ $te(selectedState.name) ? $t(selectedState.name) : selectedState.name }}</span>
        <b-popover
          v-if="selectedState.icon === 'clock-rotate-left'" 
          target="SELECTED_STATE_NAME"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="selectedStatePopup">
        </b-popover>
      </template>
  
      <template>
        <b-dropdown-item @click="onMenu('epoch-clear')">
          <span class="action-item-label-with-icon"><font-awesome-icon class="mr-2 fa-fw" :icon="['far', 'wave-pulse']"/>{{ $t('live') }}</span>
          <span class="action-item-icon" v-if="isTodayChecked()">
            <font-awesome-icon class="" :icon="['far','check']"/>
          </span>
        </b-dropdown-item>
        <b-dropdown-item @click="onMenu('epoch-select')">
          <span class="action-item-label-with-icon"><font-awesome-icon class="mr-2 fa-fw" :icon="['far', 'clock-rotate-left']"/>{{ $t('select_epoch') }}</span>
          <span class="action-item-icon" v-if="isTimeMachineChecked()">
            <font-awesome-icon class="" :icon="['far','check']"/>
          </span>
        </b-dropdown-item>
        <b-dropdown-group v-if="canView()">
          <template #header>
            <font-awesome-icon class="mr-2 fa-fw" :icon="['far', 'code-branch']"/>{{ $t('sandbox.menu_title') }}
          </template>
          <!--<b-dropdown-item v-if="this.$store.state.sandbox.value" @click="onPublish"><span class="submenu-indent action-item-label-with-icon">{{ $t('sandbox.publish') }}</span></b-dropdown-item>-->
          <b-dropdown-item v-if="canAdd()" @click="onMenu('sandbox-add')"><span class="submenu-indent action-item-label-with-icon">{{ $t('sandbox.add') }}</span></b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item v-for="(item, index) in sandboxList" @click="onSandbox(item)" v-bind:key="index">
            <span :id="`sandboxname${index}`" class="submenu-indent action-item-label-with-icon">{{ item.name }}</span>
            <b-popover
              v-if="isEllipsisActive(item.name)"
              :target="`sandboxname${index}`"
              placement="top"
              boundary="viewport"
              custom-class="popover-margin"
              triggers="hover"
              offset="-100"
              :content="item.name">
            </b-popover> 
            <span>
              <span class="action-item-icon position-third" 
                  v-if="!editPermissionSandbox(item)"
                  @[infoMouseEnterEvent]="onSandboxInfoOver(index)" @mouseleave="onSandboxInfoLeave(index)"
                  :id="`BTN_SANDBOX_INFO_${index}`">
                <font-awesome-icon class="" :icon="['far','circle-info']"/>
              </span>
              <b-popover
                v-if="!editPermissionSandbox(item)"
                :target="`BTN_SANDBOX_INFO_${index}`"
                :ref="`BTN_SANDBOX_INFO_${index}`"
                :show.sync="showInfoSandbox[index]"
                placement="top"
                boundary="viewport"
                triggers="manual"
                :content="$t('owner', [sandboxList[index].ownerName])">
              </b-popover>
              <span class="action-item-icon position-third" 
                  v-if="editPermissionSandbox(item)"
                  :id="`BTN_SANDBOX_SHARE_${index}`"
                  @click.stop.prevent="shareSandbox(item)">
                <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
              </span>
              <b-popover
                v-if="editPermissionSandbox(item)"
                :target="`BTN_SANDBOX_SHARE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.share')">
              </b-popover>
              <span class="action-item-icon position-second" 
                  v-if="editPermissionSandbox(item)"
                  :id="`BTN_SANDBOX_UPDATE_${index}`"
                  @click.stop.prevent="saveSandbox(item)">
                <font-awesome-icon class="" :icon="['far','save']"/>
              </span>
              <b-popover
                v-if="editPermissionSandbox(item)"
                :target="`BTN_SANDBOX_UPDATE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.update_sandbox')">
              </b-popover>
              <span class="action-item-icon"
                  v-if="editPermissionSandbox(item)"
                  :id="`BTN_SANDBOX_DELETE_${index}`"
                  @click.stop.prevent="deleteSandbox(index)">
                <font-awesome-icon class="" :icon="['far','trash-alt']"/>
              </span>
              <b-popover
                v-if="editPermissionSandbox(item)"
                :target="`BTN_SANDBOX_DELETE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.delete')">
              </b-popover>
              <span class="action-item-icon" :class="getCheckMarkClass(item)">
                <font-awesome-icon class="" :icon="['far','check']"/>
              </span>
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="sandboxList.length !== 0"/>
          <b-dropdown-item v-if="canEdit()" @click="onMenu('sandbox-manage')"><span class="submenu-indent action-item-label-with-icon">{{ $t('sandbox.manage') }}</span></b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group v-if="canView('LABEL')">
          <template #header>
            <font-awesome-icon class="mr-2 fa-fw" :icon="['far', 'thumbtack']"/>{{ $t('label.menu_title') }}
          </template>
          <b-dropdown-item v-if="canAdd('LABEL')" @click="saveLabel"><span class="submenu-indent action-item-label-with-icon">{{ $t('label.add') }}</span></b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item v-for="(item, index) in labelList" @click="onLabel(item)" v-bind:key="index">
            <span :id="`labelname${index}`" class="submenu-indent action-item-label-with-icon">{{ item.name }}</span>
            <b-popover
              v-if="isEllipsisActive(item.name)"
              :target="`labelname${index}`"
              placement="top"
              boundary="viewport"
              custom-class="popover-margin"
              triggers="hover"
              offset="-100"
              :content="item.name">
            </b-popover> 
            <span>
              <span class="action-item-icon" 
                  v-if="canAdd('LABEL') && !editPermission(item)"
                  :id="`BTN_LABEL_COPY_${index}`"
                  @click.stop.prevent="copyColumnSettings(item.name, item)">
                <font-awesome-icon class="" :icon="['far','copy']"/>
              </span>
              <b-popover
                v-if="canAdd('LABEL') && !editPermission(item)"
                :target="`BTN_LABEL_COPY_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.copy')">
              </b-popover>
              <span class="action-item-icon position-third" 
                  v-if="!editPermission(item)"
                  @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                  :id="`BTN_LABEL_INFO_${index}`">
                <font-awesome-icon class="" :icon="['far','circle-info']"/>
              </span>
              <b-popover
                v-if="!editPermission(item)"
                :target="`BTN_LABEL_INFO_${index}`"
                :ref="`BTN_LABEL_INFO_${index}`"
                :show.sync="showInfo[index]"
                placement="top"
                boundary="viewport"
                triggers="manual"
                :content="$t('owner', [labelList[index].owner])">
              </b-popover>
              <span class="action-item-icon position-third" 
                  v-if="editPermission(item)"
                  :id="`BTN_LABEL_SHARE_${index}`"
                  @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                <font-awesome-icon class="" :icon="['far','share-nodes']"/>
              </span>
              <b-popover
                v-if="editPermission(item)"
                :target="`BTN_LABEL_SHARE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.share')">
              </b-popover>
              <span class="action-item-icon position-second" 
                  v-if="canEdit('LABEL') && editPermission(item)"
                  :id="`BTN_LABEL_UPDATE_${index}`"
                  @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                <font-awesome-icon class="" :icon="['far','save']"/>
              </span>
              <b-popover
                v-if="canEdit('LABEL') && editPermission(item)"
                :target="`BTN_LABEL_UPDATE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.update_label')">
              </b-popover>
              <span class="action-item-icon"
                  v-if="canDelete('LABEL') && editPermission(item)"
                  :id="`BTN_LABEL_DELETE_${index}`"
                  @click.stop.prevent="removeColumnSettings(index)">
                <font-awesome-icon class="" :icon="['far','trash-alt']"/>
              </span>
              <b-popover
                v-if="canDelete('LABEL') && editPermission(item)"
                :target="`BTN_LABEL_DELETE_${index}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('button.delete')">
              </b-popover>
              <span class="action-item-icon" :class="getCheckMarkClassLabel(item)">
                <font-awesome-icon class="" :icon="['far','check']"/>
              </span>
            </span>
          </b-dropdown-item>
        </b-dropdown-group>
      </template>
    </b-dropdown>
    
    <SaveLabelModal :show.sync="promptSaveShow" :name="saveName" :title="saveProfile.uuId ? $t('label.update') : $t('label.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveLabelModal :show.sync="promptShareShow" :name="saveName" :title="$t('label.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/> 
    
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteLabelShow"
        @ok="confirmDeleteLabelOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('label.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteSandboxShow"
        @ok="confirmDeleteSandboxOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('sandbox.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <SandboxModal v-if="editorShow" 
      :data="editData"
      :id="editData.uuId"
      :show.sync="editorShow" 
      :sharing="editorShare"
      :nameEdit="!editorShare"
      @success="editorSuccess" 
      :title="$t(editorShare ? 'sandbox.title_share' : `sandbox.title_edit`)"
    />
    
    <AuditSelectorModalForAdmin
      :show.sync="auditShow"
      @ok="onPublishComplete"
    />
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { EventBus, isEllipsisActive } from '@/helpers';
import { sandboxService, labelProfileService, profileService, userService } from '@/services';

export default {
  name: 'Breadcrumb',
  components: {
    SaveLabelModal: () => import('@/components/modal/SaveLabelModal.vue'),
    SandboxModal: () => import('@/components/modal/SandboxModal'),
    AuditSelectorModalForAdmin: () => import('@/components/modal/AuditSelectorModalForAdmin')
  },
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    urlParams: {
      type: Object,
      default: () => {}
    },
    selectedState: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      permissionName: 'SANDBOX',
      userId: null,
      sandboxList: [],
      labelList: [],
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: {},
      saveIndex: -1,
      showInfo: [],
      showInfoSandbox: [],
      confirmDeleteLabelShow: false,
      deleteLabelIndex: -1,
      confirmDeleteSandboxShow: false,
      deleteSandboxIndex: -1,
      editorShow: false,
      editorShare: false,
      editData: {},
      selectedLabel: null,
      auditShow: false
    }
  },
  computed: {
    selectedStatePopup() {
      return moment(this.$store.state.epoch.value).format('L');
    },
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    routeRecords: function () {
      return this.list.filter((route) => route.name || route.meta.label)
    },
    contextName: function() {
      return this.$store.state.breadcrumb.contextName;
    },
    viewName: function() {
      return this.$store.state.breadcrumb.viewName;
    }
  },
  watch: {
    $route: function() {
      this.$store.dispatch("breadcrumb/clearView");
    },
    selectedState() {
      this.loadSandboxes();
    }
  },
  methods: {
    getName (item) {
      return item.meta && typeof item.meta.label !== 'undefined' ? this.$te(item.meta.label, 'en-projectal') ? this.$t(item.meta.label) : item.meta.label : this.$te(item.name, 'en-projectal') ? this.$t(item.name) : item.name || null
    },
    isLast (index) {
      return index === this.list.length - 1
    },
    hasParam(item) {
      const _item = item;
      if(_item && _item.path) {
        return _item.path.match(this.paramRegex) != null;
      } else {
        return false;
      }
    },
    hasMetaLabel (item) {
      return item.meta && typeof item.meta.label !== 'undefined';
    },
    getRecordRoutePath(item) {
      const matched = item.path.match(this.paramRegex) || [null, null];
      const paramKey = matched[1];
      if(paramKey) {
        let index = item.path.indexOf(`:${paramKey}`);
        return `${getParentSlash(item.path.substring(0, index+1), 2)}${this.urlParams[paramKey]}`;
      }
      return item.path;
      
      //Private function
      //Get sub-string from 1st character to last (nth time of) slash based on number of times provided.
      //e.g.: Original string: "/projects/gantt/:id" 
      //       0 times --> "/projects/gantt/:id"
      //       1 times --> "/projects/gantt/"
      //       2 times --> "/projects/"
      //       3 times --> "/projects/"           
      //Note: it will return same string when the remaining string has one slash left (excluding 1st slash when it is 1st character)
      function getParentSlash(value, times) {
        if(!value || value.length < 1 || times < 1) {
          return value;
        }
        const idx = value.lastIndexOf("/", value.length-2);
        if(idx < 1) {
          return value;
        }
        return getParentSlash(value.substring(0, idx+1), --times);
      }
    },
    getRecordRoutePathCustom(item)  {
      const obj = {
        path: item.path
      }
      if(item.meta && item.meta.enableParamsAsQueries) {
        obj.query = { ...this.$route.params }
      }
      return obj;
    },
    onMenu(payload) {
      this.selectedLabel = null;
      EventBus.$emit(payload);
    },
    getButtonClass() {
      if (this.selectedState.icon === 'code-branch') {
        return 'mode-sandbox';
      }
      return '';
    },
    onSandbox(item) {
      this.$store.state.epoch.value = null;
      this.selectedLabel = null;
      this.$store.state.sandbox.value = item.identifier;
      this.$store.state.sandbox.canEdit = item.owner === this.userId || item.editBy.includes(this.userId);
      this.$emit('sandbox', item);
    },
    onLabel(item) {
      this.selectedLabel = item;
      this.$store.state.sandbox.value = null;
      this.$store.state.epoch.value = moment(item.epoch).unix() * 1000;
      this.$emit('epoch', item);
    },
    shareSandbox(sandbox) {
      this.editData.name = sandbox.name
      this.editData.viewBy = sandbox.viewBy
      this.editData.editBy = sandbox.editBy
      this.editData.uuId = sandbox.identifier
      this.editorShare = true;
      this.editorShow = true;
    },
    saveSandbox(sandbox) {
      this.editData.name = sandbox.name
      this.editData.viewBy = sandbox.viewBy
      this.editData.editBy = sandbox.editBy
      this.editData.uuId = sandbox.identifier
      this.editorShare = false;
      this.editorShow = true;
    },
    deleteSandbox(index) {
      this.confirmDeleteSandboxShow = true;
      this.deleteSandboxIndex = index;
    },
    editPermission(label) {
      if (typeof label.editingPermissions === 'undefined') {
        return true;    
      }
      
      return label.editingPermissions.includes(this.userId);
    },
    editPermissionSandbox(sandbox) {
      return sandbox.owner === this.userId;
    },
    onInfoOver(index) {
      profileService.nodeList(this.labelList[index].uuId).then((response) => {
        this.labelList[index].owner = response.data.resultList.filter(v => this.labelList[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    onSandboxInfoOver(index) {
      userService.get([{ uuId: this.sandboxList[index].owner }]).then((response) => {
        this.sandboxList[index].ownerName = response.data[response.data.jobCase].filter(v => this.sandboxList[index].owner === v.uuId).map(r => { return r.name }).join(", ");
        this.$set(this.showInfoSandbox, index, true);
      });
    },
    onSandboxInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    saveLabel() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        type: 'label',
        sharingMembers: this.userId,
        editingPermissions: this.userId
      };
      this.promptSaveShow = true;
    },
    confirmSaveOk({ profile, newDefault, updateUsers, sharing }) {      
      
      if (this.saveIndex !== -1) {
        this.labelList.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addLabels([profile]);
      } 
    },    
    addLabels(labels) {
      for (const label of labels) {
        // if not in the list, add it
        if (label.type === 'label' &&
            this.labelList.findIndex((i) => i.uuId === label.uuId) === -1) {
          this.showInfo.push(false);
          this.labelList.push(label);
        }
      }
      
      this.labelList.sort(function( a, b ) {
        if ( a.name === null ||
             (b.name !== null && a.name.toLowerCase() < b.name.toLowerCase() )){
          return -1;
        }
        if ( b.name === null ||
             a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: profile.name,
        uuId: profile.uuId,
        type: 'label',
        sharingMembers: profile.sharingMembers,
        editingPermissions: profile.editingPermissions,
        epoch: profile.epoch
      };
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings(name, profile) {
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: 'label',
        sharingMembers: this.userId,
        editingPermissions: this.userId,
        epoch: profile.epoch
      };
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteLabelShow = true;
      this.deleteLabelIndex = index;
    },
    async confirmDeleteSandboxOk() {
      await sandboxService.remove(this.sandboxList[this.deleteSandboxIndex].identifier);
      this.loadSandboxes();
      if (parseInt(this.sandboxList[this.deleteSandboxIndex].identifier) === this.$store.state.sandbox.value) {
        EventBus.$emit('epoch-clear');
      }
    },
    confirmDeleteLabelOk() {
      const toRemove = this.labelList.splice(this.deleteLabelIndex, 1);
      
      labelProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {  
                        //
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      if (moment(toRemove[0].epoch).unix() * 1000 === this.$store.state.epoch.value) {
        EventBus.$emit('epoch-clear');
      }
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    },
    loadSandboxes() {
      sandboxService.list().then((response) => {
        this.sandboxList = response.data[response.data.jobCase];
      })
      .catch(e => {
        this.sandboxList = [];
      });
    },
    editorSuccess() {
      this.loadSandboxes();
    },
    getCheckMarkClass(item) {
      if (this.$store.state.sandbox.value === item.identifier) {
        return 'check-mark-visible';
      }
      return 'check-mark-hidden';
    },
    isTodayChecked() {
      return this.$store.state.sandbox.value === null &&
             this.$store.state.epoch.value === null;
    },
    getCheckMarkClassLabel(item) {
      if (this.selectedLabel !== null &&
          this.$store.state.epoch.value === moment(item.epoch).unix() * 1000) {
        return 'check-mark-visible';
      }
      return 'check-mark-hidden';
    },
    isTimeMachineChecked() {
      return this.$store.state.epoch.value && this.selectedLabel === null;
    },
    onPublish() {
      this.auditShow = true;
    },
    onPublishComplete(items) {
      console.log(items);//eslint-disable-line
    }
  },
  beforeDestroy() {
    this.paramRegex = null;
  },
  created() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.paramRegex = /:([a-zA-Z0-9]+)$/;
    this.loadSandboxes();
    labelProfileService.list(this.userId).then((response) => {
      this.addLabels(response.data[response.data.jobCase]);
    })
    .catch(e => {
      this.labelList = [];
    });
    labelProfileService.listPublic(localStorage.companyId).then((response) => {
      this.addLabels(response.data[response.data.jobCase]);
    })
    .catch(e => {
      
    });
  }
}
</script>

<style scoped>
  .breadcrumb {
    z-index: 10;
    background-color: var(--header-bg);
  }
  
  .mode-button {
    position: absolute;
    right: 220px;
    top: 5px;
    z-index: 99;
    height: 33px;
    
    .dropdown-toggle::after {
      margin-left: 0.5rem;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .mode-button {
      right: 20px;
      top: 50px;
    }
  }
  
  .check-mark-visible {
    visibility: visible;
  }
  
  .check-mark-hidden {
    visibility: hidden;
  }
</style>