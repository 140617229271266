<template>
  <div :id="`DATAVIEW_FOLDER_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-form-group :label="$t('dataview.field.parent_folder')" label-for="parent">
        <b-input-group>
          <b-form-input id="parent" type="text"
            v-model="parent.name" 
            :disabled="true">
          </b-form-input>
          <b-input-group-append>
            <b-button size="sm" @click="modalRemove">{{ $t('button.remove') }}</b-button>
            <b-button v-if="canList()" size="sm" variant="info" @click="modalSelect">{{ $t('button.select') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group :label="$t('dataview.folder_name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('dataview.folder_name')"
            data-vv-name="folder.name"
            :maxlength="maxNameLength"
            data-vv-delay="500"
            v-model="folder.name" 
            v-validate="{ required: true }"
            :disabled="isReadOnly"
            :autofocus="true"
            :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'folder.name')"
            trim 
            @keydown.native="keydownHandler">
          </b-form-input>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('folder.name') }}
        </b-form-invalid-feedback>
      </b-form-group>
        
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="canEdit() || !exists" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <DataviewFolderSelectorModal v-if="modalShowSelector" :exclude="folder.uuId" :preselected="parent.uuId" :visibility="visibility" :title="$t('dataview.title_parent_selector')" :show.sync="modalShowSelector" @ok="modalSuccessSelector" :forceSingleSelection="true" mode="SELECT"/>
     
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';


export default {
  name: 'DataviewFolderModal',
  components: {
    DataviewFolderSelectorModal: () => import('@/components/modal/DataviewFolderSelectorModal')
  },
  props: {
    id:        { type: String,   default: `DATAVIEW_FOLDER_NEW_${strRandom(5)}` },
    folderName: { type: String, default: null },
    parentData:{ type: [String, Object],   default: null },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    visibility: { type: String, default: 'private' },
  },
  data() {
    return {
      permissionName: 'DATAVIEW',
      modelInfo: null,
      alertMsg: null,
      modalShowSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false
      },
      folder: {
        uuId:               null,
        name:               null,
      },
      parent: {
        uuId:               null,
        name:               null
      }
    }
  },
  beforeCreate() {
    this.$options.components.DataviewFolderSelectorModal = require('./DataviewFolderSelectorModal.vue').default;
  },
  created() {
    if(this.id && this.id.indexOf('DATAVIEW_FOLDER_NEW_') !== -1) {
      // new folder, set the parent to the selected folder
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLocation = [];
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  computed: {
    exists() {
      return this.id && !this.id.startsWith('DATAVIEW_FOLDER_NEW_');
    },
    isReadOnly() {
      return !this.state.editable && this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'folder.name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title: this.$t('dataview.create_folder');
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        if(this.id.indexOf('DATAVIEW_FOLDER_NEW_') !== -1) {
          this.resetFolderProperties();
        }
        else {
          this.folder.uuId = this.id;
          this.folder.name = this.folderName;
        }
        
        if (this.parentData !== null) {
          this.parent.uuId = this.parentData.uuId;
          this.parent.name = this.parentData.name;
        }
      }
    }
  },
  methods: {
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    modalSelect() {
      this.modalShowSelector = true;
    },
    modalRemove() {
      this.parent.uuId = null;
      this.parent.name = null;
    },
    ok() {
      this.errors.clear();
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.folderSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async folderSubmit() {
      const data = cloneDeep(this.folder);
       
      this.$emit('update:show', false);
      this.$emit('success', { folderUuid: this.folder.uuId, folderName: this.folder.name, 
                              parentUuid: this.parent.uuId, parentName: this.parent.name });
    },
    parentChanged() {
      // is the parent uuId different
      return (this.parentData === null && this.parent.uuId !== null) ||
                 (this.parentData !== null && this.parentData.uuId !== this.parent.uuId);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetFolderProperties() {
      const keys = Object.keys(this.folder);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        this.folder[keys[i]] = null;
      }
      
      // set the parent to the selected folder
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
    },  
    modalSuccessSelector(details) {
      this.parent.uuId = details.uuId ? details.uuId : null;
      this.parent.name = details.name ? details.name : null;
      this.modalShowSelector = false;
    },
    modalCancelSelector() {
      this.modalShowSelector = false;
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    }
  }
}
</script>
