const state = {
  value: null,
  name: null,
  canEdit: false
};

const actions = {
  update({ commit }, value) {
    commit('update', value);
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  update(_state, value) {
    _state.value = value;
  },
  clear(_state) {
    _state.value = null;
  },
};

const sandbox = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default sandbox;
