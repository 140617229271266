<template>
  <div>
    <b-button :disabled="disableButtons" :id="`BTN_FIELD_PERMISSIONS_${params.eGridCell.getAttribute('comp-id')}`" v-if="shouldShow" class="fields-button" size="sm" @click="edit"><font-awesome-icon :icon="['far', 'pen-field']"/><span v-if="params && params.data.denyRules && params.data.denyRules.length !== 0" class="changed-dot"></span></b-button>
    <b-popover
          v-if="shouldShow"
          :target="`BTN_FIELD_PERMISSIONS_${params.eGridCell.getAttribute('comp-id')}`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('permission.field_permissions')">
        </b-popover>
    <b-button :disabled="disableButtons" :id="`BTN_DATA_PERMISSIONS_${params.eGridCell.getAttribute('comp-id')}`" v-if="shouldShowData" class="ml-2 fields-button" size="sm" @click="editData"><font-awesome-icon :icon="['far', 'list']"/><span v-if="params && params.data.dataRules && params.data.dataRules.length !== 0" class="changed-dot"></span></b-button>
    <b-popover
           v-if="shouldShowData"
          :target="`BTN_DATA_PERMISSIONS_${params.eGridCell.getAttribute('comp-id')}`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('permission.data_permissions')">
        </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'PermissionButtonCellRenderer',
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    disableButtons() {
      const node = this.params.api.getRowNode(this.params.data.uuId);
      if (node) {
        return !node.selected;
      }
      return false;
    },
    shouldShow() {
      if(!this.params) {
        return false;
      }
      const schema = this.params.context.componentParent.whitelist;
      const whitelist = this.params.context.componentParent.whitelist;
      if (whitelist && this.params.data) {
        const whitelisted = whitelist[this.params.data.path[0]];
        if (this.params.data.path[this.params.data.path.length - 2] === 'CALENDAR' ||
            (!whitelisted || !schema[this.params.data.path[0]])) {
        
          return false;
        }
      }
      return !this.params.node.group && this.params.data && (this.params.data.path[this.params.data.path.length - 1] === 'EDIT' || 
             this.params.data.path[this.params.data.path.length - 1] === 'VIEW' || 
             this.params.data.path[this.params.data.path.length - 1] === 'ADD');
    },
    shouldShowData() { // this is for dataRules button
      if(!this.params || !this.params.data) {
        return false;
      }
      
      if (this.params.context.componentParent.isOwnUser) {
        return false;
      }
      
      if (this.params.data.path[this.params.data.path.length - 2] !== 'PROJECT' &&
          this.params.data.path[this.params.data.path.length - 2] !== 'CUSTOMER' &&
          this.params.data.path[this.params.data.path.length - 2] !== 'STAFF' &&
          this.params.data.path[this.params.data.path.length - 2] !== 'DEPARTMENT' &&
          this.params.data.path[this.params.data.path.length - 2] !== 'TAG' &&
          this.params.data.path[this.params.data.path.length - 2] !== 'STAGE') {
        return false;
      }
      return !this.params.node.group && this.params.data && (this.params.data.path[this.params.data.path.length - 1] === 'VIEW');
    }
  },
  methods: {
    edit(e) {
      e.stopPropagation();
      const cp = this.params.context.componentParent;
      if (cp.editPermission) {
        cp.editPermission(this.params);
      }
    },
    editData(e) {
      e.stopPropagation();
      const cp = this.params.context.componentParent;
      if (cp.editDataPermission) {
        cp.editDataPermission(this.params);
      }
    }
  }
});
</script>

<style lang="scss">
.fields-button {
  line-height: 1;
}

.changed-dot {
  content: '';
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  margin-left: 4px;
  background: red;
  border-radius: 50%;
}
</style>