var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-action-bar" },
    [
      _c(
        "div",
        { staticClass: "action-bar-wrapper grid-toolbar border-bottom" },
        [
          _vm.allowManage
            ? _c(
                "PriorityNavigation",
                {
                  staticClass: "gantt-toolbar",
                  attrs: { ulClassName: "gantt-action-bar-uncollapsed-ul" },
                },
                [
                  _c("li", {}, [
                    _c(
                      "div",
                      { staticClass: "mr-2" },
                      [
                        _c(
                          "label",
                          { staticClass: "ml-2 mr-1", attrs: { for: "dates" } },
                          [_vm._v(_vm._s(_vm.$t("task.control.date")))]
                        ),
                        _c("b-form-select", {
                          staticClass: "mw-150 minw-170",
                          attrs: {
                            disabled: _vm.readOnly,
                            options: _vm.dateOptions,
                            size: "sm",
                          },
                          on: { change: _vm.datesChanged },
                          model: {
                            value: _vm.local_dates,
                            callback: function ($$v) {
                              _vm.local_dates = $$v
                            },
                            expression: "local_dates",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      { staticClass: "d-flex mr-2" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-1 gantt-action-bar-datetime-label",
                            attrs: { for: "gab_startDate" },
                          },
                          [_vm._v(_vm._s(_vm.$t("task.control.date_from")))]
                        ),
                        _c("b-form-datepicker", {
                          staticClass: "date-picker",
                          attrs: {
                            disabled: _vm.readOnly,
                            id: "gab_startDate",
                            size: "sm",
                            "today-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateSelected(false, $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.local_startDate,
                            callback: function ($$v) {
                              _vm.local_startDate = $$v
                            },
                            expression: "local_startDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      { staticClass: "d-flex mr-2" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-1 gantt-action-bar-datetime-label",
                            attrs: { for: "gab_endDate" },
                          },
                          [_vm._v(_vm._s(_vm.$t("task.control.date_to")))]
                        ),
                        _c("b-form-datepicker", {
                          staticClass: "date-picker",
                          attrs: {
                            disabled: _vm.readOnly,
                            id: "gab_endDate",
                            size: "sm",
                            "today-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateSelected(true, $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.local_endDate,
                            callback: function ($$v) {
                              _vm.local_endDate = $$v
                            },
                            expression: "local_endDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "div",
                      { staticClass: "d-flex mr-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-1 align-self-baseline",
                            attrs: { for: "gab_timescale" },
                          },
                          [_vm._v(_vm._s(_vm.$t("task.control.timescale")))]
                        ),
                        _c("b-form-select", {
                          staticClass: "mw-150 align-self-baseline",
                          attrs: {
                            disabled: _vm.readOnly,
                            id: "gab_timescale",
                            options: _vm.scaleOptions,
                            size: "sm",
                          },
                          on: { change: _vm.changeTimescale },
                          model: {
                            value: _vm.local_timescale,
                            callback: function ($$v) {
                              _vm.local_timescale = $$v
                            },
                            expression: "local_timescale",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  !_vm.hideCriticalPath && !_vm.hideFreeFloat
                    ? _c("li", { staticClass: "divider" }, [
                        _c(
                          "span",
                          {
                            staticClass: "action-v-divider",
                            attrs: { readonly: "" },
                          },
                          [_vm._v("|")]
                        ),
                      ])
                    : _vm._e(),
                  !_vm.hideCriticalPath
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "gab_criticalpath" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "tool-button",
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    pressed: _vm.local_criticalPath,
                                  },
                                  on: { click: _vm.toggleCriticalPath },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "calendar-exclamation"],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "gab_criticalpath",
                                triggers: "hover",
                                placement: "top",
                                boundary: "viewport",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("task.control.critical_path")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canView() && !_vm.hideFreeFloat
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "gab_freefloat" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "tool-button",
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    pressed: _vm.local_freeFloat,
                                  },
                                  on: { click: _vm.toggleFreeFloat },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "arrows-left-right"],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "gab_freefloat",
                                triggers: "hover",
                                placement: "top",
                                boundary: "viewport",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("task.control.free_float")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "menu-toggler",
              style: { top: 7 + _vm.menuTogglerOffsetTop + "px" },
            },
            [
              _c(
                "b-dropdown",
                {
                  staticClass: "settings-dropdown",
                  attrs: { offset: "25", "no-caret": "" },
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "div",
                      { staticClass: "text" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "ellipsis-vertical"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("showSettings")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.settings")))]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.allowManage && !_vm.hideSecondRow
        ? _c(
            "PriorityNavigation",
            {
              staticClass: "task-toolbar grid-toolbar",
              attrs: {
                ulClassName: "task-toolbar grid-toolbar",
                forceRefresh: _vm.forcePriorityNavRefresh,
              },
              on: {
                "update:forceRefresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
                "update:force-refresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
              },
            },
            [
              !_vm.hideMasterCheckBox
                ? _c(
                    "li",
                    [
                      _c("b-form-checkbox", {
                        staticClass: "secondary-checkbox select-state",
                        attrs: {
                          disabled: _vm.readOnly || _vm.actionProcessing,
                          id: "BTN_SELECT_" + _vm.id,
                          indeterminate: _vm.masterCheckboxIndeterminateState,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$emit("master-checkbox-changed", $event)
                          },
                        },
                        model: {
                          value: _vm.local_selectAll,
                          callback: function ($$v) {
                            _vm.local_selectAll = $$v
                          },
                          expression: "local_selectAll",
                        },
                      }),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_SELECT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.select")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd() && !_vm.hideTaskAdd
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_ADD_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.readOnly || _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-new-open", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_ADD_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canView()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_EDIT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableEdit ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-edit-open", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.canEdit()
                                  ? _vm.$t("button.edit")
                                  : _vm.$t("button.view")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canDelete()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_DELETE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableDelete ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("row-delete", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_DELETE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              ((_vm.canAdd() && !_vm.hideTaskAdd) ||
                _vm.canView() ||
                _vm.canDelete()) &&
              _vm.canEdit(_vm.permissionName, ["TASK"]) &&
              !(_vm.hideTaskOutdent && _vm.hideTaskIndent)
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit(_vm.permissionName, ["TASK"]) && !_vm.hideTaskOutdent
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_OUTDENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableOutdent ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-outdent", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "outdent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_OUTDENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.outdent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canEdit(_vm.permissionName, ["TASK"]) && !_vm.hideTaskIndent
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_INDENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableIndent ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-indent", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "indent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_INDENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.indent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canAdd() && !_vm.hideTaskCopy) ||
              (_vm.canEdit() && !_vm.hideTaskPaste)
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canAdd() && !_vm.hideTaskCopy
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_COPY_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableTaskCopy ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-copy", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "copy"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_COPY_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.copy")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd() && !_vm.hideTaskPaste
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_PASTE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableTaskPaste ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-paste", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "paste"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_PASTE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.paste")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canAdd() &&
                _vm.canList("TEMPLATE__PROJECT") &&
                !_vm.hideTemplateApply) ||
              (_vm.canAdd("TEMPLATE__PROJECT") &&
                _vm.canAdd("TEMPLATE__TASK") &&
                !_vm.hideTemplateSave)
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              !_vm.hideTemplateApply &&
              _vm.canAdd() &&
              _vm.canList("TEMPLATE__PROJECT")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_APPLY_TEMPLATE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.readOnly || _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("template-apply", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "layer-plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_APPLY_TEMPLATE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.apply_template")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.hideTemplateSave &&
              _vm.canAdd("TEMPLATE__PROJECT") &&
              _vm.canAdd("TEMPLATE__TASK")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_SAVE_TEMPLATE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableDelete ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("template-save", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "layer-group"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_SAVE_TEMPLATE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.save_template")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              !_vm.hideFileImport && _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_IMPORT_DOCUMENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.disableWhenMoreThanOne ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("file-import", {
                                    triggeredByActionBar: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "inbox-in"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.import_document")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled: _vm.readOnly || _vm.actionProcessing,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("file-export", {
                                triggeredByActionBar: true,
                              })
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-out"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("task.button.export_document")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.canEdit() &&
              !(
                _vm.hideScheduling &&
                _vm.disableManualScheduleProject &&
                _vm.disableAutoScheduleProject
              )
                ? _c("li", [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit() &&
              !(
                _vm.disableManualScheduleProject &&
                _vm.disableAutoScheduleProject
              )
                ? _c(
                    "li",
                    [
                      _vm.disableManualScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: "BTN_MANUAL_SCHEDULE_PROJECT_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.readOnly || _vm.actionProcessing,
                                    },
                                    on: {
                                      click: _vm.autoScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "thumbtack"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target:
                                    "BTN_MANUAL_SCHEDULE_PROJECT_" + _vm.id,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.manual_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.disableAutoScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: "BTN_AUTO_SCHEDULE_PROJECT_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.readOnly || _vm.actionProcessing,
                                    },
                                    on: {
                                      click: _vm.manualScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "calendar-check"],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "BTN_AUTO_SCHEDULE_PROJECT_" + _vm.id,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.auto_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.hideScheduling && _vm.canEdit()
                ? _c(
                    "li",
                    {
                      staticClass: "scheduler",
                      on: _vm._d({ mouseleave: _vm.onSchedulerLeave }, [
                        _vm.schedulerMouseEnterEvent,
                        _vm.onSchedulerOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "scheduler",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            disabled: _vm.readOnly,
                            id: "BTN_SCHEDULE_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4096594029
                          ),
                        },
                        [
                          !_vm.readOnly
                            ? [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.disableManualScheduleTask ||
                                        _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: {
                                      click: _vm.manualScheduleTaskHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "item-icon",
                                      attrs: { icon: ["far", "thumbtack"] },
                                    }),
                                    _c("span", { staticClass: "item-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "task.button.manual_schedule_task"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.disableAutoScheduleTask ||
                                        _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: { click: _vm.autoScheduleTaskHandler },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "item-icon",
                                      attrs: {
                                        icon: ["far", "calendar-check"],
                                      },
                                    }),
                                    _c("span", { staticClass: "item-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "task.button.auto_schedule_task"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                !_vm.isTemplate
                                  ? [
                                      _c("b-dropdown-divider"),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled: _vm.actionProcessing,
                                            href: "#",
                                          },
                                          on: {
                                            click:
                                              _vm.scheduleProjectNowHandler,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "item-icon",
                                            attrs: {
                                              icon: ["far", "calendar-days"],
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "item-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "task.button.schedule_project_now"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.hideAutoAssignTasks && _vm.canEdit()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_AUTO_ASSIGN_STAFF_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  !_vm.hasAutoAssignTasks ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.autoAssignStaff },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "user-helmet-safety"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_AUTO_ASSIGN_STAFF_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.auto_assign_staff")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              !_vm.hideTaskCollapse
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_COLLAPSE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.actionProcessing ||
                                  _vm.expandLevel === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-collapse")
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["far", "magnifying-glass-minus"],
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_COLLAPSE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("task.button.minus")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.hideTaskExpand
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_EXPAND_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.readOnly ||
                                  _vm.actionProcessing ||
                                  _vm.expandLevel === _vm.maxLevel,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("task-expand")
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["far", "magnifying-glass-plus"],
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_EXPAND_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("task.button.plus")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.hideSearch
                ? _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                        _vm.filterMouseEnterEvent,
                        _vm.onFilterOver,
                      ]),
                    },
                    [
                      !_vm.filterPinned
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "filter",
                              staticClass: "action-bar-dropdown text-filter",
                              attrs: {
                                disabled: _vm.readOnly,
                                id: "BTN_FILTER_" + _vm.id,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              on: { hide: _vm.onFilterTextDropdownHide },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.local_filterText !== ""
                                              ? "active"
                                              : "",
                                          attrs: {
                                            icon: [
                                              "far",
                                              "file-magnifying-glass",
                                            ],
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2505458109
                              ),
                            },
                            [
                              !_vm.readOnly
                                ? _c(
                                    "b-dropdown-form",
                                    {
                                      staticClass: "filter-padding",
                                      on: {
                                        submit: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "search-input" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder:
                                                _vm.$t("task.filter"),
                                            },
                                            on: {
                                              focus: function ($event) {
                                                _vm.filterTextFocus = true
                                              },
                                              blur: function ($event) {
                                                _vm.filterTextFocus = false
                                              },
                                            },
                                            model: {
                                              value: _vm.local_filterText,
                                              callback: function ($$v) {
                                                _vm.local_filterText = $$v
                                              },
                                              expression: "local_filterText",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  staticClass:
                                                    "search-append search-append-bg",
                                                  attrs: {
                                                    id:
                                                      "BTN_CLEAR_FILTER_" +
                                                      _vm.id,
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.onFilterClear,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "xmark"],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "BTN_CLEAR_FILTER_" +
                                                      _vm.id,
                                                    triggers: "hover",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("button.clear")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  staticClass:
                                                    "search-append search-append-bg",
                                                  attrs: {
                                                    id:
                                                      "BTN_SEARCH_FILTER_" +
                                                      _vm.id,
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.onFilterSubmit,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "magnifying-glass",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "BTN_SEARCH_FILTER_" +
                                                      _vm.id,
                                                    triggers: "hover",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("button.search")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  staticClass:
                                                    "search-append search-append-bg",
                                                  attrs: {
                                                    id:
                                                      "BTN_PIN_FILTER_" +
                                                      _vm.id,
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.onPinFilter,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "thumbtack",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "BTN_PIN_FILTER_" +
                                                      _vm.id,
                                                    triggers: "hover",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "button.pinOnToolbar"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                [
                  _vm.filterPinned
                    ? _c(
                        "b-input-group",
                        { staticClass: "search-input" },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: _vm.$t("task.filter") },
                            on: {
                              focus: function ($event) {
                                _vm.filterTextFocus = true
                              },
                              blur: function ($event) {
                                _vm.filterTextFocus = false
                              },
                            },
                            model: {
                              value: _vm.local_filterText,
                              callback: function ($$v) {
                                _vm.local_filterText = $$v
                              },
                              expression: "local_filterText",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: "BTN_CLEAR_FILTER_" + _vm.id,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onFilterClear },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "xmark"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_CLEAR_FILTER_" + _vm.id,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.clear")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: "BTN_SEARCH_FILTER_" + _vm.id,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onFilterSubmit },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "magnifying-glass"],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_SEARCH_FILTER_" + _vm.id,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.search")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: "BTN_UNPIN_FILTER_" + _vm.id,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onUnPinFilter },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "thumbtack"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_UNPIN_FILTER_" + _vm.id,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.unpinFromToolbar")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.hideSearch
                ? _c(
                    "li",
                    {
                      on: _vm._d({}, [
                        _vm.badgeFilterMouseEnterEvent,
                        _vm.onBadgeFilterEnter,
                        _vm.badgeFilterMouseOverEvent,
                        _vm.onBadgeFilterOver,
                        _vm.badgeFilterMouseLeaveEvent,
                        _vm.onBadgeFilterLeave,
                        _vm.badgeFilterTouchEndEvent,
                        _vm.onBadgeFilterOver,
                      ]),
                    },
                    [
                      !_vm.badgeFilterPinned
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "badgeFilter",
                              staticClass: "action-bar-dropdown text-filter",
                              attrs: {
                                disabled: _vm.isAccessDenied,
                                id: "BTN_BADGE_FILTER_" + _vm.id,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              on: { hide: _vm.onBadgeFilterDropdownHide },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.badgeFilters.length > 0
                                              ? "active"
                                              : "",
                                          attrs: { icon: ["far", "filter"] },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3929189510
                              ),
                            },
                            [
                              !_vm.isAccessDenied
                                ? [
                                    _c(
                                      "b-dropdown-form",
                                      {
                                        staticClass: "filter-padding",
                                        on: {
                                          submit: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c("TaskViewBadgeFilter", {
                                          attrs: {
                                            filters: _vm.badgeFilters,
                                            fields: _vm.badgeFilterFields,
                                            fieldValues:
                                              _vm.badgeFilterFieldValues,
                                            useLink: true,
                                            canPin: "",
                                          },
                                          on: {
                                            modified: _vm.onBadgeFilterModified,
                                            filterModalOpened:
                                              _vm.onBadgeFilterModalOpened,
                                            filterModalClosed:
                                              _vm.onBadgeFilterModalClosed,
                                            fetchFieldOptions:
                                              _vm.onBadgeFilterFetchOptions,
                                            pin: _vm.onPinBadgeFilter,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                [
                  _vm.badgeFilterPinned
                    ? _c("TaskViewBadgeFilter", {
                        attrs: {
                          filters: _vm.badgeFilters,
                          fields: _vm.badgeFilterFields,
                          fieldValues: _vm.badgeFilterFieldValues,
                          canPin: "",
                          pinned: "",
                        },
                        on: {
                          modified: _vm.onBadgeFilterModified,
                          filterModalOpened: _vm.onBadgeFilterModalOpened,
                          filterModalClosed: _vm.onBadgeFilterModalClosed,
                          fetchFieldOptions: _vm.onBadgeFilterFetchOptions,
                          pin: _vm.onUnPinBadgeFilter,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass: "view",
                  on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                    _vm.viewMouseEnterEvent,
                    _vm.onViewOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "view",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        disabled: _vm.readOnly,
                        id: "BTN_VIEW_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "desktop"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4186387599
                      ),
                    },
                    [
                      !_vm.readOnly
                        ? [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.showAllColumns },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.button.all_columns"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.showNoColumns },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.button.no_columns"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("b-dropdown-divider"),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.savePreset },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("task.button.save_as_gantt_view")
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("b-dropdown-divider"),
                            _vm._l(_vm.views, function (item, index) {
                              return [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    key: index,
                                    staticClass: "action-item",
                                    attrs: {
                                      disabled: _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadViewSettings(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "action-item-label-with-icon",
                                        attrs: { id: "viewname" + index },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _vm.isEllipsisActive(item.name)
                                      ? _c("b-popover", {
                                          attrs: {
                                            target: "viewname" + index,
                                            placement: "top",
                                            boundary: "viewport",
                                            "custom-class": "popover-margin",
                                            triggers: "hover",
                                            offset: "-100",
                                            content: item.name,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      [
                                        !_vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon",
                                                  attrs: {
                                                    id: "COPY_GANTT_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.copyColumnSettings(
                                                        index,
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "copy"],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "COPY_GANTT_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.copy_gantt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-third",
                                                  attrs: {
                                                    id: "BTN_INFO_" + index,
                                                  },
                                                  on: _vm._d(
                                                    {
                                                      mouseleave: function (
                                                        $event
                                                      ) {
                                                        return _vm.onInfoLeave(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    [
                                                      _vm.infoMouseEnterEvent,
                                                      function ($event) {
                                                        return _vm.onInfoOver(
                                                          index
                                                        )
                                                      },
                                                    ]
                                                  ),
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "circle-info",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("b-popover", {
                                                ref: "BTN_INFO_" + index,
                                                refInFor: true,
                                                attrs: {
                                                  target: "BTN_INFO_" + index,
                                                  show: _vm.showInfo[index],
                                                  placement: "top",
                                                  boundary: "viewport",
                                                  triggers: "manual",
                                                  content: _vm.$t("owner", [
                                                    _vm.views[index].owner,
                                                  ]),
                                                },
                                                on: {
                                                  "update:show": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.showInfo,
                                                      index,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-third",
                                                  attrs: {
                                                    id: "SHARE_GANTT_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.shareColumnSettings(
                                                        index,
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        item.defaultView
                                                          ? "fas"
                                                          : "far",
                                                        "share-nodes",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "SHARE_GANTT_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.share_gantt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-second",
                                                  attrs: {
                                                    id: "UPDATE_GANTT_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.updateColumnSettings(
                                                        index,
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "floppy-disk",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "UPDATE_GANTT_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.update_gantt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon",
                                                  attrs: {
                                                    id: "DELETE_GANTT_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeColumnSettings(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "trash-can",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "DELETE_GANTT_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.delete_gantt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                    _vm.colorMouseEnterEvent,
                    _vm.onColoringOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "coloring",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        disabled: _vm.readOnly,
                        id: "BTN_COLORING_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "palette"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1244906976
                      ),
                    },
                    [
                      !_vm.readOnly
                        ? _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("colorby") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "none",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("none")))]
                                  ),
                                  _vm.coloring.none
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "task",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.task"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.task
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "stage",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.stage"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.stage
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.coloring.company =
                                        !_vm.coloring.company
                                      _vm.onColorChange(
                                        "skill",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.skill"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.skill
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "staff",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.staff"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.staff
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "resource",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.resource"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.resource
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "rebate",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.rebate"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.rebate
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange(
                                        "file",
                                        "gantt_view_coloring"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.coloring.file"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.file
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_ENABLE_FLATLIST_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        { on: { click: _vm.toggleFlatListHandler } },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: [
                                "far",
                                _vm.flatList ? "list-tree" : "list",
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_ENABLE_FLATLIST_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.flatList
                                ? "task.button.tree"
                                : "task.button.flatlist"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }