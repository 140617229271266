import { toFixed } from '@/helpers/task-duration-process';
import { costFormat } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export function evaluateSearch(value, data) {
  const terms = value.toLowerCase().split(',');
  for (const term of terms) {
    
    if (term.trim().length > 0) {
      if (term.includes('+')) {
        const parts = term.split('+');
        const matched = parts.filter(p => 
          Object.keys(data).filter(key => checkMatch(key, data[key], p.trim())).length !== 0);
        if (matched.length === parts.filter(p => p.trim().length !== 0).length) {
          return true;
        }
      }
      else if (Object.keys(data).filter(key => checkMatch(key, data[key], term.trim())).length !== 0) {
        return true;
      }
    }
  }
  return false;
}

function checkMatch(key, value, term) {
  if (value === null ||
      value === "null" ||
      key === 'tu' ||
      key.toLowerCase().includes('uuid')) {
    return false;
  }
  
  if (term.length !== 0) {
    if (typeof value === 'string' && value.toLowerCase().includes(term)) {
      return true;
    }
    else if (Array.isArray(value) && value.filter(a => typeof a === 'object' && 
            a !== null &&
            ((a.name && a.name.toLowerCase().includes(term)) ||
            (a.text && a.text.toLowerCase().includes(term)) ||
            (a.level && a.level.toLowerCase().includes(term)))).length !== 0) {
      return true;
    }
    else if (Array.isArray(value) && value.filter(a => typeof a === 'string' && 
        a.toLowerCase().includes(term)).length !== 0) {
      return true;
    }
    else if (typeof value === 'object' && value.name &&
             value.name.toLowerCase().includes(term)) {
      return true;
    }
    else if (key.includes('Cost') && `$${costFormat(value)}`.toLowerCase().includes(term)) {
      return true;
    }
    else if (key === 'progress') {
      const dispVal = `${toFixed(value * 100, 0)}%`;
      if (dispVal.includes(term)) {
        return true;
      }
    }
    else if (key === 'startTime' ||
             key === 'closeTime' ||
             key === 'constraintTime') {
      const dispVal = moment.utc(value).format('YYYY-MM-DD hh:mm A');
      if (dispVal.includes(term)) {
        return true;
      }
    }
  }
  return false;
}

function eventMatches(f, event, field) {

  // use operator to switch between is and is not
  if (!f.operator || f.operator === 'is') {
    if (Array.isArray(f.value) && -1 !== f.value.findIndex(v => event[field] === v.text)) {
      return true;
    }
  }
  else {
    if (Array.isArray(f.value) && f.value.length === f.value.filter(v => event[field] !== v.text).length) {
      return true;
    }
  }
  return false;
}

function applyRule(list, value, operator) {
  if (operator === 'is' || !operator) {
    return -1 !== list.findIndex(sm => sm.text === value);
  }
  else {
    return list.length === list.filter(l => l.text !== value).length
  }
}

export function filterCheck(val, filter, filterText, events = null, projectId = null) {
  var ret = false;
  var textMatch = false;
  var typeMatch = false;
  var projectIdMatch = false;
  if (filter.length === 0 && !filterText && projectId === null) {
    return true;
  }

  if (projectId && events && val.uuId in events) {
    const eventList = events[val.uuId];
    for (const prjevent of eventList) {
      if (prjevent.pu === projectId ||
          prjevent.project === projectId) {
        projectIdMatch = true;
        break;
      }
    }
  }
  
  if (filterText) {
    const filterTerms = filterText.split(',');
    for (const fText of filterTerms) {
      const parts = fText.split('+');
      let matchCount = 0;
      for (let part of parts) {
        part = part.trim();
        if (part !== '') {
          // check all fields to see if they contain filterText
          for (const key of Object.keys(val)) {
            if (typeof val[key] === 'string' &&
                key !== 'frequency' && // blacklist this value
                key !== 'uuId' &&
                val[key].toLowerCase().indexOf(part.toLowerCase()) !== -1 &&
                (projectIdMatch || !projectId)) {
              matchCount++;
              break;
            }
            else if (key === 'tagList' &&
                     Array.isArray(val[key])) {
              for (const entry of val[key]) {
                if (entry &&
                    entry.name &&
                    entry.name.toLowerCase().indexOf(part.toLowerCase()) !== -1 &&
                    (projectIdMatch || !projectId)) {
                  matchCount++;
                  break;
                }
              }
            }
          }
          
          // check the events also
          if (events && val.uuId in events) {
            const eventList = events[val.uuId];
            for (const event of eventList) {
              if ((event.text.toLowerCase().indexOf(part.toLowerCase()) !== -1 ||
                  (event.stageName && event.stageName.toLowerCase().indexOf(part.toLowerCase()) !== -1)) &&
                 (projectIdMatch || !projectId)) {
                matchCount++;
                break;
              }
            }
          }
        }
      }
      
      if (matchCount >= parts.length) {
        textMatch = true;
        break;
      }
    }
  }
  
  let count = 0;
  const fieldMatch = {};
  for (const f of filter) {
    if (events && 
        (f.field === 'projectName' ||
         f.field === 'activityName' ||
         f.field === 'bookingName' ||
         f.field === 'taskName' ||
         f.field === 'stageName' ||
         f.field === 'nonWork')) {
      const field = f.field === 'projectName' ? 'pn' : f.field === 'taskName' ? 'name'  : f.field === 'stageName' ? f.field : 'text';
      if (events && val.uuId in events) {
        const eventList = events[val.uuId];
        for (const event of eventList) {
          if (eventMatches(f, event, field)) {
            let nameFilters = null;
            if (f.field === 'stageName' && filter.find(fil => fil.field === 'activityName' ||
                                                              fil.field === 'bookingName' ||
                                                              fil.field === 'taskName')) {
              nameFilters = filter.filter(fil => fil.field === 'activityName' ||
                  fil.field === 'bookingName' ||
                  fil.field === 'taskName')
              const nameField = nameFilters[0].field === 'taskName' ? 'name' : 'text';
              if (eventMatches(nameFilters[0], event, nameField)) {
                count += 2; // the filter matches 2 conditions
                fieldMatch[f.field] = true;
                fieldMatch[nameFilters[0].field] = true;
                break;
              }
            }
            else if (f.field === 'stageName' && filter.find(fil => fil.field === 'projectName')) {
              nameFilters = filter.filter(fil => fil.field === 'projectName')
              if (eventMatches(nameFilters[0], event, 'pn')) {
                count += 2; // the filter matches 2 conditions
                fieldMatch[f.field] = true;
                fieldMatch[nameFilters[0].field] = true;
                break;
              }
            } 
            else if ((f.field === 'activityName' ||
                      f.field === 'bookingName' ||
                      f.field === 'taskName') && filter.find(fil => fil.field === 'stageName')) {
              nameFilters = filter.filter(fil => fil.field === 'stageName')
              if (eventMatches(nameFilters[0], event, 'text')) {
                count += 2; // the filter matches 2 conditions
                fieldMatch[f.field] = true;
                fieldMatch[nameFilters[0].field] = true;
                break;
              }
            }
            else {
              count++;
              fieldMatch[f.field] = true;
              break;
            }
          }
        }
      }
    }

    // use operator to switch between is and is not
    if (!f.operator || f.operator === 'is') {
      if (f.field === 'tag' && val.tagList &&
               -1 !== f.value.findIndex(v => val.tagList.findIndex(t => t.name === v.text) !== -1)) {
        count++; // keep track of matches for the AND condition
        fieldMatch[f.field] = true;
      }
    }
    else {
      if (f.field === 'tag' && val.tagList &&
          f.value.length === f.value.filter(v => val.tagList.filter(t => t.name !== v.text).length === val.tagList.length).length) {
         count++; // keep track of matches for the AND condition
         fieldMatch[f.field] = true;
      }
    }

    if ((filter.filter(fil => fil.field === 'skill' || fil.field === 'skillLevel').length === 2 && f.field === 'skill') ||
        f.field === 'skillLevel') {
      // use operator to switch between is and is not
      if (!f.operator || f.operator === 'is') {
        const skillLevelMatches = val.skillList ? f.value.filter(v => val.skillList.findIndex(t => t.level === v.text) !== -1) : [];
        if (f.field === 'skillLevel' &&
                 skillLevelMatches.length !== 0) {
          const skillFilters = filter.filter(fil => fil.field === 'skill');
          if (skillFilters.length !== 0) {
            if (((!skillFilters[0].operator || skillFilters[0].operator === 'is') && -1 !== skillFilters[0].value.findIndex(v => val.skillList.findIndex(t => t.name === v.text && applyRule(skillLevelMatches, t.level, f.operator)) !== -1)) ||
                (skillFilters[0].operator === 'is_not' && f.value.length === f.value.filter(v => val.skillList.filter(t => t.name !== v.text || applyRule(skillLevelMatches, t.level, f.operator)).length === val.skillList.length).length)) {
              count += 2;
              fieldMatch[f.field] = true;
              fieldMatch[skillFilters[0].field] = true;
            }
          }
          else {
            count++; // keep track of matches for the AND condition
            fieldMatch[f.field] = true;
          }
        }
      }
      else {
        const notSkillLevelMatches = val.skillList ? f.value.filter(v => val.skillList.filter(t => t.level !== v.text).length === val.skillList.length) : [];
        if (f.field === 'skillLevel' &&
            f.value.length === notSkillLevelMatches.length) {
          const skillFilters = filter.filter(fil => fil.field === 'skill');
          if (skillFilters.length !== 0) {
            if (((!skillFilters[0].operator || skillFilters[0].operator === 'is') && -1 !== skillFilters[0].value.findIndex(v => val.skillList.findIndex(t => t.name === v.text && applyRule(notSkillLevelMatches, t.level, f.operator)) !== -1)) ||
                (skillFilters[0].operator === 'is_not' && f.value.length === f.value.filter(v => val.skillList.filter(t => t.name !== v.text || applyRule(notSkillLevelMatches, t.level, f.operator)).length === val.skillList.length).length)) {
              count += 2;
              fieldMatch[f.field] = true;
              fieldMatch[skillFilters[0].field] = true;
            }
          }
          else {
           count++; // keep track of matches for the AND condition
           fieldMatch[f.field] = true;
          }
        }
      }
    }
    else {
      // use operator to switch between is and is not
      if (!f.operator || f.operator === 'is') {
        if ((Array.isArray(f.value) 
            && (-1 !== f.value.findIndex(v => {
                  return val[f.field] === v.text 
                    || (val[`${f.field}List`] && val[`${f.field}List`].find(l => l.name === v.text))
                    || (f.field.startsWith(val.type) && val['name'] === v.text)
                    || (v.text === '(Empty)' 
                        && (val[f.field] == null 
                            || (typeof val[f.field] === 'string' && val[f.field] == '')))
                }) 
                || -1 !== f.value.findIndex(v => val.name === v.text))
            )) {
          count++; // keep track of matches for the AND condition
          fieldMatch[f.field] = true;
          if (f.field.startsWith(val.type)) {
            typeMatch = true;
          }
        }
      }
      else {
        if (
            (Array.isArray(f.value) 
              && (f.value.length === f.value.filter(v => {
                    return (typeof val[f.field] !== 'undefined' && val[f.field] !== v.text && v.text !== '(Empty)') 
                      || (f.field.startsWith(val.type) && f.field !== 'staffType' && val['name'] !== v.text)
                      || (v.text === '(Empty)' 
                          && (val[f.field] != null 
                              && typeof val[f.field] === 'string' 
                              && val[f.field] !== ''))
                  }).length 
                  || (f.field.startsWith(val.type) 
                      && f.field !== 'staffType' && f.value.length === f.value.filter(v => val.name !== v.text).length)))) {
          count++; // keep track of matches for the AND condition
          fieldMatch[f.field] = true;
          if (f.field.startsWith(val.type)) {
            typeMatch = true;
          }
        }
      }
    }
  }
  
  if (filter.length !== 0) {
    if ((filter.filter(f => fieldMatch[f.field]).length >= filter.filter(f => Array.isArray(f.value)).length && (textMatch || !filterText)) &&
        (projectId === null || projectIdMatch)) {
      ret = true; // the value matches all filter conditions
    }
  }
  else {
    if ((textMatch || !filterText) &&
        (projectId === null || projectIdMatch)) {
      ret = true; // the value matches all filter conditions
    }
  }
  
  if (!ret && val.children && 
      (!filter.find(f => f.field.startsWith(val.type)) ||
       (filter.find(f => f.field.startsWith(val.type)) && count !== 0 && typeMatch))) {
    for (let c = val.children.length - 1; c >= 0; c--) {
      const child = val.children[c];
      if(filterCheck(child, filter, filterText, events, projectId)) {
        ret = true;
      }
      else {
        val.children.splice(c, 1);
      }
    }
    
    if (val.children.length === 0) {
      delete val.children;
    }
  }
  return ret;
}