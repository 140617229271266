<template>
  <div class="kanban-page-container">
    <div v-if="!isWidget" class="kanban-controls">
      <div v-if="configDidLoad" class="board-name">{{boardName}}</div>
      <div v-else>&nbsp;</div>
      <div class="kanban-search-container">
        <b-input-group class="search-group">
          <b-form-input class="search-field" v-model="searchValue" :placeholder="$t('project.placeholder.search')" trim
            @keydown.native="keydownHandler"></b-form-input>
          <b-input-group-append>
            <b-btn @click="clear" variant="danger" class="search-clear-btn"><font-awesome-icon class="search-clear" :icon="['far', 'xmark']"/></b-btn>
          </b-input-group-append>
          <b-input-group-append>
            <b-btn  @click="search" variant="secondary"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-btn id="project_settings"
            class="filter-button btn-secondary btn-kanban"
            @click="showProjectSettings()">
              <b-popover
                target="project_settings"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('project.settings.filter')">
              </b-popover>
              <font-awesome-icon :icon="['far', 'filter']" :class="filter.query === null || (filter.query !== null && filter.query.children.length === 0) ? '' : 'active'" />
      </b-btn>
      <b-btn id="clear_project_settings"
            class="clear-filter-button btn-secondary btn-kanban"
            @click="clearProjectSettings()">
              <b-popover
                target="clear_project_settings"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('project.settings.clear')">
              </b-popover>
              <font-awesome-icon :icon="['far', 'filter-circle-xmark']" :style="{ paddingTop: '5px' }"/>
      </b-btn>
      <template v-if="configDidLoad && canEdit('PROJECT')">
        <b-btn id="kanban_settings"
              class="settings-button btn-secondary btn-kanban"
              @click="showKanbanSettings()">
                <font-awesome-icon :icon="['far', 'gear']"/>
        </b-btn>
        <b-popover target="kanban_settings" triggers="hover" placement="top" boundary="viewport">
          {{ $t('kanban.settings.board') }}
        </b-popover>
      </template>
      <div class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
        <b-dropdown :id="`KANBAN_BTN_VIEW`" ref="view" class="action-bar-dropdown view-btn" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far','desktop']"/>
          </template>
          <b-dropdown-item @click="savePreset" href="#">
            <span class="action-item-label">{{ $t('kanban.button.save') }}</span>
          </b-dropdown-item>
          <b-dropdown-divider/>
          <template v-for="(item, index) in kanbanViews">
            <b-dropdown-item class="action-item" @click="loadViewSettings(item)" href="#" :key="index">
              <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
              <b-popover
                v-if="isEllipsisActive(item.name)"
                :target="`viewname${index}`"
                placement="top"
                boundary="viewport"
                custom-class="popover-margin"
                triggers="hover"
                offset="-100"
                :content="item.name">
              </b-popover> 
              <span>
                <span class="action-item-icon" 
                    v-if="!editPermission(item)"
                    :id="`BTN_COPY_${index}`"
                    @click.stop.prevent="copyColumnSettings(item.name, item)">
                  <font-awesome-icon class="" :icon="['far','copy']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_COPY_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t('kanban.button.copy')">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="!editPermission(item)"
                    @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                    :id="`BTN_INFO_${index}`">
                  <font-awesome-icon class="" :icon="['far','circle-info']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_INFO_${index}`"
                  :ref="`BTN_INFO_${index}`"
                  :show.sync="showInfo[index]"
                  placement="top"
                  boundary="viewport"
                  triggers="manual"
                  :content="$t('owner', [kanbanViews[index].owner])">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="editPermission(item)"
                    :id="`BTN_SHARE_${index}`"
                    @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_SHARE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t('kanban.button.share')">
                </b-popover>
                <span class="action-item-icon position-second" 
                    v-if="editPermission(item)"
                    :id="`BTN_UPDATE_${index}`"
                    @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="['far','save']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_UPDATE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t('kanban.button.update')">
                </b-popover>
                <span class="action-item-icon"
                    v-if="editPermission(item)"
                    :id="`BTN_DELETE_${index}`"
                    @click.stop.prevent="removeColumnSettings(index)">
                  <font-awesome-icon class="" :icon="['far','trash-alt']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_DELETE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t('kanban.button.delete')">
                </b-popover>
              </span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <div v-if="canEdit('PROJECT')" @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
        <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="coloring-btn action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far', 'palette']"/>
          </template>
          <b-dropdown-group :header="$t('colorby')">
            <b-dropdown-item @click="onColorChange('none', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('column', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.column') }}</span><font-awesome-icon class="active-check" v-if="coloring.column" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('task', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.task') }}</span><font-awesome-icon class="active-check" v-if="coloring.task" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('stage', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="coloring.company = !coloring.company; onColorChange('skill', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('staff', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('resource', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="coloring.resource" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('rebate', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.rebate') }}</span><font-awesome-icon class="active-check" v-if="coloring.rebate" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onColorChange('file', 'kanban_coloring')" href="#">
              <span class="action-item-label">{{ $t('task.coloring.file') }}</span><font-awesome-icon class="active-check" v-if="coloring.file" :icon="['far', 'check']"/>
            </b-dropdown-item>
          </b-dropdown-group>
        </b-dropdown>
      </div>
      <div v-if="configDidLoad && canEdit('PROJECT')" class="kanban-attribute-toggler">
        <b-dropdown id="kanban_settings_display" variant="link" offset="25" no-caret>
          <template slot="button-content">
            <div class="text">
              <font-awesome-icon :icon="['far', 'ellipsis-vertical']"/>
            </div>
          </template>
          <template v-for="(item, key) in display">
              <div :key="key" class="dropdown-item" @click.prevent>
                <b-form-checkbox v-model="display[key]" @click.native="toggleVisible(key)">{{displayLabels[key]}}</b-form-checkbox>
              </div>
          </template>
        </b-dropdown>
        <b-popover target="kanban_settings_display" triggers="hover" placement="top" boundary="viewport">
          {{ $t('kanban.settings.display') }}
        </b-popover>
      </div>
    </div>
  
    <div class="kanban-container" :style="isWidget ? `height: ${height}px` : `height: calc(100vh - ${heightOffset}px)`">
      <span v-if="loading" class='kanban-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>{{ $t('kanban.loading') }}</span>
      <div v-else class="kanban-board" id="kanban-board" v-bind:class="{ 'mobile': mobile }" :set="move = []" :style="`min-height: calc(100vh - ${heightOffset+100}px)`">
        <div v-if="configDidLoad && columns.length == 0" id="kanban-empty">
          No project stages defined.
        </div>
        <div v-if="configDidLoad && columns.length > 0 && enabledColumns.length == 0" id="kanban-empty">
          No project stages are displayed.
        </div>
  
        <div v-for="(column, index) in enabledColumns" :key="index"
            class="kanban-column"
            :data-id="column.uuId"
            v-bind:class="{ 'max-reached': columnHasMax(column)}"
        >
          <div class="kanban-column-title" v-bind:style="{ backgroundColor: column.color, visibility: column.dropType == 'archive' ? 'hidden' : 'visible'}">
            <span class="kanban-column-name">{{column.name}}</span>
            <span class="kanban-column-title-right">
              <span v-if="(!dataview || canList('PROJECT')) && canAdd('TASK')" :id="`BTN_ADD_${index}`" class="kanban-column-add" @click="onNewTask(column.uuId, column.progress, projectId)">
                <i class="far fa-circle-plus">
                  <b-popover :target="`BTN_ADD_${index}`" triggers="hover" placement="top" boundary="viewport">
                    {{ $t('button.add_task') }}
                  </b-popover>
                </i>
              </span>
              <span class="kanban-column-limit">({{column.total ? column.total : column.tasks.length}}{{column.limit ? "/" +column.limit : ""}})</span>
            </span>
          </div>
          <div class="kanban-column-card-container dropzone"
            v-bind:class="{
              'dropzone-stage': column.dropType == 'stage',
              'dropzone-backlog': column.dropType == 'backlog',
            }"
            v-on:dragenter="dragEnter($event)"
            v-on:dragleave="dragLeave($event)"
            v-on:dragover="allowDrop"
            v-on:drop="drop($event)"
          >
            <template v-if="column.dropType == 'archive'">
              <div class="archive-content-container">
                <div class="dropzone dropzone-archive">
                  <div><i class="far fa-sign-out-alt"></i></div>
                  <div>{{ $t('kanban.archive_task') }}</div>
                </div>
              </div>
            </template>
            <div v-for="(task, tIndex) in column.tasks"
            :key="`${tIndex}_${task.uuId}_${JSON.stringify(coloring)}_${task.loaded}`"
            :data-id="task.uuId"
            :data-index="index"
            :data-tIndex="tIndex"
            class="kanban-column-card" :class="{'highlight': task.uuId == highlightOnLoad, 'nograb': !canView('TASK')}"
            :style="(dragId == task.uuId && highlightColor) || getStyle(task, true)"
            v-on:click="canView('TASK') ? onEdit(task) : ''"
            :draggable="canEdit('TASK') ? true : false"
            v-on:dragstart="dragStart($event)"
            v-on:dragend="dragEnd($event)"
            :set="move[task.uuId] = getMoveMap(column.uuId, task.projectId ? task.projectId : projectId)"
            >
              <lazy-component wrapper-tag="section" :threshold="[0, 0.25, 0.5, 0.75, 1]" @intersected="onIntersected(column.tasks, tIndex, ...arguments)" :data-uuId="task.uuId">
                <div v-if="display.image" class="card-line-img" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">
                    <b-img-lazy style="object-fit:contain" class="card-image mb-1" :src="avatarUrl(task.avatarRef)" v-if="display.image && task.avatarRef != null" @error.native="handleImageError(task.avatarRef)"/>
                </div>
                <div class="card-line0" v-if="!projectId && display.project_name">
                  <v-clamp autoresize :max-lines="1" class="kanban-card-project" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">
                    {{projects[task.projectId].name}}
                  </v-clamp>
                </div>
                <div class="card-line0">
                  <v-clamp autoresize :max-lines="1" class="kanban-card-path" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">{{task.path}}</v-clamp>
                </div>
                <div class="card-line1">
                    <v-clamp autoresize :max-lines="2" class="kanban-card-title" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">{{task.name}}</v-clamp>
                    <div v-if="display.avatar" class="kanban-card-users">
                      <span class="user-holder" v-for="staff in staffsForCard(task.staffs)" :key=staff.uuId>
                        <span v-if="staff.uuId in avatars">
                          <span :id="`STAFF_AVATAR_${task.uuId}_${staff.uuId}`" v-bind:style="{ backgroundImage: 'url('+ urlForStaffAvatar(staff.uuId) +')' }"
                                class="kanban-owner-label picture">&nbsp;</span>
                          <b-popover :target="`STAFF_AVATAR_${task.uuId}_${staff.uuId}`" triggers="hover" placement="top" boundary="viewport">
                            {{ staff.fullname }}
                          </b-popover>
                        </span>
                        <span v-else>
                          <span class="kanban-owner-label letter" v-bind:class="{ small: staff.small }"
                                :id="`STAFF_AVATAR_${task.uuId}_${staff.uuId}`">
                            {{staff.name}}
                          </span>
                          <b-popover :target="`STAFF_AVATAR_${task.uuId}_${staff.uuId}`" triggers="hover" placement="top" boundary="viewport">
                              {{ staff.fullname }}
                          </b-popover>
                        </span>
                      </span>
                    </div>
                </div>
                
                <div v-if="display.description" class="card-line2" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">
                    <v-clamp autoresize :max-lines="3" v-if="display.description" class="kanban-card-description">{{task.description}}</v-clamp>
                </div>
                <div v-if="display.due || display.priority || display.progress" class="card-line3" :style="(dragId == task.uuId && highlightColor) || getStyle(task)">
                    <div v-if="display.due" class="kanban-card-due"><i class="far fa-calendar-days"></i> {{task.closeTime ? shortDate(task.closeTime) : ''}}</div>
                    <div v-if="display.priority" class="kanban-card-priority" :class="task.priority">{{task.priority}}</div>
                    <div v-if="display.progress" class="kanban-card-progress">{{task.progress ? Math.round(task.progress*100) : 0}}%</div>
                </div>
                <div class="card-line4 quick-movers" :style="(dragId == task.uuId && highlightColor) || getStyle(task)" v-if="canEdit('TASK')">
                  <span>
                    <template v-if="move[task.uuId].leftId">
                      <span :id="`kanban_settings_move_left-${tIndex}-${move[task.uuId].leftId}`" @click='moveByArrow(task.uuId, move[task.uuId].leftId, $event)'>
                        <i class="far fa-circle-chevron-left"></i>
                      </span>
                      <b-popover :target="`kanban_settings_move_left-${tIndex}-${move[task.uuId].leftId}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('kanban.move_to') + move[task.uuId].leftName }}
                      </b-popover>
                    </template>
                  </span>
                  <span>
                    <template v-if="move[task.uuId].rightId">
                      <span :id="`kanban_settings_move_right-${tIndex}-${move[task.uuId].rightId}`" @click='moveByArrow(task.uuId, move[task.uuId].rightId, $event)'>
                        <i class="far fa-circle-chevron-right"></i>
                      </span>
                     <b-popover :target="`kanban_settings_move_right-${tIndex}-${move[task.uuId].rightId}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('kanban.move_to') + move[task.uuId].rightName }}
                      </b-popover>
                    </template>
                  </span>
                </div>
              </lazy-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <TaskModal
      :show.sync="taskEditShow"
      :id="taskEdit.uuId" 
      :projectId="taskEdit.projectId"
      :parentId="taskEdit.parentId"
      :stageForNew="taskEdit.stageId"
      :progressForNew="taskEdit.progress"
      @success="taskEditSuccess"
    />
    <KanbanSettingsModal
      :columns="columns"
      :profile="profile"
      :projects="projects"
      :show.sync="kanbanSettingsShow"
      @success="saveSettings"
    />

    <!-- project selector -->
    <GenericSelectorModalForAdmin v-if="projectSelectorShow"
      :show.sync="projectSelectorShow" 
      :entityService="projectUtil" 
      entity="PROJECT"
      :projectIds="selectorProjectIds"
      nonAdmin
      singleSelection
      @ok="projectSelectorOk"
    />

    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/> 
   
    <ProjectFilterModal :show.sync="filterShow" :title="$t('kanban.filter')" :userId="userId" :entity="['TASK']" :data="filter" @success="filterSuccess"/>
    
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>

// import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { strRandom, invertColor, getFirstColor, createBody, transformField, buildFilter, getFieldType, isEllipsisActive } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import VClamp from 'vue-clamp'
import { companyService, projectService, taskService, kanbanProfileService, dataviewProfileService, taskLinkStageService, viewProfileService, compositeService, profileService } from '@/services';
import kSettings from "@/_kanbanSettings";
import LazyComponent from "v-lazy-component";
import { projectUtil } from '@/views/management/script/project';

export default {
  name: 'KanbanBoard',
  components: {
    VClamp,
    TaskModal: () => import('@/components/modal/TaskModal'),
    KanbanSettingsModal: () => import('@/components/modal/KanbanSettingsModal'),
    SaveViewModal: () => import('@/components/modal/SaveViewModal.vue'),
    ProjectFilterModal: () => import('@/components/modal/ProjectFilterModal'),
    LazyComponent,
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    projectId:      { type: String, required: false },
    taskIds:   {
      type: Array, required: false, default: function() {return null}
    },
    dataview: { type: Object, default: null, required: false},
    isWidget: { type: Boolean, default: false, required: false},
    height: { type: Number, default: 300 },
    heightOffset: {
      type: Number,
      default: 220
    },
    idsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debug: false,  // more event logging
      service: null, // project or view, depending on context
      profileKey: null, // project ID if project context, view ID if view context
      userId: null,
      taskEditShow: false,
      projectSelectorShow: false,
      avatars: {},
      taskEdit: {
        uuId: null,
        parentId: null,
        stageId: null,
        progress: null,
        isNew: false,
        sortFieldBefore: null // the content of the sorted field at time of open (re-sort if changed)
      },
      profile: {},
      tasks: [],
      projects: {},
      selectorProjectIds: [],

      kanbanSettingsShow: false,
      columns: [],
      backlog: {
        uuId: 'backloguuid',
        dropType: 'backlog',
        name: "Backlog",
        color: "var(--kanban-column-backlog-title-bg)",
        tasks: []
      },
      archive: {
        uuId: 'archiveuuid',
        dropType: 'archive',
        name: "Archive",
        color: "var(--kanban-column-backlog-title-bg)",
        tasks: []
      },
      settingsLoaded: false,
      configDidLoad: false,
      loading: true,
      displayLabels: {
        project_name: this.$i18n.t("kanban.display.project_name"),
        avatar: this.$i18n.t("kanban.display.avatar"),
        description: this.$i18n.t("kanban.display.description"),
        image: this.$i18n.t("kanban.display.image"),
        due: this.$i18n.t("kanban.display.due"),
        priority: this.$i18n.t("kanban.display.priority"),
        progress: this.$i18n.t("kanban.display.progress"),
      },
      currentSortBy: null,
      currentSortDirection: null,
      highlightOnLoad: null,
      draggedCard: null,
      mobile: false,
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      kanbanViews: [],
      
      coloring: {
        none: true,
        task: false,
        stage: false, 
        skill: false,
        staff: false,    
        resource: false,   
        rebate: false, 
        file: false  
      },
      imageError: {},
      total: 0,
      loaded: 0,
      
      highlightColor: null,
      dragId: null,
      updateIds: [],
      updateTasks: {},
      searchFilter: '',
      searchValue: '',
      filterShow: false,
      filter: {
        entity: "TASK",
        query: null,
        sortdirection: "incr",
        sortfield: "TASK.closeTime"
      },
      schema: null,
      macros: null,
      startOffset: 0,
      showInfo: []
    }
  },
  mounted() {
    this.loadSettings();
  },
  computed: {        
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    cardMinHeight() {
      if (!this.display.due &&
          !this.display.priority &&
          !this.display.progress) {
        if (!this.display.description) {
          return '104px';
        }
        return '115px';
      }
      else if (!this.display.description) {
        return '135px';
      }
      return '146px';
    },
    display() {
      // Take out the project name if in project mode
      if (this.projectId) {
        delete this.profile.display['project_name'];
      }
      return this.profile.display;
    },
    enabledColumns() {
      return this.getVisibleColumns();
    },
    boardName() {
      if (this.profile.name) {
        return this.profile.name
      }
      if (this.dataview) {
        return this.dataview.name;
      }
      if (this.projectId) {
        return this.projects[this.projectId].name;
      }
      return "&nbsp;";
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  created() {
    this.projectUtil = projectUtil;
    this.$store.dispatch('data/info', { type: 'model' }).then((value) => { 
      this.schema = value;
    })
    .catch(e => {
      console.error(e); // eslint-disable-line no-console
    });
    
    this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
      this.macros = value;
    })
    .catch(e => {
      console.error(e); // eslint-disable-line no-console
    });
    this.id = this.$route.params.id;
    let user = this.$store.state.authentication.user;
    this.userId = user.uuId;
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    this.backlog.name = this.$t('kanban.backlog');
    this.archive.name = this.$t('kanban.archive');
    this.loadUserProfile(); // User profile holds Kanban views  
    this.loadPublicProfile(); // Public profile holds public Kanban views
  },
  beforeDestroy: function () {
    // Remove window hooks
    window.onscroll = null;
    this.projectUtil = null;
  },
  watch: {
    profile: {
      deep: true,
      handler(profile) {
        // Reload cards if the sort order changes
        if (this.currentSortBy) { // But not on first load
          if (profile.sort_by != this.currentSortBy || profile.sort_direction != this.currentSortDirection) {
            this.sortCards();
          }
        }
        this.currentSortBy = profile.sort_by;
        this.currentSortDirection = profile.sort_direction
      }
    },
    taskIds() {
      // clear the existing settings
      this.columns = [];
      this.tasks = [];
      
      this.loadSettings();
    }
  },
  methods: {
    debugLog(s) {
      if (this.debug) {
        console.debug(s); // eslint-disable-line no-console
      }
    },
    async loadSettings() {
      this.debugLog("Loading settings");
      const self = this;
      if (this.dataview) {
        this.debugLog("Settings come from dataview");
        // If coming from dataviews, we have to load the project list before we continue
        // since we aren't given project IDs. Settings require project details like
        // stages to be available so we have to do this first.
        // To make things more fun, the only way to get the project list is to load
        // all the tasks and fetch their project IDs, so in dataview mode the first
        // thing we do is load all the tasks (and skip it later).

        if (this.taskIds === null || this.taskIds.length === 0) {
          this.debugLog("No tasks to load");
          if (!this.idsLoading) {
            this.loading = false;
          }
          return;
        }

        await this.loadTasks();

        this.tasks.forEach(t => {
          self.projects[t.projectId] = true;
        });

        if (Object.keys(self.projects).length !== 0) {
          // Now that we have a set of project IDs, we fetch the projects
          await projectService.get(Object.keys(this.projects).map(u => { return { uuId: u }}), ['STAGE_LIST']).then(response => {
            let projects = response.data[response.data.jobCase];
            projects.forEach(p => {
              self.projects[p.uuId] = p;
            });
          });
        }
        
        let defaultLoaded = false;
        // We were given a dataview. It has to exist to end up here, so we don't create
        // any new profiles. Instead, we will use the 'kanban' key within it to store our data
        if (!("kanban" in this.dataview)) {
          this.dataview['kanban'] = this.getDefaultBoard();
          this.useDefault = true;
          const defaultView = this.kanbanViews.find(v => v.defaultView);
          if (defaultView) {
            this.loadViewSettings(defaultView);
            defaultLoaded = true;
          }
        }
        
        if (!defaultLoaded) {
          this.profile = this.dataview['kanban'];
          this.upgradeProfileIfNeeded();
          this.loadColumns();
          this.loadCards();
          this.loadColors(this.profile.coloring);
          if (self.profile.viewName) {
            this.$store.dispatch("breadcrumb/updateView", self.profile.viewName, { root: true });
          }
          else {
            this.$store.dispatch("breadcrumb/clearView");
          }
        }
      } else {
        this.debugLog("Settings come from project");
        // We are in a project. We need to use the kanban profile service set up
        // just for projects.

        // Load the project
        await projectService.get([{ uuId: this.projectId }], ['STAGE_LIST']).then(response => {
          let projects = response.data[response.data.jobCase];
          projects.forEach(p => {
            self.projects[p.uuId] = p;
            self.$store.dispatch('breadcrumb/update', p.name, { root: true });
          });
        });

        const profileData = await kanbanProfileService.list(this.projectId).then((response) => {
          return response.data[response.data.jobCase];
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
        
        if (profileData.length === 0) {
          this.useDefault = true;
          // First load - no settings saved yet, so make it
          this.profile = this.getDefaultBoard();
          await kanbanProfileService.create([this.profile], this.projectId).then((response) => {
            const profileData = response.data[response.data.jobCase];
            self.profile.uuId = profileData[0].uuId;
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
          });
          
          const defaultView = this.kanbanViews.find(v => v.defaultView);
          if (defaultView) {
            this.loadViewSettings(defaultView);
          }
        } else {
          self.profile = profileData[0];
                  
          if (self.profile.filter) {
            self.filter = self.profile.filter;
          }
          self.upgradeProfileIfNeeded();
        }
        await self.loadTasks();
        this.loadCards();
        self.loadColumns();
        await self.loadTasksForColumns();
        //self.loadCards();
        self.loadColors(self.profile.coloring);
        self.searchValue = typeof self.profile.boardSearchValue !== 'undefined' ? self.profile.boardSearchValue : '';
        if (self.searchValue !== '') {
          self.search(false);
        }
        
        if (self.profile.viewName) {
          this.$store.dispatch("breadcrumb/updateView", self.profile.viewName, { root: true });
        }
        else {
          this.$store.dispatch("breadcrumb/clearView");
        }
      }
      this.attachScrollers();
      this.loading = false;
    },
    prependEntity(entity, query) {
      if (query.children) {
        for (let i = 0; i < query.children.length; i++) {
          query.children[i] = this.prependEntity(entity, query.children[i]);
        }
      }
      
      if (query.field) {
        query.field = `${entity}.${query.field}`;
      }
      return query;
    },
    getDefaultFilters(uuId) {
      if (this.projectId) {
        return [['STAGE.TASK.PROJECT.uuId', 'eq', this.projectId], ['STAGE.uuId', 'eq', uuId]];
      }
      return [['STAGE.uuId', 'eq', uuId]];
    },
    async loadTasksForColumns() {
      const self = this;
      const prefix = 'STAGE.TASK';
      let fields = [`${prefix}.uuId`];
      const cmdList = [];
      let index = 0;
      let clonedQuery = this.filter ? cloneDeep(this.filter.query) : null;
      let filter = this.filter !== null && this.filter.query !== null ? buildFilter(this.filter.entity, self.prependEntity('STAGE', clonedQuery)) : null;

      if (this.searchFilter) {
        if (filter) {
          filter = [['STAGE.TASK.name', 'has', this.searchFilter], ...filter];
        }
        else {
          filter = [['STAGE.TASK.name', 'has', this.searchFilter]];
        }
      }
      
      var ksort = this.filter !== null ? this.filter.sortfield : null;
      var order = this.filter !== null ? this.filter.sortdirection : null;
      var ksortType = null;
      if (ksort !== null && ksort.includes('.=')) {
        ksort = transformField(ksort, 'Macro', 'sort');
      }
      else if (ksort !== null) {
        ksortType = getFieldType(ksort, this.schema);
      }
      
      if (ksort) {
        ksort = `STAGE.${ksort}`;
      }
      
      for (const col of this.columns) {
        const defaultFilters = this.getDefaultFilters(col.uuId);
        cmdList.push({
           "note":`${index}-query data for kanban column`
          ,"invoke" : "/api/query/match"
          ,"body"   :  createBody({ 
            start: self.startOffset, 
            limit: 20, 
            ksort: ksort,
            holder: col.uuId,
            ksortAgFunc: null,
            ksortType: ksortType,
            order: order
        }, fields, filter !== null ? ['_and_', [...defaultFilters, ...filter]] : [...defaultFilters], 
         null, 
         false)
        });
        index++;
      }
         
      if (cmdList.length === 0) {
        this.loading = false;
        return;
      }
      
      const responseData = await compositeService.exec(cmdList, true)
      .then(response => {
        return response.data.feedbackList;
      })
      .catch((e) => {
        return null;
      });
      
      let idx = 0;
      this.columns.forEach(column => {
        column.total = responseData[idx].total;
        for (const taskRaw of responseData[idx].fetch) {
          for (let i = 0; i < taskRaw.length; i++) {

            const task = {
              uuId: taskRaw[i],
              projectId: self.projectId
            }
          
            task.columnColor = column.color;
            task.stage = column.uuId;
            self.updateTasks[task.uuId] = task;
            column.tasks.push(task);
          }
        }
        idx++;
      })
      this.loading = false;
    },
    getVisibleColumns() {
      // Don't start reporting columns until fully loaded, we actually have columns,
      // and there is at least one visible. Otherwise, we draw the meta columns
      // (backlog, archive) when there is nothing to move from/to.
      if (!this.configDidLoad || this.columns.length == 0) {
        return [];
      }
      var visible = this.columns.filter(column => column.visible);
      if (visible.length == 0) {
        return [];
      }
      return [this.backlog].concat(visible).concat(this.archive);
    },
    getDefaultBoard() {
      let stageSet = {};
      for (var uuId in this.projects) {
        let project = this.projects[uuId];
        if (project.stageList) {
          project.stageList.forEach(stage => {
            stageSet[stage.uuId] = stage;
          });
        }
      }

      let columns = [];
      for (var stageId in stageSet) {
        let stage = stageSet[stageId];
        columns.push({
          uuId: stage.uuId,
          name: stage.name,
          visible: true,
          color: kSettings.getDefaultColor(columns.length),
        });
      }
      var name;
      if (this.projectId) {
        name = this.projects[this.projectId].name
      } else {
        name = this.dataview.name;
      }
      return {
        name: name,
        sort_by: 'closeTime',
        sort_direction: 'desc',
        columns: columns,
        display: {
          project_name: false,
          avatar: true,
          description: true,
          due: true,
          priority: true,
          progress: true,
        },
      }
    },
    upgradeProfileIfNeeded() {
      // Ensure any new additions exist as keys
      if (!("sort_by" in this.profile)) {
        this.profile['sort_by'] = "closeTime";
      }
      if (!("sort_direction" in this.profile)) {
        this.profile['sort_direction'] = "desc";
      }
      if (!("project_name" in this.profile.display)) {
        this.profile["display"]["project_name"] = false;
      }
      if (!("image" in this.profile.display)) {
        this.$set(this.profile["display"], "image", false);
      }
      // Delete any we have removed
      if ("title" in this.profile.display) {
        delete this.profile["display"]["title"];
      }
    },
    loadColumns() {
      this.debugLog("Loading columns");

      // Go through each stage and match it up with our column settings
      // Discard any that disappear and create any that are new
      let stageSet = {};
      let highestIdx = 0;
      for (var uuId in this.projects) {
        let project = this.projects[uuId];
        if (project.stageList) {
          project.stageList.forEach(stage => {
            stageSet[stage.uuId] = stage;
          });
          if (project.stageList.length > highestIdx) {
            highestIdx = project.stageList.length;
          }
        }
      }
      
      // Build column objects from the setting we have stored in the profile
      let filledColumns = {};
      this.profile.columns.forEach(column => {
        if (!(column.uuId in stageSet)) {
          // If settings exist but no stage, the stage has been deleted. Ignore it here
          // to avoid making a dead column and it will disappear from settings on next save.
          return;
        }

        let stage = stageSet[column.uuId];
        filledColumns[stage.uuId] = {
          uuId: stage.uuId,
          dropType: 'stage',
          name: stage.name,
          color: column.color,
          limit: column.limit,
          visible: column.visible,
          progress: column.progress,
          tasks: [] // Fill in asynchronously later
        };
      });

      // Reorder to match how they were given to us, as best as we can for multi-project.
      // Start at stage index 0 and check each project, then increment index. Choose
      // the column at that index if it is the first time it has appeared.
      // I.e:
      // Index    : 0  1  2  3
      // Project 1: A* B* Y* X
      // Project 2: A  B  C  D*
      // Project 3: A  B  X*
      // Order    = A  B  Y  X  D
      // When deciding to add the column, check if it's part of our filledColumns -- it's
      // possible we have not created it yet because the stage is new and we don't have
      // a setting for it yet.
      let columns = [];
      let added = {};
      for (var i = 0; i < highestIdx; i++) {
        Object.values(this.projects).forEach(project => {
          if (project.stageList) {
            if (i >= project.stageList.length) {
              return;
            }
            let stageId = project.stageList[i].uuId;
            if (!(stageId in added)) {
              // Is first encounter
              added[stageId] = true;
              let stage = stageSet[stageId];
              if (!(stage.uuId in filledColumns)) {
                // A new stage was added since the last profile save
                // Make a new one for it, to be saved later.
                filledColumns[stage.uuId] = {
                  uuId: stage.uuId,
                  name: stage.name,
                  dropType: 'stage',
                  color: kSettings.getDefaultColor(columns.length),
                  visible: true,
                  tasks: [] // Fill in asynchronously later
                };
              }
              columns.push(filledColumns[stage.uuId]);
            }
          }
        })
      }
      this.configDidLoad = true;
      this.columns = columns;
    },
    async loadTasks() {
      let clonedQuery = this.filter ? cloneDeep(this.filter.query) : null;
      let filter = this.filter !== null && this.filter.query !== null ? buildFilter(this.filter.entity, this.prependEntity('PROJECT', clonedQuery)) : null;
      
      if (this.searchFilter) {
        if (filter) {
          filter = [['PROJECT.TASK.name', 'has', this.searchFilter], ...filter];
        }
        else {
          filter = [['PROJECT.TASK.name', 'has', this.searchFilter]];
        }
      }
      
      var ksort = this.filter !== null ? this.filter.sortfield : null;
      var sortDirection = this.filter !== null ? this.filter.sortdirection : null;
      var ksortType = null;
      if (ksort !== null && ksort.includes('.=')) {
        ksort = transformField(ksort, 'Macro', 'sort');
      }
      else if (ksort !== null) {
        ksortType = getFieldType(ksort, this.schema);
      }
      
      if (ksort) {
        ksort = `PROJECT.${ksort}`;
      }
      
      this.debugLog("Loading tasks");
      let self = this;
      if (this.dataview) {
        // Load tasks in stages because we can't do them all at once
        // make sure they are unique
        const uniq = [...new Set(this.taskIds)];
        for (var i = 0; i < uniq.length; i += 200) {
          const taskIds = uniq.slice(i, i + 200);
          await taskService.listKanban({taskId: taskIds}).then(response => {
            self.tasks = self.tasks.concat(response.data);
          });
        }
      } else {
        
        let retry = false;
        const allData = [];
        let start = this.startOffset;
        this.total = 0;
        do {
        
          let data = await taskService.listKanban({start: start, sortBy: ksort, sortDirection: sortDirection, projectId: this.projectId, limit: 20, filter: filter ? ["_and_", [ "_not_", [["PROJECT.TASK.STAGE.name"]]], ...filter] : [ "_not_", [["PROJECT.TASK.STAGE.name"]]]}).then(async response => {
            if (response.status === 207 &&
                     response.jobClue.clue === 'Query_timeout') {
              // we did not get all the records, issue another request to get the remaining records
              // starting at arg_count
              // don't retry forever
              if (start + response.arg_count === response.arg_total ||
                response.arg_count === 0 && retry) {
                retry = false;
              }
              else {
                start = start + response.arg_count;
                retry = true;
              }
            }
            else if (response.status === 200) {
              retry = false; // success
            }
            this.total = response.arg_total;
            return response.data;
          });  
          this.loaded = allData.length;
          allData.push(...data);
        } while (retry);
        
        if (self.startOffset === 0) {
          self.tasks = allData;
          self.backlog.total = self.total;
        }
        else {
          self.tasks.push(...allData);
          allData.forEach(task => {
            task.projectId = this.projectId;
            self.updateTasks[task.uuId] = task;
          });
          self.backlog.tasks.push(...allData);
        }
      }
    },
    loadCards(clearBacklog = true) {
      this.debugLog("Loading cards");

      if (clearBacklog) {
        // Clear out columns before populating
        this.backlog.tasks = [];
        this.columns.forEach(column => {
          column.tasks = [];
        });
      }
            
      // Build an avatar map
      this.tasks.forEach(task => {
          task.staffs.forEach(staff => {
            if (staff.avatarRef && staff.avatarRef != '00000000-0000-0000-0000-000000000000') {
              this.avatars[staff.uuId] = staff.avatarRef;
            }
          });
      });
      
      const self = this;
      // Distribute tasks to their columns
      this.tasks.forEach(task => {
        if (!task.stage && task.progress != 1) {
          task.columnColor = '';
          self.backlog.tasks.push(task);
        }
        
        if (!task.projectId) {
          task.projectId = this.projectId;
        }
        
        this.updateTasks[task.uuId] = task;
        this.columns.forEach(column => {
          if (task.stage && column.uuId == task.stage) {
            task.columnColor = column.color;
            column.tasks.push(task);
          }
        })
      });
      this.sortCards();
    },
    sortCards() {
      this.columns.forEach(c => {
        c.tasks.sort(this.taskCompare);
        if (this.profile.sort_direction == "desc") {
          c.tasks.reverse();
        }
      });
      this.backlog.tasks.sort(this.taskCompare);
      if (this.profile.sort_direction == "desc") {
        this.backlog.tasks.reverse();
      }
    },
    taskCompare(a, b) {
      const key = this.profile.sort_by;
      let aVal = a[key];
      let bVal = b[key];

      if (typeof aVal === 'undefined') {
        return 0;
      }
      
      if (key == "name") {
        return aVal.localeCompare(bVal);
      }
      if (key == "path") {
        return aVal.localeCompare(bVal);
      }

      if (key == "priority") {
        return aVal.localeCompare(bVal);
      }

      // console.log(aVal + " - " + bVal);
      // Empty values will always sort to the bottom, in both sort directions
      if (aVal && bVal === null) {
        return this.profile.sort_direction == "desc" ? 1 : -1;
      } else if (aVal === null && bVal) {
        return this.profile.sort_direction == "desc" ? -1 : 1;
      } else if (aVal === null && bVal === null) {
        return 0;
      }

      return aVal - bVal;
    },
    getColumnById(id) {
      // Get the column object
      for(var i = 0; i < this.columns.length; i++) {
        let column = this.columns[i];
        if (column.uuId == id) {
          return column;
        }
      }
    },
    getTaskById(id) {
      // Get the task object
      return this.updateTasks[id];
    },
    onEdit(task) {
      this.taskEdit.uuId = task.uuId;
      this.taskEdit.projectId = task.projectId;
      this.taskEdit.sortFieldBefore = task[this.profile.sort_by];
      this.taskEdit.isNew = false;
      this.taskEditShow = true;
    },
    onNewTask(stageId, progress, projectId) {
      this.taskEdit.uuId = `TASK_NEW_${strRandom(5)}`;
      this.taskEdit.stageId = stageId;
      this.taskEdit.progress = progress;
      this.taskEdit.isNew = true;

      
      // In project mode, we know the project and can open the task modal with
      // the project set immediately. For dataviews, we may need to ask the user
      // which project they want, and different stages can have a different set
      // of projects that contain them.
      // Here, we find the valid projects of the chosen stage and offer them for
      // selection.
      // NOTE: a downside to this approach is that a project that doesn't appear
      // on the board (e.g., all tasks 100% already) will not be a candidate for
      // selection since our known project list comes from tasks on the board only.
      if (!projectId) {        
        if (Object.keys(this.projects).length > 1) {
          // Only use projects that actually have this stage
          this.selectorProjectIds = [];
          Object.values(this.projects).forEach(p => {
            if (stageId == this.backlog.uuId || p.stageList.filter(s => s.uuId == stageId).length > 0) {
              this.selectorProjectIds.push(p.uuId)
            }
          })
          this.projectSelectorShow = true;
          return;
        } else {
          // However, if we only have 1 valid project, we will just use it without prompting
          projectId = Object.keys(this.projects)[0];
        }
      }
      const project = this.projects[projectId];
      this.taskEdit.projectId = project.uuId;
      this.taskEditShow = true;
    },
    taskEditSuccess(data) {
      let self = this;
      let taskId = this.taskEdit.isNew ? data.id : this.taskEdit.uuId;
      let stageUuid = data.stageUuid;
      let origStage = this.taskEdit.isNew ? null : this.updateTasks[taskId].stage;
      const toCol = this.columns.find(c => c.uuId === stageUuid);
      
      // if the stage on the task has been changed
      if (stageUuid !== origStage) {
        // move the task to the new column
        /*const fromCol = origStage !== null ? this.columns.find(c => c.uuId === origStage) : null;
        
        if (fromCol) {
          const idx = fromCol.tasks.findIndex(t => t.uuId === taskId);
          if (idx !== -1) {
            fromCol.tasks.splice(idx, 1);
          }
        }*/
        this.startOffset = 0;
        this.loadSettings();
      }
      else {
        taskService.listKanban({taskId: taskId}).then((result) => {
          let updatedTask = result.data[0];
  
          // If task was newly added, add it to the list
          if (this.taskEdit.isNew) {
            self.tasks.push(updatedTask);
            toCol.tasks.push(updatedTask);
          } else {
            // Otherwise we find the old one in the list and replace it with this one
            if (this.updateTasks[updatedTask.uuId]) {
              const projectId = this.updateTasks[updatedTask.uuId].projectId;
              updatedTask.projectId = projectId;
              updatedTask.loaded = false;
              updatedTask.stage = stageUuid;
              
              setTimeout(() => {
                
                updatedTask.loaded = false;
              }, 50);
              
              // Build an avatar map
              updatedTask.staffs.forEach(staff => {
                if (staff.avatarRef && staff.avatarRef != '00000000-0000-0000-0000-000000000000') {
                  this.avatars[staff.uuId] = staff.avatarRef;
                }
              });
              
              for (const key of Object.keys(updatedTask)) {
                this.$set(this.updateTasks[updatedTask.uuId], key, updatedTask[key]);
              }
            }
          }
  
          //this.loadCards();
          // Highlight effect
          this.highlightOnLoad = taskId;
          setTimeout(() => {this.highlightOnLoad = null}, 1000);
        });
      }
    },
    columnHasMax(column) {
      return column.limit && column.tasks.length > column.limit
    },
    shortDate(date) {
      return moment(date).format('MMM Do, YYYY');
    },
    toggleVisible(key) {
      this.profile.display[key] = !this.profile.display[key];
      let self = this;
      if (this.dataview) {
        this.debugLog("Saving profile - dataview");
        this.dataview['kanban'] = this.profile;
        dataviewProfileService.update([this.dataview], this.userId).then(() => {
          self.debugLog("Profile saved")
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      } else {
        this.debugLog("Saving profile - project");
        // clear the view name from the breadcrumb
        this.$store.dispatch("breadcrumb/clearView");
        this.profile.viewName = null;
        
        kanbanProfileService.update([this.profile], this.projectId).then(() => {
          self.debugLog("Profile saved")
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      }
    },
    staffsForCard(staffs) {
      if (!staffs) {
        return;
      }
      let shortStaffs = staffs.map(function(staff) {
        var fullname = staff.name;
        if (staff.utilization < 1) {
          fullname += " (" + Math.round(staff.utilization*100) +"%)";
        }

        return {
          name: staff.name.substr(0, 1).toUpperCase(),
          fullname: fullname,
          uuId: staff.uuId,
          utilization: staff.utilization,
          small: false,
        };
      });
      
      if (shortStaffs.length <= 4) {
        return shortStaffs;
      }
      // If we have more than 4, use the first 3 staff as normal
      let list = shortStaffs.slice(0, 3);
      let remainder = shortStaffs.length-3;
      let remainderFullname = shortStaffs.slice(3).map(function(staff){
        return staff.fullname;
      }).join(", ");
      // But fill in the 4th one with a summary of the remainder (e.g, +3)
      let short = {
        name: "+"+remainder,
        fullname: remainderFullname,
        uuId: "",
        small: remainder > 9
      };
      list.push(short);
      return list;
    },
    urlForStaffAvatar(staffId) {
      if (!this.canView('STORAGE_FILE')) {
        return `${process.env.BASE_URL}img/avatars/male.png`;
      }
      return '/api/file/' + this.avatars[staffId];
    },
    showKanbanSettings() {
      this.kanbanSettingsShow = true;
    },
    /* Drag-drop events */
    dragStart: function(e) {
      this.draggedCard = e.target;
      e.dataTransfer.setData("text/plain", "Random text");
      e.dataTransfer.effectAllowed = "move";

      this.dragId = e.target != null && e.target.dataset != null? e.target.dataset.id : null;
      this.highlightColor = { background: 'var(--kanban-card-highlight)' };
    },
    dragEnd: function(/**e */) {
      this.highlightColor = null;
      this.dragId = null;
    },
    dragEnter: function(e) {
      let target = e.target;
      while (!target.classList || !target.classList.contains("dropzone")) {
        target = target.parentNode;
      }

      // Remove highlights from containers except for current one
      let elems = document.getElementsByClassName("dropzone");
      for (var i = 0; i < elems.length; i++) {
        let container = elems[i];
        if (container.isSameNode(target)) {
          continue;
        }
        container.style.backgroundColor = "unset";
      }
      if (this.draggedCard.parentNode.isSameNode(target)) {
        // No style changes if we drag over same container
        return;
      }
      let container = target;
      let column = container.parentNode;

      // We have different types of columns with different hover rules. Handle each.
      if (container.classList.contains('dropzone-stage')) {
        // Don't show column highlight if task is not valid inside this stage (in dataviews)
        let stageId = container.parentNode.dataset.id;
        let task = this.getTaskById(this.draggedCard.dataset.id);
        let validStages = this.projects[task.projectId].stageList.map(s => s.uuId);
        if (!validStages.includes(stageId)) {
          // TODO: show a better effect - the cursor one isn't even working
          e.dataTransfer.dropEffect = "none";
          return;
        }
        e.preventDefault();
        
        // Valid stages show background color
        let columnColor = column.getElementsByClassName("kanban-column-title")[0].style.backgroundColor;
        // Add opacity to rgb(x,x,x) value
        if(columnColor.indexOf('a') == -1){
            columnColor = columnColor.replace(')', ', 0.45)').replace('rgb', 'rgba');
        }
        container.style.backgroundColor = columnColor;
      } else if (container.classList.contains('dropzone-archive')) {
        // Don't allow backlog -> archive
        if (!this.draggedCard.parentNode.classList.contains('dropzone-backlog')) {
          container.style.backgroundColor = 'var(--kanban-column-nonstage-bg)';  
        }
      } else if (container.classList.contains('dropzone-backlog')) {
        container.style.backgroundColor = 'var(--kanban-column-nonstage-bg)';
      }
    },
    dragLeave: function(e) {
      // Walk up the DOM until we find a dropzone. If there isn't one, we have dragged
      // out of the board and need to remove container highlights
      let target = e.relatedTarget;     
      while (target && (!target.classList || !target.classList.contains("dropzone"))) {
        target = target.parentNode;
      }
      if (!target) {
        let elems = document.getElementsByClassName("dropzone");
        for (var i = 0; i < elems.length; i++) {
          elems[i].style.backgroundColor = "unset";
        }
      }
    },
    allowDrop: function(e) {
      // Only allow dropping into container elements
      // Also, don't allow dropping onto own container
      let target = e.target;
      while (!target.classList || !target.classList.contains("dropzone")) {
        target = target.parentNode;
      }
      if (!this.draggedCard.parentNode.isSameNode(target)) {
        e.preventDefault();
      }

      let container = target;
      // We have different types of columns with different hover rules. Handle each.
      if (container.classList.contains('dropzone-stage')) {
        // Don't allow drop if task is not valid inside this stage
        let stageId = container.parentNode.dataset.id;
        let task = this.getTaskById(this.draggedCard.dataset.id);
        let validStages = this.projects[task.projectId].stageList.map(s => s.uuId);
        if (!validStages.includes(stageId)) {
          e.dataTransfer.dropEffect = "none";
          e.preventDefault();
        }
      } else if (container.classList.contains('dropzone-archive')) {
        if (this.draggedCard.parentNode.classList.contains('dropzone-backlog')) {
          // Don't allow backlog -> archive
          e.preventDefault();
        }
      } else {
        // We allow any other dropzone
      }
    },
    drop(e) {
      let target = e.target;
      while (!target.classList || !target.classList.contains("dropzone")) {
        target = target.parentNode;
      }
      let toContainer = target;
      toContainer.style.backgroundColor = "unset";

      let taskId = this.draggedCard.dataset.id;
      let task = this.getTaskById(taskId);

      // We have different types of columns with different hover rules. Handle each.
      if (toContainer.classList.contains('dropzone-stage')) {
        this.debugLog("Moving")
        let destColumnId = toContainer.parentNode.dataset.id;
        let destColumn = this.getColumnById(destColumnId);
        this.moveTaskToColumn(task, destColumn);
      } else if (toContainer.classList.contains('dropzone-archive')) {
        this.moveTaskToColumn(task, null);
      } else if (toContainer.classList.contains('dropzone-backlog')) {
        this.moveTaskToColumn(task, null);
      }
    },
    async moveTaskToColumn(task, destColumn) {
      // Update task's stage by removing link and making new one
      if (task.stage) {
        await taskLinkStageService.remove(task.uuId, {uuId: task.stage});
          
        // remove the task from the column
        this.columns.forEach(col => {
          if (col.uuId === task.stage) {
            const idx = col.tasks.findIndex(t => t.uuId === task.uuId);
            col.tasks.splice(idx, 1);
          }
        });
      } else {
        // We are coming from the backlog, nothing to unlink
        const idx = this.backlog.tasks.findIndex(t => t.uuId === task.uuId);
        if (idx !== -1) {
          this.backlog.tasks.splice(idx, 1);
        }
      }

      if (destColumn) {
        await taskLinkStageService.create(task.uuId, {uuId: destColumn.uuId});
        task.stage = destColumn.uuId;
        
        if (!isNaN(destColumn.progress)) {
          await taskService.update([{uuId: task.uuId, progress: destColumn.progress/100}]);
          // TODO: some kind of error handling would be good
          task.progress = destColumn.progress/100;
        }
      } else {
        // Task is being moved to backlog or archive. No new link is made. Clear out old link from state.
        task.stage = null;
        task.stageColor = '';
      }

      await taskService.get([{ uuId: task.uuId }], ['STAGE'])
      .then(response => {
        if (response != null && response.data != null
          && response.data.jobCase != null 
          && Array.isArray(response.data[response.data.jobCase])
          && response.data[response.data.jobCase].length > 0
          && response.data[response.data.jobCase][0].stage != null
        ) {
          const color = response.data[response.data.jobCase][0].stage.color;
          task.stageColor = color == null? '' : color;
        }
      })

      if (destColumn) {
        // move the task to the column
        this.columns.forEach(col => {
          if (col.uuId === destColumn.uuId) {
            col.tasks.push(task);
          }
        });
      }
      else {
        this.backlog.tasks.push(task);
      }
            
      //this.loadCards();
      // Highlight effect
      this.highlightOnLoad = task.uuId;
      setTimeout(() => {this.highlightOnLoad = null}, 1000);
    },
    saveSettings(result) {
      this.profile = result.profile;
      this.profile.columns = result.columns;
      let self = this;
      if (this.dataview) {
        this.debugLog("Saving profile - dataview");
        if (!result.skipClear) {
          // clear the view name from the breadcrumb
          this.$store.dispatch("breadcrumb/clearView");
          this.profile.viewName = null;
        }
        
        dataviewProfileService.update([this.dataview], this.userId).then(() => {
          self.debugLog("Profile saved")
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      } else {
        this.debugLog("Saving profile - project");
        if (!result.skipClear) {
          // clear the view name from the breadcrumb
          this.$store.dispatch("breadcrumb/clearView");
          this.profile.viewName = null;
        }
        
        kanbanProfileService.update([this.profile], this.projectId).then(() => {
          self.debugLog("Profile saved")
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      }
      // copy the settings to the columns
      for (const column of result.columns) {
        const col = this.columns.findIndex(c => c.uuId === column.uuId);
        if (col !== -1) {
          this.columns[col].visible = column.visible;
          if (this.columns[col].color !== column.color) {
            for (const task of this.columns[col].tasks) {
              task.columnColor = column.color;
            }
          }
          this.columns[col].color = column.color;
          this.columns[col].progress = column.progress;
          this.columns[col].limit = column.limit;
        }
      }
    },
    projectSelectorOk(selected) {
      this.projectSelectorShow = false;
      const uuId = selected.details[0].uuId;
      this.onNewTask(this.taskEdit.stageId, this.taskEdit.progress, uuId);
    },
    attachScrollers() {
      const self = this;
      
      // Scroll events for floating elements like column headers.
      let kanbanContainer = document.getElementsByClassName("kanban-container")[0];
      if (kanbanContainer) {
        kanbanContainer.onscroll = function() {onScroll()};
      }

      // Floating column headers. 
      var titles = document.getElementsByClassName("kanban-column-title");
      function onScroll() {        
        for (var i = 0; i < titles.length; i++) {
          let title = titles[i];
          let topOffset = kanbanContainer.scrollTop;
          if (kanbanContainer.scrollTop > 75) {
            title.style.transform = "translate3d(0, " + (topOffset) + "px, 0) ";
          } else {
            title.style.transform = "translate3d(0, 0, 0) ";
          }
        }
        alignArchive();
        if (!self.dataview && kanbanContainer.scrollTop + kanbanContainer.clientHeight >= kanbanContainer.scrollHeight - 2) {
          self.startOffset += 20;
          self.loadTasks();
          self.loadTasksForColumns();
        }
      }

      // "Archive" column card
      function alignArchive() {  
        var archiver = document.getElementsByClassName("archive-content-container")[0];
        if (archiver) {
          let midOffset = kanbanContainer.scrollTop + (kanbanContainer.clientHeight / 2) - 200;
          archiver.style.transform = "translate3d(0, " + midOffset + "px, 0)";
        }
      }
      alignArchive();
    },
    /**
     * Get a map of which immediate left or right columns we are
     * allowed to move a task to. Takes into account dataviews:
     * only consider visible columns from same project, in display order.
     */
    getMoveMap(stageId, projectId) {
      const stages = this.projects[projectId] ? this.projects[projectId].stageList : [];
      const stageMap = {};
      stageMap[this.backlog.uuId] = this.backlog;
      if (stages) {
        stages.forEach(stage => {
          stageMap[stage.uuId] = stage;
        });
      }
      stageMap[this.archive.uuId] = this.archive;

      // Only the visible columns within same project are candidates for moving to
      var allowedColumns = [];
      var visibleColumns = this.getVisibleColumns();
      for (var i = 0; i < visibleColumns.length; i++) {
        const c = visibleColumns[i];
        if (c.uuId in stageMap) {
          allowedColumns.push(c);
        }
      }
      // Get current column's index
      for (i = 0; i < allowedColumns.length; i++) {
        if (allowedColumns[i].uuId == stageId) {
          break;
        }
      }

      var leftId = null;
      var leftName = null;
      if (i != 0) {
        leftId = allowedColumns[i-1].uuId;
        leftName = allowedColumns[i-1].name;
      }
      var rightId = null;
      var rightName = null;
      if (allowedColumns.length != 0 && i != allowedColumns.length - 1 &&
          allowedColumns[i+1]) {
        rightId = allowedColumns[i+1].uuId;
        rightName = allowedColumns[i+1].name;
      }
      return {
        leftId: leftId,
        leftName: leftName,
        rightId: rightId,
        rightName: rightName
      }
    },
    moveByArrow(taskId, stageId, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      let destColumn = this.getColumnById(stageId);
      let task = this.getTaskById(taskId);
      this.moveTaskToColumn(task, destColumn);
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (typeof arg.toElement?.className === 'string' &&
          arg.toElement?.className !== 'arrow' &&
          arg.toElement?.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    },
    async loadViewSettings(view) {
      this.updateTasks = {};
      this.columns = [];
      this.tasks = [];
      this.configDidLoad = false;
      const self = this;
      setTimeout(() => {
        self.configDidLoad = true;
      }, 1000);
      const uuId = this.profile.uuId;
      this.profile = cloneDeep(view.profile);
      this.searchFilter = this.searchValue = (this.profile.boardSearchValue ? this.profile.boardSearchValue : '');
      this.profile.uuId = uuId;
      if (this.profile.filter) {
        this.filter = this.profile.filter;
      }
      else {
        this.filter = {
          entity: "TASK",
          query: null,
          sortdirection: "incr",
          sortfield: "TASK.closeTime"
        }; // clear out the filter
      }
      this.loadColumns();
      this.loadCards(this.searchFilter.length !== 0);
      this.loadColors(view.profile.coloring);
      this.startOffset = 0;
      this.search();
      
      // Save the new layout after applying it
      const cloneColumns = cloneDeep(this.columns);
      for (const c of cloneColumns) {
        delete c.tasks;
      }
      
      // save the view name in the profile
      this.profile.viewName = view.name;
      
      if (this.dataview) {
        this.dataview['kanban'] = this.profile;
      }
      this.saveSettings({profile: this.profile, columns: cloneColumns, skipClear: true});
      
      this.$store.dispatch("breadcrumb/updateView", view.name, { root: true });
    },
    savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        type: 'kanban',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId), 
        profile: this.profile
      };
      this.promptSaveShow = true;
    },
    async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(this.projectId, user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0]['boardSearchValue'] = profile.profile.boardSearchValue;
            profileData[0]['coloring'] = profile.profile.coloring;
            profileData[0]['filter'] = profile.profile.filter;
            
            await service.update(profileData, this.projectId, user)
          }
        }
      }
    },
    confirmSaveOk({/**  name,*/ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.kanbanViews.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, kanbanProfileService);
      
      if (this.saveIndex !== -1) {
        this.kanbanViews.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.profile.viewName = profile.name;
        this.$store.dispatch("breadcrumb/updateView", profile.name, { root: true });
         if (this.dataview) {
          this.$emit('profileChanged', this.profile);
        }
        else {
          kanbanProfileService.update([this.profile], this.projectId);
        }
      }
    },
    addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === 'kanban' &&
            this.kanbanViews.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.kanbanViews.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
          else {
            let params = new URL(document.location.toString()).searchParams;
            if (params.has("view")) {
              let uuId = params.get("view");
              if (view.uuId === uuId) {
                this.loadViewSettings(view);
              }
            }
          }
        }
      }
      
      this.kanbanViews.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: profile.name,
        uuId: profile.uuId,
        type: 'kanban',
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        profile: this.profile
      };
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings(name, profile) {
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: 'kanban',
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        profile: profile.profile
      };
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      const toRemove = this.kanbanViews.splice(this.deleteViewIndex, 1);
      
      viewProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {})
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    },
    async loadUserProfile() {
      const self = this;
      // const userProfile = 
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    
      const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    onColorChange(val, color_key) {
      const coloring = this.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.profile[color_key] = coloring;
      if (this.dataview) {
        // clear the view name from the breadcrumb
        this.$store.dispatch("breadcrumb/clearView");
        this.profile.viewName = null;
        
        this.$emit('profileChanged', this.profile);
      }
      else {
          
        // clear the view name from the breadcrumb
        this.$store.dispatch("breadcrumb/clearView");
        this.profile.viewName = null;
        
        kanbanProfileService.update([this.profile], this.projectId);
      }
    },
    getColorBy(data) {
      if (data &&
        data.color &&
        this.coloring.task) {
        return data.color;
      }
      else if (data &&
        data.stageColor &&
        this.coloring.stage) {
        return getFirstColor(data.stageColor);
      }
      else if (data &&
        data.rebateColor &&
        this.coloring.rebate) {
        return getFirstColor(data.rebateColor);
      }
      else if (data &&
        data.fileColor &&
        this.coloring.file) {
        return getFirstColor(data.fileColor);
      }
      else if (data &&
        data.staffColor &&
        this.coloring.staff) {
        return getFirstColor(data.staffColor);
      }
      else if (data &&
        data.skillColor &&
        this.coloring.skill) {
        return getFirstColor(data.skillColor);
      }
      else if (data &&
        data.resourceColor &&
        this.coloring.resource) {
        return getFirstColor(data.resourceColor);
      }
      else if (data &&
        data.columnColor &&
        this.coloring.column) {
        return getFirstColor(data.columnColor);
      }
      return null;
    },
    loadColors(profile) {
      const self = this;
      self.coloring.none = profile ? profile.none : true;
      self.coloring.column = profile ? profile.column : false;
      self.coloring.staff = profile ? profile.staff : false;
      self.coloring.task = profile ? profile.task : false;
      self.coloring.stage = profile ? profile.stage : false;
      self.coloring.rebate = profile ? profile.rebate : false;
      self.coloring.skill = profile ? profile.skill : false;
      self.coloring.resource = profile ? profile.resource : false;
      self.coloring.file = profile ? profile.file : false;
      
      self.profile.coloring = self.coloring;
    },
    getStyle(task, card = false) {
      let style = '';
      const color = this.getColorBy(task);
      if (color) {
        style = { background: color, color: invertColor(color, true) };
      }
      if (card) {
        if (color) {
          style['min-height'] = this.cardMinHeight;
        }
        else {
          style = { 'min-height': this.cardMinHeight };
        }
      }
      return style;
    },
    
    handleImageError(uuId) {
      this.imageError[uuId] = true;
    },
    avatarUrl(avatarRef) {
      return avatarRef && avatarRef !== '00000000-0000-0000-0000-000000000000' && !this.imageError[avatarRef] ? `${process.env.BASE_URL}api/file/${avatarRef}` : null;
    },
    onIntersected(tasks, index, el) {
      const self = this;
      if (!tasks[index].loaded) {
        const taskId = tasks[index].uuId;
        clearTimeout(this.timeoutId);
        this.updateIds.push(taskId);
        this.timeoutId = setTimeout(() => {
          taskService.listKanban({start: 0, limit: -1, taskId: this.updateIds.splice(0, this.updateIds.length)}, true).then((result) => {
            let updatedTasks = result.data;
            
            for (const updatedTask of updatedTasks) {
              // we find the old one in the list and replace it with this one
              if (self.updateTasks[updatedTask.uuId]) {
                const projectId = self.updateTasks[updatedTask.uuId].projectId;
                updatedTask.projectId = projectId;
                updatedTask.loaded = true;
                          
                // Build an avatar map
                updatedTask.staffs.forEach(staff => {
                  if (staff.avatarRef && staff.avatarRef != '00000000-0000-0000-0000-000000000000') {
                    self.avatars[staff.uuId] = staff.avatarRef;
                  }
                });
                
                for (const key of Object.keys(updatedTask)) {
                  self.$set(self.updateTasks[updatedTask.uuId], key, updatedTask[key]);
                }
              }
            }
          });
        }, 100);
      }
    },
    showProjectSettings() {
      this.filterShow = true;
    },
    async clearProjectSettings() {
      const self = this;
      this.filter = {
        entity: "TASK",
        query: null,
        sortdirection: "incr",
        sortfield: "TASK.closeTime"
      };
      this.profile.filter = null;
    
      // clear the view name from the breadcrumb
      this.$store.dispatch("breadcrumb/clearView");
      this.profile.viewName = null;
      
      kanbanProfileService.update([this.profile], this.projectId);
      
      // reload
      // Clear out columns before populating
      this.backlog.tasks = [];
      this.columns.forEach(column => {
        column.tasks = [];
      });
      this.loading = true;
      this.startOffset = 0;
      await Promise.allSettled([
        this.loadTasks(),
        this.loadTasksForColumns()
      ]).then(results => { 
        this.backlog.tasks = this.tasks;
        this.tasks.forEach(task => {
          self.updateTasks[task.uuId] = task;
        });
        this.loading = false;
      });
    },
    async filterSuccess(result) {
      const self = this;
      this.filter = result;
      this.profile.filter = result;
      this.saveSettings( { profile: this.profile, columns: this.columns });
      
      // reload
      // Clear out columns before populating
      this.backlog.tasks = [];
      this.columns.forEach(column => {
        column.tasks = [];
      });
      this.loading = true;
      this.startOffset = 0;
      await Promise.allSettled([
        this.loadTasks(),
        this.loadTasksForColumns()
      ]).then(results => { 
        this.backlog.tasks = this.tasks;
        this.tasks.forEach(task => {
          self.updateTasks[task.uuId] = task;
        });
        this.loading = false;
      });
    },
    async search(clearView = true) {
      this.startOffset = 0;
      const self = this;
      this.searchFilter = this.searchValue;
      if (clearView) {
        // clear the view name from the breadcrumb
        this.$store.dispatch("breadcrumb/clearView");
        this.profile.viewName = null;
      }
            
      this.profile.boardSearchValue = this.searchValue;
      if (this.dataview) {
        this.$emit('profileChanged', this.profile);
      }
      else {
        kanbanProfileService.update([this.profile], this.projectId).then(() => {
          self.debugLog("Profile saved")
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      }
            
      // reload
      // Clear out columns before populating
      this.backlog.tasks.splice(0);
      this.columns.forEach(column => {
        column.tasks.splice(0);
      });
      this.loading = true;
      this.startOffset = 0;
      await Promise.allSettled([
        this.loadTasks(),
        this.loadTasksForColumns()
      ]).then(results => { 
        this.backlog.tasks = this.tasks.filter(t => !t.stage);
        this.tasks.forEach(task => {
          self.updateTasks[task.uuId] = task;
        });
        this.loading = false;
      });
    },
    clear() {
      this.searchFilter = "";
      this.searchValue = "";
      this.search();
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.search();
      }
    },
    onInfoOver(index) {
      profileService.nodeList(this.kanbanViews[index].uuId).then((response) => {
        this.kanbanViews[index].owner = response.data.resultList.filter(v => this.kanbanViews[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
  }
}


</script>

<style lang="scss">

/** Some differences when in dataview container. */
.tab-container {
  .kanban-controls {
    margin: 0;
    border: 1px solid var(--border-medium);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: none;
  }
  .kanban-board {
    margin-top: 15px;
  }
  .kanban-controls {
    font-size: 16px;
  }
}

.kanban-page-container {
  background-color: var(--surface-bg);
}

.kanban-container {
  overflow-y: auto;
  background-color: var(--surface-bg);
  border: 1px solid var(--surface-border);
}

.kanban-board {
  display: flex;
  justify-content: left;
  height: auto;
  overflow-y: visible;
  padding: 0 12px;
}
#kanban_settings {
  cursor: pointer;
}

.kanban-controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 6px 12px;
  font-size: 16px;
  box-shadow: var(--surface-shadow);
  background-color: var(--surface-bg);
  
  .btn {
    font-size: 16px;
    width: 32px;
    padding: 0 6px;
  }
  
  .search-group svg {
    padding-top: 5px;
  }
}

.kanban-attribute-toggler .btn {
  font-size: 18px;
  color: inherit;
}
.kanban-attribute-toggler .dropdown ul {
  margin-top: 0;
}

.kanban-column {
  width: 320px;
  min-width: 320px;
  padding-bottom: 48px;
}

.kanban-column:nth-child(even) {
  background-color: var(--kanban-column-even-bg);
}

.kanban-column-title {
  color: var(--white);
  font-weight: 700;;
  padding: 15px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  height: 45px;
  will-change: transform;
}
.kanban-column-title-right {
  display: flex;
  justify-content: space-between;
}

.kanban-column-limit {
  font-size: 14px;
}

.kanban-column.max-reached {
  background-color: var(--kanban-max-reached);
}

.kanban-column-add {
  cursor: pointer;
  margin-right: 5px;
}

.kanban-column-card-container {
  height: 100%;
  padding-top: 15px;
}

.kanban-column-card {
  background-color: var(--kanban-card-bg);
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  padding: 20px;
  box-shadow: var(--surface-shadow);
  cursor: pointer;
  margin: 0 20px 15px 20px;
}
.kanban-column-card.highlight {
  background-color: var(--kanban-card-highlight);
}
.kanban-column-card.nograb {
  cursor: unset;
}

.card-line-img {
  display: flex;
}

.card-image {
  width: 100%;
  max-height: 300px;
}

.card-line1, .card-line2, .card-line3 {
  display: flex;
  justify-content: space-between;
}
.kanban-card-project {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-light);
  margin-bottom: 5px;
}

.kanban-card-path {
  font-size: 12px;
  color: var(--text-light);
  margin-bottom: 5px;
}
// .card-line0 {
  
// }
.card-line1 {
  align-items: center;
}
.card-line2 {
  flex-grow: 1;
  margin-top: 6px;
}
.card-line3 {
  align-items: center;
  color: var(--text-light);
  font-weight: 700;
  margin-top: 6px;
}

.quick-movers {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: -12px;
  color: var(--text-light);
  visibility: hidden;
}
.mobile .quick-movers {
  visibility: visible;
}
.kanban-column-card:hover .quick-movers {
  visibility: visible;
}

.kanban-card-title {
  font-weight: 600;
  color: var(--text-dark);
  font-size: 16px;
  width: 75%;
  line-height: 18px;
  max-height: 40px;
}
.kanban-card-users {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.user-holder {
  margin: 1px;
}

.kanban-card-description {
  color: var(--text--light);
  font-size: 16px;
  margin-top: 5px;
}

.kanban-card-priority {
  color: var(--white);
  border-radius: 6px;
  padding: 2px 15px;
}
.kanban-card-priority.Low {
  background-color: var(--status-blue);
}
.kanban-card-priority.Normal {
  background-color: var(--status-green);
}
.kanban-card-priority.High {
  background-color: var(--status-red);
}

.kanban-owner-label {
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  border: 1px solid var(--border-light);
  border-radius: 25px;
}
.kanban-owner-label.small {
  font-size: 9px;
}
.kanban-owner-label.letter {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: var(--text-light);
}
.kanban-owner-label.picture {
  font-size: 11px;
  background-position: center;
  background-size: cover;
}

#kanban-empty {
  margin: auto;
  margin-top: 15%;
  font-weight: 700;
  font-size: 16px;
}

.nodrop {
  cursor: not-allowed;
}

.archive-content-container {
  display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
  color: var(--text-disabled);
  width: 300px;
  font-size: 18px;
  box-shadow: var(--surface-shadow);
  background-color: var(--kanban-card-bg);
  border: 1px solid var(--border-medium);
  margin: auto;
  will-change: transform;
  cursor: default;
  border-radius: 6px;
  box-shadow: var(--surface-shadow);
}

.dropzone-archive {
  padding: 50px;
  border-radius: 6px;
}

.board-name {
  margin-right: auto;
}

.search-clear-btn {
  height: 35px;
}
</style>