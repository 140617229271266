
<template>
  <div :id="`LAUNCHPAD_FORM`" style="height: 100%, width: 100%">
    <b-modal size="lg" :title="labelTitle" :visible="show" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @ok="modalOk"
      @hidden="modalCancel"
      id="launchpad-modal"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
      
      <AvatarBanner v-if="canView('STORAGE_FILE')" v-model="avatarBanner" :readOnly="isReadOnly" :baseAvatarIcon="['fad', 'user-tie']" @status="avatarBannerStatus" :bannerOnly='true'/>

      <b-row>
        <b-col cols="12" md="12">
          <b-form-group :label="$t('dashboard.launchpad.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
            <b-input-group>
              <b-form-input id="name" type="text"
                :data-vv-as="$t('dashboard.launchpad.field.name')"
                data-vv-name="launchpad.name"
                data-vv-delay="500"
                v-model="launchpad.name" 
                v-validate="{ required: true }"
                :readonly="isReadOnly"
                :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'dashboard.launchpad.field.name')"
                autofocus trim>
              </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('launchpad.name') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('dashboard.launchpad.field.description')" label-for="description">
        <b-input-group>
          <b-form-textarea id="description"
            v-model="launchpad.description" 
            :readonly="isReadOnly"
            :rows="3"
            :max-rows="6"
            trim 
            >
          </b-form-textarea>
        </b-input-group>
      </b-form-group>
      
      <b-form-group>
        <label class="mr-1">{{ $t(`dashboard.launchpad.field.link`) }}</label>
        <b-row v-for="(link, index) in launchpad.links" v-bind:key="index">
          <b-col sm>
            <b-form-group :label="$t('dashboard.launchpad.url_and_button')" :label-for='`link_url`+index' :class="{ 'mb-0': showUrlError(index) }">
              <b-input-group>
                <b-form-input type="text"
                  :id='`link_url`+index'
                  :placeholder="$t('dashboard.launchpad.field.link_url')"
                  :data-vv-name='`link_url`+index'
                  :data-vv-as="$t('dashboard.launchpad.field.link_url')"
                  v-model="link.link_url"
                  data-vv-delay="500"
                  v-validate="{ required: true }"
                  trim>
                </b-form-input>
                <b-form-input type="text"
                  :id='`link_text`+index'
                  :placeholder="$t('dashboard.launchpad.field.link_text')"
                  :data-vv-name='`link_text`+index'
                  :data-vv-as="$t('dashboard.launchpad.field.link_text')"
                  v-model="link.link_text"
                  data-vv-delay="500"
                  v-validate="{ required: true }"
                  trim>
                </b-form-input>
                <b-input-group-append>
                  <b-button :id="`DASHBOARD_LAUNCHPAD_LINK_ADD-${index}`" variant="secondary" @click="linkAdd(index)"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                  <b-popover :target="`DASHBOARD_LAUNCHPAD_LINK_ADD-${index}`" triggers="hover" placement="top">
                    {{ $t('button.add') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button :id="`DASHBOARD_LAUNCHPAD_LINK_REMOVE-${index}`" variant="secondary" @click="linkRemove(index)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>            
                  <b-popover :target="`DASHBOARD_LAUNCHPAD_LINK_REMOVE-${index}`" triggers="hover" placement="top">
                    {{ $t('button.remove') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showUrlError(index) }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('link_url'+index) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import dSettings from "@/_dashboardSettings";
import { fieldValidateUtil } from '@/script/helper-field-validate';
import Vue from 'vue';

export default {
  name: 'DashboardLaunchpadModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner')
  },
  props: {
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    widget:    { type: Object,   default: function() { return null }}
  },
  data() {
    return {
      isNew: true,
      show: false,
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
      },
      // If new, clone the launchpad config from the dashboard settings
      // If edit, we get it from the parent
      launchpad: null,
      avatarBanner: {
        avatarId: null, // unused, but silences prop warning from component
        bannerId: null
      },
    }
  },
  created() {
    if (this.widget) {
      this.isNew = false;
      this.launchpad = cloneDeep(this.widget);
      this.avatarBanner.bannerId = this.launchpad.image;
    } else {
      this.isNew = true;
      this.launchpad = cloneDeep(dSettings.launchpad);
    }
    this.addLinkIfMissing();
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLaunchpad = null;
    this.show = true;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originLaunchpad = null;
    this.show = false;
  },
  computed: {
    isReadOnly() {
      return !this.state.editable && this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'launchpad.name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.isNew ? this.$t('dashboard.launchpad.title_new') : this.$t('dashboard.launchpad.title_detail');
    }
  },
  methods: {
    hasFieldValidationError(key) {
      return this.errors.first(key) && this.errors.first(key).length? true: false;
    },
    // Form field validation
    fieldStateValidate(ref) {
      if (!this.readOnly) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)? null: false;
        }
      }
      return null;
    },
    fieldInvalidFeedback(ref) {
      const matchedErrors = this.errors.items.filter(i => i.field == ref);
      if(matchedErrors.length > 0) {
        return matchedErrors[0].msg;
      } else {
        return '';
      }
    },
    showUrlError(index) {
      return fieldValidateUtil.hasError(this.errors, 'link_url'+index);
    },
    showTextError(index) {
      return fieldValidateUtil.hasError(this.errors, 'link_text'+index);
    },
    modalOk() {
      this.errors.clear();
      //Cross field validation
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.launchpadSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          // this.scrollToTop();
        }
      });
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('cancel');
    },
    launchpadSubmit() {
      this.launchpad.label = this.launchpad.name;
      this.launchpad.image = this.avatarBanner.bannerId;
      if(this.isNew) {
        this.$emit('added', this.launchpad);
      } else {
        this.$emit('updated', this.launchpad);
      }
    },
    scrollToTop() {
      document.querySelector(`#LAUNCHPAD_FORM`).scrollIntoView();
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    linkAdd(index) {
      this.launchpad.links.splice(index+1, 0, {url: '', text: ''});
    },
    linkRemove(index) {
      this.launchpad.links.splice(index, 1);
      this.addLinkIfMissing();
    },
    addLinkIfMissing() {
      // Ensure we always have as least one link
      if (!this.launchpad.links) {
        this.launchpad.links = Vue.set(this.launchpad, "links", [])
      }
      if (this.launchpad.links.length == 0) {
        this.launchpad.links.push({url: '', text: ''})
      }
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
        this.alertError = true;
      }
    },
  }
}
</script>

<style lang="scss">
#launchpad-modal___BV_modal_body_ .avatar-banner {
  background-size: contain;
  background-repeat: no-repeat;
}
</style>