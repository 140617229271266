<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      content-class="shadow" no-close-on-backdrop
      scrollable
    >

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <div class="container pl-0">
        <b-row>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('folder.field.parent_folder')" label-for="parent">
              <b-input-group>
                <b-form-input id="parent" type="text"
                  v-model="parent.name" 
                  :readonly="true">
                </b-form-input>
                <b-input-group-append v-if="!isNameReadOnly">
                  <b-button size="sm" variant="info" @click="parentSelectOpen">Select</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showParentError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('parent.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['parentFolder'] != null">
            <b-col v-for="(field, index) in customFieldMap['parentFolder']" :key="'parentFolder'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="folder[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('folder.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
              <b-input-group>
                <b-form-input id="name" type="text"
                  :ref="`${id}.name`"
                  data-vv-as="Name"
                  data-vv-name="folder.name"
                  data-vv-delay="500"
                  v-model="folder.name" 
                  :readonly="isNameReadOnly"
                  :state="fieldValidateUtil.stateValidate(isNameReadOnly, veeFields, errors, 'folder.name')"
                  autofocus trim 
                  @keydown.native="keydownHandler">
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('folder.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="folder[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="folder[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" md="8" class="pr-0" v-if="isTagVisible">
            <b-form-group>
              <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly"/>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['tags'] != null">
            <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="folder[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col cols="12" md="4" class="pr-0" v-if="isColorVisible">
            <div class="color-container">
              <Color :disabled="isColorReadOnly" v-model="folder.color" :update="updatedColor"/>
            </div>
          </b-col>

          <template v-if="customFieldMap['color'] != null">
            <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="folder[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="canEdit() || !exists" size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <FolderSelectorModal :currentPath="path" :show.sync="folderSelectorShow" @success="folderSelectorSuccess"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';

import { folderService,
         folderLinkTagService, tagService } from '@/services';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'FolderModal',
  components: { 
    FolderSelectorModal: () => import('./FolderSelectorModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `FOLDER_NEW_${strRandom(5)}` },
    name:      { type: String,   default: '' },
    parentData:{ type: Object,   default: null },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    currentPath: { type: Object, default: null }
  },
  data() {
    return {
      permissionName: 'STORAGE_FILE',
      alertMsg: null,
      folderSelectorShow: false,
      selectorTitle: 'Parent Folder Selector',
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false
      },
      folder: {
        uuId:               null,
        name:               null,
        color:              null
      },
      parent: {
        uuId:               null,
        name:               null
      },
      path: null,
      tags: [],
      updatedColor: null,
      
      customFields: [],
      customFieldMap: {}
    }
  },
  created() {
    if(this.id && this.id.indexOf('FOLDER_NEW_') == -1) {
      this.folder.name = cloneDeep(this.name);
      this.folder.uuId = this.id;
    }
    else {
      // new folder, set the parent to the selected folder
      if (this.parentData != null) {
        this.parent.name = this.parentData.name;
        this.parent.uuId = this.parentData.uuId;
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.originTags = [];
    this.originFolder = null;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originTags = null;
    this.originFolder = null;
  },
  computed: {
    componentId() {
      return `FOLDER_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'folder.name');
    },
    showParentError() {
      return fieldValidateUtil.hasError(this.errors, 'parent.name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title : this.$t('folder.title_new');
    },
    exists() {
      return this.id && !this.id.startsWith('FOLDER_NEW_');
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isColorVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['color']) && this.canView(this.permissionName, ['color'])) 
      || (this.exists && this.canView(this.permissionName, ['color']))
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    }
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.state.modalShow) {
        await getCustomFieldInfo(this, 'STORAGE_FOLDER');
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        this.state.editable = (!this.exist && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        if(this.id.indexOf('FOLDER_NEW_') === -1) {
          this.folder.name = this.name;
          this.folder.uuId = this.id;
          this.loadData();
          if (this.parentData != null) {
            this.parent.name = this.parentData.name;
            this.parent.uuId = this.parentData.uuId;
          }
          else {
            this.parent.name = '';
            this.parent.uuId = null;
          }
        } else {
          this.resetFolderProperties();
        }
        this.path = this.currentPath;
        delete this.path[this.folder.uuId]; //Remove current folder as currentPath includes current folder.
      }
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    parentSelectOpen() {
      this.folderSelectorShow = true;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.folder[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      const self = this;
      this.errors.clear();
      //Cross field validation
      this.validateParent();
      this.validateName();
      this.$validator.validate().then(valid => {
        if (valid && self.errors.items.length < 1) {
          this.alertMsg = null;
          this.folderSubmit();
        } else {
          this.alertMsg = this.$i18n.t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    folderSubmit() {
      const data = cloneDeep(this.folder);
      
      if(this.id.indexOf('FOLDER_NEW_') !== -1) {
        delete data['uuId'];
        if (this.parent.uuId !== null && this.parent.uuId !== "-1") {
          data.parent = this.parent.uuId;
        }
        this.folderPost('create', data);
        
      } else {
        this.folderPost('update', data);
      }
    },
    parentChanged() {
      // is the parent uuId different
      return (this.parentData === null && this.parent.uuId !== null) ||
              (this.parentData !== null && this.parentData.uuId !== this.parent.uuId);
    },
    loadData() {
      folderService.get(this.folder.uuId, ['TAG']).then(response => {
        const data = response.data[response.data.jobCase];
        if (data) {
          this.folder.name = data.name;
          this.folder.color = data.color;
          
          for (const field of this.customFields) {
            if (typeof data[field.name] !== 'undefined') {
              this.folder[field.name] = data[field.name];
            }
            else {
              this.folder[field.name] = null;
            }
          }
          
          //Setup Tags data
          if (data.tagList && data.tagList.length > 0) {
            const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
            this.originTags.splice(0, this.originTags.length, ...list);
            this.tags.splice(0, this.tags.length, ...cloneDeep(list));
          }
          else {
            this.tags = [];
            this.originTags = [];
          }
          
          this.originFolder = JSON.parse(JSON.stringify(this.folder));
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    async folderPost(method, data) {
      const self = this;
      const result = {
        hasError: false,
        msg: self.$i18n.t(`folder.${method}`)
      }
      self.state.isSubmitting = true;
      const folderId = this.folder.uuId;

      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedFolderProperties(data);
      }
      if (method == 'create' || hasChanged) {
        await folderService[method](data).then(response => {
          return response.data.jobClue === "Created";
        })
        .catch(e => {
          result.hasError = true;
          result.msg = self.$i18n.t(`folder.error.failed_to_${method}`);
          const response = e.response;
          if(response) {
            if (422 === response.status) {
              const feedback = response.data[response.data.jobCase][0];
              this.errors.add({
                field: `folder.${feedback.spot}`,
                msg: this.$i18n.t(`error.${feedback.clue}`)
              })
            }
          }
        });
        if (result.hasError) {
          self.alertMsg = result.msg;
          self.scrollToTop();
          return;
        }
      }
      
      
      // save the color in the profile
      this.updatedColor = data.color;
      
      const originParentId = self.parentData? self.parentData.uuId: '-1';
      const newParentId = self.parent && self.parent.uuId? self.parent.uuId: '-1';

      if ('update' === method && originParentId !== self.parent.uuId) {
        if('create' !== method  && originParentId !== '-1') {
          await folderService.unlink(originParentId, folderId, 'folder')
          .catch(() => {
            result.hasError = true;
            const main = self.$i18n.t(`folder.error.failed_to_${method}`);
            const detail = self.$i18n.t(`folder.error.detail.failed_to_unlink`);
            result.msg = `${main} (${detail})`;
          });
        }

        if(result.hasError) {
          self.alertUploadMsg = result.msg;
          return;
        }

        if(newParentId !== '-1') {
          await folderService.link(newParentId, folderId, 'folder')
          .catch(() => {
            result.hasError = true;
            const main = self.$i18n.t(`folder.error.failed_to_${method}`);
            const detail = self.$i18n.t(`folder.error.detail.failed_to_link`);
            result.msg = `${main} (${detail})`;
          });
        }
      }

      await this.updateTags(folderId);
      
      self.state.isSubmitting = false;
      if (result.hasError) {
        self.alertMsg = result.msg;
        self.scrollToTop();
      } else {
        self.$emit('update:show', false);
        self.$emit('success', { 
           msg: self.$i18n.t(`folder.${method}`), 
           parentId: self.parent.uuId, 
           id: self.folder.id, 
           name: self.folder.name
        });
      }
    },
    async updateTags(folderId) {
      const result = {
        hasError: false
      }
      const tags = cloneDeep(this.tags);
      const originTags = this.originTags;

      // If any tag has been removed from the list, delete the link
      let toDelete = [];
      for (let i = 0; i < originTags.length; i++) {
        if (!(tags.map(s => s.uuId).includes(originTags[i].uuId))) {
          toDelete.push({uuId: originTags[i].uuId});
        }
      }
      if (toDelete.length > 0) {
        await folderLinkTagService.remove(folderId, toDelete);
      }

      // If any tag has been added to the list, create the link
      let toAdd = [];
      for (let i = 0; i < tags.length; i++) {
        if (!tags[i].uuId) {
          // get the uuId
          tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
            if (response.data.length !== 0) {
              return response.data[0].uuId;
            }
            return null;
          });
          
          if (tags[i].uuId === null) {
            tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
              if (response.data[response.data.jobCase].length !== 0) {
                return response.data[response.data.jobCase][0].uuId;
              }
              return null;
            });
          }
        }
        
        if (tags[i].uuId !== null &&
            !(originTags.map(s => s.uuId).includes(tags[i].uuId))) {
          toAdd.push({uuId: tags[i].uuId});
        }
      }
      if (toAdd.length > 0) {
        await folderLinkTagService.create(folderId, toAdd);
      }

      return result;
    },
    scrollToTop() {
      document.querySelector(`#FOLDER_FORM_${this.id}`).scrollIntoView();
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetFolderProperties() {
      const keys = Object.keys(this.folder);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        this.folder[keys[i]] = null;
      }
      // set the parent to the selected folder
      if (this.parentData != null) {
        this.parent.name = this.parentData.name;
        this.parent.uuId = this.parentData.uuId;
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
  
      for (const field of this.customFields) {
        this.folder[field.name] = typeof field.def !== 'undefined' ? field.def : null;
      }
      
      this.originTags = [];
      this.tags = [];
      this.originFolder = null;
    },
    folderSelectorSuccess(payload) {
      this.folderSelectorShow = false;
      this.parent.uuId = payload.uuId;
      this.parent.name = payload.name;
      this.path = payload.path;
      this.validateParent();
    },
    validateParent() {
      this.alertMsg = null;
      this.errors.remove('parent.name');
      const folderId = this.folder.uuId;
      if(folderId && this.path[folderId]) {
        this.errors.add({
          field: `parent.name`,
          msg: this.$i18n.t('folder.error.parent_cant_be_same_or_under_current')
        });
        return false;
      } else {
        return true;
      }
    },
    validateName() {
      this.alertMsg = null;
      this.errors.remove('folder.name');
      const name = this.folder.name;
      if(name === '' || name === null) {
        this.errors.add({
          field: `folder.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('folder.field.name')])
        });
        return false;
      } else {
        return true;
      }
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    removeUnchangedFolderProperties(data) {
      //Remove those properties whose value is not changed in provided data against original folder.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originFolder = this.originFolder;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
       if (originFolder[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>
