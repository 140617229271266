var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.elemId } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "no-close-on-esc": _vm.isCellEditing || _vm.editorShow,
            "modal-class": [_vm.elemId],
            "content-class": "shadow",
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.nonAdmin && !_vm.hideOkBtn
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "danger" },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                        ),
                      ]
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "danger" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _vm.alertObj.msg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertObj.msg,
                  details: _vm.alertObj.msgDetails.list,
                  detailTitle: _vm.alertObj.msgDetails.title,
                  alertState: _vm.alertObj.state,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          this.nonAdmin && !this.hideBookings && _vm.projectIds.length !== 0
            ? _c(
                "b-form-checkbox",
                {
                  staticClass: "staff-bookings-switch",
                  attrs: {
                    id: "bookings-switch",
                    name: "bookings-switch ",
                    switch: "",
                  },
                  on: { change: _vm.onBookings },
                  model: {
                    value: _vm.bookings,
                    callback: function ($$v) {
                      _vm.bookings = $$v
                    },
                    expression: "bookings",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("staff.project_bookings")))]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "selector-navbar" },
            [
              _c(
                "PriorityNavigation",
                {
                  ref: "selector-nav",
                  staticClass: "selector-nav",
                  attrs: { ulClassName: "nav nav-pills" },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link",
                      class: { active: _vm.listTabActive },
                      attrs: { name: "list" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.listTabActive && _vm.entitySelection > 0
                              ? _vm.$t("staff.selector.list_w_count", {
                                  count: _vm.entitySelection,
                                })
                              : _vm.$t("staff.selector.list")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm.nonAdmin && _vm.taskUuId !== null
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-pills nav-link",
                          class: { active: _vm.recommendedTabActive },
                          attrs: { name: "recommended" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.selectorNavClick.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: "#", target: "_self" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.recommendedTabActive &&
                                  _vm.entitySelection > 0
                                  ? _vm.$t(
                                      "staff.selector.recommended_w_count",
                                      { count: _vm.entitySelection }
                                    )
                                  : _vm.$t("staff.selector.recommended")
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link",
                      class: { active: _vm.orgChartTabActive },
                      attrs: { name: "orgChart" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.orgChartTabActive && _vm.entitySelection > 0
                              ? _vm.$t("staff.selector.orgChart_w_count", {
                                  count: _vm.entitySelection,
                                })
                              : _vm.$t("staff.selector.orgChart")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link",
                      class: { active: _vm.genericTabActive },
                      attrs: { name: "generic" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.genericTabActive && _vm.entitySelection > 0
                              ? _vm.$t("staff.selector.generic_w_count", {
                                  count: _vm.entitySelection,
                                })
                              : _vm.$t("staff.selector.generic")
                          )
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-tabs",
            {
              staticClass: "staff-tab-container",
              attrs: {
                "active-nav-item-class": "active",
                "content-class": "staff-tabs mt-3 specific-list",
                pills: "",
              },
              on: { "activate-tab": _vm.onTabSwitch },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: "List", "title-item-class": "mytab" } },
                [
                  _vm.allowManage
                    ? _c(
                        "PriorityNavigation",
                        {
                          staticClass: "list-toolbar grid-toolbar border-part",
                          attrs: {
                            dropDownStayOpened:
                              _vm.badgeFilterModalOpened != "close",
                            closeDropdown: _vm.closePriorityNavDropdown,
                            forceRefresh: _vm.forcePriorityNavRefresh,
                          },
                          on: {
                            "update:closeDropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                            "update:close-dropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                            "update:forceRefresh": function ($event) {
                              _vm.forcePriorityNavRefresh = $event
                            },
                            "update:force-refresh": function ($event) {
                              _vm.forcePriorityNavRefresh = $event
                            },
                          },
                          nativeOn: _vm._d({}, [
                            _vm.priorityNavMouseOverEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "list"
                              )
                            },
                            _vm.priorityNavTouchEndEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "list"
                              )
                            },
                          ]),
                        },
                        [
                          _vm.canAdd(_vm.entity, [
                            "startDate",
                            "endDate",
                            "staffType",
                            "email",
                            "firstName",
                            "payAmount",
                            "payCurrency",
                          ])
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_ADD_" + _vm.elemId } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: {
                                          color: "var(--grid-toolbar-button)",
                                        },
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_ADD_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("button.add")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_EDIT_" + _vm.elemId } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableEdit },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_EDIT_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.edit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: { id: "BTN_DUPLICATE_" + _vm.elemId },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDuplicate },
                                      on: {
                                        click: _vm.showDuplicateDialog_list,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "clone"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_DUPLICATE_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.duplicate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDelete(_vm.entity)
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_DELETE_" + _vm.elemId } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDelete },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDelete("list")
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-can"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_DELETE_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_IMPORT_DOCUMENT_" + _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    { on: { click: _vm.fileImport } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "inbox-in"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_IMPORT_DOCUMENT_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "staff.button.import_document"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              attrs: {
                                id: "BTN_EXPORT_DOCUMENT_" + _vm.elemId,
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExport } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_EXPORT_DOCUMENT_" + _vm.elemId,
                                    triggers: "hover",
                                    placement: "top",
                                    boundary: "viewport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("staff.button.export_document")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d(
                                    {
                                      mouseleave: function ($event) {
                                        return _vm.onFilterLeave($event, "list")
                                      },
                                    },
                                    [
                                      _vm.filterMouseEnterEvent,
                                      function ($event) {
                                        return _vm.onFilterOver($event, "list")
                                      },
                                    ]
                                  ),
                                },
                                [
                                  !_vm.searchPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "filter",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id: "BTN_FILTER_" + _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onFilterTextDropdownHide(
                                                $event,
                                                "list"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.filterText !== ""
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "file-magnifying-glass",
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3499512239
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                { staticClass: "search-input" },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t("task.filter"),
                                                    },
                                                    on: {
                                                      focus: function ($event) {
                                                        _vm.filterTextFocus = true
                                                      },
                                                      blur: function ($event) {
                                                        _vm.filterTextFocus = false
                                                      },
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.onFilterSubmit(
                                                          $event,
                                                          "list"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.filterText,
                                                      callback: function ($$v) {
                                                        _vm.filterText = $$v
                                                      },
                                                      expression: "filterText",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_CLEAR_FILTER_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterClear(
                                                                $event,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "xmark",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_CLEAR_FILTER_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.clear"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_SEARCH_FILTER_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterSubmit(
                                                                $event,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "magnifying-glass",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_SEARCH_FILTER_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.search"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_PIN_FILTER_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onPinFilter(
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "thumbtack",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_PIN_FILTER_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.pinOnToolbar"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.searchPinned
                                ? _c(
                                    "b-input-group",
                                    { staticClass: "search-input" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.$t("task.filter"),
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.filterTextFocus = true
                                          },
                                          blur: function ($event) {
                                            _vm.filterTextFocus = false
                                          },
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onFilterSubmit(
                                              $event,
                                              "list"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.filterText,
                                          callback: function ($$v) {
                                            _vm.filterText = $$v
                                          },
                                          expression: "filterText",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_CLEAR_FILTER_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterClear(
                                                    $event,
                                                    "list"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "xmark"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_CLEAR_FILTER_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.clear")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_SEARCH_FILTER_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterSubmit(
                                                    $event,
                                                    "list"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "magnifying-glass",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_SEARCH_FILTER_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.search")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_UNPIN_FILTER_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onUnPinFilter(
                                                    "list"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "thumbtack"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_UNPIN_FILTER_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "button.unpinFromToolbar"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d({}, [
                                    _vm.badgeFilterMouseEnterEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterEnter(
                                        $event,
                                        "list"
                                      )
                                    },
                                    _vm.badgeFilterMouseOverEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "list"
                                      )
                                    },
                                    _vm.badgeFilterMouseLeaveEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterLeave(
                                        $event,
                                        "list"
                                      )
                                    },
                                    _vm.badgeFilterTouchEndEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "list"
                                      )
                                    },
                                  ]),
                                },
                                [
                                  !_vm.badgeFilterPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "badgeFilter",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id:
                                              "BTN_BADGE_FILTER_" + _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onBadgeFilterDropdownHide(
                                                $event,
                                                "list"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.badgeFilters !=
                                                          null &&
                                                        _vm.badgeFilters
                                                          .length > 0
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: ["far", "filter"],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1473832823
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c("TaskViewBadgeFilter", {
                                                attrs: {
                                                  filters: _vm.badgeFilters,
                                                  fields: _vm.badgeFilterFields,
                                                  fieldValues:
                                                    _vm.badgeFilterFieldValues,
                                                  canPin: "",
                                                },
                                                on: {
                                                  modified: function ($event) {
                                                    return _vm.onBadgeFilterModified(
                                                      $event,
                                                      "list"
                                                    )
                                                  },
                                                  filterModalOpened: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalOpened(
                                                      $event,
                                                      "list"
                                                    )
                                                  },
                                                  filterModalClosed: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalClosed(
                                                      $event,
                                                      "list"
                                                    )
                                                  },
                                                  fetchFieldOptions: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterFetchOptions(
                                                      $event,
                                                      "list"
                                                    )
                                                  },
                                                  pin: function ($event) {
                                                    return _vm.onPinBadgeFilter(
                                                      "list"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.badgeFilterPinned
                                ? _c("TaskViewBadgeFilter", {
                                    attrs: {
                                      filters: _vm.badgeFilters,
                                      fields: _vm.badgeFilterFields,
                                      fieldValues: _vm.badgeFilterFieldValues,
                                      canPin: "",
                                      pinned: "",
                                    },
                                    on: {
                                      modified: function ($event) {
                                        return _vm.onBadgeFilterModified(
                                          $event,
                                          "list"
                                        )
                                      },
                                      filterModalOpened: function ($event) {
                                        return _vm.onBadgeFilterModalOpened(
                                          $event,
                                          "list"
                                        )
                                      },
                                      filterModalClosed: function ($event) {
                                        return _vm.onBadgeFilterModalClosed(
                                          $event,
                                          "list"
                                        )
                                      },
                                      fetchFieldOptions: function ($event) {
                                        return _vm.onBadgeFilterFetchOptions(
                                          $event,
                                          "list"
                                        )
                                      },
                                      pin: function ($event) {
                                        return _vm.onUnPinBadgeFilter("list")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "view",
                              on: _vm._d(
                                {
                                  mouseleave: function ($event) {
                                    return _vm.onViewLeave($event, "list")
                                  },
                                },
                                [
                                  _vm.viewMouseEnterEvent,
                                  function ($event) {
                                    return _vm.onViewOver($event, "list")
                                  },
                                ]
                              ),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "view_list",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_VIEW_orgChart_" + _vm.elemId,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "desktop"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4186387599
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAllColumns("list")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.all_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showNoColumns("list")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.no_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.savePreset("list")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                (_vm.entity
                                                  ? _vm.formattedEntity + "."
                                                  : "") + "button.save_view"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _vm._l(
                                    _vm.filteredViews,
                                    function (item, index) {
                                      return [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            key: index,
                                            staticClass: "action-item",
                                            attrs: {
                                              disabled: _vm.inProgressShow,
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadViewSettings(
                                                  item,
                                                  "list"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-label-with-icon",
                                                attrs: {
                                                  id: "viewname" + index,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm.isEllipsisActive(item.name)
                                              ? _c("b-popover", {
                                                  attrs: {
                                                    target: "viewname" + index,
                                                    placement: "top",
                                                    boundary: "viewport",
                                                    "custom-class":
                                                      "popover-margin",
                                                    triggers: "hover",
                                                    offset: "-100",
                                                    content: item.name,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              [
                                                !_vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "COPY_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.copyColumnSettings(
                                                                item.name,
                                                                item,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "copy",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "COPY_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.copy_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "BTN_INFO_" +
                                                              index,
                                                          },
                                                          on: _vm._d(
                                                            {
                                                              mouseleave:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onInfoLeave(
                                                                    index,
                                                                    _vm.filteredViews,
                                                                    _vm.showInfo
                                                                  )
                                                                },
                                                            },
                                                            [
                                                              _vm.infoMouseEnterEvent,
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onInfoOver(
                                                                  index,
                                                                  _vm.filteredViews,
                                                                  _vm.showInfo
                                                                )
                                                              },
                                                            ]
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "circle-info",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-popover", {
                                                        ref:
                                                          "BTN_INFO_" + index,
                                                        refInFor: true,
                                                        attrs: {
                                                          target:
                                                            "BTN_INFO_" + index,
                                                          show: _vm.showInfo[
                                                            index
                                                          ],
                                                          placement: "top",
                                                          boundary: "viewport",
                                                          triggers: "manual",
                                                          content: _vm.$t(
                                                            "owner",
                                                            [
                                                              _vm.filteredViews[
                                                                index
                                                              ].owner,
                                                            ]
                                                          ),
                                                        },
                                                        on: {
                                                          "update:show":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.showInfo,
                                                                index,
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "SHARE_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.shareColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  item.defaultView
                                                                    ? "fas"
                                                                    : "far",
                                                                  "share-nodes",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "SHARE_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.share_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-second",
                                                          attrs: {
                                                            id:
                                                              "UPDATE_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.updateColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "floppy-disk",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "UPDATE_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.update_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "REMOVE_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.removeColumnSettings(
                                                                index,
                                                                "list"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "trash-can",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "REMOVE_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.delete_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                                _vm.colorMouseEnterEvent,
                                _vm.onColoringOver,
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "coloring",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_COLORING_" + _vm.elemId,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "palette"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1244906976
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-group",
                                    { attrs: { header: _vm.$t("colorby") } },
                                    [
                                      _vm._l(
                                        _vm.coloring,
                                        function (value, name, index) {
                                          return [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                key: index,
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onListColorChange(
                                                      name
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "entity_selector.coloring_" +
                                                            name
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm.coloring[name]
                                                  ? _c("font-awesome-icon", {
                                                      staticClass:
                                                        "active-check",
                                                      attrs: {
                                                        icon: ["far", "check"],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("li", [
                            _c(
                              "span",
                              {
                                staticClass: "action-toolbar-divider",
                                attrs: { readonly: "" },
                              },
                              [_vm._v("|")]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: { change: _vm.onSingleContractsChange },
                                  model: {
                                    value: _vm.singleContracts,
                                    callback: function ($$v) {
                                      _vm.singleContracts = $$v
                                    },
                                    expression: "singleContracts",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("staff.single_contracts"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "staff-action-bar" },
                    [
                      _c(
                        "PriorityNavigation",
                        {
                          staticClass: "grid-toolbar border-ends",
                          attrs: {
                            closeDropdown: _vm.closePriorityNavDropdown,
                          },
                          on: {
                            "update:closeDropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                            "update:close-dropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                          },
                          nativeOn: _vm._d({}, [
                            _vm.priorityNavMouseOverEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                                null,
                                arguments
                              )
                            },
                            _vm.priorityNavTouchEndEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                                null,
                                arguments
                              )
                            },
                          ]),
                        },
                        [
                          _c("li", [
                            _c(
                              "span",
                              { staticClass: "d-flex ml-2 mr-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { for: "dates" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("staff.dates")))]
                                ),
                                _c("b-form-select", {
                                  staticClass: "mw-150 minw-170",
                                  attrs: {
                                    id: "dates",
                                    disabled: !_vm.canView(
                                      this.permissionName,
                                      ["endDate", "startDate"]
                                    ),
                                    options: _vm.dateOptions,
                                    size: "sm",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.rangeSelected()
                                    },
                                  },
                                  model: {
                                    value: _vm.dates,
                                    callback: function ($$v) {
                                      _vm.dates = $$v
                                    },
                                    expression: "dates",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.dates !== "all-time" &&
                                    _vm.canView(this.permissionName, [
                                      "endDate",
                                      "startDate",
                                    ]),
                                  expression:
                                    "dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex mr-1 date start-date-elevation",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-1 align-self-baseline",
                                      attrs: { for: "startDate" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("staff.from")))]
                                  ),
                                  _c("b-form-datepicker", {
                                    staticClass: "date-picker d-flex",
                                    attrs: {
                                      id: "staffUsageStartDate",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                      "hide-header": "",
                                      "label-today-button":
                                        _vm.$t("date.today"),
                                      "label-reset-button":
                                        _vm.$t("date.reset"),
                                      "label-close-button":
                                        _vm.$t("date.close"),
                                      "today-button-variant": "primary",
                                      "reset-button-variant": "danger",
                                      "close-button-variant": "secondary",
                                      size: "sm",
                                    },
                                    on: { input: _vm.dateChanged },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function (ref) {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "calendar-days"],
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.startDate,
                                      callback: function ($$v) {
                                        _vm.startDate = $$v
                                      },
                                      expression: "startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.dates !== "all-time" &&
                                    _vm.canView(this.permissionName, [
                                      "endDate",
                                      "startDate",
                                    ]),
                                  expression:
                                    "dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "d-flex mr-1 date" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-1 align-self-baseline",
                                      attrs: { for: "endDate" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("staff.to")))]
                                  ),
                                  _c("b-form-datepicker", {
                                    staticClass: "date-picker d-flex",
                                    attrs: {
                                      id: "staffUsageEndDate",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                      "hide-header": "",
                                      "label-today-button":
                                        _vm.$t("date.today"),
                                      "label-reset-button":
                                        _vm.$t("date.reset"),
                                      "label-close-button":
                                        _vm.$t("date.close"),
                                      "today-button-variant": "primary",
                                      "reset-button-variant": "danger",
                                      "close-button-variant": "secondary",
                                      size: "sm",
                                    },
                                    on: { input: _vm.dateChanged },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function (ref) {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "calendar-days"],
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.endDate,
                                      callback: function ($$v) {
                                        _vm.endDate = $$v
                                      },
                                      expression: "endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.canView(this.permissionName, [
                            "endDate",
                            "startDate",
                          ])
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        id: "BTN_REFRESH_" + _vm.elemId,
                                        pressed: _vm.highlightRefresh,
                                      },
                                      on: {
                                        click: _vm.daySelected,
                                        "update:pressed": function ($event) {
                                          _vm.highlightRefresh = $event
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "arrows-rotate"],
                                        },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "BTN_REFRESH_" + _vm.elemId,
                                          placement: "top",
                                          boundary: "viewport",
                                          triggers: "hover",
                                          content: _vm.$t("button.refresh"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "staff-grid",
                      gridOptions: _vm.gridOptions,
                      columnDefs: _vm.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      getRowId: function (params) {
                        return params.data.uuId
                      },
                      pagination: "",
                      paginationPageSize: 1000,
                      cacheBlockSize: _vm.cacheBlockSize,
                      rowModelType: "serverSide",
                      rowData: _vm.rowData,
                      rowMultiSelectWithClick: _vm.nonAdmin,
                      rowSelection: _vm.singleSelection ? "single" : "multiple",
                      serverSideInfiniteScroll: true,
                      sideBar: false,
                      suppressContextMenu: "",
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: _vm.nonAdmin,
                      singleClickEdit: false,
                      suppressMultiSort: false,
                      enableRangeSelection: !_vm.nonAdmin,
                      suppressRowClickSelection: !_vm.nonAdmin,
                      enableFillHandle: !_vm.nonAdmin,
                      fillOperation: _vm.fillOperation("list"),
                      fillHandleDirection: "xy",
                      processCellForClipboard:
                        _vm.processCellForClipboard("list"),
                      processCellFromClipboard:
                        _vm.processCellFromClipboard("list"),
                      suppressClipboardApi: "",
                      navigateToNextCell: _vm.navigateToNextCell,
                      tabToNextCell: _vm.tabToNextCell,
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.noRowsOverlayComponentParams,
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      enableCellEditingOnBackspace: "",
                    },
                    on: {
                      "grid-ready": _vm.onGridReady,
                      "cell-key-down": _vm.onCellKeyDown,
                      "paste-start": _vm.onPasteStart,
                      "paste-end": _vm.onPasteEnd,
                      "cell-focused": _vm.cellFocused,
                      "cell-editing-started": _vm.onCellEditingStarted,
                      "cell-editing-stopped": _vm.onCellEditingStopped,
                    },
                  }),
                ],
                1
              ),
              _vm.nonAdmin && _vm.taskUuId !== null
                ? _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Recommended",
                        "title-item-class": "mytab",
                        "content-class": "recommended-list",
                      },
                    },
                    [
                      _c(
                        "nav",
                        {
                          staticClass:
                            "priority-nav recommended-toolbar grid-toolbar border",
                        },
                        [
                          _c("ul", [
                            _vm.canView(_vm.entity)
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "recommended-first-action",
                                    on: _vm._d(
                                      {
                                        mouseleave: function ($event) {
                                          return _vm.onFilterLeave(
                                            $event,
                                            "recommended"
                                          )
                                        },
                                      },
                                      [
                                        _vm.filterMouseEnterEvent,
                                        function ($event) {
                                          return _vm.onFilterOver(
                                            $event,
                                            "recommended"
                                          )
                                        },
                                      ]
                                    ),
                                  },
                                  [
                                    !_vm.recommended.searchPinned
                                      ? _c(
                                          "b-dropdown",
                                          {
                                            ref: "filter_recommended",
                                            staticClass:
                                              "action-bar-dropdown text-filter",
                                            attrs: {
                                              id:
                                                "BTN_FILTER_recommended_" +
                                                _vm.elemId,
                                              "toggle-class":
                                                "text-decoration-none",
                                              "no-caret": "",
                                            },
                                            on: {
                                              hide: function ($event) {
                                                return _vm.onFilterTextDropdownHide(
                                                  $event,
                                                  "recommended"
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _c("font-awesome-icon", {
                                                        class:
                                                          _vm.recommended
                                                            .filterText !== ""
                                                            ? "active"
                                                            : "",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "file-magnifying-glass",
                                                          ],
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1912393716
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-form",
                                              {
                                                staticClass: "filter-padding",
                                                on: {
                                                  submit: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass: "search-input",
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t("task.filter"),
                                                      },
                                                      on: {
                                                        focus: function (
                                                          $event
                                                        ) {
                                                          _vm.recommended.filterTextFocus = true
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          _vm.recommended.filterTextFocus = false
                                                        },
                                                        keypress: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.onFilterSubmit(
                                                            $event,
                                                            "recommended"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.recommended
                                                            .filterText,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.recommended,
                                                            "filterText",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recommended.filterText",
                                                      },
                                                    }),
                                                    _c(
                                                      "b-input-group-append",
                                                      [
                                                        _c(
                                                          "b-btn",
                                                          {
                                                            staticClass:
                                                              "search-append search-append-bg",
                                                            attrs: {
                                                              id:
                                                                "BTN_CLEAR_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onFilterClear(
                                                                  $event,
                                                                  "recommended"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "far",
                                                                    "xmark",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target:
                                                                "BTN_CLEAR_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              triggers: "hover",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "button.clear"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-input-group-append",
                                                      [
                                                        _c(
                                                          "b-btn",
                                                          {
                                                            staticClass:
                                                              "search-append search-append-bg",
                                                            attrs: {
                                                              id:
                                                                "BTN_SEARCH_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onFilterSubmit(
                                                                  $event,
                                                                  "recommended"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "far",
                                                                    "magnifying-glass",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target:
                                                                "BTN_SEARCH_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              triggers: "hover",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "button.search"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-input-group-append",
                                                      [
                                                        _c(
                                                          "b-btn",
                                                          {
                                                            staticClass:
                                                              "search-append search-append-bg",
                                                            attrs: {
                                                              id:
                                                                "BTN_PIN_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onPinFilter(
                                                                  "recommended"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "far",
                                                                    "thumbtack",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target:
                                                                "BTN_PIN_FILTER_RECOMMENDED_" +
                                                                _vm.elemId,
                                                              triggers: "hover",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "button.pinOnToolbar"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "li",
                              [
                                _vm.recommended.searchPinned
                                  ? _c(
                                      "b-input-group",
                                      { staticClass: "search-input" },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.$t("task.filter"),
                                          },
                                          on: {
                                            focus: function ($event) {
                                              _vm.recommended.filterTextFocus = true
                                            },
                                            blur: function ($event) {
                                              _vm.recommended.filterTextFocus = false
                                            },
                                            keypress: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.onFilterSubmit(
                                                $event,
                                                "recommended"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.recommended.filterText,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.recommended,
                                                "filterText",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "recommended.filterText",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-btn",
                                              {
                                                staticClass:
                                                  "search-append search-append-bg",
                                                attrs: {
                                                  id:
                                                    "BTN_CLEAR_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onFilterClear(
                                                      $event,
                                                      "recommended"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "xmark"],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "BTN_CLEAR_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  triggers: "hover",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("button.clear")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-btn",
                                              {
                                                staticClass:
                                                  "search-append search-append-bg",
                                                attrs: {
                                                  id:
                                                    "BTN_SEARCH_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onFilterSubmit(
                                                      $event,
                                                      "recommended"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "magnifying-glass",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "BTN_SEARCH_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  triggers: "hover",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("button.search")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-btn",
                                              {
                                                staticClass:
                                                  "search-append search-append-bg",
                                                attrs: {
                                                  id:
                                                    "BTN_UNPIN_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onUnPinFilter(
                                                      "recommended"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "thumbtack"],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "BTN_UNPIN_FILTER_RECOMMENDED_" +
                                                    _vm.elemId,
                                                  triggers: "hover",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "button.unpinFromToolbar"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "li",
                              {
                                staticStyle: {
                                  right: "0",
                                  position: "absolute",
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "settings-button",
                                    attrs: { id: "recommendation_settings" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showRecommendationSettings()
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "gear"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "recommendation_settings",
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "staff.recommendation_settings"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c("ag-grid-vue", {
                        staticClass:
                          "ag-theme-balham selector-grid-height-with-tabs",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "recommended-grid",
                          gridOptions: _vm.recommended.gridOptions,
                          columnDefs: _vm.recommended.columnDefs,
                          context: _vm.recommended.context,
                          defaultColDef: _vm.recommended.defaultColDef,
                          getRowId: function (params) {
                            return params.data.staffUUID
                          },
                          pagination: "",
                          paginationPageSize: 1000,
                          rowModelType: "serverSide",
                          rowSelection: _vm.singleSelection
                            ? "single"
                            : "multiple",
                          rowMultiSelectWithClick: "",
                          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                          serverSideInfiniteScroll: true,
                          sideBar: false,
                          suppressDragLeaveHidesColumns: "",
                          suppressCellFocus: "",
                          suppressMultiSort: "",
                          noRowsOverlayComponent: "noRowsOverlay",
                          noRowsOverlayComponentParams:
                            _vm.generic.noRowsOverlayComponentParams,
                        },
                        on: { "grid-ready": _vm.onRecommendedGridReady },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Org Chart",
                    "title-item-class": "mytab",
                    "content-class": "orgchart-list",
                  },
                },
                [
                  _vm.allowManage
                    ? _c(
                        "PriorityNavigation",
                        {
                          staticClass:
                            "orgchart-toolbar grid-toolbar border-part",
                          attrs: {
                            dropDownStayOpened:
                              _vm.orgChart.badgeFilterModalOpened != "close",
                            closeDropdown:
                              _vm.orgChart.closePriorityNavDropdown,
                            forceRefresh: _vm.orgChart.forcePriorityNavRefresh,
                          },
                          on: {
                            "update:closeDropdown": function ($event) {
                              return _vm.$set(
                                _vm.orgChart,
                                "closePriorityNavDropdown",
                                $event
                              )
                            },
                            "update:close-dropdown": function ($event) {
                              return _vm.$set(
                                _vm.orgChart,
                                "closePriorityNavDropdown",
                                $event
                              )
                            },
                            "update:forceRefresh": function ($event) {
                              return _vm.$set(
                                _vm.orgChart,
                                "forcePriorityNavRefresh",
                                $event
                              )
                            },
                            "update:force-refresh": function ($event) {
                              return _vm.$set(
                                _vm.orgChart,
                                "forcePriorityNavRefresh",
                                $event
                              )
                            },
                          },
                          nativeOn: _vm._d({}, [
                            _vm.priorityNavMouseOverEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "orgChart"
                              )
                            },
                            _vm.priorityNavTouchEndEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "orgChart"
                              )
                            },
                          ]),
                        },
                        [
                          _c(
                            "li",
                            {
                              attrs: {
                                id: "BTN_ADD_" + _vm.elemId + "_ORGCHART",
                              },
                            },
                            [
                              _vm.canAdd(_vm.entity)
                                ? _c(
                                    "b-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: {
                                          color: "var(--grid-toolbar-button)",
                                        },
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target:
                                      "BTN_ADD_" + _vm.elemId + "_ORGCHART",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.add")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_EDIT_" + _vm.elemId + "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOrgChartEdit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_EDIT_" +
                                          _vm.elemId +
                                          "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.edit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id:
                                      "BTN_DUPLICATE_" +
                                      _vm.elemId +
                                      "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOrgChartDuplicate,
                                      },
                                      on: {
                                        click: _vm.showDuplicateDialog_orgChart,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "clone"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_DUPLICATE_" +
                                          _vm.elemId +
                                          "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.duplicate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDelete(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id:
                                      "BTN_DELETE_" + _vm.elemId + "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOrgChartDelete,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDelete("orgChart")
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-can"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_DELETE_" +
                                          _vm.elemId +
                                          "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              attrs: {
                                id:
                                  "BTN_EXPORT_DOCUMENT_" +
                                  _vm.elemId +
                                  "_ORGCHART",
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExportOrgChart } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target:
                                      "BTN_EXPORT_DOCUMENT_" +
                                      _vm.elemId +
                                      "_ORGCHART",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("staff.button.export_document")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d(
                                    {
                                      mouseleave: function ($event) {
                                        return _vm.onFilterLeave(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                    },
                                    [
                                      _vm.filterMouseEnterEvent,
                                      function ($event) {
                                        return _vm.onFilterOver(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                    ]
                                  ),
                                },
                                [
                                  !_vm.orgChart.searchPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "filter_orgChart",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id:
                                              "BTN_FILTER_orgChart_" +
                                              _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onFilterTextDropdownHide(
                                                $event,
                                                "orgChart"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.orgChart
                                                          .filterText !== ""
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "file-magnifying-glass",
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1916436791
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                { staticClass: "search-input" },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t("task.filter"),
                                                    },
                                                    on: {
                                                      focus: function ($event) {
                                                        _vm.orgChart.filterTextFocus = true
                                                      },
                                                      blur: function ($event) {
                                                        _vm.orgChart.filterTextFocus = false
                                                      },
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.onFilterSubmit(
                                                          $event,
                                                          "orgChart"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orgChart.filterText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orgChart,
                                                          "filterText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orgChart.filterText",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_CLEAR_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterClear(
                                                                $event,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "xmark",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_CLEAR_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.clear"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_SEARCH_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterSubmit(
                                                                $event,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "magnifying-glass",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_SEARCH_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.search"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_PIN_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onPinFilter(
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "thumbtack",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_PIN_FILTER_ORGCHART_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.pinOnToolbar"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.orgChart.searchPinned
                                ? _c(
                                    "b-input-group",
                                    { staticClass: "search-input" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.$t("task.filter"),
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.orgChart.filterTextFocus = true
                                          },
                                          blur: function ($event) {
                                            _vm.orgChart.filterTextFocus = false
                                          },
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onFilterSubmit(
                                              $event,
                                              "orgChart"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.orgChart.filterText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orgChart,
                                              "filterText",
                                              $$v
                                            )
                                          },
                                          expression: "orgChart.filterText",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_CLEAR_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterClear(
                                                    $event,
                                                    "orgChart"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "xmark"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_CLEAR_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.clear")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_SEARCH_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterSubmit(
                                                    $event,
                                                    "orgChart"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "magnifying-glass",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_SEARCH_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.search")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_UNPIN_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onUnPinFilter(
                                                    "orgChart"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "thumbtack"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_UNPIN_FILTER_ORGCHART_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "button.unpinFromToolbar"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d({}, [
                                    _vm.badgeFilterMouseEnterEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterEnter(
                                        $event,
                                        "orgChart"
                                      )
                                    },
                                    _vm.badgeFilterMouseOverEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "orgChart"
                                      )
                                    },
                                    _vm.badgeFilterMouseLeaveEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterLeave(
                                        $event,
                                        "orgChart"
                                      )
                                    },
                                    _vm.badgeFilterTouchEndEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "orgChart"
                                      )
                                    },
                                  ]),
                                },
                                [
                                  !_vm.orgChart.badgeFilterPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "badgeFilter_orgChart",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id:
                                              "BTN_BADGE_FILTER_" + _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onBadgeFilterDropdownHide(
                                                $event,
                                                "orgChart"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.orgChart != null &&
                                                        _vm.orgChart
                                                          .badgeFilters !=
                                                          null &&
                                                        _vm.orgChart
                                                          .badgeFilters.length >
                                                          0
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: ["far", "filter"],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2446537638
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c("TaskViewBadgeFilter", {
                                                attrs: {
                                                  filters:
                                                    _vm.orgChart.badgeFilters,
                                                  fields:
                                                    _vm.orgChart
                                                      .badgeFilterFields,
                                                  fieldValues:
                                                    _vm.orgChart
                                                      .badgeFilterFieldValues,
                                                  canPin: "",
                                                },
                                                on: {
                                                  modified: function ($event) {
                                                    return _vm.onBadgeFilterModified(
                                                      $event,
                                                      "orgChart"
                                                    )
                                                  },
                                                  filterModalOpened: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalOpened(
                                                      $event,
                                                      "orgChart"
                                                    )
                                                  },
                                                  filterModalClosed: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalClosed(
                                                      $event,
                                                      "orgChart"
                                                    )
                                                  },
                                                  fetchFieldOptions: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterFetchOptions(
                                                      $event,
                                                      "orgChart"
                                                    )
                                                  },
                                                  pin: function ($event) {
                                                    return _vm.onPinBadgeFilter(
                                                      "orgChart"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.orgChart.badgeFilterPinned
                                ? _c("TaskViewBadgeFilter", {
                                    attrs: {
                                      filters: _vm.orgChart.badgeFilters,
                                      fields: _vm.orgChart.badgeFilterFields,
                                      fieldValues:
                                        _vm.orgChart.badgeFilterFieldValues,
                                      canPin: "",
                                      pinned: "",
                                    },
                                    on: {
                                      modified: function ($event) {
                                        return _vm.onBadgeFilterModified(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                      filterModalOpened: function ($event) {
                                        return _vm.onBadgeFilterModalOpened(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                      filterModalClosed: function ($event) {
                                        return _vm.onBadgeFilterModalClosed(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                      fetchFieldOptions: function ($event) {
                                        return _vm.onBadgeFilterFetchOptions(
                                          $event,
                                          "orgChart"
                                        )
                                      },
                                      pin: function ($event) {
                                        return _vm.onUnPinBadgeFilter(
                                          "orgChart"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "view",
                              on: _vm._d(
                                {
                                  mouseleave: function ($event) {
                                    return _vm.onViewLeave($event, "orgChart")
                                  },
                                },
                                [
                                  _vm.viewMouseEnterEvent,
                                  function ($event) {
                                    return _vm.onViewOver($event, "orgChart")
                                  },
                                ]
                              ),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "view_orgChart",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_VIEW_orgChart_" + _vm.elemId,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "desktop"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4186387599
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAllColumns("orgChart")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.all_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showNoColumns("orgChart")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.no_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.savePreset("orgChart")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                (_vm.entity
                                                  ? _vm.formattedEntity + "."
                                                  : "") + "button.save_view"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _vm._l(
                                    _vm.filteredOrgChartViews,
                                    function (item, index) {
                                      return [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            key: index,
                                            staticClass: "action-item",
                                            attrs: {
                                              disabled: _vm.inProgressShow,
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadViewSettings(
                                                  item,
                                                  "orgChart"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-label-with-icon",
                                                attrs: {
                                                  id: "viewname_org" + index,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm.isEllipsisActive(item.name)
                                              ? _c("b-popover", {
                                                  attrs: {
                                                    target:
                                                      "viewname_org" + index,
                                                    placement: "top",
                                                    boundary: "viewport",
                                                    "custom-class":
                                                      "popover-margin",
                                                    triggers: "hover",
                                                    offset: "-100",
                                                    content: item.name,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              [
                                                !_vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "COPY_ORGCHART_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.copyColumnSettings(
                                                                item.name,
                                                                item,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "copy",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "COPY_ORGCHART_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.copy_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "BTN_ORGCHART_INFO_" +
                                                              index,
                                                          },
                                                          on: _vm._d(
                                                            {
                                                              mouseleave:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onInfoLeave(
                                                                    index,
                                                                    _vm.filteredOrgChartViews,
                                                                    _vm.orgChart
                                                                      .showInfo
                                                                  )
                                                                },
                                                            },
                                                            [
                                                              _vm.infoMouseEnterEvent,
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onInfoOver(
                                                                  index,
                                                                  _vm.filteredOrgChartViews,
                                                                  _vm.orgChart
                                                                    .showInfo
                                                                )
                                                              },
                                                            ]
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "circle-info",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-popover", {
                                                        ref:
                                                          "BTN_ORGCHART_INFO_" +
                                                          index,
                                                        refInFor: true,
                                                        attrs: {
                                                          target:
                                                            "BTN_ORGCHART_INFO_" +
                                                            index,
                                                          show: _vm.orgChart
                                                            .showInfo[index],
                                                          placement: "top",
                                                          boundary: "viewport",
                                                          triggers: "manual",
                                                          content: _vm.$t(
                                                            "owner",
                                                            [
                                                              _vm
                                                                .filteredOrgChartViews[
                                                                index
                                                              ].owner,
                                                            ]
                                                          ),
                                                        },
                                                        on: {
                                                          "update:show":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.orgChart
                                                                  .showInfo,
                                                                index,
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "SHARE_ORGCHART_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.shareColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  item.defaultView
                                                                    ? "fas"
                                                                    : "far",
                                                                  "share-nodes",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "SHARE_ORGCHART_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.share_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-second",
                                                          attrs: {
                                                            id:
                                                              "UPDATE_ORGCHART_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.updateColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "floppy-disk",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "UPDATE_ORGCHART_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.update_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "REMOVE_ORGCHART_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.removeColumnSettings(
                                                                index,
                                                                "orgChart"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "trash-can",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "REMOVE_ORGCHART_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.delete_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("li", [
                            _c(
                              "span",
                              {
                                staticClass: "action-toolbar-divider",
                                attrs: { readonly: "" },
                              },
                              [_vm._v("|")]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: { change: _vm.onOrgChartRealStaffChange },
                                  model: {
                                    value: _vm.orgChartRealStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartRealStaff = $$v
                                    },
                                    expression: "orgChartRealStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.actual_staff")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: {
                                    change: _vm.onOrgChartGenericStaffChange,
                                  },
                                  model: {
                                    value: _vm.orgChartGenericStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartGenericStaff = $$v
                                    },
                                    expression: "orgChartGenericStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.generic_staff")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: {
                                    change: _vm.onOrgChartSingleContractsChange,
                                  },
                                  model: {
                                    value: _vm.orgChart.singleContracts,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orgChart,
                                        "singleContracts",
                                        $$v
                                      )
                                    },
                                    expression: "orgChart.singleContracts",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("staff.single_contracts"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "staff-action-bar" },
                    [
                      _c(
                        "PriorityNavigation",
                        {
                          staticClass: "grid-toolbar border-ends",
                          attrs: {
                            closeDropdown: _vm.closePriorityNavDropdown,
                          },
                          on: {
                            "update:closeDropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                            "update:close-dropdown": function ($event) {
                              _vm.closePriorityNavDropdown = $event
                            },
                          },
                          nativeOn: _vm._d({}, [
                            _vm.priorityNavMouseOverEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                                null,
                                arguments
                              )
                            },
                            _vm.priorityNavTouchEndEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                                null,
                                arguments
                              )
                            },
                          ]),
                        },
                        [
                          _c("li", [
                            _c(
                              "span",
                              { staticClass: "d-flex ml-2 mr-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { for: "dates" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("staff.dates")))]
                                ),
                                _c("b-form-select", {
                                  staticClass: "mw-150 minw-170",
                                  attrs: {
                                    id: "orgChart.dates",
                                    disabled: !_vm.canView(
                                      this.permissionName,
                                      ["endDate", "startDate"]
                                    ),
                                    options: _vm.dateOptions,
                                    size: "sm",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.orgChartRangeSelected()
                                    },
                                  },
                                  model: {
                                    value: _vm.orgChart.dates,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.orgChart, "dates", $$v)
                                    },
                                    expression: "orgChart.dates",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.orgChart.dates !== "all-time" &&
                                    _vm.canView(this.permissionName, [
                                      "endDate",
                                      "startDate",
                                    ]),
                                  expression:
                                    "orgChart.dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex mr-1 date start-date-elevation",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-1 align-self-baseline",
                                      attrs: { for: "startDate" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("staff.from")))]
                                  ),
                                  _c("b-form-datepicker", {
                                    staticClass: "date-picker d-flex",
                                    attrs: {
                                      id: "orgChart.staffUsageStartDate",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                      "hide-header": "",
                                      "label-today-button":
                                        _vm.$t("date.today"),
                                      "label-reset-button":
                                        _vm.$t("date.reset"),
                                      "label-close-button":
                                        _vm.$t("date.close"),
                                      "today-button-variant": "primary",
                                      "reset-button-variant": "danger",
                                      "close-button-variant": "secondary",
                                      size: "sm",
                                    },
                                    on: { input: _vm.orgChartDateChanged },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function (ref) {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "calendar-days"],
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.orgChart.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orgChart, "startDate", $$v)
                                      },
                                      expression: "orgChart.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.orgChart.dates !== "all-time" &&
                                    _vm.canView(this.permissionName, [
                                      "endDate",
                                      "startDate",
                                    ]),
                                  expression:
                                    "orgChart.dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "d-flex mr-1 date" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-1 align-self-baseline",
                                      attrs: { for: "endDate" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("staff.to")))]
                                  ),
                                  _c("b-form-datepicker", {
                                    staticClass: "date-picker d-flex",
                                    attrs: {
                                      id: "orgChart.staffUsageEndDate",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                      "hide-header": "",
                                      "label-today-button":
                                        _vm.$t("date.today"),
                                      "label-reset-button":
                                        _vm.$t("date.reset"),
                                      "label-close-button":
                                        _vm.$t("date.close"),
                                      "today-button-variant": "primary",
                                      "reset-button-variant": "danger",
                                      "close-button-variant": "secondary",
                                      size: "sm",
                                    },
                                    on: { input: _vm.orgChartDateChanged },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function (ref) {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "calendar-days"],
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.orgChart.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orgChart, "endDate", $$v)
                                      },
                                      expression: "orgChart.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.canView(this.permissionName, [
                            "endDate",
                            "startDate",
                          ])
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        id: "BTN_REFRESH_ORGCHART" + _vm.elemId,
                                        pressed: _vm.orgChart.highlightRefresh,
                                      },
                                      on: {
                                        click: _vm.orgChartDaySelected,
                                        "update:pressed": function ($event) {
                                          return _vm.$set(
                                            _vm.orgChart,
                                            "highlightRefresh",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "arrows-rotate"],
                                        },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target:
                                            "BTN_REFRESH_ORGCHART" + _vm.elemId,
                                          placement: "top",
                                          boundary: "viewport",
                                          triggers: "hover",
                                          content: _vm.$t("button.refresh"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "org-chart-grid",
                      gridOptions: _vm.orgChart.gridOptions,
                      autoGroupColumnDef: _vm.orgChart.autoGroupColumnDef,
                      columnDefs: _vm.orgChart.columnDefs,
                      context: _vm.orgChart.context,
                      defaultColDef: _vm.orgChart.defaultColDef,
                      getRowId: function (params) {
                        return params.data.path
                      },
                      rowMultiSelectWithClick: _vm.nonAdmin,
                      rowSelection: _vm.singleSelection ? "single" : "multiple",
                      sideBar: false,
                      suppressContextMenu: "",
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: _vm.nonAdmin,
                      singleClickEdit: false,
                      suppressMultiSort: false,
                      enableRangeSelection: !_vm.nonAdmin,
                      suppressRowClickSelection: !_vm.nonAdmin,
                      enableFillHandle: !_vm.nonAdmin,
                      fillOperation: _vm.fillOperation("orgChart"),
                      fillHandleDirection: "xy",
                      processCellForClipboard:
                        _vm.processCellForClipboard("orgChart"),
                      processCellFromClipboard:
                        _vm.processCellFromClipboard("orgChart"),
                      suppressClipboardApi: "",
                      navigateToNextCell: _vm.navigateToNextCell,
                      tabToNextCell: _vm.tabToNextCell,
                      getDataPath: function (data) {
                        return data.path.split(", ")
                      },
                      rowData: _vm.orgChart.rowData,
                      treeData: "",
                      groupDefaultExpanded: "-1",
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.orgChart.noRowsOverlayComponentParams,
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      enableCellEditingOnBackspace: "",
                    },
                    on: {
                      "grid-ready": _vm.onOrgChartGridReady,
                      "cell-key-down": _vm.onCellKeyDown,
                      "paste-start": _vm.onPasteStart,
                      "paste-end": _vm.onPasteEnd,
                      "cell-focused": _vm.cellFocused,
                      "cell-editing-started": _vm.onCellEditingStarted,
                      "cell-editing-stopped": _vm.onCellEditingStopped,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Generic", "title-item-class": "mytab" } },
                [
                  _vm.allowManage
                    ? _c(
                        "PriorityNavigation",
                        {
                          staticClass: "generic-toolbar grid-toolbar border",
                          attrs: {
                            dropDownStayOpened:
                              _vm.generic.badgeFilterModalOpened != "close",
                            closeDropdown: _vm.generic.closePriorityNavDropdown,
                            forceRefresh: _vm.generic.forcePriorityNavRefresh,
                          },
                          on: {
                            "update:closeDropdown": function ($event) {
                              return _vm.$set(
                                _vm.generic,
                                "closePriorityNavDropdown",
                                $event
                              )
                            },
                            "update:close-dropdown": function ($event) {
                              return _vm.$set(
                                _vm.generic,
                                "closePriorityNavDropdown",
                                $event
                              )
                            },
                            "update:forceRefresh": function ($event) {
                              return _vm.$set(
                                _vm.generic,
                                "forcePriorityNavRefresh",
                                $event
                              )
                            },
                            "update:force-refresh": function ($event) {
                              return _vm.$set(
                                _vm.generic,
                                "forcePriorityNavRefresh",
                                $event
                              )
                            },
                          },
                          nativeOn: _vm._d({}, [
                            _vm.priorityNavMouseOverEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "generic"
                              )
                            },
                            _vm.priorityNavTouchEndEvent,
                            function ($event) {
                              return _vm.onPriorityNavMouseOverOrTouchEnd(
                                $event,
                                "generic"
                              )
                            },
                          ]),
                        },
                        [
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "GENERIC_BTN_ADD_" + _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(true, true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: {
                                          color: "var(--grid-toolbar-button)",
                                        },
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "GENERIC_BTN_ADD_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("button.add")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "GENERIC_BTN_EDIT_" + _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableEdit },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditor(false, true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "GENERIC_BTN_EDIT_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.edit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "GENERIC_BTN_DUPLICATE_" + _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDuplicate },
                                      on: {
                                        click: _vm.showDuplicateDialog_generic,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "clone"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "GENERIC_BTN_DUPLICATE_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.duplicate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDelete(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "GENERIC_BTN_DELETE_" + _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDelete },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDelete("generic")
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-can"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "GENERIC_BTN_DELETE_" + _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id:
                                      "GENERIC_BTN_IMPORT_DOCUMENT_" +
                                      _vm.elemId,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    { on: { click: _vm.fileImportGeneric } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "inbox-in"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "GENERIC_BTN_IMPORT_DOCUMENT_" +
                                          _vm.elemId,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "staff.button.import_document"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              attrs: {
                                id: "GENERIC_BTN_EXPORT_DOCUMENT_" + _vm.elemId,
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExportGeneric } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target:
                                      "GENERIC_BTN_EXPORT_DOCUMENT_" +
                                      _vm.elemId,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("staff.button.export_document")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d(
                                    {
                                      mouseleave: function ($event) {
                                        return _vm.onFilterLeave(
                                          $event,
                                          "generic"
                                        )
                                      },
                                    },
                                    [
                                      _vm.filterMouseEnterEvent,
                                      function ($event) {
                                        return _vm.onFilterOver(
                                          $event,
                                          "generic"
                                        )
                                      },
                                    ]
                                  ),
                                },
                                [
                                  !_vm.generic.searchPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "filter_generic",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id:
                                              "BTN_FILTER_generic_" +
                                              _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onFilterTextDropdownHide(
                                                $event,
                                                "generic"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.generic
                                                          .filterText !== ""
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "file-magnifying-glass",
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3139671312
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                { staticClass: "search-input" },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t("task.filter"),
                                                    },
                                                    on: {
                                                      focus: function ($event) {
                                                        _vm.generic.filterTextFocus = true
                                                      },
                                                      blur: function ($event) {
                                                        _vm.generic.filterTextFocus = false
                                                      },
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.onFilterSubmit(
                                                          $event,
                                                          "generic"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.generic.filterText,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.generic,
                                                          "filterText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "generic.filterText",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_CLEAR_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterClear(
                                                                $event,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "xmark",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_CLEAR_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.clear"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_SEARCH_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterSubmit(
                                                                $event,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "magnifying-glass",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_SEARCH_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.search"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          staticClass:
                                                            "search-append search-append-bg",
                                                          attrs: {
                                                            id:
                                                              "BTN_PIN_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onPinFilter(
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "thumbtack",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "BTN_PIN_FILTER_GENERIC_" +
                                                              _vm.elemId,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.pinOnToolbar"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.generic.searchPinned
                                ? _c(
                                    "b-input-group",
                                    { staticClass: "search-input" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.$t("task.filter"),
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.generic.filterTextFocus = true
                                          },
                                          blur: function ($event) {
                                            _vm.generic.filterTextFocus = false
                                          },
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onFilterSubmit(
                                              $event,
                                              "generic"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.generic.filterText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.generic,
                                              "filterText",
                                              $$v
                                            )
                                          },
                                          expression: "generic.filterText",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_CLEAR_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterClear(
                                                    $event,
                                                    "generic"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "xmark"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_CLEAR_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.clear")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_SEARCH_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFilterSubmit(
                                                    $event,
                                                    "generic"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "magnifying-glass",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_SEARCH_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.search")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id:
                                                  "BTN_UNPIN_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onUnPinFilter(
                                                    "generic"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "thumbtack"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "BTN_UNPIN_FILTER_GENERIC_" +
                                                  _vm.elemId,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "button.unpinFromToolbar"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.canView(_vm.entity)
                            ? _c(
                                "li",
                                {
                                  on: _vm._d({}, [
                                    _vm.badgeFilterMouseEnterEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterEnter(
                                        $event,
                                        "generic"
                                      )
                                    },
                                    _vm.badgeFilterMouseOverEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "generic"
                                      )
                                    },
                                    _vm.badgeFilterMouseLeaveEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterLeave(
                                        $event,
                                        "generic"
                                      )
                                    },
                                    _vm.badgeFilterTouchEndEvent,
                                    function ($event) {
                                      return _vm.onBadgeFilterOver(
                                        $event,
                                        "generic"
                                      )
                                    },
                                  ]),
                                },
                                [
                                  !_vm.generic.badgeFilterPinned
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          ref: "badgeFilter_generic",
                                          staticClass:
                                            "action-bar-dropdown text-filter",
                                          attrs: {
                                            id:
                                              "BTN_BADGE_FILTER_" + _vm.elemId,
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onBadgeFilterDropdownHide(
                                                $event,
                                                "generic"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      class:
                                                        _vm.generic != null &&
                                                        _vm.generic
                                                          .badgeFilters !=
                                                          null &&
                                                        _vm.generic.badgeFilters
                                                          .length > 0
                                                          ? "active"
                                                          : "",
                                                      attrs: {
                                                        icon: ["far", "filter"],
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2791453377
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-form",
                                            {
                                              staticClass: "filter-padding",
                                              on: {
                                                submit: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c("TaskViewBadgeFilter", {
                                                attrs: {
                                                  filters:
                                                    _vm.generic.badgeFilters,
                                                  fields:
                                                    _vm.generic
                                                      .badgeFilterFields,
                                                  fieldValues:
                                                    _vm.generic
                                                      .badgeFilterFieldValues,
                                                  canPin: "",
                                                },
                                                on: {
                                                  modified: function ($event) {
                                                    return _vm.onBadgeFilterModified(
                                                      $event,
                                                      "generic"
                                                    )
                                                  },
                                                  filterModalOpened: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalOpened(
                                                      $event,
                                                      "generic"
                                                    )
                                                  },
                                                  filterModalClosed: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterModalClosed(
                                                      $event,
                                                      "generic"
                                                    )
                                                  },
                                                  fetchFieldOptions: function (
                                                    $event
                                                  ) {
                                                    return _vm.onBadgeFilterFetchOptions(
                                                      $event,
                                                      "generic"
                                                    )
                                                  },
                                                  pin: function ($event) {
                                                    return _vm.onPinBadgeFilter(
                                                      "generic"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            [
                              _vm.generic.badgeFilterPinned
                                ? _c("TaskViewBadgeFilter", {
                                    attrs: {
                                      filters: _vm.generic.badgeFilters,
                                      fields: _vm.generic.badgeFilterFields,
                                      fieldValues:
                                        _vm.generic.badgeFilterFieldValues,
                                      canPin: "",
                                    },
                                    on: {
                                      modified: function ($event) {
                                        return _vm.onBadgeFilterModified(
                                          $event,
                                          "generic"
                                        )
                                      },
                                      filterModalOpened: function ($event) {
                                        return _vm.onBadgeFilterModalOpened(
                                          $event,
                                          "generic"
                                        )
                                      },
                                      filterModalClosed: function ($event) {
                                        return _vm.onBadgeFilterModalClosed(
                                          $event,
                                          "generic"
                                        )
                                      },
                                      fetchFieldOptions: function ($event) {
                                        return _vm.onBadgeFilterFetchOptions(
                                          $event,
                                          "generic"
                                        )
                                      },
                                      pin: function ($event) {
                                        return _vm.onUnPinBadgeFilter("generic")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "view",
                              on: _vm._d(
                                {
                                  mouseleave: function ($event) {
                                    return _vm.onViewLeave($event, "generic")
                                  },
                                },
                                [
                                  _vm.viewMouseEnterEvent,
                                  function ($event) {
                                    return _vm.onViewOver($event, "generic")
                                  },
                                ]
                              ),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "view_generic",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_VIEW_generic_" + _vm.elemId,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "desktop"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4186387599
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAllColumns("generic")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.all_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showNoColumns("generic")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("task.button.no_columns")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.savePreset("generic")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                (_vm.entity
                                                  ? _vm.formattedEntity + "."
                                                  : "") + "button.save_view"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("b-dropdown-divider"),
                                  _vm._l(
                                    _vm.filteredGenericViews,
                                    function (item, index) {
                                      return [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            key: index,
                                            staticClass: "action-item",
                                            attrs: {
                                              disabled: _vm.inProgressShow,
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadViewSettings(
                                                  item,
                                                  "generic"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-label-with-icon",
                                                attrs: {
                                                  id: "viewname_gen" + index,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm.isEllipsisActive(item.name)
                                              ? _c("b-popover", {
                                                  attrs: {
                                                    target:
                                                      "viewname_gen" + index,
                                                    placement: "top",
                                                    boundary: "viewport",
                                                    "custom-class":
                                                      "popover-margin",
                                                    triggers: "hover",
                                                    offset: "-100",
                                                    content: item.name,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              [
                                                !_vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "COPY_GENERIC_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.copyColumnSettings(
                                                                item.name,
                                                                item,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "copy",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "COPY_GENERIC_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.copy_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "BTN_GENERIC_INFO_" +
                                                              index,
                                                          },
                                                          on: _vm._d(
                                                            {
                                                              mouseleave:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onInfoLeave(
                                                                    index,
                                                                    _vm.filteredGenericViews,
                                                                    _vm.generic
                                                                      .showInfo
                                                                  )
                                                                },
                                                            },
                                                            [
                                                              _vm.infoMouseEnterEvent,
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onInfoOver(
                                                                  index,
                                                                  _vm.filteredGenericViews,
                                                                  _vm.generic
                                                                    .showInfo
                                                                )
                                                              },
                                                            ]
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "circle-info",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-popover", {
                                                        ref:
                                                          "BTN_GENERIC_INFO_" +
                                                          index,
                                                        refInFor: true,
                                                        attrs: {
                                                          target:
                                                            "BTN_GENERIC_INFO_" +
                                                            index,
                                                          show: _vm.generic
                                                            .showInfo[index],
                                                          placement: "top",
                                                          boundary: "viewport",
                                                          triggers: "manual",
                                                          content: _vm.$t(
                                                            "owner",
                                                            [
                                                              _vm
                                                                .filteredGenericViews[
                                                                index
                                                              ].owner,
                                                            ]
                                                          ),
                                                        },
                                                        on: {
                                                          "update:show":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.generic
                                                                  .showInfo,
                                                                index,
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-third",
                                                          attrs: {
                                                            id:
                                                              "SHARE_GENERIC_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.shareColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  item.defaultView
                                                                    ? "fas"
                                                                    : "far",
                                                                  "share-nodes",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "SHARE_GENERIC_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.share_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon position-second",
                                                          attrs: {
                                                            id:
                                                              "UPDATE_GENERIC_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.updateColumnSettings(
                                                                index,
                                                                item.name,
                                                                item,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "floppy-disk",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "UPDATE_GENERIC_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.update_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.editPermission(item)
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "action-item-icon",
                                                          attrs: {
                                                            id:
                                                              "REMOVE_GENERIC_COLUMN_" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.removeColumnSettings(
                                                                index,
                                                                "generic"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "trash-can",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "REMOVE_GENERIC_COLUMN_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                            boundary:
                                                              "viewport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  (_vm.entity
                                                                    ? _vm.formattedEntity +
                                                                      "."
                                                                    : "") +
                                                                    "button.delete_view"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              on: _vm._d(
                                { mouseleave: _vm.onColoringLeaveGeneric },
                                [
                                  _vm.colorMouseEnterEvent,
                                  _vm.onColoringOverGeneric,
                                ]
                              ),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "coloring_generic",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_COLORING_" + _vm.elemId,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "palette"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1244906976
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-group",
                                    { attrs: { header: _vm.$t("colorby") } },
                                    [
                                      _vm._l(
                                        _vm.genericColoring,
                                        function (value, name, index) {
                                          return [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                key: index,
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onGenericColorChange(
                                                      name
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "entity_selector.coloring_" +
                                                            name
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm.genericColoring[name]
                                                  ? _c("font-awesome-icon", {
                                                      staticClass:
                                                        "active-check",
                                                      attrs: {
                                                        icon: ["far", "check"],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "generic-staff-grid",
                      gridOptions: _vm.generic.gridOptions,
                      columnDefs: _vm.generic.columnDefs,
                      context: _vm.generic.context,
                      defaultColDef: _vm.generic.defaultColDef,
                      getRowId: function (params) {
                        return params.data.uuId
                      },
                      pagination: "",
                      paginationPageSize: 1000,
                      cacheBlockSize: 10000,
                      rowModelType: "serverSide",
                      rowMultiSelectWithClick: _vm.nonAdmin,
                      rowSelection: _vm.singleSelection ? "single" : "multiple",
                      serverSideInfiniteScroll: true,
                      sideBar: false,
                      suppressContextMenu: "",
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: _vm.nonAdmin,
                      singleClickEdit: false,
                      suppressMultiSort: false,
                      enableRangeSelection: !_vm.nonAdmin,
                      suppressRowClickSelection: !_vm.nonAdmin,
                      enableFillHandle: !_vm.nonAdmin,
                      fillOperation: _vm.fillOperation("generic"),
                      fillHandleDirection: "xy",
                      processCellForClipboard:
                        _vm.processCellForClipboard("generic"),
                      processCellFromClipboard:
                        _vm.processCellFromClipboard("generic"),
                      suppressClipboardApi: "",
                      navigateToNextCell: _vm.navigateToNextCell,
                      tabToNextCell: _vm.tabToNextCell,
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.generic.noRowsOverlayComponentParams,
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      enableCellEditingOnBackspace: "",
                    },
                    on: {
                      "grid-ready": _vm.onGenericGridReady,
                      "cell-key-down": _vm.onCellKeyDown,
                      "paste-start": _vm.onPasteStart,
                      "paste-end": _vm.onPasteEnd,
                      "cell-focused": _vm.cellFocused,
                      "cell-editing-started": _vm.onCellEditingStarted,
                      "cell-editing-stopped": _vm.onCellEditingStopped,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.allowManage && _vm.editorShow
        ? _c("StaffModal", {
            attrs: {
              id: _vm.entityId,
              show: _vm.editorShow,
              title: _vm.editorTitleLabel,
              isGeneric: _vm.isGeneric,
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              success: _vm.editorSuccess,
              "refresh-list": _vm.reloadData,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitleLabel,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _vm.isGeneric
            ? [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.duplicateFieldNameLabel,
                      "label-for": "name",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateName",
                            type: "text",
                            "data-vv-as": _vm.duplicateFieldNameLabel,
                            "data-vv-name": "duplicate.name",
                            "data-vv-delay": "500",
                            trim: "",
                          },
                          model: {
                            value: _vm.duplicateName,
                            callback: function ($$v) {
                              _vm.duplicateName = $$v
                            },
                            expression: "duplicateName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateNameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicate.name")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.email"),
                      "label-for": "duplicateEmail",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateEmail",
                            type: "text",
                            "data-vv-as": _vm.$t("staff.field.email"),
                            "data-vv-name": "duplicateEmail",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateEmail,
                            callback: function ($$v) {
                              _vm.duplicateEmail = $$v
                            },
                            expression: "duplicateEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateEmailError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateEmail")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.firstName"),
                      "label-for": "duplicateFirstname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: { id: "duplicateFirstname", type: "text" },
                          model: {
                            value: _vm.duplicateFirstname,
                            callback: function ($$v) {
                              _vm.duplicateFirstname = $$v
                            },
                            expression: "duplicateFirstname",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.lastName"),
                      "label-for": "duplicatLastname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicatLastname",
                            type: "text",
                            "data-vv-as": _vm.$t("staff.field.lastName"),
                            "data-vv-name": "duplicateLastname",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateLastname,
                            callback: function ($$v) {
                              _vm.duplicateLastname = $$v
                            },
                            expression: "duplicateLastname",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateLastnameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateLastname")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.entity != null
        ? _c("GanttImportDialog", {
            attrs: {
              properties: _vm.docImportProperties,
              mode: "STAFF",
              show: _vm.docImportShow,
              existingData: _vm.existingStaff,
              title: _vm.$t(
                "entity_selector." + _vm.formattedEntity + "_import_document"
              ),
            },
            on: {
              "modal-ok": _vm.docImportOk,
              "modal-cancel": _vm.docImportCancel,
            },
          })
        : _vm._e(),
      _c("GanttImportDialog", {
        attrs: {
          properties: _vm.docImportGenericProperties,
          mode: "STAFF_GENERIC",
          show: _vm.docImportGenericShow,
          title: _vm.$t("staff.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportGenericOk,
          "modal-cancel": _vm.docImportGenericCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("entity_selector.confirm_deletion_title"),
            "content-class": "entity-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.ecConfirmDeleteShow = false
            },
            ok: _vm.ecConfirmDeleteOk,
            cancel: _vm.ecConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.ecShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.entityCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.entityCol, "applyAll", $$v)
                            },
                            expression: "entityCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.ecConfirmDeleteShow,
            callback: function ($$v) {
              _vm.ecConfirmDeleteShow = $$v
            },
            expression: "ecConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.ecConfirmDeleteStatement))])]
      ),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c("RecommendationSettingsModal", {
        attrs: { userId: _vm.userId, show: _vm.recommendationSettingsShow },
        on: {
          "update:show": function ($event) {
            _vm.recommendationSettingsShow = $event
          },
          success: _vm.recommendationSettingsSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }