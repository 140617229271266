<template>
  <div id="SKILL_LEVEL_EDITOR" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @cancel="modalCancel"
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <p>{{ $t('skill.auto_scheduling_detail.line1') }}</p>
      <p>{{ $t('skill.auto_scheduling_detail.line2') }}</p>

      <template v-for="(item, index) in levels">
        <b-form-group :label="$t('skill.field.skillTypeLevel', [item.kind])" :label-for="`sLevel_${index}`" :key="index">
          <b-input-group>
            <b-form-input :id="`sLevel_${index}`" type="number" :max="365" :min="1"
              v-model="item.data"
              @blur="onLevelBlur(index)"
              :readonly="isReadOnly">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if='!isReadOnly' size="sm" variant="success" @click="modalOk()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate'
import { objectClone } from '@/helpers'
export default {
  name: 'SkillLevelEditorModal'
  , components: {}
  , props: {
    title:       { type: String,   default: null }
    , readOnly:  { type: Boolean,  default: false }
    , show:      { type: Boolean, required: true }
    , skillLevels: { type: Array, required: true }
  }
  , data() {
    return {
      typeOptions: []
      , alertMsg: null
      , modalShow: false
      , levels: []
      , isDataInitiated: false
    }
  }
  , created() {
    this.getModelInfo()
    this.fieldValidateUtil = fieldValidateUtil
    this.originSkill = null
    this.processWhenShowStateChanged(this.show)
  }
  , mounted() {
  }
  , beforeDestroy() {
    this.fieldValidateUtil = null
    this.originSkill = null
  }
  , computed: {
    isReadOnly() {
      return this.readOnly === true || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit)
    }
    , errorShow() {
      return this.alertMsg != null
    }
    , labelTitle() {
      return this.title? this.title : this.readOnly? this.$t('skill.title_level_view') : this.$t('skill.title_level_edit')
    }
  }
  , watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.processWhenShowStateChanged(newValue)
      }
    }, 
    typeOptions() {
      if (this.show && !this.isDataInitiated) {
        this.prepareLevels()
      }
    }
  }
  , methods: {
    processWhenShowStateChanged(newValue) {
      this.errors.clear()
      this.$validator.reset()
      this.$validator.resume()
      this.modalShow = newValue
      this.alertMsg = null
      this.isDataInitiated = false
      if (newValue) {
        this.prepareLevels()
      } else {
        this.levels.splice(0, this.levels.length)
      }
      
    }
    , prepareLevels() {
      if (this.typeOptions.length > 0) {
        const newLevels = []
        for (const type of this.typeOptions) {
          let item = this.skillLevels.find(i => i.kind == type.value)
          if (item) {
            item = objectClone(item)
          } else {
            item = { kind: type.value, data: 5 }//, hasError: false } //5 is the agreed default value
          }
          newLevels.push(item)
        }
        this.levels.splice(0, this.levels.length, ...newLevels)
        this.isDataInitiated = true
      }
    }
    , getModelInfo() {
      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.SkillLevelEnum != null) {
              const obj = propertyList.SkillLevelEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.typeOptions.splice(0, this.typeOptions.length, ...list)
            }
          }
        }
      })
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , modalOk() {
      this.errors.clear()
      //Convert the data value to number if it is not
      for (const level of this.levels) {
        if (typeof level.data != 'number') {
          level.data = parseInt(level.data)
        }
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.count() < 1) {
          this.alertMsg = null
          this.$emit('ok', objectClone(this.levels))
          this.modalHidden()
        } else {
          this.alertMsg = this.$t('error.attention_required')
          this.scrollToTop()
        }
      })
      
    }
    , scrollToTop() {
      document.querySelector('#SKILL_LEVEL_EDITOR').scrollIntoView();
    }
    , modalHidden() {
      this.$validator.pause()
      this.$emit('update:show', false)
    }
    , modalCancel() {
      this.$emit('cancel')
      this.modalHidden()
    }
    , dismissAlert() {
      this.alertMsg = null
    }
    , onLevelBlur(index) {
      if (this.levels[index].data == null || isNaN(this.levels[index].data)) {
        this.levels[index].data = 5
      } else if (this.levels[index].data < 1) {
        this.levels[index].data = 1
      } else if (this.levels[index].data > 365) {
        this.levels[index].data = 365
      }
    }    
  }
}
</script>
