<template>
  <div>
  <a v-if="label && (typeof label === 'string' && (label.startsWith('https://') || label.startsWith('http://')))" :href="label" target="_blank">{{ label }}</a>
  <span v-else>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'GenericCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    this.prepareValue(this.params);
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    label() {
      return this.value != null? this.value: '';
    }
  },
  methods: {
    prepareValue(params) {
      if (params == null || params.value == null) {
        return;
      }
      if (Array.isArray(params.value)) {
        this.value = params.value.join(', ');
        return;
      }
      this.value = params.value;
    }
  }
})
</script>
