var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "history-item" },
    [
      _c("div", [
        _c("span", [_vm._v(_vm._s(_vm.$t("entity")) + ": ")]),
        _c("span", [_vm._v(_vm._s(_vm.entity))]),
      ]),
      _c("div", [
        _c("span", [_vm._v(_vm._s(_vm.$t("name")) + ": ")]),
        _c("span", [_vm._v(_vm._s(_vm.name))]),
      ]),
      _c("div", [
        _c("span", [_vm._v(_vm._s(_vm.$t("status")) + ": ")]),
        _c("span", [_vm._v(_vm._s(_vm.status))]),
      ]),
      _vm._l(_vm.properties, function (prop, index) {
        return [
          _c("div", { key: index }, [
            _c("span", [_vm._v(_vm._s(prop.name) + ": ")]),
            _c("span", [_vm._v(_vm._s(_vm.formatValue(prop)))]),
          ]),
        ]
      }),
      _vm.dependencies
        ? _c("div", [_vm._v(_vm._s(_vm.$t("links")))])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "audit-detail" },
        [
          _vm._l(_vm.dependencies, function (dependency, index) {
            return [
              _c("li", { key: index }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("entity")) + ": ")]),
                  _c("span", [_vm._v(_vm._s(dependency.entity))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("name")) + ": ")]),
                  _c("span", [_vm._v(_vm._s(dependency.name))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("status")) + ": ")]),
                  _c("span", [_vm._v(_vm._s(dependency.status))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("modified")) + ": ")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatDate(dependency.properties.at))),
                  ]),
                ]),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }