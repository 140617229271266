<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>
      
      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('fields.title').toLowerCase()]) }}</span>
        </div>
      </template>
      <template v-else>
      
        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" class="pr-0">
              <b-form-group :label="$t('language.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('language.field.name')"
                    data-vv-name="language.field.name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="language.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :autofocus="true"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'language.field.name')"
                    trim >
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('language.field.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            
            <b-col cols="12" class="pr-0">
              <b-form-group :label="$t('language.field.description')" label-for="language-description">
                <b-form-textarea id="language-description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('placeholder.description')"
                  :data-vv-as="$t('language.field.description')"
                  data-vv-name="language.description"
                  data-vv-delay="500"
                  v-model="language.description"
                  :max-rows="6"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
              </b-form-group>
            </b-col>
            
            <b-col v-if="isLocaleVisible" cols="12" class="pr-0">
              <b-form-group :label="$t('language.field.locale')" label-for="display_name">
                <b-input-group>
                  <b-form-select id="locale" :options="localeOptions"
                    :data-vv-as="$t('language.field.locale')"
                    data-vv-name="language.locale"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="language.locale" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'language.locale')"
                    trim 
                    @keydown.native="keydownHandler">
                  </b-form-select>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLocaleError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('language.field.locale') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="pr-0">
              <b-form-group>
                <label class="mr-1">{{ $t(`language.field.path`) }}</label>
                <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" :disabled="isStorageFileReadOnly" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="FILE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('task.button.file_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="language.path" :readOnly="isStorageFileReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                      :text="item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isStorageFileReadOnly"
                      enableClickWhenReadOnly
                    />
                  </template>
                </BadgeGroup>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPathError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('language.field.path') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col>
              <div>     
                <b-form-checkbox class="d-inline-block" v-model="language.default">{{ $t('button.default_lang') }}</b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!isAccessDenied" :disabled="language.path.length === 0 || language.name === null || language.locale === null" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <FileSelectorModal v-if="state.fileSelectorShow" :multiple="false" :show.sync="state.fileSelectorShow" @ok="fileSelectorOk"/>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import fromExponential from 'from-exponential';
import * as locale from 'locale-codes';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import { managementService, profileService } from '@/services';

export default {
  name: 'LanguageModal',
  components: {
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge')
  },
  props: {
    data:        { type: Object,   default: () => {} },
    languageList: { type: Array, default: () => [] },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'LANGUAGE',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      modalShowSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        historyShow:         false,
        showIndexWarning:    false,
        fileSelectorShow:    false
      },
      language: {
        id:                 null,
        name:               null,
        locale:             null,
        description:        null,
        path:               [],
        default:            false
      },
      isAccessDenied: false
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.processWhenShowModal(true);
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  computed: {
    localeOptions() {
      return locale.all.map(l => { return { value: l.tag, text: l.location !== null ? `${l.name} (${l.location}) [${l.tag}]` : `${l.name} [${l.tag}]` }}).sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
    },
    componentId() {
      return `LANGUAGE_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    isDescriptionReadOnly() {
      return this.isReadOnly;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'language.field.name');
    },
    showLocaleError() {
      return fieldValidateUtil.hasError(this.errors, 'language.field.locale');
    },
    showPathError() {
      return fieldValidateUtil.hasError(this.errors, 'language.field.path');
    },
    isStorageFileReadOnly() {
      return this.isReadOnly
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return true;//this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly
    },
    isLocaleVisible() {
      return true;
    },
    isLocaleReadOnly() {
      return this.isReadOnly
    },
    isPathVisible() {
      //Type is mandatory field so checking against canAdd() can be skipped
      return true;//this.canView(this.permissionName, ['type'])
    },
    isPathReadOnly() {
      return this.isReadOnly;
    },
    labelTitle() {
      return this.title? this.title: this.$t('fields.title_new');
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue);
      }
    }
  },
  methods: {
    fileSelectorOk(details) {
      this.errors.clear();
      if (details.length > 0 && 
      (details[0].type !== 'application/json' || details[0].size === 0)) {
        this.errors.add({
          field: `language.field.path`,
          msg: this.$i18n.t('language.error.invalid_type', [this.$i18n.t('language.field.path')])
        });
        return;
      }
      
      this.language.path = [ {uuId: details[0].uuId, name: details[0].name, type: details[0].type} ];
    },
    processWhenShowModal(newValue) {
      this.$validator.resume();
      this.state.modalShow = newValue;
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable =  true;
      if (this.data.id) {
        this.language.id = this.data.id;
      }
      else {
        this.language.id = null;
      }
      this.language.name = cloneDeep(this.data.name);
      this.language.locale = cloneDeep(this.data.locale);
      this.language.path = this.data.path !== null ? [cloneDeep(this.data.path)] : [];
      this.language.description = cloneDeep(this.data.description);
      this.language.default = this.data.default ? cloneDeep(this.data.default) : false;
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    nameKeydownHandler(event) {
      var key = event.keyCode;
      if (key === 32) {
        event.preventDefault();
      }
      this.keydownHandler(event);
    },
    modalSelect() {
      this.modalShowSelector = true;
    },
    ok() {
      this.errors.clear();
      
      if (this.language.name.length > 32) {
        this.errors.add({
          field: `language.field.name`,
          msg: this.$i18n.t('language.error.invalid_name_length', [this.$i18n.t('language.field.name')])
        });
        return;
      }
      
      if (this.language.path.length === 0) {
        this.errors.add({
          field: `language.field.path`,
          msg: this.$i18n.t('language.error.invalid_path_length', [this.$i18n.t('language.field.path')])
        });
        return;
      }
      
      if (this.languageList.find(l => l.locale === this.language.locale && l.id !== this.language.id)) {
        this.errors.add({
          field: `language.field.locale`,
          msg: this.$i18n.t('language.error.locale_in_use', [this.$i18n.t('language.field.locale')])
        });
        return;
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
          this.languageSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async languageSubmit() {
      const data = cloneDeep(this.language);
      
      this.$emit('update:show', false);
      this.$emit('success', { id: this.language.id, name: this.language.name, locale: this.language.locale, description: this.language.description, path: this.language.path[0], default: this.language.default });
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetLanguageProperties() {
      const keys = Object.keys(this.fields);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        this.fields[keys[i]] = null;
      }
    }, 
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    fileSelectorToggle() {
      this.state.fileSelectorShow = true;
    },
    fileBadgeRemove: function(index) {
      this.language.path.splice(index,1);
    },
    fileBadgeClick(item) {
      this.fileSelectorToggle();
    }
  }
}
</script>
