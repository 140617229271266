<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="title" footer-class="footerClass"
      @hide="hide"
      content-class="shadow" no-close-on-backdrop
    >
    
      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-row>
        <b-col cols="12" md="2">
          <b-form-radio-group class="preview-state-toggler"
              v-model="preview"
              :options="[{ text: $t('comment.button.write'), value: false }, { text: $t('comment.button.preview'), value: true }]"
              buttons
              button-variant="outline-secondary"
              size="sm"
            />
        </b-col>
      
        <b-col cols="12" offset-md="4" md="6" class="pl-md-0">
          <b-form-group class="mt-2 mt-md-0" :label="$t('field.identifier')" label-for="identifier" label-align-md="right" label-cols-md="3" content-cols-md="9">
            <b-input-group>
              <b-form-input id="identifier" type="text"
                :data-vv-as="$t('field.identifier')"
                data-vv-name="note.identifier"
                :maxlength="maxIdentifierLength"
                v-model="note.identifier" 
                :disabled="isReadOnly"
                size=""
                trim>
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <template v-if="customFieldMap['identifier'] != null">
          <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12">
            <b-form-group>
              <template v-if="field.type !== 'Boolean'" slot="label">
                <span class="mr-2">{{ field.displayName }}</span>
                <span v-if="field.description">
                  <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                  <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                    {{ field.description }}
                  </b-popover>  
                </span>
              </template>
              <CustomField v-model="note[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
            </b-form-group>
          </b-col>
        </template>
      </b-row>

      <b-textarea ref="textarea" v-model="note.text"  refs="commentInput" name="note" id="message" rows="9"
        :placeholder="$t('comment.placeholder.your_comment')"
        class="comment-textarea rounded-0"
        :class="{ 'd-none': previewState }"
        trim required autofocus/>

      <div class="preview markdown-body" :class="{ 'd-none': !previewState }" v-html="compiledMarkdown">
      </div>

      <div v-if="customFieldMap['note'] != null || customFieldMap['default'] != null" class="container pl-0 mt-3">
        <b-row>
          <template v-if="customFieldMap['note'] != null">
            <b-col v-for="(field, index) in customFieldMap['note']" :key="'note'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="note[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="note[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>

      <div class="markdown-hint">
        <font-awesome-layers class="fa-lg info-icon">
          <font-awesome-icon :icon="['far','circle']" transform="shrink-2" />
          <font-awesome-icon :icon="['far', 'info']" transform="shrink-8" />
        </font-awesome-layers>
        <span><a target="_blank" href="https://projectal.com/resources/markdown">{{ $t('comment.link.markdown') }}</a> {{ $t('comment.link.is_supported') }}</span>
      </div>

      <NoteList class="mt-2" v-if="canList('NOTE') && showList" :notes="displayNotes" @add="add" @edit="edit" @toRemove="toRemove"  />
      
      <template v-slot:modal-footer="{}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template v-if="allowSelect">
          <b-button v-if="canEdit() || !exists" size="sm" variant="success" @click="saveAllChanges(true)">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="hide">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.save_comment')"
        v-model="promptSaveNoteShow"
        @hidden="promptSaveNoteShow = false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <span>{{ $t('comment.save_prompt') }}</span>
      <template v-slot:modal-footer="{}">
        <b-button size="sm" variant="success" @click="confirmSaveOk(true)">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="confirmSaveOk(false)">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('comment.confirmation.title')"
        v-model="promptUnsavedChangesShow"
        @hidden="promptUnsavedChangesShow = false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <span>{{ $t('comment.confirmation.content_unsaved_change') }}</span>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmUnsavedChangeOK">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('title_save_with_error')"
        v-model="saveErrorShow"
        @hidden="saveErrorOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-row>
        <template v-for="(item, index) in saveErrors">
            <b-col cols="12" :key="index">{{ item }}</b-col>
        </template>
      </b-row>
      <span>{{ $t('comment.save_prompt') }}</span>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.ok') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { strRandom, objectClone } from '@/helpers';
import { persistNotes, hasNotesChanged } from '@/components/Note/script/crud-util';
import { cloneDeep } from 'lodash';
import * as DOMPurify from 'dompurify';
import * as Marked from 'marked';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

const renderer = new Marked.Renderer();
renderer.link = function(href, title, text) {
  const link = Marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a","<a target='_blank' ");
};
Marked.setOptions({
  renderer: renderer,
  gfm: true
});

export default {
  name: 'NoteWithListModal',
  components: {
    NoteList: () => import('@/components/Note/NoteList.vue'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:           { type: String,   default: 'NOTE_NEW' },
    holderid:     { type: String, default: null },
    title:        { type: String,   default: function() { return this.$t('comment.button.add_comment'); } },
    show:         { type: Boolean, required: true },
    mode:         { type: String, default: 'BOTH' }, //Possible value: ['SELECT', 'MANAGE', 'BOTH'],
    notes:        { type: Array, default: () => { return []; } },
    showList:     { type: Boolean, default: true },
    newText:      { type: String, default: null }, //Used (for new note) when this modal is shown.
    highlightAllOnFocus: { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'NOTE',
      modelInfo: null,
      modalShow: false,
      alertMsg: null,
      note: {
        text: null,
        identifier: null
      },
      preview: false,
      promptSaveNoteShow: false,
      promptUnsavedChangesShow: false,
      nextNote: null,
      displayNotes: [],
      saveErrors: [],
      saveErrorShow: false,
      customFields: [],
      customFieldMap: {}
    }
  },
  created() {
    this.getModelInfo();
    this.modalShow = this.show;
    if (this.show) {
      this.initiate(this.id);
    }
  },
  beforeDestroy() {
    this.originNote = null;
    this.originNotes = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        this.preview = false;
        this.alertMsg = null;
        this.promptUnsavedChangesShow = false;
        this.saveErrorShow = false;
        this.promptSaveNoteShow = false;
        this.initiate(this.id);
      }
    },
    notes(newValue) {
      //Defensive code: The notes may be modified in parent component. Refresh the current note's properties with the latest notes.
      const found = newValue.find(i => i.uuId == this.note.uuId);
      if(found != null) {
        this.$set(this.note, 'text', found.text);
        this.$set(this.note, 'identifier', (found.identifier != null? found.identifier : null));
        this.$set(this.note, 'authorRef', (found.authorRef != null? found.authorRef : null));
        for (const f of this.customFields) {
          if (Object.hasOwn(found, f.name)) {
            this.$set(this.note, f.name, found[f.name]);
          }
        }
        this.$nextTick(() => {
          this.originNotes = cloneDeep(this.notes);
        });
      }
    }
  },
  computed: {
    componentId() {
      return `NOTE_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    currentUser() {
      return this.$store.state.authentication.user;
    },
    exists() {
      return this.id && !this.id.startsWith('NOTE_NEW');
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    isReadOnly() {
      return this.mode && 'SELECT' === this.mode || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    errorShow() {
      return this.alertMsg != null;
    },
    compiledMarkdown: function () {
      if (this.note.text === null) {
        return '';
      }
      return DOMPurify.sanitize(Marked(this.note.text));
    },
    previewState() {
      return this.preview;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    }
  },
  methods: {
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "NOTE"}).then(value => {
        self.modelInfo = value.NOTE.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    async initiate(id) {
      await getCustomFieldInfo(this, 'NOTE');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }

      const found = this.notes.find(c => c.uuId === id);
      if (found != null) {
        this.note = cloneDeep(found);
       
        for (const field of this.customFields) {
          if (Object.hasOwn(found, field.name)) {
            this.note[field.name] = found[field.name];
          }
        }
      } else if (this.newText != null) {
        this.note.text = this.newText;
        for (const field of this.customFields) {
          delete this.note[field.name];
        }
      }

      this.originNotes = cloneDeep(this.notes);
      this.displayNotes.splice(0, this.displayNotes.length, ...this.notes);
      this.setHighlightAllOnFocus();
    },
    setHighlightAllOnFocus() {
      setTimeout(() => {
        // need to check if the input reference is still valid - if the edit was cancelled before it started there
        // wont be an editor component anymore
        if (this.$refs.textarea) {
          if (this.highlightAllOnFocus) {
            this.$refs.textarea.select();
          }
          this.$refs.textarea.focus();
        }
      }, 100);
    },
    add() {
      if (this.note.uuId == null) {
        if ((this.note.text != null && this.note.text.trim().length > 0) || 
            (this.note.identifier != null && this.note.identifier.trim().length > 0)) {
          this.nextNote = { text: null, identifier: null };
          this.promptSaveNoteShow = true;
        } else {
          this.note = { text: null, identifier: null };
          this.$refs.textarea.focus();
        }
      } else {
        const found = this.displayNotes.find(c => c.uuId === this.note.uuId);
        if (found != null && 
              (found.text !== this.note.text || found.identifier != this.note.identifier)) {
          this.nextNote = { text: null, identifier: null };
          this.promptSaveNoteShow = true;
        } else {
          this.note = { text: null, identifier: null };
          this.$refs.textarea.focus();
        }
      }
    },
    edit(id) {
      const found = this.displayNotes.find(i => i.uuId == id);
      let _note = null;
      //Defensive code: Provide an empty note as if toggle 'add new note' when provided id can't find a match
      if (found == null) {
        _note = { text: null, identifier: null }
      } else {
        _note = cloneDeep(found);
      }

      if (this.note.uuId == null) {
        if ((this.note.text != null && this.note.text.trim().length > 0) || 
            (this.note.identifier != null && this.note.identifier.trim().length > 0)) {
          this.nextNote = _note;
          this.promptSaveNoteShow = true;
        } else {
          this.note = _note;
          this.$refs.textarea.focus();

        }
      } else {
        const found = this.displayNotes.find(c => c.uuId === this.note.uuId);
        if (found != null && 
              (found.text !== this.note.text || found.identifier != this.note.identifier)) {
          this.nextNote = _note;
          this.promptSaveNoteShow = true;
        } else {
          this.note = _note;
          this.$refs.textarea.focus();
        }
      }
    },
    toRemove(id) {
      const index = this.displayNotes.findIndex(i => i.uuId == id);
      if (index != -1) {
        const removedList = this.displayNotes.splice(index, 1);
        if (removedList.length > 0 && removedList[0].uuId == this.note.uuId) {
          this.note = { text: null, identifier: null };
          this.$refs.textarea.focus();
        }
      }
    },
    confirmSaveOk(toSave=true) {
      if (toSave) {
        this.saveNote();
      } else if (this.nextNote !== null) {
        this.note = this.nextNote;
        this.nextNote = null;
        this.$refs.textarea.focus();
        this.promptSaveNoteShow = false;
      }
    },
    hide(bvModalEvent) {
      bvModalEvent.preventDefault();
      let hasChanged = hasNotesChanged(this.originNotes, this.displayNotes, this.customFields? this.customFields : []);
      
      const canEdit = this.canEdit();
      if (canEdit && hasChanged) {
        this.promptUnsavedChangesShow = true;
      } else {
        this.$emit('update:show', false);
        this.$emit('cancel');
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    async saveNote() {
      if (this.note.text === '' ||
          this.note.text === null) {
        this.alertMsg = 'Comment cannot be empty';
        this.promptSaveNoteShow = false;
        return;
      }

      const data = {
        text: this.note.text
        , identifier: this.note.identifier
      }

      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.note[field.name])) {
          field.showError = true;
          this.promptSaveNoteShow = false;
          return;  
        }
        if (typeof this.note[field.name] !== 'undefined') {
          data[field.name] = this.note[field.name];
        }
      }

      const notes = this.displayNotes;

      if(this.note.uuId) {
        const hasChanged = this.removeUnchangedCommentProperties(data);
        if (hasChanged) {
          const index = notes.findIndex(i => i.uuId == this.note.uuId);
          if (index != -1) {
            notes[index].text = this.note.text;
            if (this.note.identifier != null) {
              notes[index].identifier = this.note.identifier;
            }
            for (const field of this.customFields) {
              if (typeof this.note[field.name] !== 'undefined') {
                notes[index][field.name] = this.note[field.name];
              }
            }
          }
        } else {
          //Do nothing when uuId can't find the match.
        }
      } else {
        data.uuId = `NEW_NOTE_${strRandom(5)}`
        data.author = this.currentUser.name;
        data.authorRef = this.currentUser.uuId;
        notes.unshift(data); //append data to the beginning of the notes.
      }

      this.note = cloneDeep(this.nextNote);
      this.promptSaveNoteShow = false;
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 300);
    },
    async saveAllChanges() {
      const isTextEmpty = this.note.text == null || this.note.text.trim().length == 0;
      const isIdentifierEmpty = this.note.identifier == null || this.note.identifier.trim().length == 0;

      if (isTextEmpty && 
          (this.note.uuId != null || !isIdentifierEmpty)) {
        this.alertMsg = 'Comment cannot be empty';
        return;
      }

      const data = {
        text: this.note.text
        , identifier: this.note.identifier
      }
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.note[field.name])) {
          field.showError = true;
          return;  
        }
        if (typeof this.note[field.name] !== 'undefined') {
          data[field.name] = this.note[field.name];
        }
      }

      const notes = cloneDeep(this.displayNotes);
      if(this.note.uuId) {
        const hasChanged = this.removeUnchangedCommentProperties(data);
        if (hasChanged) {
          const index = notes.findIndex(i => i.uuId == this.note.uuId);
          if (index != -1) {
            notes[index].text = this.note.text;
            if (this.note.identifier != null) {
              notes[index].identifier = this.note.identifier;
            }
            for (const field of this.customFields) {
              if (typeof this.note[field.name] !== 'undefined') {
                notes[index][field.name] = this.note[field.name];
              }
            }
          }
        } else {
          //Do nothing when uuId can't find the match.
        }
      } else if (data.text != null && data.text.trim().length > 0) {
        notes.unshift(data); //append data to the beginning of the notes.
      } else {
        //Ignore data
      }

      //Remove uuId of new notes before saving
      for (let i = 0, len = notes.length; i < len; i++) {
        if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
          delete notes[i].uuId;
        }
      }

      if (!hasNotesChanged(this.originNotes, notes, this.customFields? this.customFields : [])) {
        this.promptUnsavedChangesShow = false;
        this.$emit('update:show', false);
        this.$emit('cancel');
        return;
      }

      //When holderId is null, return the changed notes to parent component. Delegate the update job to parent component.
      if (this.holderid == null) {
        this.$emit('changed', objectClone(notes));
        this.$emit('update:show', false);
        return;
      } 
      
      const result = await persistNotes(this.holderid, this.originNotes, notes);
            
      this.promptUnsavedChangesShow = false;
      if (result.errors != null && result.errors.length > 0) {
        this.saveErrors.splice(0, this.saveErrors.length, ...result.errors.map(i => {
          if (i.args != null) {
            return this.$t(i.key, i.args);
          }
          return this.$t(i.key);
        }));
        this.saveErrorShow = true;
      } else {
        this.$emit('changed');
        this.$emit('update:show', false);
      }
    },
    saveErrorOk() {
      this.saveErrorShow = false;
      this.$emit('update:show', false);
      this.$emit('changed', { hasErrors: true });
    },
    confirmUnsavedChangeOK() {
      this.promptUnsavedChangesShow = false;
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    removeUnchangedCommentProperties(data) {
      //Remove those properties whose value is not changed in provided data against original comment.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const oNote = this.originNotes.find(i => i.uuId == data.uuId);
      //Defensive code: return true when uuId can't find a match
      if (oNote == null) {
        return true;
      }
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (oNote[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
                          && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists))
    }
  }
}
</script>

<style lang="scss">
  .preview {
    border: 1px solid var(--form-control-border);
    padding: 6px 12px;
    margin-top: 8px;
    min-height: 203px;
    height: 203px;
    overflow-y: auto;
    resize: vertical;
    background-color: var(--comment-bg);
  }

  .note-content {
    font-family: Arial;
    font-size: 14px;
  }

  .comment-textarea {
    margin-top: 8px;
  }

  .markdown-hint {
    position: relative;
    color: var(--bs-text-muted);
    margin-top: 5px;
    font-size: 0.75rem;

    .info-icon {
      position: absolute;
      left: 0;
      top: 1px;
    }
    span {
      padding-left: 20px;
    }
  }

  .preview-state-toggler {
    .btn.btn-outline-secondary.focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled):active:focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled).active:focus {
      box-shadow: none;
    }
  }
</style>