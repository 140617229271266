<template>
  <div :id="elemId" style="height: 100%, width: 100%">
    <b-modal v-model="selectorShow" size="xl" :title="selectorTitleLabel" footer-class="footerClass"
      no-close-on-backdrop
      :no-close-on-esc="isCellEditing || editorShow" :modal-class="[elemId]"
      content-class="shadow" @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertObj.msg != null" 
        :msg="alertObj.msg" 
        :details="alertObj.msgDetails.list" 
        :detailTitle="alertObj.msgDetails.title" 
        :alertState="alertObj.state" 
        @resetAlert="resetAlert"
      />
      <b-form-checkbox v-if="this.nonAdmin && !this.hideBookings && projectIds.length !== 0" 
        class="staff-bookings-switch" 
        v-model="bookings" 
        id="bookings-switch" 
        name="bookings-switch " 
        switch @change="onBookings">{{ $t('staff.project_bookings') }}</b-form-checkbox>

      <div class="selector-navbar">
        <PriorityNavigation class="selector-nav" ref="selector-nav" ulClassName="nav nav-pills">
          <li name="list" class="nav-pills nav-link" :class="{ active: listTabActive }" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ listTabActive && entitySelection > 0? $t('staff.selector.list_w_count', { count: entitySelection }) : $t('staff.selector.list') }}</a>
          </li>
          <li v-if="nonAdmin && taskUuId !== null" name="recommended" class="nav-pills nav-link" :class="{ active: recommendedTabActive }" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ recommendedTabActive && entitySelection > 0? $t('staff.selector.recommended_w_count', { count: entitySelection }) : $t('staff.selector.recommended') }}</a>
          </li>
          <li name="orgChart" class="nav-pills nav-link" :class="{ active: orgChartTabActive }" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ orgChartTabActive && entitySelection > 0? $t('staff.selector.orgChart_w_count', { count: entitySelection }) : $t('staff.selector.orgChart') }}</a>
          </li>
          <li name="generic" class="nav-pills nav-link" :class="{ active: genericTabActive }" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ genericTabActive && entitySelection > 0? $t('staff.selector.generic_w_count', { count: entitySelection }) : $t('staff.selector.generic') }}</a>
          </li>
        </PriorityNavigation>
      </div>
       
      <b-tabs v-model="activeTab" @activate-tab="onTabSwitch"
        class="staff-tab-container"
        active-nav-item-class="active"
        content-class="staff-tabs mt-3 specific-list" pills>
        
        <b-tab title="List" title-item-class="mytab">
          <PriorityNavigation class="list-toolbar grid-toolbar border-part" v-if="allowManage"
            :dropDownStayOpened="badgeFilterModalOpened != 'close'" 
            :closeDropdown.sync="closePriorityNavDropdown" 
            @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'list')"
            @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'list')"
            :forceRefresh.sync="forcePriorityNavRefresh"
          >
            <li v-if="canAdd(entity, ['startDate', 'endDate','staffType','email','firstName','payAmount','payCurrency'])" :id="`BTN_ADD_${elemId}`">
              <b-btn @click="openEditor(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`BTN_ADD_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            
            <li v-if="canView(entity)" :id="`BTN_EDIT_${elemId}`">
              <b-btn :disabled="disableEdit" @click="openEditor(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`BTN_EDIT_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li v-if="canAdd(entity)" :id="`BTN_DUPLICATE_${elemId}`">
              <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog_list"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`BTN_DUPLICATE_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.duplicate') }}
              </b-popover>
            </li>
            <li  v-if="canDelete(entity)" :id="`BTN_DELETE_${elemId}`">
              <b-btn :disabled="disableDelete" @click="rowDelete('list')"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`BTN_DELETE_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li v-if="canAdd(entity)" :id="`BTN_IMPORT_DOCUMENT_${elemId}`">
              <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
              <b-popover :target="`BTN_IMPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('staff.button.import_document') }}
              </b-popover>
            </li>
            <li :id="`BTN_EXPORT_DOCUMENT_${elemId}`">
              <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`BTN_EXPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </li>
            <li v-if="canView(entity)" @[filterMouseEnterEvent]="onFilterOver($event, 'list')" @mouseleave="onFilterLeave($event, 'list')">
              <b-dropdown v-if="!searchPinned" :id="`BTN_FILTER_${elemId}`" ref="filter"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onFilterTextDropdownHide($event, 'list')"
              >
                <template #button-content>
                  <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <b-input-group class="search-input">
                    <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'list')"></b-form-input>
                    <b-input-group-append>
                      <b-btn :id="`BTN_CLEAR_FILTER_${elemId}`" @click="onFilterClear($event, 'list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                      <b-popover :target="`BTN_CLEAR_FILTER_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.clear') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_SEARCH_FILTER_${elemId}`" @click="onFilterSubmit($event, 'list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                      <b-popover :target="`BTN_SEARCH_FILTER_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.search') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_PIN_FILTER_${elemId}`" @click="onPinFilter('list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                      <b-popover :target="`BTN_PIN_FILTER_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.pinOnToolbar') }}
                      </b-popover>
                    </b-input-group-append>
                  </b-input-group>
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <b-input-group class="search-input" v-if="searchPinned">
                <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'list')"></b-form-input>
                <b-input-group-append>
                  <b-btn :id="`BTN_CLEAR_FILTER_${elemId}`" @click="onFilterClear($event, 'list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                  <b-popover :target="`BTN_CLEAR_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.clear') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_SEARCH_FILTER_${elemId}`" @click="onFilterSubmit($event, 'list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                  <b-popover :target="`BTN_SEARCH_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.search') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_UNPIN_FILTER_${elemId}`" @click="onUnPinFilter('list')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                  <b-popover :target="`BTN_UNPIN_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.unpinFromToolbar') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </li>
            <li v-if="canView(entity)" @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter($event, 'list')" 
                @[badgeFilterMouseOverEvent]="onBadgeFilterOver($event, 'list')" 
                @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave($event, 'list')" 
                @[badgeFilterTouchEndEvent]="onBadgeFilterOver($event, 'list')">
              <b-dropdown v-if="!badgeFilterPinned" :id="`BTN_BADGE_FILTER_${elemId}`" ref="badgeFilter"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onBadgeFilterDropdownHide($event, 'list')"
              >
                <template #button-content>
                  <font-awesome-icon :class="badgeFilters != null && badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <TaskViewBadgeFilter :filters="badgeFilters" 
                    :fields="badgeFilterFields" 
                    :fieldValues="badgeFilterFieldValues"
                    canPin
                    @modified="onBadgeFilterModified($event, 'list')" 
                    @filterModalOpened="onBadgeFilterModalOpened($event, 'list')"
                    @filterModalClosed="onBadgeFilterModalClosed($event, 'list')"
                    @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'list')"
                    @pin="onPinBadgeFilter('list')"
                    />
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <TaskViewBadgeFilter v-if="badgeFilterPinned" 
                :filters="badgeFilters" 
                :fields="badgeFilterFields" 
                :fieldValues="badgeFilterFieldValues"
                canPin
                pinned
                @modified="onBadgeFilterModified($event, 'list')" 
                @filterModalOpened="onBadgeFilterModalOpened($event, 'list')"
                @filterModalClosed="onBadgeFilterModalClosed($event, 'list')"
                @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'list')"
                @pin="onUnPinBadgeFilter('list')"
                />
            </li>
            <li class="view" @[viewMouseEnterEvent]="onViewOver($event, 'list')" @mouseleave="onViewLeave($event, 'list')">
              <b-dropdown :id="`BTN_VIEW_orgChart_${elemId}`" ref="view_list" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far','desktop']"/>
                </template>
                
                <b-dropdown-item @click="showAllColumns('list')" href="#">
                  <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showNoColumns('list')" href="#">
                  <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item @click="savePreset('list')" href="#">
                  <span class="action-item-label">{{ $t(`${entity? formattedEntity+'.' : ''}button.save_view`) }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <template v-for="(item, index) in filteredViews">
                  <b-dropdown-item class="action-item" :disabled="inProgressShow" @click="loadViewSettings(item, 'list')" href="#" :key="index">
                    <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                    <b-popover
                      v-if="isEllipsisActive(item.name)"
                      :target="`viewname${index}`"
                      placement="top"
                      boundary="viewport"
                      custom-class="popover-margin"
                      triggers="hover"
                      offset="-100"
                      :content="item.name">
                    </b-popover> 
                    <span>
                      <template v-if="!editPermission(item)">
                        <span class="action-item-icon"
                            :id="`COPY_COLUMN_${index}`"
                            @click.stop.prevent="copyColumnSettings(item.name, item, 'list')">
                          <font-awesome-icon class="" :icon="['far','copy']"/>
                        </span>
                        <b-popover :target="`COPY_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.copy_view`) }}
                        </b-popover>
                        <span class="action-item-icon position-third" 
                            @[infoMouseEnterEvent]="onInfoOver(index, filteredViews, showInfo)" @mouseleave="onInfoLeave(index, filteredViews, showInfo)"
                            :id="`BTN_INFO_${index}`">
                          <font-awesome-icon class="" :icon="['far','circle-info']"/>
                        </span>
                        <b-popover
                          :target="`BTN_INFO_${index}`"
                          :ref="`BTN_INFO_${index}`"
                          :show.sync="showInfo[index]"
                          placement="top"
                          boundary="viewport"
                          triggers="manual"
                          :content="$t('owner', [filteredViews[index].owner])">
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-third"
                            :id="`SHARE_COLUMN_${index}`"
                            @click.stop.prevent="shareColumnSettings(index, item.name, item, 'list')">
                          <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                        </span>
                        <b-popover :target="`SHARE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.share_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-second" 
                            :id="`UPDATE_COLUMN_${index}`"
                            @click.stop.prevent="updateColumnSettings(index, item.name, item, 'list')">
                          <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                        </span>
                        <b-popover :target="`UPDATE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.update_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon"
                            :id="`REMOVE_COLUMN_${index}`"
                            @click.stop.prevent="removeColumnSettings(index, 'list')">
                          <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                        </span>
                        <b-popover :target="`REMOVE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.delete_view`) }}
                        </b-popover>
                      </template>
                    </span>
                  </b-dropdown-item>
                </template>
                
              </b-dropdown>
            </li>
            <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
              <b-dropdown :id="`BTN_COLORING_${elemId}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far', 'palette']"/>
                </template>
                <b-dropdown-group :header="$t('colorby')">
                  <template v-for="(value, name, index) in coloring">
                    <b-dropdown-item @click="onListColorChange(name)" href="#" :key="index">
                      <span class="action-item-label">{{ $t(`entity_selector.coloring_${name}`) }}</span><font-awesome-icon class="active-check" v-if="coloring[name]" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </template>
                </b-dropdown-group>
              </b-dropdown>
            </li>
            <li>
              <span readonly class="action-toolbar-divider">|</span>
            </li>
            <li>
              <b-form-checkbox v-model="singleContracts" @change="onSingleContractsChange" class='ml-2'>{{$t('staff.single_contracts')}}</b-form-checkbox>
            </li>
          </PriorityNavigation>
          <div class="staff-action-bar">
            <PriorityNavigation class="grid-toolbar border-ends"
              :closeDropdown.sync="closePriorityNavDropdown" 
              @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
              @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
            >
              <li>
                <span class="d-flex ml-2 mr-2">
                  <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
                  <b-form-select id="dates" v-model="dates" :disabled="!canView(this.permissionName, ['endDate', 'startDate'])" :options="dateOptions" @change="rangeSelected()" class="mw-150 minw-170" size="sm"></b-form-select>
                </span>
              </li>
              <li v-show="dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])">
                <span class="d-flex mr-1 date start-date-elevation">
                  <label class="mr-1 align-self-baseline" for="startDate">{{ $t('staff.from') }}</label>
                  <b-form-datepicker id="staffUsageStartDate" v-model="startDate" class="date-picker d-flex" @input="dateChanged"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      size="sm"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                </span>
              </li>
              <li v-show="dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])">
                <span class="d-flex mr-1 date">
                  <label class="mr-1 align-self-baseline" for="endDate">{{ $t('staff.to') }}</label>
                  <b-form-datepicker id="staffUsageEndDate" v-model="endDate" class="date-picker d-flex" @input="dateChanged"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      size="sm"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                </span>
              </li>
              <li v-if="canView(this.permissionName, ['endDate', 'startDate'])">
                <b-btn :id="`BTN_REFRESH_${elemId}`" class="ml-1" @click="daySelected" :pressed.sync="highlightRefresh">
                  <font-awesome-icon :icon="['far', 'arrows-rotate']"/>
                  <b-popover
                    :target="`BTN_REFRESH_${elemId}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.refresh')">
                  </b-popover>
                </b-btn>
              </li>
            </PriorityNavigation> 
          </div>
          
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="staff-grid"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :columnDefs="columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.uuId"
                pagination
                :paginationPageSize="1000"
                :cacheBlockSize="cacheBlockSize"
                rowModelType="serverSide"
                :rowData="rowData"
                :rowMultiSelectWithClick="nonAdmin"
                :rowSelection="singleSelection? 'single' : 'multiple'"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressContextMenu
                suppressDragLeaveHidesColumns
                :suppressCellFocus="nonAdmin"
                :singleClickEdit="false"
                :suppressMultiSort="false"
                :enableRangeSelection="!nonAdmin"
                :suppressRowClickSelection="!nonAdmin"

                
                :enableFillHandle="!nonAdmin"
                :fillOperation="fillOperation('list')"
                fillHandleDirection="xy"
                :processCellForClipboard="processCellForClipboard('list')"
                :processCellFromClipboard="processCellFromClipboard('list')"
                suppressClipboardApi
                :navigateToNextCell="navigateToNextCell"
                :tabToNextCell="tabToNextCell"
                @cell-key-down="onCellKeyDown"
                @paste-start="onPasteStart"
                @paste-end="onPasteEnd"
                @cell-focused="cellFocused"

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="noRowsOverlayComponentParams"

                :overlayLoadingTemplate="overlayLoadingTemplate"
                enableCellEditingOnBackspace

                @cell-editing-started="onCellEditingStarted"
                @cell-editing-stopped="onCellEditingStopped"
                >
          </ag-grid-vue>
        </b-tab>

        <b-tab v-if="nonAdmin && taskUuId !== null" title="Recommended" title-item-class="mytab" content-class="recommended-list">
          <nav class="priority-nav recommended-toolbar grid-toolbar border"> 
            <ul>
              <li v-if="canView(entity)" @[filterMouseEnterEvent]="onFilterOver($event, 'recommended')" @mouseleave="onFilterLeave($event, 'recommended')" class="recommended-first-action">
                <b-dropdown v-if="!recommended.searchPinned" :id="`BTN_FILTER_recommended_${elemId}`" ref="filter_recommended"
                  class="action-bar-dropdown text-filter" 
                  toggle-class="text-decoration-none" no-caret
                  @hide="onFilterTextDropdownHide($event, 'recommended')"
                >
                  <template #button-content>
                    <font-awesome-icon :class="recommended.filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
                  </template>
                  
                  <b-dropdown-form @submit.stop.prevent class="filter-padding">
                    <b-input-group class="search-input">
                      <b-form-input v-model="recommended.filterText" @focus="recommended.filterTextFocus = true" @blur="recommended.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'recommended')"></b-form-input>
                      <b-input-group-append>
                        <b-btn :id="`BTN_CLEAR_FILTER_RECOMMENDED_${elemId}`" @click="onFilterClear($event, 'recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                        <b-popover :target="`BTN_CLEAR_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                          {{ $t('button.clear') }}
                        </b-popover>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-btn :id="`BTN_SEARCH_FILTER_RECOMMENDED_${elemId}`" @click="onFilterSubmit($event, 'recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                        <b-popover :target="`BTN_SEARCH_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                          {{ $t('button.search') }}
                        </b-popover>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-btn :id="`BTN_PIN_FILTER_RECOMMENDED_${elemId}`" @click="onPinFilter('recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                        <b-popover :target="`BTN_PIN_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                          {{ $t('button.pinOnToolbar') }}
                        </b-popover>
                      </b-input-group-append>
                    </b-input-group>
                  </b-dropdown-form>
                  
                </b-dropdown>
              </li>
              <li>
                <b-input-group class="search-input" v-if="recommended.searchPinned">
                  <b-form-input v-model="recommended.filterText" @focus="recommended.filterTextFocus = true" @blur="recommended.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'recommended')"></b-form-input>
                  <b-input-group-append>
                    <b-btn :id="`BTN_CLEAR_FILTER_RECOMMENDED_${elemId}`" @click="onFilterClear($event, 'recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                    <b-popover :target="`BTN_CLEAR_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                      {{ $t('button.clear') }}
                    </b-popover>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-btn :id="`BTN_SEARCH_FILTER_RECOMMENDED_${elemId}`" @click="onFilterSubmit($event, 'recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                    <b-popover :target="`BTN_SEARCH_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                      {{ $t('button.search') }}
                    </b-popover>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-btn :id="`BTN_UNPIN_FILTER_RECOMMENDED_${elemId}`" @click="onUnPinFilter('recommended')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                    <b-popover :target="`BTN_UNPIN_FILTER_RECOMMENDED_${elemId}`" triggers="hover" placement="top">
                      {{ $t('button.unpinFromToolbar') }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
              </li>
              <li style="right: 0; position: absolute">
                <b-btn id="recommendation_settings" class="settings-button" @click="showRecommendationSettings()"><font-awesome-icon :icon="['far', 'gear']"/></b-btn>
                <b-popover target="recommendation_settings" triggers="hover" placement="top">
                  {{ $t('staff.recommendation_settings') }}
                </b-popover>
              </li>
            </ul>
          </nav>
    
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="recommended-grid"
                :gridOptions="recommended.gridOptions"
                @grid-ready="onRecommendedGridReady"
                :columnDefs="recommended.columnDefs"
                :context="recommended.context"
                :defaultColDef="recommended.defaultColDef"
                :getRowId="params => params.data.staffUUID"
                pagination
                :paginationPageSize="1000"
                rowModelType="serverSide"
                :rowSelection="singleSelection? 'single':'multiple'"
                rowMultiSelectWithClick
                
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressMultiSort

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="generic.noRowsOverlayComponentParams"
                >
                <!-- :overlayNoRowsTemplate="overlayNoRowsTemplate" -->
          </ag-grid-vue>
        </b-tab>

        <b-tab title="Org Chart" title-item-class="mytab" content-class="orgchart-list">
          <PriorityNavigation class="orgchart-toolbar grid-toolbar border-part" v-if="allowManage"
            :dropDownStayOpened="orgChart.badgeFilterModalOpened != 'close'" 
            :closeDropdown.sync="orgChart.closePriorityNavDropdown" 
            @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'orgChart')"
            @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'orgChart')"
            :forceRefresh.sync="orgChart.forcePriorityNavRefresh"
          >
            <li :id="`BTN_ADD_${elemId}_ORGCHART`">
              <b-btn v-if="canAdd(entity)" @click="openEditor(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`BTN_ADD_${elemId}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            <li v-if="canView(entity)" :id="`BTN_EDIT_${elemId}_ORGCHART`">
              <b-btn :disabled="disableOrgChartEdit" @click="openEditor(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`BTN_EDIT_${elemId}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li v-if="canAdd(entity)" :id="`BTN_DUPLICATE_${elemId}_ORGCHART`">
              <b-btn :disabled="disableOrgChartDuplicate" @click="showDuplicateDialog_orgChart"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`BTN_DUPLICATE_${elemId}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.duplicate') }}
              </b-popover>
            </li>
            <li v-if="canDelete(entity)" :id="`BTN_DELETE_${elemId}_ORGCHART`">
              <b-btn :disabled="disableOrgChartDelete" @click="rowDelete('orgChart')"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`BTN_DELETE_${elemId}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li :id="`BTN_EXPORT_DOCUMENT_${elemId}_ORGCHART`">
              <b-btn @click="fileExportOrgChart"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`BTN_EXPORT_DOCUMENT_${elemId}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </li>
            <li v-if="canView(entity)" @[filterMouseEnterEvent]="onFilterOver($event, 'orgChart')" @mouseleave="onFilterLeave($event, 'orgChart')">
              <b-dropdown v-if="!orgChart.searchPinned" :id="`BTN_FILTER_orgChart_${elemId}`" ref="filter_orgChart"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onFilterTextDropdownHide($event, 'orgChart')"
              >
                <template #button-content>
                  <font-awesome-icon :class="orgChart.filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <b-input-group class="search-input">
                    <b-form-input v-model="orgChart.filterText" @focus="orgChart.filterTextFocus = true" @blur="orgChart.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'orgChart')"></b-form-input>
                    <b-input-group-append>
                      <b-btn :id="`BTN_CLEAR_FILTER_ORGCHART_${elemId}`" @click="onFilterClear($event, 'orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                      <b-popover :target="`BTN_CLEAR_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.clear') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_SEARCH_FILTER_ORGCHART_${elemId}`" @click="onFilterSubmit($event, 'orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                      <b-popover :target="`BTN_SEARCH_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.search') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_PIN_FILTER_ORGCHART_${elemId}`" @click="onPinFilter('orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                      <b-popover :target="`BTN_PIN_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.pinOnToolbar') }}
                      </b-popover>
                    </b-input-group-append>
                  </b-input-group>
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <b-input-group class="search-input" v-if="orgChart.searchPinned">
                <b-form-input v-model="orgChart.filterText" @focus="orgChart.filterTextFocus = true" @blur="orgChart.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'orgChart')"></b-form-input>
                <b-input-group-append>
                  <b-btn :id="`BTN_CLEAR_FILTER_ORGCHART_${elemId}`" @click="onFilterClear($event, 'orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                  <b-popover :target="`BTN_CLEAR_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.clear') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_SEARCH_FILTER_ORGCHART_${elemId}`" @click="onFilterSubmit($event, 'orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                  <b-popover :target="`BTN_SEARCH_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.search') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_UNPIN_FILTER_ORGCHART_${elemId}`" @click="onUnPinFilter('orgChart')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                  <b-popover :target="`BTN_UNPIN_FILTER_ORGCHART_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.unpinFromToolbar') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </li>
            <li v-if="canView(entity)" @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter($event, 'orgChart')" 
                @[badgeFilterMouseOverEvent]="onBadgeFilterOver($event, 'orgChart')" 
                @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave($event, 'orgChart')" 
                @[badgeFilterTouchEndEvent]="onBadgeFilterOver($event, 'orgChart')">
              <b-dropdown v-if="!orgChart.badgeFilterPinned" :id="`BTN_BADGE_FILTER_${elemId}`" ref="badgeFilter_orgChart"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onBadgeFilterDropdownHide($event, 'orgChart')"
              >
                <template #button-content>
                  <font-awesome-icon :class="orgChart != null && orgChart.badgeFilters != null && orgChart.badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <TaskViewBadgeFilter :filters="orgChart.badgeFilters" 
                    :fields="orgChart.badgeFilterFields" 
                    :fieldValues="orgChart.badgeFilterFieldValues"
                    canPin 
                    @modified="onBadgeFilterModified($event, 'orgChart')" 
                    @filterModalOpened="onBadgeFilterModalOpened($event, 'orgChart')"
                    @filterModalClosed="onBadgeFilterModalClosed($event, 'orgChart')"
                    @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'orgChart')"
                    @pin="onPinBadgeFilter('orgChart')"
                    />
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <TaskViewBadgeFilter v-if="orgChart.badgeFilterPinned"
                :filters="orgChart.badgeFilters" 
                :fields="orgChart.badgeFilterFields" 
                :fieldValues="orgChart.badgeFilterFieldValues"
                canPin
                pinned
                @modified="onBadgeFilterModified($event, 'orgChart')" 
                @filterModalOpened="onBadgeFilterModalOpened($event, 'orgChart')"
                @filterModalClosed="onBadgeFilterModalClosed($event, 'orgChart')"
                @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'orgChart')"
                @pin="onUnPinBadgeFilter('orgChart')"
                />
            </li>
            <li class="view" @[viewMouseEnterEvent]="onViewOver($event, 'orgChart')" @mouseleave="onViewLeave($event, 'orgChart')">
              <b-dropdown :id="`BTN_VIEW_orgChart_${elemId}`" ref="view_orgChart" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far','desktop']"/>
                </template>
                
                <b-dropdown-item @click="showAllColumns('orgChart')" href="#">
                  <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showNoColumns('orgChart')" href="#">
                  <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item @click="savePreset('orgChart')" href="#">
                  <span class="action-item-label">{{ $t(`${entity? formattedEntity+'.' : ''}button.save_view`) }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <template v-for="(item, index) in filteredOrgChartViews">
                  <b-dropdown-item class="action-item" :disabled="inProgressShow" @click="loadViewSettings(item, 'orgChart')" href="#" :key="index">
                    <span :id="`viewname_org${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                    <b-popover
                      v-if="isEllipsisActive(item.name)"
                      :target="`viewname_org${index}`"
                      placement="top"
                      boundary="viewport"
                      custom-class="popover-margin"
                      triggers="hover"
                      offset="-100"
                      :content="item.name">
                    </b-popover> 
                    <span>
                      <template v-if="!editPermission(item)">
                        <span class="action-item-icon"
                            :id="`COPY_ORGCHART_COLUMN_${index}`"
                            @click.stop.prevent="copyColumnSettings(item.name, item, 'orgChart')">
                          <font-awesome-icon class="" :icon="['far','copy']"/>
                        </span>
                        <b-popover :target="`COPY_ORGCHART_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.copy_view`) }}
                        </b-popover>
                        <span class="action-item-icon position-third" 
                            @[infoMouseEnterEvent]="onInfoOver(index, filteredOrgChartViews, orgChart.showInfo)" @mouseleave="onInfoLeave(index, filteredOrgChartViews, orgChart.showInfo)"
                            :id="`BTN_ORGCHART_INFO_${index}`">
                          <font-awesome-icon class="" :icon="['far','circle-info']"/>
                        </span>
                        <b-popover
                          :target="`BTN_ORGCHART_INFO_${index}`"
                          :ref="`BTN_ORGCHART_INFO_${index}`"
                          :show.sync="orgChart.showInfo[index]"
                          placement="top"
                          boundary="viewport"
                          triggers="manual"
                          :content="$t('owner', [filteredOrgChartViews[index].owner])">
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-third"
                            :id="`SHARE_ORGCHART_COLUMN_${index}`"
                            @click.stop.prevent="shareColumnSettings(index, item.name, item, 'orgChart')">
                          <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                        </span>
                        <b-popover :target="`SHARE_ORGCHART_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.share_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-second" 
                            :id="`UPDATE_ORGCHART_COLUMN_${index}`"
                            @click.stop.prevent="updateColumnSettings(index, item.name, item, 'orgChart')">
                          <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                        </span>
                        <b-popover :target="`UPDATE_ORGCHART_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.update_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon"
                            :id="`REMOVE_ORGCHART_COLUMN_${index}`"
                            @click.stop.prevent="removeColumnSettings(index, 'orgChart')">
                          <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                        </span>
                        <b-popover :target="`REMOVE_ORGCHART_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.delete_view`) }}
                        </b-popover>
                      </template>
                    </span>
                  </b-dropdown-item>
                </template>
                
              </b-dropdown>
            </li>
            <li>
              <span readonly class="action-toolbar-divider">|</span>
            </li>
            <li>
                <b-form-checkbox v-model="orgChartRealStaff" @change="onOrgChartRealStaffChange" class='ml-2'>{{$t('staff.actual_staff')}}</b-form-checkbox>
                <b-form-checkbox v-model="orgChartGenericStaff" @change="onOrgChartGenericStaffChange" class='ml-2'>{{$t('staff.generic_staff')}}</b-form-checkbox>
                <b-form-checkbox v-model="orgChart.singleContracts" @change="onOrgChartSingleContractsChange" class='ml-2'>{{$t('staff.single_contracts')}}</b-form-checkbox>
            </li>
          </PriorityNavigation>
          
          <div class="staff-action-bar">
            <PriorityNavigation class="grid-toolbar border-ends"
              :closeDropdown.sync="closePriorityNavDropdown" 
              @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
              @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
            >
              <li>
                <span class="d-flex ml-2 mr-2">
                  <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
                  <b-form-select id="orgChart.dates" :disabled="!canView(this.permissionName, ['endDate', 'startDate'])" v-model="orgChart.dates" :options="dateOptions" @change="orgChartRangeSelected()" class="mw-150 minw-170" size="sm"></b-form-select>
                </span>
              </li>
              <li v-show="orgChart.dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])">
                <span class="d-flex mr-1 date start-date-elevation">
                  <label class="mr-1 align-self-baseline" for="startDate">{{ $t('staff.from') }}</label>
                  <b-form-datepicker id="orgChart.staffUsageStartDate" v-model="orgChart.startDate" class="date-picker d-flex" @input="orgChartDateChanged"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      size="sm"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                </span>
              </li>
              <li v-show="orgChart.dates !== 'all-time' && canView(this.permissionName, ['endDate', 'startDate'])">
                <span class="d-flex mr-1 date">
                  <label class="mr-1 align-self-baseline" for="endDate">{{ $t('staff.to') }}</label>
                  <b-form-datepicker id="orgChart.staffUsageEndDate" v-model="orgChart.endDate" class="date-picker d-flex" @input="orgChartDateChanged"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      size="sm"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                </span>
              </li>
              <li v-if="canView(this.permissionName, ['endDate', 'startDate'])">
                <b-btn :id="`BTN_REFRESH_ORGCHART${elemId}`" class="ml-1" @click="orgChartDaySelected" :pressed.sync="orgChart.highlightRefresh">
                  <font-awesome-icon :icon="['far', 'arrows-rotate']"/>
                  <b-popover
                    :target="`BTN_REFRESH_ORGCHART${elemId}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.refresh')">
                  </b-popover>
                </b-btn>
              </li>
            </PriorityNavigation> 
          </div>
          
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="org-chart-grid"
                :gridOptions="orgChart.gridOptions"
                @grid-ready="onOrgChartGridReady"
                :autoGroupColumnDef="orgChart.autoGroupColumnDef"
                :columnDefs="orgChart.columnDefs"
                :context="orgChart.context"
                :defaultColDef="orgChart.defaultColDef"
                :getRowId="params => params.data.path"
                :rowMultiSelectWithClick="nonAdmin"
                :rowSelection="singleSelection? 'single' : 'multiple'"
                
                :sideBar="false"
                suppressContextMenu
                suppressDragLeaveHidesColumns
                :suppressCellFocus="nonAdmin"
                :singleClickEdit="false"
                
                :suppressMultiSort="false"
                :enableRangeSelection="!nonAdmin"
                :suppressRowClickSelection="!nonAdmin"
                
                :enableFillHandle="!nonAdmin"
                :fillOperation="fillOperation('orgChart')"
                fillHandleDirection="xy"
                :processCellForClipboard="processCellForClipboard('orgChart')"
                :processCellFromClipboard="processCellFromClipboard('orgChart')"
                suppressClipboardApi
                :navigateToNextCell="navigateToNextCell"
                :tabToNextCell="tabToNextCell"
                @cell-key-down="onCellKeyDown"
                @paste-start="onPasteStart"
                @paste-end="onPasteEnd"
                @cell-focused="cellFocused"

                :getDataPath="data => data.path.split(', ')"
                :rowData="orgChart.rowData"
                treeData
                groupDefaultExpanded="-1"

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="orgChart.noRowsOverlayComponentParams"

                :overlayLoadingTemplate="overlayLoadingTemplate"
                enableCellEditingOnBackspace

                @cell-editing-started="onCellEditingStarted"
                @cell-editing-stopped="onCellEditingStopped"
                >
          </ag-grid-vue>
        </b-tab>

        <b-tab title="Generic" title-item-class="mytab">
          <PriorityNavigation class="generic-toolbar grid-toolbar border" v-if="allowManage"
            :dropDownStayOpened="generic.badgeFilterModalOpened != 'close'" 
            :closeDropdown.sync="generic.closePriorityNavDropdown" 
            @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'generic')"
            @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd($event, 'generic')"
            :forceRefresh.sync="generic.forcePriorityNavRefresh"
          >
            <li v-if="canAdd(entity)" :id="`GENERIC_BTN_ADD_${elemId}`">
              <b-btn @click="openEditor(true, true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`GENERIC_BTN_ADD_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            <li v-if="canView(entity)" :id="`GENERIC_BTN_EDIT_${elemId}`">
              <b-btn :disabled="disableEdit" @click="openEditor(false, true)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_EDIT_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li v-if="canAdd(entity)" :id="`GENERIC_BTN_DUPLICATE_${elemId}`">
              <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog_generic"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_DUPLICATE_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.duplicate') }}
              </b-popover>
            </li>
            <li  v-if="canDelete(entity)" :id="`GENERIC_BTN_DELETE_${elemId}`">
              <b-btn :disabled="disableDelete" @click="rowDelete('generic')"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_DELETE_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li v-if="canAdd(entity)" :id="`GENERIC_BTN_IMPORT_DOCUMENT_${elemId}`">
              <b-btn @click="fileImportGeneric"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_IMPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top">
                {{ $t('staff.button.import_document') }}
              </b-popover>
            </li>
            <li :id="`GENERIC_BTN_EXPORT_DOCUMENT_${elemId}`">
              <b-btn @click="fileExportGeneric"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_EXPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </li>
            <li v-if="canView(entity)" @[filterMouseEnterEvent]="onFilterOver($event, 'generic')" @mouseleave="onFilterLeave($event, 'generic')">
              <b-dropdown v-if="!generic.searchPinned" :id="`BTN_FILTER_generic_${elemId}`" ref="filter_generic"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onFilterTextDropdownHide($event, 'generic')"
              >
                <template #button-content>
                  <font-awesome-icon :class="generic.filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <b-input-group class="search-input">
                    <b-form-input v-model="generic.filterText" @focus="generic.filterTextFocus = true" @blur="generic.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'generic')"></b-form-input>
                    <b-input-group-append>
                      <b-btn :id="`BTN_CLEAR_FILTER_GENERIC_${elemId}`" @click="onFilterClear($event, 'generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                      <b-popover :target="`BTN_CLEAR_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.clear') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_SEARCH_FILTER_GENERIC_${elemId}`" @click="onFilterSubmit($event, 'generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                      <b-popover :target="`BTN_SEARCH_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.search') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-btn :id="`BTN_PIN_FILTER_GENERIC_${elemId}`" @click="onPinFilter('generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                      <b-popover :target="`BTN_PIN_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                        {{ $t('button.pinOnToolbar') }}
                      </b-popover>
                    </b-input-group-append>
                    
                  </b-input-group>
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <b-input-group class="search-input" v-if="generic.searchPinned">
                <b-form-input v-model="generic.filterText" @focus="generic.filterTextFocus = true" @blur="generic.filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit($event, 'generic')"></b-form-input>
                <b-input-group-append>
                  <b-btn :id="`BTN_CLEAR_FILTER_GENERIC_${elemId}`" @click="onFilterClear($event, 'generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                  <b-popover :target="`BTN_CLEAR_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.clear') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_SEARCH_FILTER_GENERIC_${elemId}`" @click="onFilterSubmit($event, 'generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                  <b-popover :target="`BTN_SEARCH_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.search') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_UNPIN_FILTER_GENERIC_${elemId}`" @click="onUnPinFilter('generic')" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                  <b-popover :target="`BTN_UNPIN_FILTER_GENERIC_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.unpinFromToolbar') }}
                  </b-popover>
                </b-input-group-append>
                
              </b-input-group>
            </li>
            <li v-if="canView(entity)" @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter($event, 'generic')" 
                @[badgeFilterMouseOverEvent]="onBadgeFilterOver($event, 'generic')" 
                @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave($event, 'generic')" 
                @[badgeFilterTouchEndEvent]="onBadgeFilterOver($event, 'generic')">
              <b-dropdown v-if="!generic.badgeFilterPinned" :id="`BTN_BADGE_FILTER_${elemId}`" ref="badgeFilter_generic"
                class="action-bar-dropdown text-filter" 
                toggle-class="text-decoration-none" no-caret
                @hide="onBadgeFilterDropdownHide($event, 'generic')"
              >
                <template #button-content>
                  <font-awesome-icon :class="generic != null && generic.badgeFilters != null && generic.badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
                </template>
                
                <b-dropdown-form @submit.stop.prevent class="filter-padding">
                  <TaskViewBadgeFilter :filters="generic.badgeFilters" 
                    :fields="generic.badgeFilterFields" 
                    :fieldValues="generic.badgeFilterFieldValues" 
                    canPin
                    @modified="onBadgeFilterModified($event, 'generic')" 
                    @filterModalOpened="onBadgeFilterModalOpened($event, 'generic')"
                    @filterModalClosed="onBadgeFilterModalClosed($event, 'generic')"
                    @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'generic')"
                    @pin="onPinBadgeFilter('generic')"
                    />
                </b-dropdown-form>
                
              </b-dropdown>
            </li>
            <li>
              <TaskViewBadgeFilter v-if="generic.badgeFilterPinned"
                :filters="generic.badgeFilters" 
                :fields="generic.badgeFilterFields" 
                :fieldValues="generic.badgeFilterFieldValues" 
                canPin
                @modified="onBadgeFilterModified($event, 'generic')" 
                @filterModalOpened="onBadgeFilterModalOpened($event, 'generic')"
                @filterModalClosed="onBadgeFilterModalClosed($event, 'generic')"
                @fetchFieldOptions="onBadgeFilterFetchOptions($event, 'generic')"
                @pin="onUnPinBadgeFilter('generic')"
                />
            </li>
            <li class="view" @[viewMouseEnterEvent]="onViewOver($event, 'generic')" @mouseleave="onViewLeave($event, 'generic')">
              <b-dropdown :id="`BTN_VIEW_generic_${elemId}`" ref="view_generic" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far','desktop']"/>
                </template>
                
                <b-dropdown-item @click="showAllColumns('generic')" href="#">
                  <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showNoColumns('generic')" href="#">
                  <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item @click="savePreset('generic')" href="#">
                  <span class="action-item-label">{{ $t(`${entity? formattedEntity+'.' : ''}button.save_view`) }}</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <template v-for="(item, index) in filteredGenericViews">
                  <b-dropdown-item class="action-item" :disabled="inProgressShow" @click="loadViewSettings(item, 'generic')" href="#" :key="index">
                    <span :id="`viewname_gen${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                    <b-popover
                      v-if="isEllipsisActive(item.name)"
                      :target="`viewname_gen${index}`"
                      placement="top"
                      boundary="viewport"
                      custom-class="popover-margin"
                      triggers="hover"
                      offset="-100"
                      :content="item.name">
                    </b-popover> 
                    <span>
                      <template v-if="!editPermission(item)">
                        <span class="action-item-icon"
                            :id="`COPY_GENERIC_COLUMN_${index}`"
                            @click.stop.prevent="copyColumnSettings(item.name, item, 'generic')">
                          <font-awesome-icon class="" :icon="['far','copy']"/>
                        </span>
                        <b-popover :target="`COPY_GENERIC_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.copy_view`) }}
                        </b-popover>
                        <span class="action-item-icon position-third" 
                            @[infoMouseEnterEvent]="onInfoOver(index, filteredGenericViews, generic.showInfo)" @mouseleave="onInfoLeave(index, filteredGenericViews, generic.showInfo)"
                            :id="`BTN_GENERIC_INFO_${index}`">
                          <font-awesome-icon class="" :icon="['far','circle-info']"/>
                        </span>
                        <b-popover
                          :target="`BTN_GENERIC_INFO_${index}`"
                          :ref="`BTN_GENERIC_INFO_${index}`"
                          :show.sync="generic.showInfo[index]"
                          placement="top"
                          boundary="viewport"
                          triggers="manual"
                          :content="$t('owner', [filteredGenericViews[index].owner])">
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-third"
                            :id="`SHARE_GENERIC_COLUMN_${index}`"
                            @click.stop.prevent="shareColumnSettings(index, item.name, item, 'generic')">
                          <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                        </span>
                        <b-popover :target="`SHARE_GENERIC_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.share_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon position-second" 
                            :id="`UPDATE_GENERIC_COLUMN_${index}`"
                            @click.stop.prevent="updateColumnSettings(index, item.name, item, 'generic')">
                          <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                        </span>
                        <b-popover :target="`UPDATE_GENERIC_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.update_view`) }}
                        </b-popover>
                      </template>
                      <template v-if="editPermission(item)">
                        <span class="action-item-icon"
                            :id="`REMOVE_GENERIC_COLUMN_${index}`"
                            @click.stop.prevent="removeColumnSettings(index, 'generic')">
                          <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                        </span>
                        <b-popover :target="`REMOVE_GENERIC_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                          {{ $t(`${entity? formattedEntity+'.' : ''}button.delete_view`) }}
                        </b-popover>
                      </template>
                    </span>
                  </b-dropdown-item>
                </template>
                
              </b-dropdown>
            </li>
            <li @[colorMouseEnterEvent]="onColoringOverGeneric" @mouseleave="onColoringLeaveGeneric">
              <b-dropdown :id="`BTN_COLORING_${elemId}`" ref="coloring_generic" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far', 'palette']"/>
                </template>
                <b-dropdown-group :header="$t('colorby')">
                  <template v-for="(value, name, index) in genericColoring">
                    <b-dropdown-item @click="onGenericColorChange(name)" href="#" :key="index">
                      <span class="action-item-label">{{ $t(`entity_selector.coloring_${name}`) }}</span><font-awesome-icon class="active-check" v-if="genericColoring[name]" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </template>
                </b-dropdown-group>
              </b-dropdown>
            </li>
          </PriorityNavigation>
          
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="generic-staff-grid"
                :gridOptions="generic.gridOptions"
                @grid-ready="onGenericGridReady"
                :columnDefs="generic.columnDefs"
                :context="generic.context"
                :defaultColDef="generic.defaultColDef"
                :getRowId="params => params.data.uuId"
                pagination
                :paginationPageSize="1000"
                :cacheBlockSize="10000"
                rowModelType="serverSide"
                :rowMultiSelectWithClick="nonAdmin"
                :rowSelection="singleSelection? 'single' : 'multiple'"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressContextMenu
                suppressDragLeaveHidesColumns
                :suppressCellFocus="nonAdmin"
                :singleClickEdit="false"
                :suppressMultiSort="false"
                :enableRangeSelection="!nonAdmin"
                :suppressRowClickSelection="!nonAdmin"
                
                :enableFillHandle="!nonAdmin"
                :fillOperation="fillOperation('generic')"
                fillHandleDirection="xy"
                :processCellForClipboard="processCellForClipboard('generic')"
                :processCellFromClipboard="processCellFromClipboard('generic')"
                suppressClipboardApi
                :navigateToNextCell="navigateToNextCell"
                :tabToNextCell="tabToNextCell"
                @cell-key-down="onCellKeyDown"
                @paste-start="onPasteStart"
                @paste-end="onPasteEnd"
                @cell-focused="cellFocused"

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="generic.noRowsOverlayComponentParams"

                :overlayLoadingTemplate="overlayLoadingTemplate"
                enableCellEditingOnBackspace

                @cell-editing-started="onCellEditingStarted"
                @cell-editing-stopped="onCellEditingStopped"
                >
          </ag-grid-vue>
        </b-tab>
      </b-tabs>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="nonAdmin && !hideOkBtn">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
        </template>
        <b-button v-else size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>

    <StaffModal v-if="allowManage && editorShow" 
      :id="entityId" 
      :show.sync="editorShow" 
      @success="editorSuccess" 
      @refresh-list="reloadData"
      :title="editorTitleLabel" 
      :isGeneric="isGeneric"
    />

    <b-modal :title="duplicateTitleLabel"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      
      <template v-if="isGeneric">
      <b-form-group :label="duplicateFieldNameLabel" label-for="name">
        <b-input-group>
          <b-form-input id="duplicateName" type="text"
            :data-vv-as="duplicateFieldNameLabel"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>
      </template>

      <template v-else>
        <b-form-group :label="$t('staff.field.email')" label-for="duplicateEmail">
          <b-input-group>
            <b-form-input id="duplicateEmail" type="text"
              :data-vv-as="$t('staff.field.email')"
              data-vv-name="duplicateEmail"
              data-vv-delay="500"
              v-model="duplicateEmail"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateEmailError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateEmail') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('staff.field.firstName')" label-for="duplicateFirstname">
          <b-input-group>
            <b-form-input id="duplicateFirstname" type="text"
              v-model="duplicateFirstname"/>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="$t('staff.field.lastName')" label-for="duplicatLastname">
          <b-input-group>
            <b-form-input id="duplicatLastname" type="text"
              :data-vv-as="$t('staff.field.lastName')"
              data-vv-name="duplicateLastname"
              data-vv-delay="500"
              v-model="duplicateLastname"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateLastnameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateLastname') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    
    <GanttImportDialog v-if="entity != null"
      :properties="docImportProperties" 
      mode="STAFF" :show="docImportShow"
      :existingData="existingStaff"
      :title="$t(`entity_selector.${formattedEntity}_import_document`)"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />

    <GanttImportDialog :properties="docImportGenericProperties"
      :mode="'STAFF_GENERIC'" :show="docImportGenericShow"
      :title="$t('staff.button.import_document')"
      @modal-ok="docImportGenericOk"
      @modal-cancel="docImportGenericCancel" />
    
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>

    <b-modal :title="$t('entity_selector.confirm_deletion_title')"
        v-model="ecConfirmDeleteShow"
        @hidden="ecConfirmDeleteShow=false"
        @ok="ecConfirmDeleteOk"
        @cancel="ecConfirmDeleteCancel"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ ecConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="ecShowApplyAllCheckbox" class="apply-to-all" v-model="entityCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/>

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <RecommendationSettingsModal :userId="userId" :show.sync="recommendationSettingsShow" @success="recommendationSettingsSuccess" />
  </div>
</template>

<script>
import * as moment from 'moment-timezone'
moment.tz.setDefault('Etc/UTC')

import 'ag-grid-enterprise'
import { AgGridVue } from 'ag-grid-vue'

import alertStateEnum from '@/enums/alert-state'
import { cloneDeep, debounce } from 'lodash'
import { 
  formatDate, 
  getNextWorkingDay, 
  strRandom, 
  objectClone, 
  invertColor, 
  randomString, 
  makeTree, 
  costFormat, 
  costFormatAdv, 
  prepareDepartmentTreeRequest,
  isEllipsisActive 
} from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, columnDefSortFunc } from '@/views/management/script/common'

import { getKeysWithoutRedactedFields } from '@/services/common';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { fieldValidateUtil } from '@/script/helper-field-validate'

import { viewProfileService, tagService, companyService, recommendationProfileService
, compositeService, staffService, profileService } from '@/services'
import { getComparator } from './script/columnComparator';
import { getCustomFieldInfo, prepareCustomFieldColumnDef, handleCustomFieldError, getCustomFieldExportDataPropertyHandler } from '@/helpers/custom-fields'

import { extractDurationConversionOpts } from '@/helpers/task-duration-process';

import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink'
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color'
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/SelectorRowSelector'
import SelectorHeaderComponent from '@/components/Aggrid/CellHeader/SelectorRangeSelection'
import TreeSelectorHeaderComponent from '@/components/Aggrid/CellHeader/TreeSelectorRangeSelection'
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum'
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray'
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly'
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic'
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost'
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean'

import NameEditor from '@/components/Aggrid/CellEditor/Name'
import StringEditor from '@/components/Aggrid/CellEditor/String'
import TagEditor from '@/components/Aggrid/CellEditor/Tag'
import ColorEditor from '@/components/Aggrid/CellEditor/Color'
import ListEditor from '@/components/Aggrid/CellEditor/List'
import LocationsEditor from '@/components/Aggrid/CellEditor/Locations'
import ResourcesEditor from '@/components/Aggrid/CellEditor/Resources'
import SkillsEditor from '@/components/Aggrid/CellEditor/Skills'
import CompaniesEditor from '@/components/Aggrid/CellEditor/Companies'
import DepartmentsEditor from '@/components/Aggrid/CellEditor/Departments'
import PhonesEditor from '@/components/Aggrid/CellEditor/Phones'
import SocialsEditor from '@/components/Aggrid/CellEditor/Socials'
import WebsitesEditor from '@/components/Aggrid/CellEditor/Websites'
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime'
import CostEditor from '@/components/Aggrid/CellEditor/Cost'
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric'
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric'
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric'

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows'

import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import currencies from '@/views/management/script/currencies';

let first = true; // for exporting only on the first export after the purge we get a modelUpdated so we need to skip this until the data is loaded

const formatArray = (params) => {
  if (Array.isArray(params.value)) {
    return params.value.map(i => i.name).join(', ')
  }
  return ''
}

const colorOptions = {
  none: true
  , staff: false
  , company: false
  , department: false
  , location: false
  , skill: false
  , resource: false
}
const genericColorOptions = {
  none: true
  , staff: false
  , company: false
  , location: false
  , skill: false
  , resource: false
}

const operationStatus = {
  SUCCESS: 'SUCCESS'
  , ABORT: 'ABORT'
}
Object.freeze(operationStatus)
const operationStatusKeys = Object.keys(operationStatus)

const CELL_COPY_CODE = '_COPIED_OBJ='

function ServerSideDatasource(self) {
  const buildParams = ({ request: {sortModel, endRow, startRow} }, { badgeFilters=null }={}) => {
    const params = {
      start: !self.exportData ? startRow : 0,
      limit: !self.exportData ? endRow - startRow + 1 : -1,
      badgeFilters
    };
    const companyViewDenyProperties = getPermissionDenyProperties('STAFF', 'VIEW')
    params.ksort = []
    params.order = []
    for(let i = 0, len = sortModel.length; i < len; i++) {
      if (sortModel[i].colId === 'uuId' && !companyViewDenyProperties.includes('lastName')) {
        params.ksort.push('lastName');
      } else if (sortModel[i].colId === 'skills') {
        const viewDenyProperties = getPermissionDenyProperties('SKILL', 'VIEW')
        if (!viewDenyProperties.includes('SKILL')) {
          params.ksort.push('skillName');
        }
      } else if (sortModel[i].colId === 'locations') {
        const viewDenyProperties = getPermissionDenyProperties('LOCATION', 'VIEW')
        if (!viewDenyProperties.includes('LOCATION')) {
          params.ksort.push('locationName');
        }
      } else if (sortModel[i].colId === 'resources') {
        const viewDenyProperties = getPermissionDenyProperties('RESOURCE', 'VIEW')
        if (!viewDenyProperties.includes('RESOURCE')) {
          params.ksort.push('resourceName');
        }
      } else if (sortModel[i].colId === 'companies') {
        const viewDenyProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
        if (!viewDenyProperties.includes('COMPANY')) {
          params.ksort.push('companyName');
        }
      } else if (sortModel[i].colId === 'departments') {
        const viewDenyProperties = getPermissionDenyProperties('DEPARTMENT', 'VIEW')
        if (!viewDenyProperties.includes('DEPARTMENT')) {
          params.ksort.push('departmentName');
        }
      } else if (!companyViewDenyProperties.includes(sortModel[i].colId)) {
        params.ksort.push(sortModel[i].colId);
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
    }
    params.singleContracts = self.singleContracts;
    params.startDate = self.dates === 'all-time' ? null : self.startDate;
    params.endDate = self.dates === 'all-time' ? null : self.endDate;

    let bookingrule = null;
    if (self.projectIds.length > 0 && self.bookings) {
      bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', self.projectIds.join('|')];   
    }
    
    const companyrule = self.companyrule;
    const departmentrule = self.departmentrule;
    const skillrule = self.skillrule;
    const locationrule = self.locationrule;
    const resourcerule = self.resourcerule;

    if (companyrule && departmentrule) {
      if (self.searchFilter == null || self.searchFilter === '') {
        if (bookingrule) {
          params.filter = [
            bookingrule,
            companyrule,
            departmentrule
          ];
        }
        else {
          params.filter = [
            companyrule,
            departmentrule
          ];
        }
      }
      else {
        if (bookingrule) {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            bookingrule,
            companyrule,
            departmentrule
          
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            companyrule,
            departmentrule
          ];
        }
      }
    }
    else if (companyrule) {
      if (self.searchFilter == null || self.searchFilter === '') {
        if (bookingrule) {
          params.filter = [
            bookingrule,
            companyrule
          ];
        }
        else {
          params.filter = [companyrule];
        }
      }
      else {
        if (bookingrule) {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            bookingrule,
            companyrule
          
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            companyrule
          
          ];
        }
      }
    } else if (skillrule) {
      if (this.searchFilter == null || this.searchFilter === '') {
        if (bookingrule) {
          params.filter = [
            bookingrule,
            skillrule
          ];
        }
        else {
          params.filter = [skillrule];
        }
      }
      else {
        if (bookingrule) {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            bookingrule,
            skillrule
          
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            skillrule
          
          ];
        }
      }
    } else if (locationrule) {
      if (self.searchFilter == null || self.searchFilter === '') {
        if (bookingrule) {
          params.filter = [
            bookingrule,
            locationrule
          ];
        }
        else {
          params.filter = [locationrule];
        }
      }
      else {
        if (bookingrule) {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', this.searchFilter]
              , ['STAFF.lastName', 'has', this.searchFilter]
              , ['STAFF.identifier', 'has', this.searchFilter]
              , ['STAFF.COMPANY.name', 'has', this.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
              , ['STAFF.email', 'has', this.searchFilter]
              , ['STAFF.position', 'has', this.searchFilter]
              , ['STAFF.LOCATION.name', 'has', this.searchFilter]
              , ['STAFF.TAG.name', 'has', this.searchFilter]
              , ['STAFF.SKILL.name', 'has', this.searchFilter]
            ],
            bookingrule,
            locationrule
          
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', this.searchFilter]
              , ['STAFF.lastName', 'has', this.searchFilter]
              , ['STAFF.identifier', 'has', this.searchFilter]
              , ['STAFF.COMPANY.name', 'has', this.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
              , ['STAFF.email', 'has', this.searchFilter]
              , ['STAFF.position', 'has', this.searchFilter]
              , ['STAFF.LOCATION.name', 'has', this.searchFilter]
              , ['STAFF.TAG.name', 'has', this.searchFilter]
              , ['STAFF.SKILL.name', 'has', this.searchFilter]
            ],
            locationrule
          
          ];
        }
      }
    } else if (resourcerule) {
      if (self.searchFilter == null || self.searchFilter === '') {
        if (bookingrule) {
          params.filter = [
            bookingrule,
            resourcerule
          ];
        }
        else {
          params.filter = [resourcerule];
        }
      }
      else {
        if (bookingrule) {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            bookingrule,
            resourcerule
          
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', self.searchFilter]
              , ['STAFF.lastName', 'has', self.searchFilter]
              , ['STAFF.identifier', 'has', self.searchFilter]
              , ['STAFF.COMPANY.name', 'has', self.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
              , ['STAFF.email', 'has', self.searchFilter]
              , ['STAFF.position', 'has', self.searchFilter]
              , ['STAFF.LOCATION.name', 'has', self.searchFilter]
              , ['STAFF.TAG.name', 'has', self.searchFilter]
              , ['STAFF.SKILL.name', 'has', self.searchFilter]
            ],
            resourcerule
          
          ];
        }
      }
    }
    else if (bookingrule) {
      if (self.searchFilter == null || self.searchFilter === '') {
        params.filter = [
          bookingrule
        ];
      }
      else {
        params.filter = [
          '_or_', [
              ['STAFF.firstName', 'has', self.searchFilter]
            , ['STAFF.lastName', 'has', self.searchFilter]
            , ['STAFF.identifier', 'has', self.searchFilter]
            , ['STAFF.COMPANY.name', 'has', self.searchFilter]
            , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
            , ['STAFF.email', 'has', self.searchFilter]
            , ['STAFF.position', 'has', self.searchFilter]
            , ['STAFF.LOCATION.name', 'has', self.searchFilter]
            , ['STAFF.TAG.name', 'has', self.searchFilter]
            , ['STAFF.SKILL.name', 'has', self.searchFilter]
          ],
          bookingrule
        
        ];
      }
    }
    else {
      params.filter = self.searchFilter;
    }

    if (self.staffListUuIds) {
      params.holders = self.staffListUuIds;
    }
    return params;
  }
  return {
    getRows(params) {
      if (self.lackOfMandatoryField()) {
        params.successCallback([], 0);
        self.showNoRowsOverlay(self, self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return;
      }
      
      staffService.listv2(buildParams(params, { badgeFilters: self.badgeFilters }), false, self.customFields
        , { 
          skillCustomFields: self.skillCustomFields
          , resourceCustomFields: self.resourceCustomFields 
        }
      ).then((response) => {
        const gridApi = params.api;
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(gridApi != null && self.selectorShow) {
          setTimeout(() => {
            gridApi.deselectAll();
            if(self.preselected && self.preselected.length > 0) {
              gridApi.forEachNode(function(node) {
                if (node.data != null && self.preselected.includes(node.data.uuId)) {
                  node.setSelected(true);
                  self.preselected.splice(self.preselected.indexOf(node.data.uuId), 1);
                }
              });
              
              if (self.preselected.length !== 0) {
                self.alertMsg = self.$t('staff.error.not_found');
                self.alertState = alertStateEnum.WARNING;
              }
            }
          }, 300)
        }
        
        if (self.totalRecords !== 0 &&
            gridApi != null) {
          gridApi.hideOverlay();
        }       
        if (response.arg_total === 0) {
          self.showNoRowsOverlay(self, null)
        }
        
      })
      .catch(e => {
        // console.error(e)
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowsOverlay(self, self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      })
    }
  }
}

function GenericDatasource(self, parentComponent) {
  const buildParams = ({ request: {sortModel, endRow, startRow} }, { badgeFilters=null }={}) => {
    const params = {
      start: startRow,
      limit: endRow - startRow + 1,
      badgeFilters
    };

    const companyViewDenyProperties = getPermissionDenyProperties('STAFF', 'VIEW')
    params.ksort = []
    params.order = []
    for(let i = 0, len = sortModel.length; i < len; i++) {
      if (sortModel[i].colId === 'uuId' && !companyViewDenyProperties.includes('firstName')) {
        params.ksort.push('firstName');
      } else if (sortModel[i].colId === 'skills') {
        const viewDenyProperties = getPermissionDenyProperties('SKILL', 'VIEW')
        if (!viewDenyProperties.includes('SKILL')) {
          params.ksort.push('skillName');
        }
      } else if (sortModel[i].colId === 'locations') {
        const viewDenyProperties = getPermissionDenyProperties('LOCATION', 'VIEW')
        if (!viewDenyProperties.includes('LOCATION')) {
          params.ksort.push('locationName');
        }
      } else if (sortModel[i].colId === 'resources') {
        const viewDenyProperties = getPermissionDenyProperties('RESOURCE', 'VIEW')
        if (!viewDenyProperties.includes('RESOURCE')) {
          params.ksort.push('resourceName');
        }
      } else if (sortModel[i].colId === 'companies') {
        const viewDenyProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
        if (!viewDenyProperties.includes('COMPANY')) {
          params.ksort.push('companyName');
        }
      } else if (sortModel[i].colId === 'departments') {
        const viewDenyProperties = getPermissionDenyProperties('DEPARTMENT', 'VIEW')
        if (!viewDenyProperties.includes('DEPARTMENT')) {
          params.ksort.push('departmentName');
        }
      } else if (!companyViewDenyProperties.includes(sortModel[i].colId)) {
        params.ksort.push(sortModel[i].colId);
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
    }

    let bookingrule = null;
    const companyrule = parentComponent.companyrule;
    const departmentrule = parentComponent.departmentrule;
    const skillrule = parentComponent.skillrule;
    const locationrule = parentComponent.locationrule;
    const resourcerule = parentComponent.resourcerule;

    if (parentComponent.projectIds.length > 0 && parentComponent.bookings) {
      bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', parentComponent.projectIds.join('|')];  
      if (self.searchFilter != null && self.searchFilter !== '') {
        params.filter = [
          '_or_', [
            ['STAFF.firstName', 'has', self.searchFilter]
            , ['STAFF.lastName', 'has', self.searchFilter]
            , ['STAFF.identifier', 'has', self.searchFilter]
            , ['STAFF.COMPANY.name', 'has', self.searchFilter]
            , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
            , ['STAFF.email', 'has', self.searchFilter]
            , ['STAFF.position', 'has', self.searchFilter]
            , ['STAFF.LOCATION.name', 'has', self.searchFilter]
            , ['STAFF.TAG.name', 'has', self.searchFilter]
            , ['STAFF.SKILL.name', 'has', self.searchFilter]
          ],
          bookingrule
        
        ]; 
      }
      else {
        params.filter = [bookingrule];
      }
    }
    else {
      params.filter = self.searchFilter;
    }
        
    if (departmentrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(departmentrule);
    }
    if (companyrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(companyrule);
    }
    if (skillrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(skillrule);
    }
    if (locationrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(locationrule);
    }
    if (resourcerule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(resourcerule);
    }
    
    if (parentComponent.staffListUuIds) {
      params.holders = parentComponent.staffListUuIds;
    }
    // if (companyrule) {
    //   if (self.searchFilter === '') {
    //     params.filter = [companyrule];
    //   }
    //   else {
    //     params.filter = [
    //       '_or_', [
    //           ['STAFF.firstName', 'has', self.searchFilter]
    //         , ['STAFF.lastName', 'has', self.searchFilter]
    //       ],
    //       companyrule
        
    //     ];
    //   }
    // }
    // else {
    //   params.filter = self.searchFilter;
    // }
    return params;
  }
  return {
    getRows(params) {
      if (parentComponent.lackOfMandatoryField()) {
        parentComponent.showNoRowsOverlay(self, parentComponent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        params.successCallback([], 0);
        return;
      }
      staffService.listv2(buildParams(params, { badgeFilters: self.badgeFilters }), true, parentComponent.customFields
        , {
          skillCustomFields: parentComponent.skillCustomFields
          , resourceCustomFields: parentComponent.resourceCustomFields
        }
      ).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        // if(self.gridOptions.api) {
        //   self.gridOptions.api.deselectAll();
        //   if(self.selected && self.selected.length > 0) {
        //     const selected = cloneDeep(self.selected);
        //     self.gridOptions.api.forEachNode(function(node) {
        //       if (selected.includes(node.data.uuId)) {
        //         node.setSelected(true);
        //         selected.splice(selected.indexOf(node.data.uuId), 1);
        //       }
        //     });
        //   }
        // }
        self.gridOptions.api.hideOverlay();
             
        if (response.arg_total === 0) {
          parentComponent.showNoRowsOverlay(self, null)
        }
      })
      .catch(function(e) {
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          parentComponent.showNoRowsOverlay(self, parentComponent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

function RecommendedDatasource(self, parentComponent) {
  return {
    getRows(params) {
      if (parentComponent.lackOfMandatoryField()) {
        parentComponent.showNoRowsOverlay(self, parentComponent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        parentComponent.successCallback([], 0);
        return;
      }
      
      staffService.staffToTask([{ uuId: parentComponent.taskUuId}], self.staffUuIds, self.settings)
      .then((response) => {
        let data = response.data[response.data.jobCase];
        data = data && data.length > 0? data[0] : [];
        if(data.length > 0) { data = data[0]; }
        data = data.staffAssignmentList || [];

        for (var i = data.length - 1; i >= 0; i--) {
          data[i].duration = `${data[i].duration / (parentComponent.durationConversionOpts.hourPerDay * 60)}D`;
          if (typeof data[i].staffSkillList !== 'undefined') {
            for (var j = 0; j < data[i].staffSkillList.length; j++) {
              if (typeof data[i].skills === 'undefined') {
                data[i].skills = '';
              }
              
              var skills = `${data[i].staffSkillList[j].name} (${data[i].staffSkillList[j].level})`;
              if (data[i].skills.indexOf(skills) === -1) {
                if (data[i].skills !== '') {
                  data[i].skills += ', ';
                }
                data[i].skills += skills;
              }
            }
          }

          if (self.filterText != null && self.filterText !== '') { 
            if (((typeof data[i].staffSkillList !== 'undefined' 
                    && typeof data[i].staffSkillList[0].name !== 'undefined' 
                    && !data[i].staffSkillList[0].name.toLowerCase().includes(self.filterText.toLowerCase()))
                  || typeof data[i].staffSkillList === 'undefined') 
                &&
                 (typeof data[i].firstName !== 'undefined' 
                    && !data[i].firstName.toLowerCase().includes(self.filterText.toLowerCase()) 
                    && typeof data[i].lastName !== 'undefined' 
                    && !data[i].lastName.toLowerCase().includes(self.filterText.toLowerCase()))) {
              data.splice(i, 1);
            }
          }
        }
        
        params.successCallback(data, data.length);
        if (self.gridOptions && self.gridOptions.api) {
          self.gridOptions.api.hideOverlay();
        }     
        if (response.arg_total === 0) {
          self.gridOptions.api.showNoRowsOverlay();
        }
      })
      .catch(e => {
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          parentComponent.showNoRowsOverlay(self, parentComponent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

function sortFunc(a, b) {
  if (a.staff && !b.staff) {
    return 1;
  }
  else if (b.staff && !a.staff) {
    return -1;
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export default {
  name: 'StaffSelectorModalForAdmin'
  // , emits: ['ok', 'cancel']
  , components: {
    'ag-grid-vue': AgGridVue
    , AlertFeedback: () => import('@/components/AlertFeedback')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog')
    , StaffModal: () => import('@/components/modal/StaffModal')
    , TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue')
    , SaveViewModal: () => import('@/components/modal/SaveViewModal.vue')
    , RecommendationSettingsModal: () => import('@/components/modal/RecommendationSettingsModal')
    /* eslint-disable vue/no-unused-components */
    //Renderer
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , colorCellRenderer: ColorCellRenderer
    , rowSelectorCellRenderer: RowSelectorCellRenderer
    , selectorHeaderComponent: SelectorHeaderComponent
    , treeSelectorHeaderComponent: TreeSelectorHeaderComponent
    , enumCellRenderer: EnumCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , dateOnlyCellRenderer: DateOnlyCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , costCellRenderer: CostCellRenderer
    , booleanCellRenderer: BooleanCellRenderer
    //Editor
    , nameEditor: NameEditor
    , stringEditor: StringEditor
    , tagEditor: TagEditor
    , colorEditor: ColorEditor
    , listEditor: ListEditor
    , locationsEditor: LocationsEditor
    , resourcesEditor: ResourcesEditor
    , skillsEditor: SkillsEditor
    , companiesEditor: CompaniesEditor
    , departmentsEditor: DepartmentsEditor
    , phonesEditor: PhonesEditor
    , socialsEditor: SocialsEditor
    , websitesEditor: WebsitesEditor
    , dateTimeEditor: DateTimeEditor
    , costEditor: CostEditor
    , numericEditor: NumericEditor
    , floatNumericEditor: FloatNumericEditor
    , integerNumericEditor: IntegerNumericEditor
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
    , PriorityNavigation
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    //----label----
    , selectorTitle: {
      type: String,
      default: null
    }
    /** Non admin related props **/
    , nonAdmin: {
      type: Boolean,
      default: false
    }
    , hideOkBtn: {
      type: Boolean,
      defualt: false
    }
    // , preselected: {
    //   type: [String, Array],
    //   default: null
    // }
    , singleSelection: {
      type: Boolean,
      default: false
    }
    , companies: {
      type: Array,
      default: () => { return []; }
    }
    , departments: {
      type: Array,
      default: () => { return []; }
    }
    , taskUuId: {
      type: String,
      default: null
    }
    , projectIds: {
      type: Array,
      default: () => []
    }
    , skill: {
      type: Object,
      default: null
    }
    , location: {
      type: Object,
      default: null
    }
    , resource: {
      type: Object,
      default: null
    }
    , hideBookings: {
      type: Boolean,
      default: false
    }
    , staffListUuIds: {
      type: Array,
      default: null
    }
    // Used by AutoAssignStaffModal, preselect staff in list tab only
    , selectedStaff: {
      type: Array,
      default: () => { return []; }
    }
  },
  data: function() {
    return {
      elemId: 'STAFF_SELECTOR_FOR_ADMIN'
      , permissionName: 'STAFF'
      , allowManage: true //always true. It is referenced in  DetailLink.vue

      , alertObj: {
        msg: null
        , msgDetails: { title: null, list: [] }
        , state: alertStateEnum.SUCCESS

      }
      , selectorShow: false
      , searchFilter: ''
      // , searchFilterTerm: ''
      , filterText: ''
      , filterTextFocus: false
      , closePriorityNavDropdown: false
      , activeTab: 0

      , editorShow: false
      , entityId: null
      
      , coloring: {}
      , genericColoring: {}

      , duplicateShow: false
      , duplicateRefId: null
      , duplicateFirstname: null
      , duplicateLastname: null
      , duplicateEmail: null
      , duplicateName: null
      , duplicateInProgress: false
      , isGeneric: false
      
      , docImportGenericShow: false
      , docImportGenericProperties: []
      , docImportShow: false
      , existingStaff: null
      , docImportProperties: []
      , entityMap: {}

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false
      , inProgressState: {
        cancel: false
      }

      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , entitySelection: []
      , orgChartEntitySelection: []

      , pendingListByFillOperation: []
      , triggeredByFillOperation: false
      , processValueChangedList: []
      , processDateValueChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []

      , ecConfirmDeleteShow: false
      , ecConfirmDeleteEntities: []
      , entityCol: {
        entityName: null
        , parentName: null
        , entityId: null
        , parentId: null
        , colId: null
        , applyAll: false
        , tabName: null
      }

      , parentMap: {}
      , treeData: null
      , rowData: null
      , selectedParent: null
      , showInfo: []
      , columnDefs: []

      , orgChart: {
        gridOptions: null
        , columnDefs: []
        , autoGroupColumnDef: null
        , searchFilter: ''
        // , searchFilterTerm: ''
        , filterText: ''
        , filterTextFocus: false
        , closePriorityNavDropdown: false
        , listStaff: true
        , rowData: null
        , disableEdit: true
        , disableDelete: true
        , disableDuplicate: true
        , overlayNoRowsTemplate: this.$t('staff.grid.no_data')

        , noRowsMessage: null
        , noRowsOverlayComponentParams: null
        , lastOpenColumnMenuParams: null

        //BadgeFilter related
        , badgeFilters: [
          // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
        ]
        , badgeFilterFields: [
          // { value: 'tagName', text: 'Tag Name' }
          // , { value: 'type', text: 'Type' }
        ]
        , badgeFilterFieldValues: {
          // taskName: [
          //   { text: 'Task #1' }
          //   , { text: 'Task #2' }
          // ],
        }
        , badgeFilterFocus: false
        , badgeFilterModalOpened: 'close'
        , badgeFilterOptionFetchFunc: null

        , searchPinned: false
        , badgeFilterPinned: false
        , forcePriorityNavRefresh: false

        //User view related
        , views: []
        , viewName: null
        , startDate: null
        , endDate: null
        , dates: 'today'
        , highlightRefresh: false
        , singleContracts: true
        , showInfo: []

        // , nonAdminSelection: []
      }

      , generic: {
        gridOptions: null
        , columnDefs: []
        , searchFilter: ''
        // , searchFilterTerm: ''
        , filterText: ''
        , filterTextFocus: false
        , closePriorityNavDropdown: false
        , totalRecords: 0
        , disableEdit: true
        , disableDelete: true
        , disableDuplicate: true
        , select_state: {
          checked: false
          , indeterminate: false
        }
        , overlayNoRowsTemplate: this.$t('staff.grid.no_data')

        , noRowsMessage: null
        , noRowsOverlayComponentParams: null
        , lastOpenColumnMenuParams: null

        //BadgeFilter related
        , badgeFilters: [
          // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
        ]
        , badgeFilterFields: [
          // { value: 'tagName', text: 'Tag Name' }
          // , { value: 'type', text: 'Type' }
        ]
        , badgeFilterFieldValues: {
          // taskName: [
          //   { text: 'Task #1' }
          //   , { text: 'Task #2' }
          // ],
        }
        , badgeFilterFocus: false
        , badgeFilterModalOpened: 'close'
        , badgeFilterOptionFetchFunc: null

        , searchPinned: false
        , badgeFilterPinned: false
        , forcePriorityNavRefresh: false

        //User view related
        , views: []
        , viewName: null
        , showInfo: []

        // , nonAdminSelection: []
      }

      , recommended: {
        gridOptions: null
        , columnDefs: []
        , filterText: ''
        , filterTextFocus: false
        , closePriorityNavDropdown: false
        , settings: {
          allow_over_alloc: false
          , match_staff_based_on_skills: true
          , include_staff_exact_skill: true
          , include_staff_higher_skill: true
          , adjust_task_duration_higher: false
          , include_staff_lower_skill: true
          , adjust_task_duration_lower: false
        }
        , overlayNoRowsTemplate: this.$t('staff.grid.no_data')
        
        , noRowsMessage: null
        , noRowsOverlayComponentParams: null
        , lastOpenColumnMenuParams: null
        , staffUuIds: []

        , searchPinned: false
        
        // , nonAdminSelection: []
      }

      , singleContracts: true
      , orgChartRealStaff: true
      , orgChartGenericStaff: false

      , overlayNoRowsTemplate: this.$t('staff.grid.no_data')

      , noRowsMessage: null
      , noRowsOverlayComponentParams: null

      , isCellEditing: false
      , lastOpenColumnMenuParams: null

      , optionCurrency: []

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
      ]
      , badgeFilterFields: [
        // { value: 'tagName', text: 'Tag Name' }
        // , { value: 'type', text: 'Type' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
      }
      , badgeFilterFocus: false
      , badgeFilterModalOpened: 'close'
      , badgeFilterOptionFetchFunc: null

      , searchPinned: false
      , badgeFilterPinned: false

      , forcePriorityNavRefresh: false

      //User view related
      , views: []
      , viewName: null
      //User view props shared among tabs
      , promptSaveShow: false
      , promptShareShow: false
      , saveTabName: null
      , saveName: null
      , saveProfile: null
      , saveIndex: -1
      , confirmDeleteViewShow: false
      , deleteViewIndex: -1
      , deleteViewTabName: null

      //Booking related
      , bookings: false

      , customFields: []
      , skillCustomFields: []
      , resourceCustomFields: []

      , startDate: null
      , endDate: null
      , dates: 'today'
      , highlightRefresh: false

      , recommendationSettingsShow: false
      // , nonAdminSelection: []

      //nonAdmin mode
      , deletedIds: []
      , deleteInProgressIds: []
      , preselected: []
      , durationConversionOpts: {}
    }
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId
    
    const self = this
    this.orgChart.autoGroupColumnDef = {
      headerName: self.$t('staff.department_and_staff')
      , field: 'uuId'
      , pinned: 'left'
      , minWidth: 150
      , width: 200
      , menuTabs: ['columnsMenuTab']
      , editable: false //the value is a combined string from firstName and lastName. Decision: Read-only.
      , cellEditor: 'nameEditor'
      , valueSetter: function(params) {
        const newValue = params.newValue.trim()
        const oldValue = objectClone(params.data.name)
        if (newValue !== '' && newValue != oldValue) {
          self.$set(params.data, 'oldName', oldValue)
          params.data.name = newValue
          return true
        }
        return false
      }
      , cellRendererParams: {
        suppressCount: true,
        label: 'name',
        tabName: 'orgChart',
        decorateCompany: true,
        innerRenderer: 'detailLinkCellRenderer',
        enableReadonlyStyle: true,
        checkbox: params => { 
          return self.nonAdmin 
            && typeof params.data !== 'undefined' 
            && typeof params.data.staff !== 'undefined'
        }
      }
      , comparator: getComparator('name')
    }
    
    const getGridOptions = (tab) =>{
      const gridTarget = tab != null && tab.name != 'list'? self[tab.name] : self;

      const returnObj = {
        onColumnVisible: function(params) {
          let fromToolPanel = params.source == "toolPanelUi"
          if (fromToolPanel) {
            let colKey = params.column.colId;
            let columnMenuColumnIndex = params.columnApi
              .getAllGridColumns()
              .findIndex(col => {
                return col === gridTarget.lastOpenColumnMenuParams.column;
              });

            params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
          }
          const cols = params.columnApi.getAllGridColumns().map(i => { 
            return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
          const columnState =  params.columnApi.getColumnState();
          //get the actual hide value from columnState
          for (const col of columnState) {
            const found = cols.find(i => i.colId == col.colId)
            if (found) {
              found.hide = col.hide;
            }
          }
          cols.sort(columnDefSortFunc)
          for (const [index,c] of cols.entries()) {
            params.columnApi.moveColumn(c.colId, index);
          }
          // const columns = params.columnApi.getAllDisplayedColumns()
          // self.saveSelectorColumnSettings(columns, tab)

          //Skip saving column setting to profile when this event is triggered by loading user view / loading view profile via init()
          //Reason: Unnecessary saving column setting will remove the existing viewName value in the view profile.
          if (gridTarget.skipViewNameReset == true) {
            gridTarget.skipViewNameReset = false;
          } else if (!self.ignoreColumnChanged) {
            const columns = params.columnApi.getAllDisplayedColumns()
            self.saveSelectorColumnSettings(columns, tab, false)
          }
        }
        , postProcessPopup: params => {
          if ((params.type == 'columnMenu')) {
            gridTarget.lastOpenColumnMenuParams = params;
          }
        }
        , onSortChanged: function(event) {
          //Clear last focused cell. Reset vertical scroll position to top. Clear range selection
          self.lastFocusedCell = null
          event.api.clearRangeSelection()
          if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
            event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
          }
          //Update column setting
          const columns = event.columnApi.getAllDisplayedColumns()
          self.saveSelectorColumnSettings(columns, tab, false)
        }
        , onDragStopped: function(event) {
          const columns = event.columnApi.getAllDisplayedColumns()
          self.saveSelectorColumnSettings(columns, tab, false)
        }
        , onGridSizeChanged: function(event) {
          if (tab.name == 'orgChart') {
            const width = event.api.gridBodyCtrl && event.api.gridBodyCtrl.eBodyViewport? event.api.gridBodyCtrl.eBodyViewport.clientWidth : 0
            self.orgChart.autoGroupColumnDef.pinned = width < 800 ? '' : 'left'
            event.api.setAutoGroupColumnDef(self.orgChart.autoGroupColumnDef) 
          }
          
        }
        , onFirstDataRendered: function(event) {
          //Call sizeColumnsToFit on visible tab only
          if (self.tabList[self.activeTab].name === tab.name) {
            self.sizeColumnToFitWhenNewToProfile(tab, event.api, event.columnApi)
          }
        }
        , onCellValueChanged: function(event) {
          let colId = event.column.colId
          const rowIndex = event.rowIndex
          let newValue = event.newValue
          let oldValue = event.oldValue
          const rowNode = event.api.getDisplayedRowAtIndex(rowIndex)

          if (event.column.colId == self.COLUMN_AGGRID_AUTOCOLUMN && tab.name == 'orgChart') {
            colId = 'name'
            newValue = rowNode.data.name
            oldValue = rowNode.data.oldName //oldName is added in autoGroupColumnDef.valueSetter.
          }

          if (event.column.colId == 'uuId') {
            if (tab.name == 'generic') {
              colId = 'firstName'
              newValue = rowNode.data.firstName
            } else {
              colId = 'lastName'
              newValue = rowNode.data.lastName
            }
            oldValue = rowNode.data.oldName //oldName is added in columndef.valueSetter.
          }

          const payload = {
            colId
            , data: objectClone(rowNode.data)
            , newValue
            , oldValue
            , property: colId
            , entityId: rowNode.id
            , parentId: rowNode.data.pUuId
            , entityName: rowNode.data.name
            , color: event.colDef.color //Default Color. Used when creating new entity.
          }
          
          if (colId === self.COLUMN_AGGRID_AUTOCOLUMN || payload.newValue == payload.oldValue) {
            //do nothing
          } else if (self.triggeredByFillOperation) {
            self.pendingListByFillOperation.push(payload)
          } else if (self.isPasteInProgress) {
            self.processValueChangedList.push(payload)
          } else {
            self.processValueChangedList.push(payload)
            self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
            self.processValueChanged(event.api)
          }
        }
        , onRangeSelectionChanged: function(event) {
          if (event.finished == true) {
            if (self.triggeredByFillOperation) {
              const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length)
              self.processValueChangedList.push(...pendingList)
              // self.consolidateChangedCellValues(self.processValueChangedList)
              self.triggeredByFillOperation = false
              self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
              self.processValueChanged(event.api)
            }
            const cellRanges = event.api.getCellRanges()
            const originalRanges = cellRanges.map(i => {
              return {
                rowStartIndex: i.startRow.rowIndex,
                rowEndIndex: i.endRow.rowIndex,
                columns: i.columns.map(j => j.colId)
              }
            })
            if (cellRanges.length > 0) {
              const lastRange = (() => {
                  const tmp = cellRanges[cellRanges.length-1]
                  const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
                  return {
                    rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                    rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                    columns: tmp.columns.map(i => i.colId),
                    startColumn: tmp.startColumn.colId,
                    orgRowStartIndex: tmp.startRow.rowIndex,
                    orgRowEndIndex: tmp.endRow.rowIndex
                  }
                })()
              const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0] 
              //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
              //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
              if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
                self.rowSelectorChanged({
                  cellRanges,
                  originalRanges,
                  lastRange,
                  api: event.api, 
                  columnApi: event.columnApi,
                  mainColId: self.tabList[self.activeTab].colId
                })
              } else {
                //Brief: Reshape previous ranges to avoid clashing with last/latest range.
                const lastRangeColumns = lastRange.columns
                const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex
                const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex
                const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex

                const cRanges = cellRanges.map(i => {
                  return {
                    rowStartIndex: i.startRow.rowIndex,
                    rowEndIndex: i.endRow.rowIndex,
                    columns: i.columns.map(j => j.colId)
                  }
                })
                cRanges.splice(cRanges.length - 1, 1)
                const newCellRanges = []
                let hasChanges = false

                //Before reshape, check if last range's start cell clashes with previous cell range.
                //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
                const startColumnId = lastRange.startColumn
                const startCell = {
                  rowStartIndex: lastRange.orgRowStartIndex,
                  rowEndIndex: lastRange.orgRowStartIndex
                }
                let isStartCellClashed = false
                for (const range of cRanges) {
                  const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                  const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                  const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                  const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
                  const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
                  if (isRowClashed && columnClashed) {
                    isStartCellClashed = true
                    break
                  }
                }
                if (isStartCellClashed) {
                  return
                }

                //Reshape previous ranges to avoid clashing with last/latest range.
                for (const range of cRanges) {
                  const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                  const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                  const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                  const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                      (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex)
                  const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i))                
                  if (!isRowClashed || clashedColumns.length == 0) {
                    newCellRanges.push(range)
                    continue
                  }
                  
                  //Reshape cell range if it clash with lastRange.
                  const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex
                  const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex
                  const firstClashedCol = clashedColumns[0]
                  const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0
                  const lastClashedCol = clashedColumns[clashedColumns.length-1]
                  const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1

                  if (splitTopNeeded) {
                    newCellRanges.push({
                      rowStartIndex: cellRangeStartIndex,
                      rowEndIndex: lastRangeStartIndex-1,
                      columns: objectClone(range.columns)
                    })
                    hasChanges = true
                  }

                  if (splitRightNeeded) {
                    const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1
                    newCellRanges.push({
                      rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                      rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                      columns: range.columns.slice(startColIdx)
                    })
                    hasChanges = true
                  }

                  if (splitBottomNeeded) {
                    newCellRanges.push({
                      rowStartIndex: lastRangeEndIndex+1,
                      rowEndIndex: cellRangeEndIndex,
                      columns: objectClone(range.columns)
                    })
                    hasChanges = true
                  }

                  if (splitLeftNeeded) {
                    const sliceLen = range.columns.findIndex(i => i == firstClashedCol)
                    newCellRanges.push({
                      rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                      rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                      columns: range.columns.slice(0, sliceLen)
                    })
                    hasChanges = true
                  }
                }
                
                //Merge last range to any existing range when condition met.
                //Conditions: 1) Matched rows and column(s) in sequence, or 
                //            2) Matched columns and row(s) in sequence.
                let hasRangeMerged = false
                if (newCellRanges.length > 0) {
                  const allColumns = event.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
                  const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0])
                  const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1])
                  const cloned = objectClone(newCellRanges)
                  const newRanges = []
                  for (const cRange of cloned) {
                    const startColIndex = allColumns.findIndex(i => i == cRange.columns[0])
                    const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1])
                    const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex
                    const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex
                    const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex
                    const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex
                    if (isRowIndexMatched && isColumnInSequence) {
                      newRanges.push({
                        rowStartIndex: lastRangeStartIndex
                        , rowEndIndex: lastRangeEndIndex
                        , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                      })
                      hasRangeMerged = true
                      continue
                    } else if (isColumnMatched && isRowInSequence) {
                      newRanges.push({
                        rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                        , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                        , columns: lastRangeColumns
                      })
                      hasRangeMerged = true
                      continue
                    }
                    newRanges.push(cRange)
                  }
                  if (hasRangeMerged) {
                    newCellRanges.splice(0, newCellRanges.length, ...newRanges)
                  }
                }
                
                //hasChanges flag is important to avoid infinite loop. 
                //any addCellRange() call will trigger onRangeSelectionChange() event.
                //Don't call addCellRange() when no change is required.
                if (hasChanges || hasRangeMerged) {
                  //Adding last range when hasRangeMerged is false. 
                  //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                  if (!hasRangeMerged) {
                    newCellRanges.push({
                      rowStartIndex: lastRange.rowStartIndex,
                      rowEndIndex: lastRange.rowEndIndex,
                      columns: lastRange.columns
                    })
                  }
                  event.api.clearRangeSelection()
                  for (const ncRange of newCellRanges) {
                    event.api.addCellRange(ncRange)
                  }
                } else {
                  //When thing settles down, update entitySelection variable.
                  let selectedEntities = []
                  for (const oRange of originalRanges) {
                    const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex
                    const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex
                    
                    for (let i = startRowIdx; i <= endRowIdx; i++) {
                      const rowNode = event.api.getDisplayedRowAtIndex(i)
                      
                      if (rowNode == null || rowNode.data == null) {
                        continue
                      }
                      
                      //Treat any cell selection is main column Id (e.g. ag-Grid-AutoColumn for orgChart) cell selection.
                      selectedEntities.push({ 
                          uuId: rowNode.data.uuId
                        , name: rowNode.data.name
                        , parent: rowNode.data.pUuId
                        , parentName: rowNode.data.pName
                        , colId: self.tabList[self.activeTab].colId
                        , rowIndex: rowNode.rowIndex
                      })
                    }
                  }
                  
                  //Rearrange order of the tasks.
                  //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                  //The first task/summary task will be target parent for new task creation.
                  selectedEntities.sort(function( a, b ) {
                    if (a.rowIndex == null && b.rowIndex == null) {
                      return 0
                    }
                    if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                      return -1
                    }
                    if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                      return 1
                    }
                    return 0
                  })
                  self.entitySelection.splice(0, self.entitySelection.length, ...selectedEntities)

                  const ocEntitySelection = []
                  for(const entity of self.entitySelection) {
                    const rowNode = event.api.getDisplayedRowAtIndex(entity.rowIndex)
                    if (rowNode != null && rowNode.data != null && rowNode.data.staff == true) {
                      ocEntitySelection.push(entity)
                    }
                  }
                  self.orgChartEntitySelection.splice(0, self.orgChartEntitySelection.length, ...ocEntitySelection)
                }
              }
            } else {
              //Clean up entitySelection when range selection is empty.
              self.entitySelection.splice(0, self.entitySelection.length)
              self.orgChartEntitySelection.splice(0, self.orgChartEntitySelection.length)
            }
            event.api.refreshHeader()
          }
          
        }
        , getDataPath: function(data) {
          if (typeof data !== 'undefined') {
            const path = data.path.split(', ')
            return path
          }
          return [];
        }
        //For non-admin
        , onSelectionChanged: function(event) {
          self.entitySelection.splice(0, self.entitySelection.length, ...(event.api.getSelectedNodes().map(i => i.data)));
        }
      }

      if (tab.name == 'orgChart') {
        returnObj.isExternalFilterPresent = function() {
          return Object.hasOwn(gridTarget, 'searchData');
        }
        returnObj.doesExternalFilterPass = function(node) {
          if (node.data) {
            if (!gridTarget.searchData) {
              return true;
            }
            return gridTarget.searchData.find(d => d.uuId === node.data.uuId) != null ? true : false;
          }
          return false;
        }
      }

      return returnObj;
    }
    this.gridOptions = getGridOptions(this.tabList.find(i=> i.name == 'list'))
    this.cacheBlockSize = 1000;
    this.context = { componentParent: self }
    this.generic.gridOptions = getGridOptions(this.tabList.find(i=> i.name == 'generic'))
    this.generic.context = { componentParent: self }

    this.orgChart.gridOptions = getGridOptions(this.tabList.find(i=> i.name == 'orgChart'))
    this.orgChart.context = { componentParent: self }

    if (this.nonAdmin && this.taskUuId != null) {
      this.recommended.gridOptions = getGridOptions(this.tabList.find(i => i.name == 'recommended'))
      this.recommended.context = { componentParent: self }
    }

    const getDefaultColDef = ({ sortable=false } = {}) => {
      return {
        sortable
        , resizable: true
        , minWidth: 100
        , hide: true
        , lockPinned: true
        , menuTabs: ['columnsMenuTab']
        , suppressKeyboardEvent: (params) => {
          if (params.event.keyCode === 46 
              || params.event.keyCode === 35 || params.event.keyCode == 36 
              || (params.event.keyCode == 37 && params.event.ctrlKey)) {
            return true
          } else if (params.event.keyCode == 13) {
            if (params.event.ctrlKey) {
              return true
            }
            if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
                (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
              return true
            }
          } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
            return true
          }
          return false
        }
        , cellRendererParams: { enableReadonlyStyle: true }
      }
    }
    this.defaultColDef = getDefaultColDef({ sortable: true })
    this.recommended.defaultColDef = getDefaultColDef({ sortable: true })
    this.orgChart.defaultColDef = getDefaultColDef({ sortable: true })
    this.generic.defaultColDef = getDefaultColDef({ sortable: true })
  },
  mounted() {
    if (this.show) {
      this.init()  
    }
    
    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus)
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  created() {
    this.entity = 'STAFF'
    this.formattedEntity = 'staff'
    this.profileKeyColoring = 'staff_selector_coloring'
    this.profileKeyGenericColoring = 'staff_selector_generic_coloring'
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn'
    this.cellCopyPrefix = `${this.entity}${CELL_COPY_CODE}`
    this.dataGroup = {
      stringGroup: ['name', 'identifier', 'firstName', 'lastName'],
      dateGroup: ['startDate', 'endDate']
    }
    this.modelInfo = null
    this.getDurationConversionOpts();
    this.typeOptions = []
    this.payFrequencyOptions = []
    this.profileSettings = null
    this.exportData = false
    this.lastFocusedCell = null
    this.isPasteInProgress = false
    this.queryMandatoryFields = ['uuId', 'firstName', 'lastName', 'genericStaff']
    this.queryMandatoryFields_orgChart = ['uuId', 'firstName', 'lastName', 'genericStaff', 'DEPARTMENT.uuId']

    this.mandatoryFields = ['email', 'lastName', 'locations', 'staffType', 'companies']
    this.mandatoryFieldsForGeneric = ['firstName', 'locations', 'staffType']

    this.recommended_settings_template = JSON.parse(JSON.stringify(this.recommended.settings));

    this.isInitCompleted = false;
    this.ignoreColumnChanged = true;
    this.isGridReady = false;
    this.isOrgChartGridReady = false;
    this.isGenericGridReady = false;
    this.iSRecommendedReady = false;
    this.isLayoutColumnDefReady = false;
    
    this.tabList = [
      { 
        self: this
        , name: 'list'
        , colId: 'uuId'
        , profileKey: `staff${this.nonAdmin? '':'_admin'}_selector_list`
        , profileKeySearch: `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_search`
        , profileKeyBadgeFilters: `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_badgeFilters`
        , profileKeyView: `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_view`
        , filterRefId: 'filter'
        , badgeFilterRefId: 'badgeFilter'
        , profileKeySearchPinned: `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_searchPinned`
        , profileKeyBadgeFilterPinned: `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`
      }
    , { 
        self: this.orgChart
        , name: 'orgChart'
        , colId: this.COLUMN_AGGRID_AUTOCOLUMN
        , profileKey: `staff${this.nonAdmin? '':'_admin'}_selector_orgChart`
        , profileKeySearch: `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_search`
        , profileKeyBadgeFilters: `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_badgeFilters`
        , profileKeyView: `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_view`
        , filterRefId: 'filter_orgChart'
        , badgeFilterRefId: 'badgeFilter_orgChart'
        , profileKeySearchPinned: `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_searchPinned`
        , profileKeyBadgeFilterPinned: `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`
      }
    , { 
        self: this.generic
        , name: 'generic'
        , colId: 'uuId'
        , profileKey: `staff${this.nonAdmin? '':'_admin'}_selector_generic`
        , profileKeySearch: `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_search`
        , profileKeyBadgeFilters: `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_badgeFilters`
        , profileKeyView: `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_view` 
        , filterRefId: 'filter_generic'
        , badgeFilterRefId: 'badgeFilter_generic'
        , profileKeySearchPinned: `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_searchPinned`
        , profileKeyBadgeFilterPinned: `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`
      }
    ]
    if (this.nonAdmin && this.taskUuId != null) {
      this.tabList.splice(1, 0, { 
        self: this.recommended
        , name: 'recommended'
        , colId: 'uuId'
        , profileKey: `staff${this.nonAdmin? '':'_admin'}_selector_recommended`
        , profileKeySearch: `${this.formattedEntity}_recommended${this.nonAdmin? '':'_admin'}_selector_search`
        , filterRefId: 'filter_recommended'
        , profileKeySearchPinned: `${this.formattedEntity}_recommended${this.nonAdmin? '':'_admin'}_selector_searchPinned`
      });
    }


    this.toggleSelectorShow(this.show)
    document.addEventListener('keydown', this.keyDownHandler)

    Object.keys(colorOptions).forEach(key => this.coloring[key] = colorOptions[key])
    Object.keys(genericColorOptions).forEach(key => this.genericColoring[key] = genericColorOptions[key])
    
    this.docImportProperties.splice(0, this.docImportProperties.length, ...[
      { value: 'color', text: this.$t('field.color') }
      , { value: 'company', text: this.$t('staff.field.company') }
      , { value: 'department', text: this.$t('staff.field.department') }
      , { value: 'email', text: this.$t('staff.field.email') }
      , { value: 'enddate', text: this.$t('staff.field.endDate') }
      , { value: 'firstname', text: this.$t('staff.field.firstName') }
      , { value: 'identifier', text: this.$t('field.identifier') }
      , { value: 'name', text: this.$t('staff.field.lastName') }
      , { value: 'location', text: this.$t('staff.field.location') }
      , { value: 'notes', text: this.$t('staff.field.notes') }
      , { value: 'payamount', text: this.$t('staff.field.payAmount') }
      , { value: 'paycurrency', text: this.$t('staff.field.payCurrency') }
      , { value: 'payfrequency', text: this.$t('staff.field.payFrequency') }
      , { value: 'phone', text: this.$t('staff.field.phones') }
      , { value: 'position', text: this.$t('staff.field.position') }
      , { value: 'resources', text: this.$t('staff.field.resources') }
      , { value: 'skills', text: this.$t('staff.field.skills') }
      , { value: 'socials', text: this.$t('staff.field.socials') }
      , { value: 'startdate', text: this.$t('staff.field.startDate') }
      , { value: 'tag', text: this.$t('field.tag') }
      , { value: 'type', text: this.$t('staff.field.type') }
      , { value: 'websites', text: this.$t('staff.field.websites') }
    ])

    this.docImportGenericProperties.splice(0, this.docImportGenericProperties.length, ...[
      { value: 'color', text: this.$t('field.color') }
      , { value: 'department', text: this.$t('staff.field.department') }
      , { value: 'identifier', text: this.$t('field.identifier') }
      , { value: 'location', text: this.$t('staff.field.location') }
      , { value: 'name', text: this.$t('staff.field.name') }
      , { value: 'payamount', text: this.$t('staff.field.payAmount') }
      , { value: 'paycurrency', text: this.$t('staff.field.payCurrency') }
      , { value: 'payfrequency', text: this.$t('staff.field.payFrequency') }
      , { value: 'skills', text: this.$t('staff.field.skills') }
      , { value: 'type', text: this.$t('staff.field.type') }
      , { value: 'resources', text: this.$t('staff.field.resources') }
      , { value: 'tag', text: this.$t('field.tag') }
    ])

    this.orgChart.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this.orgChart)
    }

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this)
    }

    this.generic.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this.generic)
    }
  },
  beforeDestroy() {
    this.isPasteInProgress = false
    this.cellCopyPrefix = null
    this.lastFocusedCell = null
    this.exportData = false
    this.userId = null
    this.profileSettings = null
    this.profileKeyColoring = null
    this.COLUMN_AGGRID_AUTOCOLUMN = null
    this.autoGroupColumnDef = null
    this.modelInfo = null
    this.dataGroup = null
    this.entity = null
    this.formattedEntity = null
    this.typeOptions = null
    this.payFrequencyOptions = null
    this.queryMandatoryFields = null
    this.mandatoryFields = null
    this.mandatoryFieldsForGeneric = null
    this.tabList = null
    this.context = null
    document.removeEventListener('keydown', this.keyDownHandler)
    this.orgChart.searchData = null
    
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';
    this.orgChart.badgeFilterFocus = false;
    this.orgChart.badgeFilterModalOpened = 'close';
    this.generic.badgeFilterFocus = false;
    this.generic.badgeFilterModalOpened = 'close';
    this.isInitCompleted = false;
    this.isGridReady = false;
    this.isOrgChartGridReady = false;
    this.isGenericGridReady = false;
    this.iSRecommendedReady = false;
    this.isLayoutColumnDefReady = false;
  },
  watch: {
    async show(newValue) {
      //Reset value
      this.resetAlert()
      this.entityId = null
      this.searchFilter = ''
      this.orgChart.searchData = null
      this.entitySelection.splice(0, this.entitySelection.length)
      this.rangeSelection.splice(0, this.rangeSelection.length)
      this.lastFocusedCell = null
      this.isPasteInProgress = false
      this.activeTab = 0
      this.exportData = false
      this.isCellEditing = false
      this.skipViewNameReset = false
      this.orgChart.skipViewNameReset = false
      this.generic.skipViewNameReset = false
      this.deletedIds = []
      const requests = [
        this.getModelInfo(this.entity).catch(e => this.httpAjaxError(e))
        , getCustomFieldInfo(this, this.entity).catch(e => this.httpAjaxError(e))
        , getCustomFieldInfo(this, 'SKILL_LINK', null, { customFieldsPropName: 'skillCustomFields' }).catch(e => this.httpAjaxError(e))
        , getCustomFieldInfo(this, 'RESOURCE_LINK', null, { customFieldsPropName: 'resourceCustomFields' }).catch(e => this.httpAjaxError(e))
      ];

      await Promise.allSettled(requests);
      
      // make sure the custom fields are selectable for import
      if (this.customFields) {
        for (const cfield of this.customFields) {
          const found = this.docImportProperties.find(v => v.value === cfield.name);
          if (!found) {
            this.docImportProperties.push({ value: cfield.name, text: cfield.displayName });
          }
        }
      }
      
      if(newValue) {
        this.init()
      }
      this.toggleSelectorShow(newValue)
    },
  },
  computed: {     
    
    dateOptions() {
      const options = [
        { text: this.$t('view_dates.custom'),  value: null},
        { text: this.$t('view_dates.today'),  value: 'today'},
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: this.$t('view_dates.this_week'), value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: this.$t('view_dates.this_week_to_date'), value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: this.$t('view_dates.this_month'), value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_month_to_date'), value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: this.$t('view_dates.this_quarter'), value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_quarter_to_date'), value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: this.$t('view_dates.this_year'), value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: this.$t('view_dates.this_year_to_date'), value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_week'), value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_week_to_date'), value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_month'), value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_month_to_date'), value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: this.$t('view_dates.last_quarter'), value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: this.$t('view_dates.last_quarter_to_date'), value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: this.$t('view_dates.last_year'), value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: this.$t('view_dates.next_week'), value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_4_weeks'), value: "next-4-weeks" },
        // - means next 8 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_8_weeks'), value: "next-8-weeks" },
        // - means next 12 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_12_weeks'), value: "next-12-weeks" },
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_24_weeks'), value: "next-24-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: this.$t('view_dates.next_month'), value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: this.$t('view_dates.next_quarter'), value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: this.$t('view_dates.next_year'), value: "next-year" } ,
        // - means all staff no dates
        { text: this.$t('view_dates.all_time'), value: "all-time" }
      ]

      return options;
    },   
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    listTabActive() {
      return this.tabList[this.activeTab].name == 'list'
    }
    , recommendedTabActive() {
      return this.tabList[this.activeTab].name == 'recommended'
    }
    , orgChartTabActive() {
      return this.tabList[this.activeTab].name == 'orgChart'
    }
    , genericTabActive() {
      return this.tabList[this.activeTab].name == 'generic'
    }
    , masterCompany() {
      if (this.treeData === null) {
        return null;
      }
      
      const master = this.treeData.filter(c => c.type === 'Primary');
      return master.length > 0 ? master[0] : null;
    }
    , editorTitleLabel() {
      if (this.entityId && this.entityId.indexOf('NEW') > -1) {
        return this.$t(`entity_selector.${this.formattedEntity}_new`)
      }
      if (this.canEdit(this.entity)) {
        return this.$t(`entity_selector.${this.formattedEntity}_edit`)
      }
      return this.$t(`entity_selector.${this.formattedEntity}_view`)
    }
    , selectorTitleLabel() {
      let entity = this.entity != null? this.entity.toLowerCase() : ''
      let tab = this.activeTab == -1 || this.activeTab >= this.tabList.length || this.tabList[this.activeTab] == null? this.tabList[0] : this.tabList[this.activeTab];
      let viewNamePrefix = tab.self?.viewName != null? ' - '+ tab.self.viewName : ''
      return this.selectorTitle != null? this.selectorTitle + viewNamePrefix : this.$t(`entity_selector.${entity}_selector`) + viewNamePrefix
      // return this.selectorTitle != null? this.selectorTitle : this.$t(`entity_selector.${this.formattedEntity}_selector`)
    }
    , duplicateTitleLabel() {
      return this.duplicateTitle != null? this.duplicateTitle : this.$t(`entity_selector.${this.formattedEntity}_duplicate`)
    }
    , duplicateFieldNameLabel() {
      return this.$t(`entity_selector.${this.formattedEntity}_duplicate_field_name`)
    }
    , showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name')
    }
    , showDuplicateEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateEmail');
    }
    , showDuplicateLastnameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateLastname');
    }
    , colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter'
    }
    , disableEdit() {
      return this.entitySelection.length != 1
    }
    , disableDuplicate() {
      return this.entitySelection.length != 1
    }
    , disableDelete() {
      return this.entitySelection.length < 1
    }
    , disableOrgChartEdit() {
      return this.orgChartEntitySelection.length != 1
    }
    , disableOrgChartDuplicate() {
      return this.orgChartEntitySelection.length != 1
    }
    , disableOrgChartDelete() {
      return this.orgChartEntitySelection.length < 1
    }
    , ecShowApplyAllCheckbox() {
      return this.ecConfirmDeleteEntities.length > 0
    }
    , ecConfirmDeleteStatement() {
      if (this.formattedEntity) {
        return this.$t(`entity_selector.${this.formattedEntity}_entitycol_delete`, [this.entityCol.entityName])
      } else {
        return ''
      }
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('staff.grid.loading_list') }</span>`;
    }
    // , companyrule() {
    //   if (this.$store.state.company && 
    //       this.$store.state.company.type !== 'Primary' &&
    //       this.$store.state.company.filterIds) {
    //     const companies = this.$store.state.company.filterIds
    //     const companyrule = ['STAFF.COMPANY.uuId', 'within', companies.join('|')]
    //     return companyrule
    //   }
    //   return null
    // }
    , companyFilterIds() {
      if (this.$store.state.company && 
          this.$store.state.company.filterIds) {
        return this.$store.state.company.filterIds;
      }
      return this.$store.state.company?.uuId != null? [this.$store.state.company?.uuId] : [];
    }
    , minQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].min : 1;
    }
    , maxQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].max : 100000;
    }
    , filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , badgeFilterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , badgeFilterMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    }
    , badgeFilterMouseLeaveEvent() {
      return this.isTouchDevice()? null : 'mouseleave';
    }
    , badgeFilterTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    }
    , priorityNavMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    }
    , priorityNavTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    }
    , viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , filteredViews() {
      return this.views.filter(i => i.type == `${this.entity}_admin_selector_list`);
    }
    , filteredOrgChartViews() {
      return this.views.filter(i => i.type == `${this.entity}_admin_selector_orgChart`);
    }
    , filteredGenericViews() {
      return this.views.filter(i => i.type == `${this.entity}_admin_selector_generic`);
    }
    // , companyrule() {
    //   if (this.$store.state.company && 
    //       this.$store.state.company.type !== 'Primary' &&
    //       this.$store.state.company.filterIds) {
    //     const companies = this.$store.state.company.filterIds
    //     const companyrule = ['STAFF.COMPANY.uuId', 'within', companies.join('|')]
    //     return companyrule
    //   }
    //   return null
    // }
    , companyrule() {
      if (this.companies.length !== 0 || (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds)) {
        const companies = this.companies.length > 0 ? this.companies.map(c => { return c.uuId }) : this.$store.state.company.filterIds;
        const companyrule = ['STAFF.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    }
    , departmentrule() {
      if (this.departments.length !== 0) {
        const departments = this.departments.map(d => { return d.uuId });
        const departmentrule = ['STAFF.DEPARTMENT.uuId', 'within', departments.join('|')];
        return departmentrule
      }
      return null;
    }
    , skillrule() {
      if (this.skill) {
        const skill = this.skill.uuId;
        const skillrule = ['STAFF.SKILL.uuId', 'eq', skill];
        return skillrule
      }
      return null;
    }
    , locationrule() {
      if (this.location) {
        const location = this.location.uuId;
        const locationrule = ['STAFF.LOCATION.uuId', 'eq', location];
        return locationrule
      }
      return null;
    }
    , resourcerule() {
      if (this.resource) {
        const resource = this.resource.uuId;
        const resourcerule = ['STAFF.RESOURCE.uuId', 'eq', resource];
        return resourcerule
      }
      return null;
    }
    , disableOk() {
      return this.entitySelection.length == 0
    }
  },
  methods: {
    profileKeySelector() {
      if (this.activeTab == -1 || this.activeTab >= this.tabList.length || this.tabList[this.activeTab] == null) {
        return this.tabList[0].profileKey
      } 
      return this.tabList[this.activeTab].profileKey
    }
    , async init() {
      this.isInitCompleted = false
      this.ignoreColumnChanged = true; //set true to ignore columnChanged triggered by default columnDef setup, will be set to false when loading ViewProfile()
      await this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
      this.columnDefs = this.getRowColumnDefs(false)
      this.generic.columnDefs = this.getRowColumnDefs(true)
      this.orgChart.columnDefs = this.getOrgChartColDefs()
      this.recommended.columnDefs = this.getRecommendedColumnDefs()

      if (this.nonAdmin) {
        //Show recommended tab when nonAdmin and taskUuId is not null
        if (this.taskUuId != null) {
          const foundIndex = this.tabList.findIndex(i => i.name == 'recommended');
          if (foundIndex > -1) {
            this.activeTab = foundIndex;
          }
        } else if (this.selectedStaff.length > 0) {
          //list tab is active by default
          //setup preselected staff. It will be used in list grid's getRows()
          this.preselected.splice(0, this.preselected.length, ...this.selectedStaff);
        }
      }

      await this.loadViewProfile()
      this.datesChanged(this);
      this.datesChanged(this.orgChart);
      
      this.loadUserProfile()
      this.loadPublicProfile()

      //badgeFilter
      //list
      this.badgeFilterFields = this.getBadgeFilterFields('list')
      this.badgeFilterOptionFetchFunc = this.getBadgeFilterOptionFetchFunc('list')

      //orgChart
      this.orgChart.badgeFilterFields = this.getBadgeFilterFields('orgChart')
      this.orgChart.badgeFilterOptionFetchFunc = this.getBadgeFilterOptionFetchFunc('orgChart')

      //generic
      this.generic.badgeFilterFields = this.getBadgeFilterFields('generic')
      this.generic.badgeFilterOptionFetchFunc = this.getBadgeFilterOptionFetchFunc('generic')

      
      if (this.isGridReady == true && this.gridOptions?.api != null) {
        this.gridOptions.api.setServerSideDatasource(new ServerSideDatasource(this))
      }
      if (this.isOrgChartGridReady == true) {
        this.reloadOrgChart()
      }
      if (this.isGenericGridReady == true && this.generic?.gridOptions?.api != null) {
        this.generic.gridOptions.api.setServerSideDatasource(new GenericDatasource(this.generic, this))
      }
      if (this.iSRecommendedReady == true && this.recommended?.gridOptions?.api != null) {
        this.recommended.gridOptions.api.setServerSideDatasource(new RecommendedDatasource(this.recommended, this));
      }
      this.isInitCompleted = true
    }
    , onGridReady(params) {
      if (this.isInitCompleted == true) {
        if (this.isLayoutColumnDefReady == true) {
          params.api.setColumnDefs(this.columnDefs)
        }
        params.api.setServerSideDatasource(new ServerSideDatasource(this))
      }
      this.isGridReady = true
    }
    , onOrgChartGridReady(params) {
      if (this.isInitCompleted == true) {
        if (this.isLayoutColumnDefReady == true) {
          params.api.setColumnDefs(this.orgChart.columnDefs)
        }
        this.reloadOrgChart()
      }
      this.isOrgChartGridReady = true
    }
    , onGenericGridReady(params) {
      if (this.isInitCompleted == true) {
        if (this.isLayoutColumnDefReady == true) {
          params.api.setColumnDefs(this.generic.columnDefs)  
        }
        params.api.setServerSideDatasource(new GenericDatasource(this.generic, this))
      }
      this.isGenericGridReady = true
    },
    async onRecommendedGridReady(params) {
      
      await this.prepareStaffList()
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.showNoRowsOverlay(this.recommended, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
      this.userId = this.$store.state.authentication.user.uuId;
      recommendationProfileService.list(this.userId).then((response) => {  
        const profileData = response.data[response.data.jobCase];
        if (profileData.length === 0) {
          this.recommended.settings = {};
        }
        else {
          this.recommended.settings = profileData[0];
          this.rectifyInvalidRecommendedSettings();
        }
        params.api.setServerSideDatasource(new RecommendedDatasource(this.recommended, this));
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.showNoRowsOverlay(this.recommended, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        } else {
          console.error(e); // eslint-disable-line no-console
        }
      });
    }
    , async prepareStaffList() {
      const data = { start: 0, limit: -1 }
      if (this.profileSettings == null || Object.keys(this.profileSettings).length === 0) {
        await this.loadViewProfile(); // make sure the booking rule is loaded
      }
      
      let bookingrule = null;
      if (!this.hideBookings && this.projectIds.length > 0 && this.bookings) {
        bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', this.projectIds.join('|')];   
      }
      
      const companyrule = this.companyrule;
      const departmentrule = this.departmentrule;
      const skillrule = this.skillrule;
      const locationrule = this.locationrule;
      const resourcerule = this.resourcerule;
      if (companyrule && departmentrule) {
        if (bookingrule) {
          data.filter = [bookingrule, companyrule, departmentrule];
        }
        else {
          data.filter = [companyrule, departmentrule];
        }         
      }
      else if (skillrule) {
        if (bookingrule) {
          data.filter = [bookingrule, skillrule];
        }
        else {
          data.filter = [skillrule];
        }
      }
      else if (locationrule) {
        if (bookingrule) {
          data.filter = [bookingrule, locationrule];
        }
        else {
          data.filter = [locationrule];
        }
      }
      else if (resourcerule) {
        if (bookingrule) {
          data.filter = [bookingrule, resourcerule];
        }
        else {
          data.filter = [resourcerule];
        }
      }
      else if (companyrule) {
        if (bookingrule) {
          data.filter = [bookingrule, companyrule];
        }
        else {
          data.filter = [companyrule];
        }
      }
      else if (bookingrule) {
        data.filter = [bookingrule];
      }
      
      if (this.staffListUuIds) {
        data.holders = this.staffListUuIds;
      }
      
      return staffService.list(data).then((response) => {
        this.recommended.staffUuIds = [];
        for (let i = 0; i < response.data.length; i++) {
          this.recommended.staffUuIds.push({ uuId: response.data[i].uuId });
        }
      })
    }
    , toggleSelectorShow(newValue) {
      this.selectorShow = newValue
    }
    , hidden() {
      this.alert = null
      this.inProgressShow = false;
      this.$emit('update:show', false)
      this.$emit('cancel', this.deletedIds)
    }
    , openEditor(isNew, isGeneric=false) {
      if(isNew) {
        this.entityId = `${this.entity}_NEW_${strRandom(5)}`
      } else {
        this.entityId = this.entitySelection[0].uuId
      }
      this.isGeneric = isGeneric;
      this.editorShow = true
      this.resetAlert()
    }
    , editorSuccess(payload) { 
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return
      }
      this.resetAlert({ msg: payload.msg })
      this.scrollToTop()
      this.reloadData()
    }
    
    , openDetail(id, params, { isGeneric=false }={}){
      this.entityId = id
      this.isGeneric = isGeneric
      this.editorShow = true
      this.resetAlert()
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertObj.msg = msg
      this.alertObj.state = alertState
      this.alertObj.msgDetails.title = detailTitle
      const list = this.alertObj.msgDetails.list
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details)
      } else {
        list.splice(0, list.length)
      }
    }
    , updateViewProfile({ clearViewName=true } = {}) {
      // clear the view name from profile
      if (clearViewName) {
        let tab = this.activeTab == -1 || this.activeTab >= this.tabList.length || this.tabList[this.activeTab] == null? this.tabList[0] : this.tabList[this.activeTab];
        this.profileSettings[tab.profileKeyView] = tab.self.viewName = null;
      }
      viewProfileService.update([this.profileSettings], this.userId)
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , createViewProfile() {
      return viewProfileService.create([this.profileSettings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.profileSettings.uuId = data[0].uuId
        for (const tab of this.tabList) {
          tab.newToProfile = true
        }
        
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , async loadViewProfile() {
      const profileData = await this.$store.dispatch('data/viewProfileList', this.userId).then((value) => {  
        return value;
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      });
      
      if (profileData.length === 0) {
        await this.createViewProfile();
        this.useDefault = true; // load the default view, if the views are not loaded yet
        const defaultView = this.views.find(view => view.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
      }
      else {
        this.ignoreColumnChanged = false
        this.profileSettings = profileData[0]
        for (const tab of this.tabList) {
          const tabName = tab.name
          const profileKey = tab.profileKey
          if (typeof this.profileSettings[profileKey] != 'undefined') {
            const target = tabName == 'list'? this : this[tabName]

            //Booking related
            if (this.nonAdmin && typeof this.profileSettings['staff_selector_bookings'] !== 'undefined') {
              this.bookings = this.profileSettings['staff_selector_bookings'];
            }

            if (tabName === 'recommended') {
              this.loadColumnSettings(target, this.profileSettings[profileKey])
              target.filterText = typeof this.profileSettings[tab.profileKeySearch] !== 'undefined' ? this.profileSettings[tab.profileKeySearch] : '';
              target.searchPinned = this.profileSettings[tab.profileKeySearchPinned] == true;
            } else {
              target.skipViewNameReset = true;
              this.loadColumnSettings(target, this.profileSettings[profileKey])
              target.viewName = this.profileSettings[tab.profileKeyView]
              
              const profileKeyOpts = this.profileSettings[`${tab.profileKey}_opts`]
              if (profileKeyOpts != null) {
                if (tabName == 'list') {
                  this.singleContracts = profileKeyOpts.singleContracts
                  if (typeof profileKeyOpts.dates !== 'undefined' &&
                      this.canView(this.permissionName, ['endDate', 'startDate'])) {
                    this.dates = profileKeyOpts.dates;
                  }
                  else if (!this.canView(this.permissionName, ['endDate', 'startDate'])) {
                    this.dates = 'all-time';
                  }
                  if (profileKeyOpts.startDate) {
                    this.startDate = profileKeyOpts.startDate;
                  }
                  if (profileKeyOpts.endDate) {
                    this.endDate = profileKeyOpts.endDate;
                  }
                } else if (tabName == 'orgChart') {
                  this.orgChart.singleContracts = profileKeyOpts.singleContracts
                  if (this.canView(this.permissionName, ['endDate', 'startDate'])) {
                    this.orgChart.dates = profileKeyOpts.dates;
                  }
                  else {
                    this.orgChart.dates = 'all-time';
                  }

                  this.orgChart.startDate = profileKeyOpts.startDate
                  this.orgChart.endDate = profileKeyOpts.endDate
                  this.orgChartRealStaff = profileKeyOpts.realStaff
                  this.orgChartGenericStaff = profileKeyOpts.genericStaff
                }
              }
              
              target.filterText = typeof this.profileSettings[tab.profileKeySearch] !== 'undefined' ? this.profileSettings[tab.profileKeySearch] : '';
              target.searchFilter = target.filterText;
              target.badgeFilters = Array.isArray(this.profileSettings[tab.profileKeyBadgeFilters])? this.profileSettings[tab.profileKeyBadgeFilters] : [];

              target.searchPinned = this.profileSettings[tab.profileKeySearchPinned] == true;
              target.badgeFilterPinned = this.profileSettings[tab.profileKeyBadgeFilterPinned] == true;

              if (target.badgeFilters != null && target.badgeFilters.length > 0) {
                this.changeFilter(tabName);
              }
            }
            
          } else {
            tab.newToProfile = true
          }
        }

        for (const optName in colorOptions) {
          this.$set(this.coloring, optName, this.profileSettings[this.profileKeyColoring]? 
                this.profileSettings[this.profileKeyColoring][optName] : colorOptions[optName])
        }

        for (const optName in genericColorOptions) {
          this.$set(this.genericColoring, optName, this.profileSettings[this.profileKeyGenericColoring]?
                this.profileSettings[this.profileKeyGenericColoring][optName] : genericColorOptions[optName])
        }

        
      }
    }
    , loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0 || target.columnDefs == null) {
        return
      }
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })

      const autoGroupSetting = columns.find(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
      
      if (autoGroupSetting != null && target.autoGroupColumnDef != null) {
        const autoGroupColumnDef = target.autoGroupColumnDef
        autoGroupColumnDef.width = autoGroupSetting.width
        autoGroupColumnDef.sort = autoGroupSetting.sort
        autoGroupColumnDef.sortIndex = autoGroupSetting.sortIndex
        if (target.gridOptions != null && target.gridOptions.api != null) {
          target.gridOptions.api.setAutoGroupColumnDef([]);
          target.gridOptions.api.setAutoGroupColumnDef({ ...autoGroupColumnDef });
        }
      }
      
      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }
      
      if (target.gridOptions != null && target.gridOptions.api != null) {
        target.gridOptions.api.setColumnDefs(cloneDeep(target.columnDefs))
      }
      
      this.isLayoutColumnDefReady = true;
      return
    }
    , async getModelInfo(entity) {
      await this.$store.dispatch('data/info', {type: 'api', object: entity}).then(value => {
        this.modelInfo = value[entity].properties
        const rawPayFrequencyOptions = this.modelInfo.find(f => f.field === 'payFrequency').options
        this.payFrequencyOptions.splice(0, this.payFrequencyOptions.length, ...rawPayFrequencyOptions.map(i => {
          return { value: i, text: this.$t(`payFrequency.${i}`) }
        }))
      })
      .catch(e => {
        this.httpAjaxError(e)
      })

      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.StaffTypeEnum != null) {
              const obj = propertyList.StaffTypeEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.typeOptions.splice(0, this.typeOptions.length, ...list)
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    }
    , applyFilter(pattern, tabName) {
      const { self, profileKeySearch } = this.getRespectiveBadgeFilterDetails(tabName);
      self.searchFilter = pattern
      this.profileSettings[profileKeySearch] = self.searchFilter;
      
      this.updateViewProfile()
      
      if (tabName == 'orgChart') {
        this.reloadOrgChart();
      } else if (self.gridOptions.api) {
        self.gridOptions.api.refreshServerSide({ purge: true })
      }
    }
    // , applyGenericFilter(pattern) {
    //   this.generic.searchFilter = pattern;
    //   const { profileKeySearch } = this.getRespectiveBadgeFilterDetails('generic');
    //   this.profileSettings[profileKeySearch] = this.searchFilter;
    //   // this.generic.searchFilterTerm = this.profileSettings[`${this.formattedEntity}_generic_admin_selector_search`] = this.generic.searchFilter;
    //   this.updateViewProfile()
      
    //   if (this.generic.gridOptions.api) {
    //     this.generic.gridOptions.api.refreshServerSide({ purge: true })
    //   }
    // }
    , showDuplicateDialog_list() {
      const data = this.gridOptions.api.getRowNode(this.entitySelection[0].uuId).data
      this.showDuplicateDialog(false, data)
    }
    , showDuplicateDialog_orgChart() {
      const data = this.orgChart.gridOptions.api.getDisplayedRowAtIndex(this.orgChartEntitySelection[0].rowIndex).data
      this.showDuplicateDialog(false, data)
    }
    , showDuplicateDialog_generic() {
      const data = this.generic.gridOptions.api.getRowNode(this.entitySelection[0].uuId).data
      this.showDuplicateDialog(true, data)
    }
    , showDuplicateDialog(isGeneric=false, data) {
      this.resetAlert()
      this.duplicateRefId = data.uuId
      if (isGeneric) {
        this.duplicateName = data.firstName
      } else {
        this.duplicateFirstname = Object.hasOwn(data, 'staffFirstName')? data.staffFirstName : data.firstName
        this.duplicateLastname = Object.hasOwn(data, 'staffLastName')? data.staffLastName : data.lastName
        this.duplicateEmail = ''
      }
      this.isGeneric = isGeneric
      this.duplicateShow = true
    }
    , duplicateOk() {
      this.duplicateEntity()
    }
    , duplicateEntity: debounce(function() {
      this.duplicateInProgress = true
      
      if(this.isGeneric) {
        if(!this.duplicateName || this.duplicateName.trim().length < 1) {
          this.errors.add({
            field: `duplicateName`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.name')])
          });
        }
      } else {
        if(!this.duplicateEmail || this.duplicateEmail.trim().length < 1) {
          this.errors.add({
            field: `duplicateEmail`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.email')])
          });
        }
        if(!this.duplicateLastname || this.duplicateLastname.trim().length < 1) {
          this.errors.add({
            field: `duplicateLastname`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.lastName')])
          });
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {

          let data = null;
          if (this.isGeneric) {
            data = {
              firstName: this.duplicateName //Use 'firstName' for name till backend corrects the name property
              , genericStaff: true
            }
          } else {
            data = {
              email: this.duplicateEmail
            , firstName: this.duplicateFirstname
            , lastName: this.duplicateLastname
            , genericStaff: false
            }
          }
          staffService.clone(this.duplicateRefId, data)
          .then(() => {
            this.resetAlert({ msg: this.$t(`${this.formattedEntity}.duplicate`) })
            this.reloadData()
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t(`entityType.${this.entity}`)])
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue
              let signal = false;
              if ('Not_unique_key' === clue) { 
                alertMsg = this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')]);
                this.errors.add({
                  field: `duplicateEmail`,
                  msg: this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')])
                });
                signal = true; //Set true to signal not to close dialog.
              } else if ('Unknown_holder' === clue) {
                alertMsg = this.$t(`entity_selector.${this.formattedEntity}_duplicate_error_not_found`)
              }
              if (!signal) {
                //Only show alert feedback when dialog is ready to be closed.
                this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              }
              this.scrollToTop()

            } else {
              this.httpAjaxError(e)
            }
          })
          .then(doNotCloseDialog => {
            if(!doNotCloseDialog) {
              this.duplicateShow = false
              this.errors.clear()
            }
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false
            });
          })
          // .finally(() => {
          //   this.duplicateShow = false
          //   this.errors.clear()
          //   //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
          //   this.$nextTick(() => {
          //     this.duplicateInProgress = false
          //   })
          // })
        } else {
          this.duplicateInProgress = false
        }
      })
    }, 100)
    
    , duplicateCancel() {
      this.duplicateShow = false
      this.errors.clear()
    }
    , scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.elemId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
    , rowDelete(tabName) {
      this.resetAlert()
      this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
      const targetColEntities = []
      let api = null
      if (tabName == 'orgChart') {
        api = this.orgChart.gridOptions.api
      } else if (tabName == 'generic') {
        api = this.generic.gridOptions.api
      } else {
        api = this.gridOptions.api
      }

      let selection = null
      if (tabName == 'orgChart') {
        selection = this.orgChartEntitySelection
      } else {
        selection = this.entitySelection
      }

      for (const task of selection) {
        const rowNode = api.getDisplayedRowAtIndex(task.rowIndex)
        // const rowNode = api.getRowNode(task.uuId)
        if (rowNode == null) {
          continue
        }
        let staffName = null
        if (tabName == 'orgChart') {
           staffName = rowNode.data.name
        } else if (tabName == 'generic') {
          staffName = rowNode.data.firstName
        } else {
          let tmp = rowNode.data.firstName != null? rowNode.data.firstName : ''
          if (rowNode.data.lastName != null) {
            tmp += (tmp.trim().length > 0? ' ' : '') + rowNode.data.lastName
          }
          staffName = tmp
        }
        targetColEntities.push({
          uuId: rowNode.id
          , name: staffName
          , parent: rowNode.data.pUuId
          , parentName: rowNode.data.pName
          , colId: tabName == 'orgChart'? this.COLUMN_AGGRID_AUTOCOLUMN : 'uuId'
          , tabName
        })
      }

      if (targetColEntities.length > 0) {
        //Prepare data for taskcol delete confirmation dialog
        this.ecConfirmDeleteEntities = targetColEntities
        this.prepareTargetColConfirmDeleteDialog()
      }
    }
    , async fileImport() {
      const existingStaff = await staffService.email({ start: 0, limit: -1}).then((response) => {
        return response.data;
      });
      this.existingStaff = existingStaff;
      this.docImportShow = true
    }
    , async docImportOk({ items }) {
      this.docImportShow = false
      this.inProgressShow = true
      this.resetAlert()
      
      await this.addEntities(items)
      .catch(() => {
        //Fail gracefully
      })
      this.inProgressShow = false
      this.reloadData()
    }
    , docImportCancel() {
      this.docImportShow = false
    }
    , fileImportGeneric() {
      this.docImportGenericShow = true;
    }
    , async docImportGenericOk({ items }) {
      this.docImportGenericShow = false
      this.inProgressShow = true;
      this.resetAlert()

      await this.addEntities(items, true)
      .catch(() => {
        //Fail gracefully
      })
      this.inProgressShow = false
      this.reloadData()
    }
    , docImportGenericCancel() {
      this.docImportGenericShow = false
    }
    , async addEntities(items, isGeneric=false) {
      this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [0])
      let percentage = 0
      const self = this
      const errorFunc = (e) => {
        self.httpAjaxError(e)
      }
      
      let cmdList = []
      let idx = 0
      if (items.length > 0) {
        cmdList.push({
          "note":"Disable macro calculations"
          ,"invoke": "PUT /api/system/features?entity=macros&action=DISABLE"
        });
        for (const item of items) {
          idx++;
          await this.importData(item, idx, cmdList, errorFunc, isGeneric)
          percentage++
          this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [parseFloat(percentage / items.length * 100).toFixed(0)])
        }
        cmdList.push({
          "note":"Enable macro calculations"
          ,"invoke": "PUT /api/system/features?entity=macros&action=ENABLE"
        });
        await compositeService.exec(cmdList)
        // .then(response => {
        //   // const feedbackList = response.data.feedbackList;
        //   // if (Array.isArray(feedbackList) && 
        //   //       feedbackList.length > 0 && 
        //   //       feedbackList[0].uuId != null) {
        //   //   return feedbackList[0].uuId;
        //   // }
        // })
        .catch((e) => {
          errorFunc(e);
          // return null;
        });
      }      
    }
    , fileExport() {
      this.inProgressShow = true
      this.inProgressLabel = this.$t('dataview.exporting')
      this.exportData = true
      this.cacheBlockSize = 15000;
      let listener = (event) =>{
        if (first) {
          first = false;
          return;
        }
        
        const keys = this.gridOptions.columnApi
          .getAllColumns()
          .filter(column => column.getColId() != 'rowSelector' && column.getColId() != 'path')
          .map(column => column.getColId())
        const idx = keys.findIndex(k => k === 'company');
        const item = keys.splice(idx, 1);
        keys.splice(3, 0, item);
        // keys.unshift(this.COLUMN_AGGRID_AUTOCOLUMN)
        
        this.gridOptions.api.exportDataAsExcel({ 
          fileName: this.$t(`entity_selector.${this.formattedEntity}_export_filename`)
          , sheetName: this.$t(`entity_selector.${this.formattedEntity}_export_sheetname`)
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: this.processCellCallback('list')
        })
      
        this.exportData = false
        this.inProgressShow = false
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      }
      
      this.gridOptions.api.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);
    }
    , fileExportGeneric() {
      this.inProgressShow = true
      this.inProgressLabel = this.$t('dataview.exporting')
      this.exportData = true
      
      let listener = () =>{
        const keys = this.generic.gridOptions.columnApi
          .getAllColumns()
          .filter(column => column.getColId() != 'rowSelector' && column.getColId() != 'path')
          .map(column => column.getColId())
        this.generic.gridOptions.api.exportDataAsExcel({ 
          fileName: this.$t(`entity_selector.${this.formattedEntity}_export_filename`)
          , sheetName: this.$t(`entity_selector.${this.formattedEntity}_export_sheetname`)
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: this.processCellCallback('generic')
        })
      
        this.exportData = false
        this.inProgressShow = false
      }
      listener()
    }
    , fileExportOrgChart() {
    
      const keys = [];
      keys.push('staff');
      keys.push('position');
      keys.push('department');
      keys.push('identifier');
      keys.push('status');
      keys.push('path');
     
      this.orgChart.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Staff'
        , sheetName: 'Staff'
        , columnKeys: keys
        , rowHeight: 20
        , processCellCallback: this.processCellCallback('orgChart')
      });
    }
    , processCellCallback(tabName) {
      let propertyHandler = null
      if (tabName == 'orgChart') {
        propertyHandler = this.getOrgChartExportDataPropertyHandler()
      } else if (tabName == 'generic') {
        propertyHandler = this.getGenericExportDataPropertyHandler()
      } else {
        propertyHandler = this.getExportDataPropertyHandler()
      }
      const customFieldHandler = getCustomFieldExportDataPropertyHandler(this.customFields)
      return (params) => {
        if (propertyHandler != null && Object.hasOwn(propertyHandler, params.column.colId)) {
          return propertyHandler[params.column.colId](params)
        } else if (Object.hasOwn(customFieldHandler, params.column.colId)) {
          return customFieldHandler[params.column.colId](params)
        } else if (params.column.colId.indexOf(this.COLUMN_AGGRID_AUTOCOLUMN) !== -1) {
          return params.node.data.name
        }
        return params.value
      }
    }
    , onColoringOver() {
      this.$refs.coloring.visible = true
      const elems = document.querySelectorAll('.ag-header-cell-resize');
      for (const el of elems) {
        el.style.pointerEvents = "none";
      }
    }
    , onColoringLeave() {
      this.$refs.coloring.visible = false
      const elems = document.querySelectorAll('.ag-header-cell-resize');
      for (const el of elems) {
        el.style.pointerEvents = "auto";
      }
    }
    , onColoringOverGeneric() {
      this.$refs.coloring_generic.visible = true
    }
    , onColoringLeaveGeneric() {
      this.$refs.coloring_generic.visible = false
    }
    , isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    }
    , onGenericColorChange(val) {
      this.onColorChange(val, this.profileKeyGenericColoring, 'generic')
    }
    , onListColorChange(val) {
      this.onColorChange(val, this.profileKeyColoring, 'list')
    }
    , onColorChange(val, profileKey, tab) {
      const coloringProp = tab == 'generic'? 'genericColoring' : 'coloring'
      const coloring = this[coloringProp]
      for (const key in coloring) {
        coloring[key] = false
      }
      coloring[val] = true
      this[coloringProp] = objectClone(coloring) //Reassign value to force vue to rerender the elements
      this.profileSettings[profileKey] = this[coloringProp]
      this.updateViewProfile()
      const api = tab == 'generic'? this.generic.gridOptions.api : this.gridOptions.api
      api.redrawRows()
    }
    , getRowSelectorColumn(isTree=false, tabName) {
      return {
        headerName: ''
        , colId: 'rowSelector'
        , field: 'name'
        , width: 48
        , minWidth: 48
        , maxWidth: 48
        , hide: false
        , cellRenderer: 'rowSelectorCellRenderer'
        , cellRendererParams: {
          isReadOnly: !this.canEdit(this.entity)
          , enableReadonlyStyle: true
          , optionalParams: { tabName }
        }
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , suppressColumnsToolPanel: true

        , menuTabs: []
        , resizable: false
        , headerComponent: isTree? 'treeSelectorHeaderComponent' : 'selectorHeaderComponent'
        , suppressFillHandle: true 
        , rowDrag: false
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseDown(rowIndex=null, optionalParams) {
      if (rowIndex == null || optionalParams == null) {
        return
      }

      const currentTab = optionalParams.tabName
      let gridOptions = null
      if (currentTab == 'list') {
        gridOptions = this.gridOptions
      } else if (currentTab == 'orgChart') {
        gridOptions = this.orgChart.gridOptions
      } else if (currentTab == 'generic') {
        gridOptions = this.generic.gridOptions
      }

      if (gridOptions == null) {
        return
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map()
      const cellRanges = gridOptions.api.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const columns = cRange.columns.map(i => i.colId)
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set())
          }
          const rCol = rowColumnMap.get(rowStartIndex)
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue
            }
            rCol.add(col)
          }
          continue
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set())
          }
          const rCol = rowColumnMap.get(i)
          for (const col of columns) {
            rCol.add(col)
          }
        }
      }

      const maxColumnsLength = gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length)
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key)
        }
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null, optionalParams } = {}) {
      if (optionalParams == null) {
        return
      }
      const currentTab = optionalParams.tabName
      let gridOptions = null
      if (currentTab == 'list') {
        gridOptions = this.gridOptions
      } else if (currentTab == 'orgChart') {
        gridOptions = this.orgChart.gridOptions
      } else if (currentTab == 'generic') {
        gridOptions = this.generic.gridOptions
      }

      if (gridOptions == null) {
        return
      }
      const mainColId = this.tabList.find(i => i.name == currentTab).colId

      const cellRanges = gridOptions.api.getCellRanges()
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      })

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = gridOptions.api.getFocusedCell()
        const focusedCellRowIndex = focusedCell.rowIndex
        const focusedCellColId = focusedCell.column.colId

        let cellRanges = gridOptions.api.getCellRanges()
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = []
        
        let isDirectionUp = rowIndex < focusedCellRowIndex
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1)
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex)
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })

        gridOptions.api.clearRangeSelection()
        for (const nRange of newRanges) {
          gridOptions.api.addCellRange(nRange)
        }
        gridOptions.api.setFocusedCell(focusedCellRowIndex, focusedCellColId)
        return
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = gridOptions.api.getFocusedCell()
        if (curFocusedCell == null) {
          gridOptions.api.clearFocusedCell()
          return
        }
        const rowIndex = gridOptions.api.getFocusedCell().rowIndex
        gridOptions.api.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        gridOptions.api.setFocusedCell(rowIndex, mainColId, null)
        return
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1]
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })()

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: gridOptions.api, 
        columnApi: gridOptions.columnApi,
        mainColId
      })
    }
    , rowSelectorChanged({ cellRanges, originalRanges, lastRange, api, columnApi, mainColId }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true
            break
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex)

        //Prepare new cell ranges for previous ranges
        const newCellRanges = []
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex)

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, mainColId, null)
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, mainColId, null)
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection()
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange)
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay
          const selection = new Set()
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex
            let endIdx = range.rowEndIndex
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex
              endIdx = range.rowStartIndex
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId)
            }
          }
        }
        
      }
    }
    , async processValueChanged(api, { customProgressLabel=null } = {}) {
      if (customProgressLabel != null) {
        this.inProgressLabel = customProgressLabel
      } else {
        this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_update_progress`)
      }
      this.inProgressShow = true
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250))
        } while(this.pendingProcessRequestList.length > 250)
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length)
          this.pendingRequestBatchList.push(requests)
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length)
          this.resetAlert()
          for (const b of bList) {
            await compositeService.exec(b)
            .then(response => {
              if (response.status == 207 && response.data != null && response.data.jobCase != null) {
                const feedbackList = response.data[response.data.jobCase]
                if (feedbackList != null && feedbackList.length > 0) {
                  const result = this.handleAjaxError(feedbackList)
                  if (result.msg != null) {
                    this.resetAlert({ msg: result.msg, alertState: alertStateEnum.ERROR })
                    this.scrollToTop()
                  } else {
                    const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                    this.resetAlert({ msg: msg != null? msg : this.$t('entity_selector.error.partial_update_operation'), alertState: alertStateEnum.WARNING })
                    this.scrollToTop()
                  }
                }
              }
            })
            .catch(e => {
              this.inProgressShow = false
              let httpStatus = null
              if (e.response != null && e.response.status != null) {
                httpStatus = e.response.status
              }
              if ((httpStatus == 422 || httpStatus == 424) && e.response.data != null && e.response.data.jobCase != null) {
                const feedbackList = e.response.data[e.response.data.jobCase]
                if (feedbackList != null && feedbackList.length > 0) {
                  const result = this.handleAjaxError(feedbackList)
                  if (result.msg != null) {
                    this.resetAlert({ msg: result.msg, alertState: alertStateEnum.ERROR })
                    this.scrollToTop()
                  } else {
                    this.httpAjaxError(e)
                  }
                } else {
                  this.httpAjaxError(e)
                }
              } else {
                this.httpAjaxError(e)
              }
            })
          }
          
          this.reloadData(() => {
            setTimeout(() => {
              this.resetFocus(api, this.lastFocusedCell);
            }, 0);
            this.inProgressShow = false;
          });
        } else {
          this.inProgressShow = false
        }
        return
      }

      let isPendingOtherProcess = false;
      do {
        let currentItem = null
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift()
        } else {
          currentItem = this.processValueChangedList.shift()
        }

        
        // const colId = currentItem.colId
        const property = currentItem.property
        const oldValue = currentItem.oldValue
        const newValue = currentItem.newValue
        // const rowData = currentItem.data
        let entityId = currentItem.entityId
        
        //Special logic for orgChart: Get the last id (zzz) of entityId (e.g. 'xxx, yyy, zzz')
        if (entityId != null && entityId.indexOf(', ') != -1) {
          entityId = entityId.split(', ').pop() 
        }

        let handler = this.getValueChangedHandler()
        
        if (handler[property] != null && typeof handler[property].execute === 'function') {
          let result
          const oVal = oldValue != null? oldValue : handler[property].defaultValueIfNull
          if (handler[property].isAsync) {
            result = await handler[property].execute(entityId, oVal, newValue)
          } else {
            result = handler[property].execute(entityId, oVal, newValue)
          }
          if (result.status == operationStatus.SUCCESS) {
            if (result.isDataProp) {
              //This flow is meant for handling custom data format for entity's property (not link/edge)
              const entityObj = { uuId: entityId }
              const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
              const newVal = result.value
              if (typeof newVal == 'string' && newVal.trim().length == 0 && blankToNullList.includes(property)) {
                entityObj[property] = null
              } else {
                entityObj[property] = newVal
              }
              this.pendingProcessRequestList.push({
                method: 'PUT',
                invoke: '/api/staff/update',
                body: [entityObj],
                vars: [],
                note: `${this.entity}_update__${property}`
              })
            } else if (result.value.length > 0) {
              //This flow is meant for handling custom data format for entity's property which is a link/edge
              this.pendingProcessRequestList.push(...result.value)
            }
          } else if (result.status == operationStatus.ABORT) {
            const prop = result.property != null? result.property : property
            const val = Object.hasOwn(result, 'value')? result.value : oldValue
            
            const rowNode = api.getRowNode(entityId)
            if (rowNode != null) {
              const data = rowNode.data
              data[prop] = val
              rowNode.setData(data)
            }
          }
          
        } else 
        
        if ('tag' === property) {
          const request = await this.updateTags(entityId, oldValue, newValue)
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request)
          }
        } else { // update entity
          const entityObj = { uuId: entityId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            entityObj[property] = null
          } else {
            entityObj[property] = newValue
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: '/api/staff/update',
            body: [entityObj],
            vars: [],
            note: `${this.entity}_update__${property}`
          })
        }

      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    }
    , resetFocus(api, cell) {
      api.clearRangeSelection()
      if (cell != null && cell.rowIndex != null && cell.colId != null) {
        api.setFocusedCell(cell.rowIndex, cell.colId, null)
        api.addCellRange({
          rowStartIndex: cell.rowIndex
          , rowEndIndex: cell.rowIndex
          , columnStart: cell.colId
          , columnEnd: cell.colId
        })
      }
    }
    , async updateTags(entityId, oldTagList, newTagList) {
      const oldTagNames = objectClone(oldTagList)
      const oldTagFilters = []
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue
        }
        oldTagFilters.push({ value: t.trim(), regex: false })
      }      
      let oldTags
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null)
        })
      } else {
        oldTags = []
      }

      const newTagNames = objectClone(newTagList)
      const newTagFilters = []
      if (Array.isArray(newTagNames)) {
        for(const t of newTagNames) {
          if (t == null || t.trim().length == 0) {
            continue
          }
          newTagFilters.push({ value: t.trim(), regex: false })
        }
      }   
      let newTags
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data
          const list = []
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0)
            if (found != null) {
              list.push(found)
            }else {
              list.push({ uuId: null, name: f.value })
            }
          }
          return list
        })
      } else {
        newTags = []
      }
      return this.updateTag(entityId, oldTags, newTags)
    }
    , updateTag(entityId, oldTagList, updatedTagList) {
      let _entityPath = this.entity
      if (_entityPath != null && _entityPath.indexOf('__') > -1) {
        _entityPath = _entityPath.replace('__', '/')  
      }
      
      const originTagList = oldTagList != null && Array.isArray(oldTagList)? oldTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const tagList = updatedTagList != null && Array.isArray(updatedTagList)? updatedTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const toAdd = []
      const toUpdate = []
      const unchangedIds = []
      for(const tag of tagList) {
        const index = originTagList.findIndex(j => j.uuId === tag.uuId)
        if(index == -1) {
          toAdd.push(tag)
        } else {
          unchangedIds.push(tag.uuId)
        }
      }

      const toAddIds = toAdd.map(i => i.uuId)
      const toUpdateIds = toUpdate.map(i => i.uuId)
      const toRemove = originTagList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId))
      const requests = []

      if(toAdd.length > 0) {
        const addTagReqTemplate = (refId, tagName) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: '/api/tag/add',
            body: [{ 
            name: tagName
            }],
            vars: [{ 
              name: refId,
              path: '$.feedbackList.uuId'
            }],
            note: `addTag__${refId}`
          }
        }
        const addTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
              , name: i.name
            })
          })
          return {
            method: 'POST',
            invoke: `/api/${_entityPath}/link/tag/add`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${this.entity}_AddTagLink__${refId}`
          }
        }
        
        // for (const [index, tag] of toAdd.entries()) {
        for (const tag of toAdd) {
          let refId = randomString(8)
          if (tag.uuId == null) {
            refId = `tagUuId_${refId}`
            tag.uuId = `@{${refId}}`
            requests.push(addTagReqTemplate(refId, tag.name))
          }
          requests.push(addTagLinkReqTemplate(`${entityId}_${refId}`, entityId, [tag]))
        }
      }

      if(toRemove.length > 0) {
        const removeTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: `/api/${_entityPath}/link/tag/delete`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${this.entity}_RemoveTagLink__${refId}`
          }
        }
        for (const [index, tag] of toRemove.entries()) {
          requests.push(removeTagLinkReqTemplate(`${index}_${entityId}`, entityId, [tag]))
        }
      }

      return requests
    }
    , keyDownHandler(e) {
      if (!this.show) {
        return
      }
     
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return
      }

      if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridOptions.api
        if (api == null) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
        if (e.shiftKey) {
          return
        }
        const rowCount = api.getDisplayedRowCount()
        if (rowCount == 0) {
          return
        }

        const firstRowIndex = 0
        const lastRowIndex = rowCount - 1
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
        const startColumn = columns[0]
        const lastColumn = columns[columns.length - 1]

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex
        const colId = e.keyCode == 36? startColumn : lastColumn
        const vPosition = e.keyCode == 36? 'top' : 'bottom'

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition)
        } else {
          const focusedCell = api.getFocusedCell()
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null)
          }
        }
        return
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridOptions.api
        const focusedCell = api.getFocusedCell()
        if (focusedCell != null) {
          const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null)
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridOptions.api
        const totalCount = api.getDisplayedRowCount()
        if (totalCount == 0) {
          return
        }
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)

        api.clearRangeSelection()
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        })
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell()
        if (focusCell != null) {
          return
        }
        api.setFocusedCell(0, columns[0], null)
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation()
        e.preventDefault()
      }

    }
    //Refer in detailLink.vue (CellRenderer)
    , getRowColor(data, params) {
      const tabName = params.tabName != null? params.tabName : 'list'
      if (data == null) {
        return ''
      }

      if (data.color &&
          ((tabName == 'list' && this.coloring[this.formattedEntity]) || 
          (tabName == 'generic' && this.genericColoring[this.formattedEntity]))) {
          return data.color
      }
      else if (data.companyColor &&
          ((tabName == 'list' && this.coloring.company) ||
          (tabName == 'generic' && this.genericColoring.company))) {
          return data.companyColor
      }
      else if (data.departmentColor &&
          ((tabName == 'list' && this.coloring.department) ||
          (tabName == 'generic' && this.genericColoring.department))) {
          return data.departmentColor
      }
      else if (data.locationColor &&
          ((tabName == 'list' && this.coloring.location) ||
          (tabName == 'generic' && this.genericColoring.location))) {
          return data.locationColor
      }
      else if (data.skillColor &&
          ((tabName == 'list' && this.coloring.skill) ||
          (tabName == 'generic' && this.genericColoring.skill))) {
          return data.skillColor
      }
      else if (data.resourceColor &&
          ((tabName == 'list' && this.coloring.resource) ||
          (tabName == 'generic' && this.genericColoring.resource))) {
          return data.resourceColor
      }
    }
    , navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex
        const colId = pColId
        api.clearRangeSelection()
        api.setFocusedCell(rowIndex, colId, null)
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        })
        api.ensureIndexVisible(rowIndex, vPosition)
        api.ensureColumnVisible(colId, 'auto')
      })
    }
    , onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.entity)) {
        const cellRanges = params.api.getCellRanges()
        //Prepare cell information
        const targetColEntities = []
        const processedCells = [] //Used to elimate duplicate records.

        const activeTabObj = this.tabList[this.activeTab]
        const mainColId = activeTabObj.colId
        const tabName = activeTabObj.name
        
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex 
          
          const columns = cRange.columns
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx)
            for (const column of columns) {
              const colId = column.colId
              const rowId = rowNode.data.uuId
              const found = processedCells.find(i => rowId == i.rowId && (this.COLUMN_AGGRID_AUTOCOLUMN == i.colId || colId == i.colId))
              if (found != null) {
                continue //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId })

              //Handle 'targetColumn' cell
              //Brief: Delete targetColumn cell means remove the whole row
              if (mainColId == colId) {
                //Skip if it is not a staff row
                if (tabName == 'orgChart' && rowNode.data.staff != true) {
                  continue
                }

                let name = null
                if (tabName == 'orgChart') {
                  name = rowNode.data.name
                } else {
                  name = `${rowNode.data.firstName != null && rowNode.data.firstName.trim().length > 0? rowNode.data.firstName + ' ': ''}${rowNode.data.lastName != null? rowNode.data.lastName : ''}`
                }
                
                targetColEntities.push({
                   uuId: rowNode.id
                  , name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                  , tabName
                })
                continue
              }

              if (rowNode.data[colId] == null) {
                continue //Skip when the property value is null.
              }

              //Handle non targetColumn cell
              //Skip when the cell is not editable
              let isEditable
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId)
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable
              }
              if (!isEditable) {
                continue
              }

              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , entityId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                , value: rowNode.data[colId]
                , tabName
              })
            }
          }
        }
        
        if (targetColEntities.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.ecConfirmDeleteEntities = targetColEntities
          this.prepareTargetColConfirmDeleteDialog()
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell()
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api
        if (api == null) {
          return
        }

        const focusedCell = api.getFocusedCell()
        if (focusedCell == null) {
          return
        }

        const cellRanges = api.getCellRanges()
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex
          }
          return false
        })
        if (fRange == null) {
          return
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex
        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId)
        return
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false
        const api = params.api
        if (api == null) {
          return
        }
        const fRowIndex = params.rowIndex
        const column = params.column
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data
          editable = column.colDef.editable({ data })
        }
        if (!editable) {
          let rowIndex
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1
            if (rowIndex < 0) {
              rowIndex = 0
            }
          } else {
            rowIndex = fRowIndex + 1
            const lastRowIndex = api.getDisplayedRowCount() - 1
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null)
          }
        }
      } else if (params.event.key == '.') { //Fixed the issue of cell editing is not triggered by '.' key
        const editingCells = params.api.getEditingCells()
        if (editingCells.length == 0) {
          params.api.setFocusedCell(params.rowIndex, params.column.colId);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.colId,
            key: params.event.key
          });
        }
      }
    }
    , fillOperation(tabName) {
      return (params) => {
        //Skip when the target cell is not editable or is rowSelector.
        const payload = { 
          node: params.rowNode
          , data: params.rowNode.data
          , column: params.column
          , colDef: params.column.colDef
          , api: params.api
          , columnApi: params.columnApi
          , context: params.context
        }
        if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
            !params.column.colDef.editable || params.column.colId == 'rowSelector') {
          return params.currentCellValue
        }

        //Skip when the target cell is blacklisted
        const blackListed = ['totalActualDuration']
        if (blackListed.includes(params.column.colId)) {
          return params.currentCellValue
        }

        //Used to signal batch operation in onCellValueChanged event.
        this.triggeredByFillOperation = true

        //Find the source Column, source rowNode based on the available details in params object.
        const tgtRowNode = params.rowNode
        const targetColId = params.column.colId
        let srcRowNode = null 
        let sourceColId = null
        
        if (params.direction == 'left' || params.direction == 'right') {
          srcRowNode = tgtRowNode //Default: Same row as target.
          const columns = params.columnApi.getColumnState().filter(i => i.hide != true).map(i => i.colId)
          const targetColIdx = columns.findIndex(i => i == targetColId)
          let sourceColIdx = targetColIdx - params.currentIndex - 1
          if (params.direction == 'left') {
            sourceColIdx = targetColIdx + params.currentIndex + 1
          }
          sourceColId = columns[sourceColIdx]
        } else {// direction: up or down
          let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1
          if (params.direction == 'up') {
            srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1
          }
          srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex)
          sourceColId = targetColId //Default: Same column as source.
        }

        let srcProperty = sourceColId
        let tgtProperty = targetColId

        if (sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN && tabName == 'orgChart') {
          srcProperty = 'name'
        }
        if (sourceColId == 'uuId') {
          if (tabName == 'generic') {
            srcProperty = 'firstName'
          } else {
            srcProperty = 'lastName'
          }
        }

        if (targetColId == this.COLUMN_AGGRID_AUTOCOLUMN && tabName == 'orgChart') {
          tgtProperty = 'name'
        }
        if (targetColId == 'uuId') {
          if (tabName == 'generic') {
            tgtProperty = 'firstName'
          } else {
            tgtProperty = 'lastName'
          }
        }

        //Check if the source and target's property value type are compatible
        if (srcProperty != tgtProperty) {
          const compatibleResult = this.isPropertyCompatible(srcProperty, tgtProperty)
          if (!compatibleResult.status) {
            return tgtRowNode.data[compatibleResult.colId != null? compatibleResult.colId : tgtProperty]
          }
        } else {
          if (tabName != 'generic') {
            //Department: Only allow paste for having same company
            if (srcProperty == 'departments') {
              const srcCompany = srcRowNode.data != null && Array.isArray(srcRowNode.data.companies) && srcRowNode.data.companies.length > 0? srcRowNode.data.companies[0] : null
              const tgtCompany = tgtRowNode.data != null && Array.isArray(tgtRowNode.data.companies) && tgtRowNode.data.companies.length > 0? tgtRowNode.data.companies[0] : null
              if (srcCompany == null || tgtCompany == null || srcCompany.uuId != tgtCompany.uuId) {
                return tgtRowNode.data[tgtProperty]
              }
            }
          }
        }

        let srcValue = srcRowNode.data[srcProperty]
        let tgtValue = objectClone(tgtRowNode.data[targetColId])
        
        //Skip when the target cell is a mandatory field and the new value is either blank or null
        const isGenericStaff = tgtRowNode.data.genericStaff != true? false : true
        let _mandatoryFields = this.mandatoryFields
        if (isGenericStaff) {
          _mandatoryFields = this.mandatoryFieldsForGeneric
        }
        if (_mandatoryFields.length > 0 && 
            _mandatoryFields.includes(tgtProperty) && 
            (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
              Array.isArray(srcValue) && srcValue.length == 0)) {
          return tgtValue
        }

        const result = this.propertyCopyOperation(tgtProperty, srcValue, tgtRowNode.data)
        if (result.status == operationStatus.ABORT) {
          return tgtValue
        }
        return result.value
      }
    }
    , processCellForClipboard(tabName) {
      return (params) => {
        const rowData = params.node.data
        let srcColId = params.column.colId
        
        if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN && tabName == 'orgChart') {
          srcColId = 'name'
        }
        
        if (srcColId == 'uuId') {
          if (tabName == 'generic') {
            srcColId = 'firstName'
          } else {
            srcColId = 'lastName'
          }
        }

        const srcRowId = rowData.uuId

        let srcRowData = null
        if (tabName == 'orgChart') {
          srcRowData = params.api.getRowNode(rowData.path).data
        } else {
          srcRowData = params.api.getRowNode(srcRowId).data
        }
        
        const source = {
          colId: srcColId
          // , data: objectClone(srcRowData)
          , value: srcRowData[srcColId]
          , property: srcColId
          , entityId:  tabName == 'orgChart'? srcRowData.path : srcRowData.uuId
          // , parentId: srcRowData.pUuId
          // , taskName: srcRowData.name
          // , taskType: srcRowData.taskType
        }

        return this.cellCopyPrefix+JSON.stringify(source)
      }
    }
    , processCellFromClipboard(tabName) {
      return (params) => {
        let rowData = params.node.data
        //Skip when the target cell is not editable or is rowSelector.
        const payload = { 
          node: params.node
          , data: params.node.data
          , column: params.column
          , colDef: params.column.colDef
          , api: params.api
          , columnApi: params.columnApi
          , context: params.context
        }
        
        if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
            !params.column.colDef.editable || params.column.colId == 'rowSelector') {
          return rowData[params.column.colId]
        }

        let colId = params.column.colId
        
        if (colId == this.COLUMN_AGGRID_AUTOCOLUMN && tabName == 'orgChart') {
          colId = 'name'
        }

        if (colId == 'uuId') {
          if (tabName == 'generic') {
            colId = 'firstName'
          } else {
            colId = 'lastName'
          }
        }
        
        //Skip when the target cell is blacklisted
        const blackListed = ['totalActualDuration']
        if (blackListed.includes(colId)) {
          return rowData[colId]
        }

        let source = params.value
        //Defensive code: return original value when the value is not from aggrid cell copy.
        if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
          return rowData[colId]
        }

        //Restore value to JSON object.
        source = source.substring(this.cellCopyPrefix.length)
        source = JSON.parse(source)
        
        const target = {
          colId: colId
          , data: objectClone(rowData)
          , value: rowData[colId]
          , property: colId
          , entityId: tabName == 'orgChart'? rowData.path : rowData.uuId
          , parentId: rowData.pUuId
          , entityName: rowData.name
        }

        if (source.property != target.property) {
          const compatibleResult = this.isPropertyCompatible(source.property, target.property)
          if (!compatibleResult.status) {
            return rowData[compatibleResult.colId != null? compatibleResult.colId : target.property]
          }
        } else {
          if (tabName != 'generic') {
            //Department: Only allow paste for having same company
            if (source.property == 'departments') {
              const sourceRow = params.api.getRowNode(source.entityId)
              const srcCompany = sourceRow.data != null && Array.isArray(sourceRow.data.companies) && sourceRow.data.companies.length > 0? sourceRow.data.companies[0] : null
              const tgtCompany = target.data != null && Array.isArray(target.data.companies) && target.data.companies.length > 0? target.data.companies[0] : null
              if (srcCompany == null || tgtCompany == null || srcCompany.uuId != tgtCompany.uuId) {
                return rowData[target.property]
              }
            }
          }
          
        }

        let srcValue = source.value

        //Skip when the target cell is a mandatory field and the new value is either blank or null
        const isGenericStaff = rowData.genericStaff != true? false : true
        let _mandatoryFields = this.mandatoryFields
        if (isGenericStaff) {
          _mandatoryFields = this.mandatoryFieldsForGeneric
        }
        if (_mandatoryFields.length > 0 && 
            _mandatoryFields.includes(target.property) && 
            (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
              Array.isArray(srcValue) && srcValue.length == 0)) {
          return target.value
        }

        const result = this.propertyCopyOperation(target.property, srcValue, target.data)
        if (result.status == operationStatus.ABORT) {
          return result.colId != null? 
            rowData[result.colId] : (result.value != null? result.value : target.value)
        }
        return result.value
      }
    }
    , prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , entityId: rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      return target
    }
    , ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtNotes = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtNotes = tgtValue.single
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId] //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift()
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0])
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier
      }
      delete newNote.uuId
      tgtNotes.unshift(newNote)
      return { action: 'add', newValue: tgtNotes }
    }
    , ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtStaffs = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtStaffs = tgtValue.single
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = []
      if (srcValue.length > 0) {
        const updatedStaffs = []
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId)
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization
            updatedStaffs.push(found)
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration
            delete srcStaff.durationAUM
            updatedStaffs.push(srcStaff)
          }
        }
        newValue = updatedStaffs
      }
      return { action: 'add', newValue }
    }
    , ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex)
      if (srcRowNode == null) {
        return
      }
      
      //Prepare source (property) value
      const srcRowData = srcRowNode.data
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix)

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value
        source.value = valueObj.single 
        source.property = valueObj.property
        source.taskId = valueObj.uuId != null? valueObj.uuId : null
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return
      }

      const pendingFilled = []
      const cellRanges = api.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }})
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i)
          if (curRowNode == null) {
            continue
          }

          const tgtRowData = curRowNode.data
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color })

            if (source.property != target.property) {
              const compatibleResult = this.isPropertyCompatible(source.property, target.property)
              if (!compatibleResult.status) {
                continue
              }
            }

            let srcValue = source.value

            const result = this.propertyCopyOperation(target.property, srcValue, tgtRowData)
            if (result.status == operationStatus.ABORT) {
              continue
            }
            target.newValue = result.value
            pendingFilled.push(target)
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled)
        this.inProgressLabel = this.$t('task.progress.updating_tasks')
        this.processValueChanged(api)
      }
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false
      // this.consolidateChangedCellValues(this.processValueChangedList)
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks')
        this.processValueChanged(params.api)  
      }
    }
    // , consolidateChangedCellValues(valueChangedList) {
    //   if (valueChangedList == null || !Array.isArray(valueChangedList)) {
    //     return
    //   }
      
    //   // if (Object.hasOwn(this.entityService, 'consolidatePropertyGroupWhenPaste')) {
    //   //   const resultList = this.entityService.consolidatePropertyGroupWhenPaste(valueChangedList)
    //   //   valueChangedList.splice(0, valueChangedList.length, ...resultList)
    //   // }
    // }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition
      const nextCellPosition = params.nextCellPosition
      if (nextCellPosition == null) {
        return previousCellPosition
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection()
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition
      }
      return nextCellPosition
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId
      const columns = params.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector')
      let rowIndex = params.previousCellPosition.rowIndex
      let index = columns.findIndex(i => i.colId == curColId)
      let nextColIdx
      if (index == 0 && params.backwards) {
        rowIndex -= 1
        nextColIdx = columns.length - 1
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1
        nextColIdx = 0
      } else if (params.backwards) {
        nextColIdx = index - 1
      } else {
        nextColIdx = index + 1
      }
      const column = columns[nextColIdx]

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex
        this.tabColumn = column
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null
          this.tabColumn = null
          this.tabTimeoutId = null
        }, 0)
        setTimeout(() => {
          params.api.clearRangeSelection()
          params.api.setFocusedCell(rowIndex, column.colId)
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null)
          params.api.ensureColumnVisible(column.colId, 'auto')
        }, 0)
        return params.previousCellPosition
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , async deleteCell() {
      //deleteCell() expects only non targetColumn cell.
      const pendingItems = []
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length)
      for (const cell of cells) {
        // Skip deleting cell when the property of the cell is a mandatory property/field of entity.
        const isGenericStaff = cell.data.genericStaff != true? false :true
        if (isGenericStaff) {
          if (this.mandatoryFieldsForGeneric.includes(cell.property)) {
            continue
          }
        } else {
          if (this.mandatoryFields.includes(cell.property)) {
            continue
          }
        }
        
        cell.oldValue = cell.value
        if (cell.property == 'color') {
          cell.newValue = null
        } else {
          //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
          const result = this.propertyDeleteOperation(cell.property, cell.data)
          if (result.state == operationStatus.ABORT) {
            continue
          }
          
          cell.newValue = result.value
        }
        delete cell.value
        pendingItems.push(cell)
      }
      
      if (pendingItems.length > 0) {
        const data = []
        for (const item of pendingItems) {
          const rowData = item.data //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue
          data.push(rowData)
        }
        this.gridOptions.api.applyTransaction({ update: data })
        this.processValueChangedList.push(...pendingItems)
      }
      
      this.processValueChanged(this.gridOptions.api, { customProgressLabel: this.$t(`entity_selector.${this.formattedEntity}_delete_progress`) })
    }
    , ecConfirmDeleteOk() {
      const entities = [{ 
        entityId: this.entityCol.entityId
        , parentId: this.entityCol.parentId 
        , colId: this.entityCol.colId
        , tabName: this.entityCol.tabName
      }]
      if (this.entityCol.applyAll == true) {
        entities.push(...this.ecConfirmDeleteEntities.map(i => {
          return {
            entityId: i.uuId
            , parentId: i.parent
            , colId: i.colId
            , tabName: i.tabName
          }
        }))
        this.ecConfirmDeleteEntities.splice(0, this.ecConfirmDeleteEntities.length)
      }
      
      const deleteTaskReqTemplate = (entityId) => {
        return {
          method: 'POST',
          invoke: '/api/staff/delete',
          body: [{
            uuId: entityId,
          }],
          vars: [],
          note: `${this.entity}_delete__${entityId}`
        }
      }
      const listToBeRemoved = []
      const orgChartToBeRemoved = []
      const genericToBeRemoved = []
      // let api = null

      const sanitizeId = (val) => {
        if (val == null) {
          return val
        }
        const tokens = val.split(', ')
        return tokens[tokens.length - 1]
      }
      
      for(const entity of entities) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(sanitizeId(entity.entityId)))
        this.deleteInProgressIds.push(entity.entityId)
        const api = entity.tabName == 'orgChart'? this.orgChart.gridOptions.api : (entity.tabName == 'generic'? this.generic.gridOptions.api : this.gridOptions.api)
        const rowNode = api.getRowNode(entity.entityId)
        const rowData = objectClone(rowNode.data)
        
        if (entity.tabName == 'orgChart') {
          orgChartToBeRemoved.push({ rowIndex: rowNode.rowIndex, data: rowData })
        } else if (entity.tabName == 'generic') {
          genericToBeRemoved.push({ rowIndex: rowNode.rowIndex, data: rowData })
        } else {
          listToBeRemoved.push({ rowIndex: rowNode.rowIndex, data: rowData })
        }
      }

      //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      const updateFocusedCell = (api, rowIndexes) => {
        const currentFocusedCell = api.getFocusedCell()
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex
          const colId = currentFocusedCell.column.colId
          const rowPinned = currentFocusedCell.rowPinned
          const isFocusedCellAffected = rowIndexes.includes(rowIndex)
          let suggestedRowIndex = -1
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1
            while (rowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1
            }
          }
          if (suggestedRowIndex > -1) {
            api.clearRangeSelection()
            api.setFocusedCell(suggestedRowIndex, colId, rowPinned)
          }
        }
      }

      if (listToBeRemoved.length > 0) {
        updateFocusedCell(this.gridOptions.api, listToBeRemoved.map(i => i.rowIndex))
        this.gridOptions.api.applyTransaction({ update: null, remove: listToBeRemoved.map(i => i.data) })
      }
      if (orgChartToBeRemoved.length > 0) {
        updateFocusedCell(this.orgChart.gridOptions.api, orgChartToBeRemoved.map(i => i.rowIndex))
        this.orgChart.gridOptions.api.applyTransaction({ update: null, remove: orgChartToBeRemoved.map(i => i.data) })
      }
      if (genericToBeRemoved.length > 0) {
        updateFocusedCell(this.generic.gridOptions.api, genericToBeRemoved.map(i => i.rowIndex))
        this.generic.gridOptions.api.applyTransaction({ update: null, remove: genericToBeRemoved.map(i => i.data) })
      }
      
      setTimeout(() => {
        this.prepareTargetColConfirmDeleteDialog()
      }, 300)
    }
    , prepareTargetColConfirmDeleteDialog() {
      if (this.ecConfirmDeleteEntities == null || this.ecConfirmDeleteEntities.length == 0) {
        this.deletedIds.push(...this.deleteInProgressIds)
        this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
        this.deleteCell()
        return
      }
      
      const entity = this.ecConfirmDeleteEntities.shift()
      this.entityCol.entityId = entity.uuId
      this.entityCol.entityName = entity.name
      this.entityCol.parentName = entity.parent == null || entity.parent == 'ROOT'? null : entity.parentName
      this.entityCol.applyAll = false
      this.entityCol.parentId = entity.parent
      this.entityCol.colId = entity.colId
      this.entityCol.tabName = entity.tabName
      this.inProgressShow = false
      this.ecConfirmDeleteShow = true
    }
    , ecConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deletedIds.push(...this.deleteInProgressIds)
        this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
        this.deleteCell()
      }
    }
    , propertyDeleteOperation(property, data) {
      //Custom handling logic
      let handler = this.getPropertyDeleteHandler(this);
      
      if (handler[property] != null) {
        const val = handler[property]
        if (typeof val === 'function') {
          const result = val(data)
          if (result != null && operationStatusKeys.includes(result.status)) {
            return { found: true, value: result.value, status: result.status }  //found prop is used for debug
          }
        } else {
          return { found: true, value: val, status: operationStatus.SUCCESS } //found prop is used for debug
        }
      }

      //Default handling logic
      let value = data[property]
      if (typeof value == 'string') {
        value = ''
      } else if (typeof value == 'number') {
        value = 0
      } else if (typeof value == 'object') {
        value = { uuId: null }
      } else if (Array.isArray(value)) {
        value = []
      }
      return { found: false, value, status: operationStatus.SUCCESS }
    }
    , propertyCopyHandler() {
      let maxNameLength = 200
      let maxFirstNameLength = 200
      let maxLastNameLength = 200
      let maxIdentifierLength = 200
      
      if (this.modelInfo != null) {
        let val = this.modelInfo.filter(info => info.field === 'name')
        if (val.length > 0) {
          maxNameLength = val[0].max
        }
        val = this.modelInfo.filter(info => info.field === 'firstName')
        if (val.length > 0) {
          maxFirstNameLength = val[0].max
        }
        val = this.modelInfo.filter(info => info.field === 'lastName')
        if (val.length > 0) {
          maxLastNameLength = val[0].max
        }
        val = this.modelInfo.filter(info => info.field === 'identifier')
        if (val.length > 0) {
          maxIdentifierLength = val[0].max
        }
      } 

      //Expected format when return value is a function:
      //{ value, status } or
      //{ value, status, colId } when status is ABORT
      //Possible status: 'SUCCESS' | 'ABORT'
      //colId is optional but is useful for specifying a different colId as reset value.
      return {
        color: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.trim().length == 0) {
            value = null
          }
          return { value, status: 'SUCCESS' }
        }
        , name: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.length > maxNameLength) {
            value = srcValue.substring(0, maxNameLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , fistName: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.length > maxFirstNameLength) {
            value = srcValue.substring(0, maxFirstNameLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , lastName: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.length > maxLastNameLength) {
            value = srcValue.substring(0, maxLastNameLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , identifier: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && value.length > maxIdentifierLength) {
            value = srcValue.substring(0, maxIdentifierLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , startDate: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue == null || srcValue == 32503680000000) {
            value = 0
          }
          return { value, status: 'SUCCESS' }
        }
        , endDate: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue == null || srcValue == 0) {
            value = 32503680000000
          }
          return { value, status: 'SUCCESS' }
        }
      }
    }
    , propertyCopyOperation(property, srcValue, tgtData) {
      const handler = this.propertyCopyHandler()
      
      //When no handler for specific property, just copy value
      if (!Object.hasOwn(handler, property)) {
        return { value: srcValue, status: operationStatus.SUCCESS }
      }

      const val = handler[property]
      if (typeof val === 'function') {
        const result = val(srcValue, tgtData)
        if (result != null && operationStatusKeys.includes(result.status)) {
          return result
        }
      }
      return { value: val, status: operationStatus.SUCCESS }
    }
    , httpAjaxError(e) {
      console.error(e) //eslint-disable-line no-console
      const response = e.response;
      let alertMsg = this.$t('error.internal_server')
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action')
      } else if (422 === response?.status) {
        const feedbackList = response.data[response.data.jobCase];
        const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
        if (msg != null) {
          alertMsg = msg;
        }
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
      this.scrollToTop();
    }
    , reloadData(callbackFunc=null) {
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return
      }

      if (this.tabList[this.activeTab].name === 'generic') {
        this.generic.gridOptions.api.refreshServerSide({ purge: true })
        if (callbackFunc != null && typeof callbackFunc == 'function') {
          callbackFunc()
        }
      } else {
        this.gridOptions.api.refreshServerSide({ purge: true })
        this.reloadOrgChart(callbackFunc, { redrawRows: true })
      }
    }
    , pruneTree(data, searchFilter) {
      if (searchFilter === '') {
        return data
      }
      for (let i = data.length - 1; i >= 0; i--) {
        // add tags
        if (data[i].uuId in this.entityMap) {
          data[i].tag = this.entityMap[data[i].uuId].tag
        }
        
        const deptMatch = (
          (typeof data[i].name !== 'undefined' && data[i].name.toLowerCase().includes(searchFilter.toLowerCase())) ||
          (data[i].identifier && data[i].identifier.toLowerCase().includes(searchFilter.toLowerCase())) ||
          (typeof data[i].tag !== 'undefined' && !Array.isArray(data[i].tag) && data[i].tag.toLowerCase().includes(searchFilter.toLowerCase())) ||
          (typeof data[i].tag !== 'undefined' && Array.isArray(data[i].tag) && data[i].tag.join(',').toLowerCase().includes(searchFilter.toLowerCase()))
        );

        if (typeof data[i].departmentList !== 'undefined') {
          data[i].departmentList = this.pruneTree(data[i].departmentList, searchFilter);
        }
        if (typeof data[i].staffList !== 'undefined' && !deptMatch) {
          data[i].staffList = this.pruneTree(data[i].staffList, searchFilter);
        }
        if (typeof data[i].companyList !== 'undefined' && !deptMatch) {
          data[i].companyList = this.pruneTree(data[i].companyList, searchFilter);
        }

        
        

        if (
          (typeof data[i].departmentList === 'undefined' || data[i].departmentList.length === 0) &&
          (typeof data[i].staffList === 'undefined' || data[i].staffList.length === 0) &&
          (typeof data[i].companyList === 'undefined' || data[i].companyList.length === 0)) {
          const lcSearchFilter = searchFilter.toLowerCase();

          let matched = false;
          if ((typeof data[i].name !== 'undefined' && data[i].name.toLowerCase().includes(lcSearchFilter)) ||
              (typeof data[i].firstName !== 'undefined' && data[i].firstName.toLowerCase().includes(lcSearchFilter)) ||
              (typeof data[i].lastName !== 'undefined' && data[i].lastName.toLowerCase().includes(lcSearchFilter)) ||
              (data[i].identifier != null && data[i].identifier.toLowerCase().includes(lcSearchFilter)) ||
              (data[i].position != null && data[i].position.toLowerCase().includes(lcSearchFilter)) 
          ) {
            matched = true;
          }

          if (!matched && Array.isArray(this.customFields) && this.customFields.length > 0) {
            for (const c of this.customFields) {
              if ((c.type == 'String' || c.type == 'Enum<String>')) {
                if (data[i][c.name] != null && data[i][c.name].toLowerCase().includes(lcSearchFilter)) {
                  matched = true;
                  break;
                }
              }
            }
          }

          if (!matched) {
            data.splice(i, 1);
          }
        }
      }
      return data;
    }
    , extractRowsFromData(path, data, listStaff) {
      const rows = [];
      if (data !== null) {
        // sort the data by name
        data.sort(sortFunc);
        
        if (this.exclude !== null && typeof data !== 'undefined') {
          for (var i = 0; i < data.length; i++) {
            if (data[i].uuId === this.exclude) {
              data.splice(i, 1); // do not list the department itself or its children
              break;
            }
          }
        }
        
        for (let i = 0; i < data.length; i++) {
          // copy tags
          if (this.departmentMap && data[i].uuId in this.departmentMap) {
            data[i].tag = this.departmentMap[data[i].uuId].tag;
            data[i].companyColor = this.departmentMap[data[i].uuId].companyColor;
          }
          
          const deptPath = path === '' ? data[i].uuId : `${path}, ${data[i].uuId}`;
          data[i].path = deptPath;
          rows.push(data[i]);
          if (listStaff && typeof data[i].staffList !== 'undefined') {
            data[i].staffList.sort(sortFunc);
            for (let j = 0; j < data[i].staffList.length; j++) {
              const staffPath = `${deptPath}, ${data[i].staffList[j].uuId}`;

              const payload = {
                name: data[i].staffList[j].name,
                uuId: data[i].staffList[j].uuId,
                position: data[i].staffList[j].position,
                identifier: data[i].staffList[j].identifier,
                status: data[i].staffList[j].status,
                path: staffPath,
                staff: true,
                genericStaff: data[i].staffList[j].genericStaff,
                staffFirstName: data[i].staffList[j].firstName, //use different property name to avoid a conflict in detailLinkLabel()
                staffLastName: data[i].staffList[j].lastName, //use different property name to avoid a conflict in detailLinkLabel()
                readOnly: data[i].staffList[j].readOnly
              }
              if (Array.isArray(this.customFields) && this.customFields.length > 0) {
                for (const cField of this.customFields) {
                  payload[cField.name] = data[i].staffList[j][cField.name] ?? null;
                }
              }
              
              rows.push(payload);
            }
          }
          
          if (typeof data[i].departmentList !== 'undefined') {
            rows.unshift(...this.extractRowsFromData(deptPath, data[i].departmentList, listStaff));
          }
          
          if (typeof data[i].companyList !== 'undefined') {
            rows.unshift(...this.extractRowsFromData(deptPath, data[i].companyList, listStaff));
          }
        }
      }
      return rows;
    }
    , parsePhone(phone) {
      if (phone.includes(':')) {
        const parts = phone.split(': ');
        return { kind: parts[0], data: parts[1] };
      }
      return { kind: 'mobile', data: phone }
    }
    , parseSocial(social) {
      if (social.includes(':')) {
        const parts = social.split(': ');
        return { kind: parts[0], data: parts[1] };
      }
      return { kind: 'linkedin', data: social }
    }
    , async importData(item, idx, cmdList, errorFunc, isGeneric) {
      const dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/
      const self = this;
      
      if (item.startdate) {
        item.startdate = moment.utc(item.startdate).format('YYYY-MM-DD')
      }
      
      if (item.enddate) {
        item.enddate = moment(item.enddate).format('YYYY-MM-DD')
      }

      let data = null
      if (isGeneric) {
        data = {
          firstName: item.name
          , position: item.position
          , payAmount: item.payamount
          , payFrequency: item.payfrequency
          , payCurrency: item.paycurrency
          , startDate:"1970-01-01"
          , endDate:"3000-01-01"
          , staffType: item.type
          , genericStaff: true
          , identifier: item.identifier
          , color: item.color
        }
      } else {
        data = {
          firstName: item.firstname
          , lastName: item.name
          , position: item.position
          , email: item.email
          , payAmount: item.payamount
          , payFrequency: item.payfrequency
          , payCurrency: item.paycurrency
          , startDate: item.startdate && item.startdate.match(dateReg) ? item.startdate : "1970-01-01"
          , endDate: item.enddate && item.enddate.match(dateReg) ? item.enddate : "3000-01-01"
          , staffType: item.type
          , genericStaff: false
          , identifier: item.identifier
          , color: item.color
        }
      }
      
      if (this.customFields) {
        for (const cfield of this.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        delete data['genericStaff'];
        if (!item.type) {
          delete data['staffType'];
        }
        if (!item.payamount) {
          delete data['payAmount'];
        }
        if (!item.payfrequency) {
          delete data['payFrequency'];
        }
        if (!item.paycurrency) {
          delete data['payCurrency'];
        }
        if (!item.startdate) {
          delete data['startDate'];
        }
        if (!item.enddate) {
          delete data['endDate'];
        }
        if (!item.identifier) {
          delete data['identifier'];
        }
        if (!item.color) {
          delete data['color'];
        }
      }
      
      if (Array.isArray(item.phone) && item.phone.length > 0) {
        data['phones'] = item.phone.split(', ').map(p => { return self.parsePhone(p) });    
      }
      
      if (!Array.isArray(item.phone) && item.phone) {
        data['phones'] = [self.parsePhone(item.phone)];    
      }
      
      if (Array.isArray(item.socials) && item.socials.length > 0) {
        data['socials'] = item.socials.split(', ').map(p => { return self.parseSocial(p) });    
      }
      
      if (Array.isArray(item.websites) && item.websites.length > 0) {
        data['websites'] = item.websites.split(', ');
      }
      
      if (!data.uuId && (data.staffType == null || data.staffType === '')) {
        data['staffType'] = 'Full_Time'
      }

      // companyId
      var companyId = null;
      if (item.company &&
          ((Array.isArray(item.company) && item.company.length > 0 && item.company[0].uuId !== null) ||
          (!Array.isArray(item.company) && item.company.uuId !== null))) {
        companyId = Array.isArray(item.company) ? item.company[0].uuId : item.company.uuId;
      }
      else {
        companyId = localStorage.companyId;
      }

      const resourceList = []
      if (item.resources && item.resources.length > 0 && item.resources[0].uuId !== null) {
        item.resources.forEach(i => {
          if (i.uuId != null && typeof i.uuId == 'string')
          resourceList.push({
            uuId: i.uuId, 
            resourceLink: { 
              utilization: i.utilization ? i.utilization : 1.00, 
              quantity: i.quantity 
            }
          });
        });
      }

      const idName = `stfk${idx}`
      
      if (data.uuId) {
        const stf = await staffService.query([{ uuId: data.uuId }], ['COMPANY', 'LOCATION', 'DEPARTMENT']).then((response) => {
          return response.data.objectList;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });

        if (stf.length !== 0) {
          if (stf[0].companyList.length > 0) {
            if (companyId !== stf[0].companyList[0].uuId) {
              // unlink the company
              cmdList.push(...[
                {
                  "invoke": '/api/company/link/staff/delete',
                  "body": {
                    uuId: stf[0].companyList[0].uuId,
                    staffList: [{ uuId: data.uuId }]
                  }
                },
                {
                  "invoke": '/api/company/link/staff/add',
                  "body": {
                    uuId: companyId,
                    staffList: [{ uuId: data.uuId }]
                  }
                }
              ])
            }
          }
          
          if (stf[0].locationList.length > 0 &&
              item.location !== stf[0].locationList[0].uuId) {
            cmdList.push(...[
            {
                "invoke": '/api/staff/link/location/delete',
                "body": {
                  uuId: data.uuId,
                  locationList: [{ uuId: stf[0].locationList[0].uuId }]
                }
            }])
          }
          
          if (stf[0].departmentList.length > 0 &&
              item.department && item.department.length !== 0) {
            for (const dep of stf[0].departmentList) {
              if (!item.department.find(d => d.uuId === dep.uuId)) {
                cmdList.push(...[
                {
                    "invoke": '/api/department/link/staff/delete',
                    "body": {
                      uuId: dep.uuId,
                      staffList: [{ uuId: data.uuId }]
                    }
                }])
              }
            }
          }
        }
      }
              
      cmdList.push({
        "invoke": data.uuId ? "/api/staff/update" : "/api/staff/add"
        ,"method": data.uuId ? "PUT" : "POST"
        ,"body": [data]
        ,"vars": [ {"name": idName,"path": "$.feedbackList.uuId"} ]
      })
        
      // if the companyId is not the primary company then we need to unlink from
      // the primary company then link to the new company
      if (!data.uuId && companyId !== localStorage.companyId) {
        // unlink the staff from the company if we are adding
        cmdList.push(...[
          {
            "invoke": '/api/company/link/staff/delete',
            "body": {
              uuId: localStorage.companyId,
              staffList: [{ uuId: `@{${idName}}` }]
            }
          },
          {
            "invoke": '/api/company/link/staff/add',
            "body": {
              uuId: companyId,
              staffList: [{ uuId: `@{${idName}}` }]
            }
          }
        ])
      }
       
      if (item.location && item.location.uuId) {
        cmdList.push(...[
          {
            "invoke": '/api/staff/link/location/add',
            "body": {
              uuId: `@{${idName}}`,
              locationList: [{ uuId: item.location.uuId }]
            }
        }])
      }
      
      if (resourceList.length !== 0) {
        for (const resource of resourceList) {
          cmdList.push({
            "invoke": '/api/staff/link/resource/add',
            "body": {
              uuId: `@{${idName}}`,
              resourceList: [resource]
            }
          });
        }
      }
              
      if (item.skills && item.skills.length !== 0) {
        for (const skill of item.skills) {
          if (skill.uuId) {
            cmdList.push({
              "invoke": '/api/staff/link/skill/add',
              "body": {"uuId":`@{${idName}}`,
                        "skillList":[{ uuId: skill.uuId, skillLink: {level: skill.skillLink ? skill.skillLink.level : skill.level}} ]
              }
            });
          }
        }
      }

      if (item.department &&
          item.department.length > 0 && item.department[0].uuId !== null) {
        for (const dep of item.department) {
          if (dep.uuId !== null) {
            cmdList.push({
              "invoke": '/api/department/link/staff/add',
              "body": {
                uuId: dep.uuId,
                staffList: [{ uuId: `@{${idName}}` }]
              }
            });
          }
        }
      }

      if (item.notes) {
        item.notes.forEach(i => {
          cmdList.push({
            "invoke": `/api/note/add?holder=@{${idName}}`,
            "body": [i]
          });
        });
      }

      if (item.tag) {
        const toAdd = []
        const tags = item.tag.split(',').map(t => { return { name: t.trim() }});
        for (let i = 0; i < tags.length; i++) {
          if (!tags[i].uuId) {
            // get the uuId
            tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
              if (response.data.length !== 0) {
                return response.data[0].uuId
              }
              return null;
            });
            
            if (tags[i].uuId === null) {
              tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
                if (response.data[response.data.jobCase].length !== 0) {
                  return response.data[response.data.jobCase][0].uuId
                }
                return null;
              });
            }
          }
          
          if (tags[i].uuId !== null) {
            toAdd.push({uuId: tags[i].uuId})
          }
        }
        
        for (const tag of toAdd) {
          cmdList.push({
            "invoke": '/api/staff/link/tag/add',
            "body": {
              uuId: `@{${idName}}`,
              tagList: [{ uuId: tag.uuId }]
            }
          });
        }
      }

      if (cmdList.length >= 499) {
        // Try to limit the number of api calls sent to the server in each batch
        await compositeService.exec(cmdList)
        // .then(response => {
        //   const feedbackList = response.data.feedbackList
        //   if (Array.isArray(feedbackList) && 
        //         feedbackList.length > 0 && 
        //         feedbackList[0].uuId != null) {
        //     return feedbackList[0].uuId;
        //   }
        // })
        .catch((e) => {
          errorFunc(e)
          // return null
        });
        cmdList.splice(0, cmdList.length);
      }
     
    }
    , isPropertyCompatible(src, tgt) {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(this.dataGroup)
      for(const key of keys) {
        if (this.dataGroup[key].includes(src) && this.dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
    , getExportDataPropertyHandler() {
      const formatCost = (value /**, currencyCode=null */) => {
        let rawValue = parseInt(value);
        if (rawValue < 0) {
          return '';
        }
        else {
          return rawValue;
        }
      }
      return {
        uuId: (params) => {
          if (params.node.data) {
            return params.node.data.lastName
          }
          return 'N/A'
        }
        , locations: (params) => {
          return formatArray(params)
        }
        , resources: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.name} (${i.unit})`).join(', ')
          }
          return ''
        }
        , skills: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.name} (${i.level})`).join(', ')
          }
          return ''
        }
        , departments: (params) => {
          return formatArray(params)
        }
        , companies: (params) => {
          return formatArray(params)
        }
        , startDate: (params) => {
          if (!params.node.data ||
              params.node.data.startDate === 253402214400000 ||
              params.node.data.startDate === 0) {
            return ''
          }
          return moment(params.node.data.startDate).format()
        }
        , endDate: (params) => {
          if (!params.node.data || 
              params.node.data.endDate === 253402214400000 ||
              params.node.data.endDate === 32503680000000 ||
              params.node.data.endDate === 0) {
            return ''
          }
          return moment(params.node.data.endDate).format()
        }
        , websites: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.join(', ')
          }
          return ''
        }
        , socials: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.kind}: ${i.data}`).join(', ')
          }
          return ''
        }
        , phones: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.kind}: ${i.data}`).join(', ')
          }
          return ''
        }
        , payAmount: (params) => {
          const currencyCode = params.node != null 
                                && params.node.data != null
                                && params.node.data.payCurrency != null 
                                && params.node.data.payCurrency.trim().length > 0? params.node.data.payCurrency : null
          return formatCost(params.value, currencyCode)
        }
      }
    }
    , getOrgChartExportDataPropertyHandler() {
      return {
        staff: (params) => {
          if (params.node.data.staff) {
            return params.node.data.name
          }
          return ''
        }
        , department: (params) => {
          if (params.node.data.staff) {
            return ''
          }
          return params.node.data.name
        }
        , path: (params) => {
          if (params.value == null) {
            return ''
          }
          const ids = params.value.split(', ')
          let path = ''
          let parentPath = ''
          for (const id of ids) {
            const parent = this.orgChart.gridOptions.api.getRowNode(parentPath)
            if (parent) {
              path = path !== '' ? `${path}/${parent.data.name}` : parent.data.name
            }
            parentPath = parentPath === '' ? id : `${parentPath}, ${id}`
          }
          return path
        }
      }
    }
    , getGenericExportDataPropertyHandler() {
      const formatCost = (value, currencyCode=null) => {
        let rawValue = parseInt(value);
        if (rawValue < 0) {
          return '';
        }
        else {
          return currencyCode == null? `$${costFormat(rawValue, {notation:'standard'})}` : costFormatAdv(rawValue, currencyCode, {notation:'standard'});
        }
      }
      return {
        uuId: (params) => {
          return params.node.data.firstName
        }
        , locations: (params) => {
          return formatArray(params)
        }
        , companies: (params) => {
          return formatArray(params)
        }
        , resources: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.name} (${i.unit})`).join(', ')
          }
          return ''
        }
        , skills: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.map(i => `${i.name} (${i.level})`).join(', ')
          }
          return ''
        }
        , departments: (params) => {
          return formatArray(params)
        }
        , payAmount: (params) => {
          const currencyCode = params.node != null 
                                && params.node.data != null
                                && params.node.data.payCurrency != null 
                                && params.node.data.payCurrency.trim().length > 0? params.node.data.payCurrency : null
          return formatCost(params.value, currencyCode)
        }
      }
    }
    , getValueChangedHandler() {
      const self = this;
      return {
        companies: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const toAdd = []
            const toUpdate = []
            const unchangedIds = []

            for(const item of list) {
              const index = oldList.findIndex(j => j.uuId === item.uuId)
              if(index == -1) {
                toAdd.push(item)
              } else {
                unchangedIds.push(item.uuId)
              }
            }

            const toAddIds = toAdd.map(i => i.uuId)
            const toUpdateIds = toUpdate.map(i => i.uuId)
            const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                                && !toUpdateIds.includes(i.uuId) 
                                                && !unchangedIds.includes(i.uuId))
            const requests = []

            if(toAdd.length > 0) {
              const addCompanyLinkReqTemplate = function(refId, companyId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/company/link/staff/add`,
                  body: { 
                    uuId: companyId,
                    staffList: list
                  },
                  vars: [],
                  note: `companyAddStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, company] of toAdd.entries()) {
                requests.push(addCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
              }
            }

            if(toUpdate.length > 0) {
              const updateCompanyLinkReqTemplate = function(refId, companyId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/company/link/staff/update`,
                  body: { 
                    uuId: companyId,
                    staffList: list
                  },
                  vars: [],
                  note: `companyUpdateStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, company] of toUpdate.entries()) {
                requests.push(updateCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
              }
            }

            if(toRemove.length > 0) {
              const removeCompanyLinkReqTemplate = function(refId, companyId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/company/link/staff/delete`,
                  body: { 
                    uuId: companyId,
                    staffList: list
                  },
                  vars: [],
                  note: `companyRemoveStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, company] of toRemove.entries()) {
                requests.push(removeCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
              }
            }

            //There is any company change, remove all department links
            if (requests.length > 0) {
              const api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null
              if (api != null) {
                const rowNode = api.getRowNode(entityId)
                if (rowNode != null && rowNode.data != null && Array.isArray(rowNode.data.departments) && rowNode.data.departments.length > 0) {
                  const toRemoveDeptIds = rowNode.data.departments.filter(i => i.uuId != null).map(i => i.uuId)
                  if (toRemoveDeptIds.length > 0) {
                    const list = [{ uuId: entityId }]
                    for (const id of toRemoveDeptIds) {
                      requests.push({
                        method: 'POST',
                        invoke: `/api/department/link/staff/delete`,
                        body: { 
                          uuId: id,
                          staffList: list
                        },
                        vars: [],
                        note: `departmentRemoveStaffLink__${id}__${entityId}`
                      })
                    }
                  }
                }
              }
            }

            if (requests.length > 0) {
              return {
                value: requests
                , status: 'SUCCESS'
              }
            }

            return {
              value: oldVal
              , status: 'ABORT'
              , property: 'companies'
            }
          }
        }
        , departments: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const toAdd = []
            const toUpdate = []
            const unchangedIds = []

            for(const item of list) {
              const index = oldList.findIndex(j => j.uuId === item.uuId)
              if(index == -1) {
                toAdd.push(item)
              } else {
                unchangedIds.push(item.uuId)
              }
            }

            const toAddIds = toAdd.map(i => i.uuId)
            const toUpdateIds = toUpdate.map(i => i.uuId)
            const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                                && !toUpdateIds.includes(i.uuId) 
                                                && !unchangedIds.includes(i.uuId))
            const requests = []

            if(toAdd.length > 0) {
              const addDepartmentLinkReqTemplate = function(refId, departmentId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/department/link/staff/add`,
                  body: { 
                    uuId: departmentId,
                    staffList: list
                  },
                  vars: [],
                  note: `departmentAddStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, department] of toAdd.entries()) {
                requests.push(addDepartmentLinkReqTemplate(`${index}_${department.uuId}`, department.uuId, [entityId]));
              }
            }

            if(toUpdate.length > 0) {
              const updateDepartmentLinkReqTemplate = function(refId, departmentId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/department/link/staff/update`,
                  body: { 
                    uuId: departmentId,
                    staffList: list
                  },
                  vars: [],
                  note: `departmentUpdateStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, department] of toUpdate.entries()) {
                requests.push(updateDepartmentLinkReqTemplate(`${index}_${department.uuId}`, department.uuId, [entityId]));
              }
            }

            if(toRemove.length > 0) {
              const removeDeparmentLinkReqTemplate = function(refId, departmentId, staffList) {
                const list = [];
                staffList.forEach(i => {
                  list.push( {
                    uuId: i
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/department/link/staff/delete`,
                  body: { 
                    uuId: departmentId,
                    staffList: list
                  },
                  vars: [],
                  note: `departmentRemoveStaffLink__${refId}__${staffList[0]}`
                }
              }
              for (const [index, department] of toRemove.entries()) {
                requests.push(removeDeparmentLinkReqTemplate(`${index}_${department.uuId}`, department.uuId, [entityId]));
              }
            }
            if (requests.length > 0) {
              return {
                value: requests
                , status: 'SUCCESS'
              }
            }

            return {
              value: oldVal
              , status: 'ABORT'
              , property: 'departments'
            }
          }
        }
        , locations: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
            const toAdd = []
            const toUpdate = []
            const unchangedIds = []

            for(const item of list) {
              const index = oldList.findIndex(j => j.uuId === item.uuId)
              if(index == -1) {
                toAdd.push(item)
              } else {
                unchangedIds.push(item.uuId)
              }
            }

            const toAddIds = toAdd.map(i => i.uuId)
            const toUpdateIds = toUpdate.map(i => i.uuId)
            const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                                && !toUpdateIds.includes(i.uuId) 
                                                && !unchangedIds.includes(i.uuId))
            const requests = []

            if(toAdd.length > 0) {
              const addLocationLinkReqTemplate = function(refId, staffId, locationList) {
                const list = [];
                locationList.forEach(i => {
                  list.push( {
                    uuId: i.uuId
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/location/add`,
                  body: { 
                    uuId: staffId,
                    locationList: list
                  },
                  vars: [],
                  note: `staffAddLocationLink__${refId}__${locationList[0].uuId}`
                }
              }
              for (const [index, location] of toAdd.entries()) {
                requests.push(addLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
              }
            }

            if(toUpdate.length > 0) {
              const updateLocationLinkReqTemplate = function(refId, staffId, locationList) {
                const list = [];
                locationList.forEach(i => {
                  list.push( {
                    uuId: i.uuId
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/location/update`,
                  body: { 
                    uuId: staffId,
                    locationList: list
                  },
                  vars: [],
                  note: `staffUpdateLocationLink__${refId}__${locationList[0].uuId}`
                }
              }
              for (const [index, location] of toUpdate.entries()) {
                requests.push(updateLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
              }
            }

            if(toRemove.length > 0) {
              const removeLocationLinkReqTemplate = function(refId, staffId, locationList) {
                const list = [];
                locationList.forEach(i => {
                  list.push( {
                    uuId: i.uuId
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/location/delete`,
                  body: { 
                    uuId: staffId,
                    locationList: list
                  },
                  vars: [],
                  note: `staffRemoveLocationLink__${refId}__${locationList[0].uuId}`
                }
              }
              for (const [index, location] of toRemove.entries()) {
                requests.push(removeLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
              }
            }

            if (requests.length > 0) {
              return {
                value: requests
                , status: 'SUCCESS'
              }
            }

            return {
              value: oldVal
              , status: 'ABORT'
              , property: 'locations'
            }
          }
        }
        , resources: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            const oldList = Array.isArray(oldVal)
              ? oldVal.map(i => { 
                const val = { uuId: i.uuId, name: i.name, unit: i.unit, utilization: i.utilization }
                for (const f of self.resourceCustomFields) {
                  if (Object.hasOwn(i, f.name)) {
                    val[f.name] = i[f.name];
                  }
                }
                return val;
              }) 
              : []
            const list = Array.isArray(newValue)
              ? newValue.map(i => { 
                const val = { uuId: i.uuId, name: i.name, unit: i.unit, utilization: i.utilization }
                for (const f of self.resourceCustomFields) {
                  if (Object.hasOwn(i, f.name)) {
                    val[f.name] = i[f.name];
                  }
                }
                return val;
              }) 
              : []
            const toAdd = []
            const toUpdate = []
            const unchangedIds = []

            for(const item of list) {
              const index = oldList.findIndex(j => j.uuId === item.uuId)
              if(index == -1) {
                toAdd.push(item)
              } else {
                let hasChanged = parseInt(oldList[index].unit) != parseInt(item.unit) 
                                  || parseFloat(oldList[index].utilization) != parseFloat(item.utilization)
                if(!hasChanged) {
                  for (const f of self.resourceCustomFields) {
                    if (Object.hasOwn(oldList[index], f.name) && Object.hasOwn(item, f.name)) {
                      if ((f.type == 'String' || f.type == 'Enum<String>') && oldList[index][f.name].localeCompare(item[f.name], undefined, { sensitivity: 'base' }) != 0) {
                        hasChanged = true
                        break
                      } else if ((f.type != 'String' && f.type != 'Enum<String>') && oldList[index][f.name] != item[f.name]) {
                        hasChanged = true
                        break
                      }
                    } else if (Object.hasOwn(oldList[index], f.name) || Object.hasOwn(item, f.name)) {
                      hasChanged = true
                      break
                    }
                  }
                }
                if (hasChanged) {
                  toUpdate.push(item)
                } else {
                  unchangedIds.push(item.uuId)
                }
              }
            }

            const toAddIds = toAdd.map(i => i.uuId)
            const toUpdateIds = toUpdate.map(i => i.uuId)
            const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                                && !toUpdateIds.includes(i.uuId) 
                                                && !unchangedIds.includes(i.uuId))
            const requests = []

            if(toAdd.length > 0) {
              const addResourceLinkReqTemplate = function(refId, staffId, resourceList) {
                const list = [];
                resourceList.forEach(i => {
                  const resourceLink = {
                    quantity: i.unit
                    , utilization: i.utilization
                  }
                  for (const f of self.resourceCustomFields) {
                    if (Object.hasOwn(i, f.name)) {
                      resourceLink[f.name] = i[f.name]
                    }
                  }

                  list.push({
                    uuId: i.uuId
                    , resourceLink
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/resource/add`,
                  body: { 
                    uuId: staffId,
                    resourceList: list
                  },
                  vars: [],
                  note: `staffAddResourceLink__${refId}__${resourceList[0].uuId}`
                }
              }
              for (const [index, resource] of toAdd.entries()) {
                requests.push(addResourceLinkReqTemplate(`${index}_${entityId}`, entityId, [resource]));
              }
            }

            if(toUpdate.length > 0) {
              const updateResourceLinkReqTemplate = function(refId, staffId, resourceList) {
                const list = [];
                resourceList.forEach(i => {
                  const resourceLink = {
                    quantity: i.unit
                    , utilization: i.utilization
                  }
                  for (const f of self.resourceCustomFields) {
                    if (Object.hasOwn(i, f.name)) {
                      resourceLink[f.name] = i[f.name]
                    }
                  }

                  list.push( {
                    uuId: i.uuId
                    , resourceLink
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/resource/update`,
                  body: { 
                    uuId: staffId,
                    resourceList: list
                  },
                  vars: [],
                  note: `staffUpdateResourceLink__${refId}__${resourceList[0].uuId}`
                }
              }
              for (const [index, resource] of toUpdate.entries()) {
                requests.push(updateResourceLinkReqTemplate(`${index}_${entityId}`, entityId, [resource]));
              }
            }

            if(toRemove.length > 0) {
              const removeResourceLinkReqTemplate = function(refId, staffId, resourceList) {
                const list = [];
                resourceList.forEach(i => {
                  list.push( {
                    uuId: i.uuId
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/resource/delete`,
                  body: { 
                    uuId: staffId,
                    resourceList: list
                  },
                  vars: [],
                  note: `staffRemoveResourceLink__${refId}__${resourceList[0].uuId}`
                }
              }
              for (const [index, resource] of toRemove.entries()) {
                requests.push(removeResourceLinkReqTemplate(`${index}_${entityId}`, entityId, [resource]));
              }
            }

            if (requests.length > 0) {
              return {
                value: requests
                , status: 'SUCCESS'
              }
            }

            return {
              value: oldVal
              , status: 'ABORT'
              , property: 'resources'
            }
          }
        }
        , skills: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            const oldList = Array.isArray(oldVal)
              ? oldVal.map(i => { 
                const val = { uuId: i.uuId, name: i.name, level: i.level } 
                for (const f of self.skillCustomFields) {
                  if (Object.hasOwn(i, f.name)) {
                    val[f.name] = i[f.name];
                  }
                }
                return val;
              }) 
              : []
            const list = Array.isArray(newValue)
              ? newValue.map(i => { 
                const val = { uuId: i.uuId, name: i.name, level: i.level } 
                for (const f of self.skillCustomFields) {
                  if (Object.hasOwn(i, f.name)) {
                    val[f.name] = i[f.name];
                  }
                }
                return val;
              }) 
              : []
            const toAdd = []
            const toUpdate = []
            const unchangedIds = []

            for(const item of list) {
              const index = oldList.findIndex(j => j.uuId === item.uuId)
              if(index == -1) {
                toAdd.push(item)
              } else {
                let hasChanged = oldList[index].level != item.level;
                if(!hasChanged) {
                  for (const f of self.skillCustomFields) {
                    if (Object.hasOwn(oldList[index], f.name) && Object.hasOwn(item, f.name)) {
                      if ((f.type == 'String' || f.type == 'Enum<String>') && oldList[index][f.name].localeCompare(item[f.name], undefined, { sensitivity: 'base' }) != 0) {
                        hasChanged = true
                        break
                      } else if ((f.type != 'String' && f.type != 'Enum<String>') && oldList[index][f.name] != item[f.name]) {
                        hasChanged = true
                        break
                      }
                    } else if (Object.hasOwn(oldList[index], f.name) || Object.hasOwn(item, f.name)) {
                      hasChanged = true
                      break
                    }
                  }
                }
                if (hasChanged) {
                  toUpdate.push(item)
                } else {
                  unchangedIds.push(item.uuId)
                }
              }
            }

            const toAddIds = toAdd.map(i => i.uuId)
            const toUpdateIds = toUpdate.map(i => i.uuId)
            const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                                && !toUpdateIds.includes(i.uuId) 
                                                && !unchangedIds.includes(i.uuId))
            const requests = []

            if(toAdd.length > 0) {
              const addSkillLinkReqTemplate = function(refId, staffId, skillList) {
                const list = [];
                skillList.forEach(i => {
                  const skillLink = {
                    level: i.level
                  }
                  for (const f of self.skillCustomFields) {
                    if (Object.hasOwn(i, f.name)) {
                      skillLink[f.name] = i[f.name]
                    }
                  }
                  list.push( {
                    uuId: i.uuId
                    , skillLink
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/skill/add`,
                  body: { 
                    uuId: staffId,
                    skillList: list
                  },
                  vars: [],
                  note: `staffAddSkillLink__${refId}__${skillList[0].uuId}`
                }
              }
              for (const [index, skill] of toAdd.entries()) {
                requests.push(addSkillLinkReqTemplate(`${index}_${entityId}`, entityId, [skill]));
              }
            }

            if(toUpdate.length > 0) {
              const updateSkillLinkReqTemplate = function(refId, staffId, skillList) {
                const list = [];
                skillList.forEach(i => {
                  const skillLink = {
                    level: i.level
                  }
                  for (const f of self.skillCustomFields) {
                    if (Object.hasOwn(i, f.name)) {
                      skillLink[f.name] = i[f.name]
                    }
                  }
                  list.push( {
                    uuId: i.uuId
                    , skillLink
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/skill/update`,
                  body: { 
                    uuId: staffId,
                    skillList: list
                  },
                  vars: [],
                  note: `staffUpdateSkillLink__${refId}__${skillList[0].uuId}`
                }
              }
              for (const [index, skill] of toUpdate.entries()) {
                requests.push(updateSkillLinkReqTemplate(`${index}_${entityId}`, entityId, [skill]));
              }
            }

            if(toRemove.length > 0) {
              const removeSkillLinkReqTemplate = function(refId, staffId, skillList) {
                const list = [];
                skillList.forEach(i => {
                  list.push( {
                    uuId: i.uuId
                  });
                });
                return {
                  method: 'POST',
                  invoke: `/api/staff/link/skill/delete`,
                  body: { 
                    uuId: staffId,
                    skillList: list
                  },
                  vars: [],
                  note: `staffRemoveSkillLink__${refId}__${skillList[0].uuId}`
                }
              }
              for (const [index, skill] of toRemove.entries()) {
                requests.push(removeSkillLinkReqTemplate(`${index}_${entityId}`, entityId, [skill]));
              }
            }

            if (requests.length > 0) {
              return {
                value: requests
                , status: 'SUCCESS'
              }
            }

            return {
              value: oldVal
              , status: 'ABORT'
              , property: 'skills'
            }
          }
        }
        , phones: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            return {
              value: Array.isArray(newValue)? newValue.map(i => { return { kind: i.kind, data: i.data }}) : []
              , status: 'SUCCESS'
              , isDataProp: true
            }
          }
        }
        , socials: {
          isAsync: false
          , execute: (entityId, oldVal, newValue) => {
            return {
              value: Array.isArray(newValue)? newValue.map(i => { return { kind: i.kind, data: i.data }}) : []
              , status: 'SUCCESS'
              , isDataProp: true
            }
          }
        }

      }
    }
    , async reloadOrgChart(callbackFunc=null, { redrawRows=false }={}) {
      if (this.orgChart == null || this.orgChart.gridOptions == null || this.orgChart.gridOptions.api == null) {
        return
      }
      const api = this.orgChart.gridOptions.api

      if (this.lackOfMandatoryField(this.queryMandatoryFields_orgChart)) {
        this.showNoRowsOverlay(this.orgChart, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return
      } else {
        this.orgChart.noRowsMessage = null
      }
      
      api.showLoadingOverlay();
      let companies = null;
      const { cmdList, departmentFields, companyFields, staffFields } = prepareDepartmentTreeRequest(this, true, { staffCustomFields: this.customFields });
      const responseData = await compositeService.exec(cmdList, true)
      .catch(e => {
        // console.error(error) // eslint-disable-line no-console
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_api') {
            this.showNoRowsOverlay(this.orgChart, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
          }
        }
        return null;
      });

      const companyKeys = getKeysWithoutRedactedFields(companyFields, { data: responseData.data.feedbackList[0] });
      const departmentKeys = getKeysWithoutRedactedFields(departmentFields, { data: responseData.data.feedbackList[1] });
      const staffKeys = getKeysWithoutRedactedFields(staffFields, { data: responseData.data.feedbackList[2] });
      companies = responseData.data.feedbackList[0].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[companyKeys[j]] = i[j];
        }
        return result;
      });
      
      let departments = {};
      
      if (responseData.data.feedbackList[1].fetch) {
        departments = responseData.data.feedbackList[1].fetch.map(i => {
          const result = {}
          for(let j = 0, len = i.length; j < len; j++) {
            result[departmentKeys[j]] = i[j];
          }
          return result;
        });
      }
      
      let staffNoDep = {};
      if (responseData.data.feedbackList[2]) {
        staffNoDep = responseData.data.feedbackList[2].fetch.map(i => {
          const result = {}
          for(let j = 0, len = i.length; j < len; j++) {
            result[staffKeys[j]] = i[j];
          }
          result.companies = [];
          if (Array.isArray(result.companyId) && result.companyId.length > 0) {
            for (let j = 0, jLen = result.companyId.length; j < jLen; j++) {
              result.companies.push({
                uuId: result.companyId[j]
                , name: result.companyName[j]
              });
            }
          }
          delete result.companyId;
          delete result.companyName;
          return result;
        });
      }
      
      let staffContracts = {};
      
      if (responseData.data.feedbackList[3]) {
        staffContracts = responseData.data.feedbackList[3].fetch.reduce((map, i) => {
          map[i[0]] = i[1].join();
          return map;
        }, {});
      }
      
      if (companies == null) {
        this.orgChart.rowData = [];
        api.setRowData(this.orgChart.rowData);
        if (redrawRows == true) {
          api.redrawRows();
        } else {
          api.refreshCells({force: true});
        }
        if (typeof callbackFunc === 'function') {
          callbackFunc();
        }
        return;
      }
    
      let tree = makeTree(companies, departments, staffNoDep, false, true, this.nonAdmin? this.company : null, { staffCustomFields: this.customFields, startDate: this.orgChart.dates === 'all-time' ? null : this.orgChart.startDate, endDate: this.orgChart.dates === 'all-time' ? null : this.orgChart.endDate, singleContracts: this.orgChart.singleContracts, staffContracts }, this.staffListUuIds ? this.staffListUuIds.reduce(function(map, obj) {
          map[obj] = true;
          return map;
      }, {}) : null);
      //filter company
      const toRemoveList = [];
      for (const t of tree) {
        this.filterCompany(t, JSON.parse(JSON.stringify(this.companyFilterIds)));
        if (t.keep != true) {
          toRemoveList.push(t.uuId);
        } else {
          delete t.keep;
        }
      }
      while(toRemoveList.length > 0) {
        const toRemoveId = toRemoveList.pop();
        const idx = tree.findIndex(i => i.uuId == toRemoveId);
        if (idx > -1) {
          tree.splice(idx, 1);
        }
      }
      
      this.treeData = this.pruneTree(tree, this.orgChart.searchFilter)
      const tmpData1 = this.extractRowsFromData('', this.treeData, this.orgChart.listStaff).filter(r => !r.staff || (r.genericStaff == true && this.orgChartGenericStaff) || (r.genericStaff != true && this.orgChartRealStaff))
      this.orgChart.rowData = cloneDeep(tmpData1)
      api.setRowData(this.orgChart.rowData)
      if (redrawRows == true) {
        api.redrawRows();
      } else {
        api.refreshCells({force: true});
      }
     
      setTimeout(() => {
        this.processOrgChartNodes()
        if (typeof callbackFunc == 'function') {
          callbackFunc()
        }
      }, 100)
    }
    , processOrgChartNodes() {
      if (this.orgChart.gridOptions.api !== null) {
        const allowedCompanies = new Set();
        this.orgChart.gridOptions.api.forEachNode((node) => {
          if (node.data == null) {
            node.setExpanded(false)
          } else if (node.data.uuId !== this.$store.state.company.uuId &&
              (
                (node.data.company?.uuId == null && node.data.companyPath == null) ||
                (node.data.company?.uuId != null && !allowedCompanies.has(node.data.company.uuId)) ||
                (node.data.companyPath != null && !node.data.companyPath.includes(this.$store.state.company.uuId))
              )
          ) {
            node.setExpanded(false)
          } else if (node.data?.companyPath != null && node.data.companyPath.includes(this.$store.state.company.uuId)) {
            for (const c of node.data.companyPath) {
              allowedCompanies.add(c)
            }
            node.setExpanded(true)
          } else if (node.data.company?.uuId != null && allowedCompanies.has(node.data.company.uuId)) {
            node.setExpanded(true)
          }
        });
      }
    }
    , selectorNavClick(event) {
      /**
       * Update activeTab with the user choice.
       */
      const liElem = event.srcElement.closest('li');
      const activeName = liElem.getAttribute('name');
      
      const tabList = this.tabList;
      for(let i = 0, len = tabList.length; i < len; i++) {
        const tab = tabList[i];
        if(tab.name == activeName) {
          this.activeTab = i;
        }
      }
    }
    , async updateTabLink(activeIndex) {
      //Update the active state/style of links.
      const navbarElem = this.$refs['selector-nav']; 
      if (navbarElem != null) {
        const childs = navbarElem.$el.querySelectorAll('li');
        for (let i = 0; i < childs.length; i++) {
          if (activeIndex == i) {
            childs[i].classList.add('active');
          } else if(childs[i] != null && childs[i].classList != null) {
            childs[i].classList.remove('active');
          }
        }
      }
    }
    , onTabSwitch(newTabIndex /**, prevTabIndex */) {
      this.updateTabLink(newTabIndex);
      //When switching between tabs, clear all selection in previous tab
      const newTab = this.tabList[newTabIndex]
      const newTabName = this.tabList[newTabIndex].name;
      if (newTab == 'list') {
        this.sizeColumnToFitWhenNewToProfile(newTab, this.gridOptions.api, this.gridOptions.columnApi);
        if(this.orgChart && this.orgChart.gridOptions.api) {
          if (this.nonAdmin) {
            this.orgChart.gridOptions.api.deselectAll()
          } else {
            this.orgChart.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.generic && this.generic.gridOptions.api) {
          if (this.nonAdmin) {
            this.generic.gridOptions.api.deselectAll()
          } else {
            this.generic.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.nonAdmin && this.recommended?.gridOptions?.api) {
          this.recommended.gridOptions.api.deselectAll()
        }
      } else if (newTabName == 'orgChart') {
        this.sizeColumnToFitWhenNewToProfile(newTab, this.orgChart.gridOptions.api, this.orgChart.gridOptions.columnApi);
        if (this.gridOptions.api) {
          if (this.nonAdmin) {
            this.gridOptions.api.deselectAll()
          } else {
            this.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.generic && this.generic.gridOptions.api) {
          if (this.nonAdmin) {
            this.generic.gridOptions.api.deselectAll()
          } else {
            this.generic.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.nonAdmin && this.recommended?.gridOptions?.api) {
          this.recommended.gridOptions.api.deselectAll()
        }
      } else if (newTabName == 'generic') {
        this.sizeColumnToFitWhenNewToProfile(newTab, this.generic.gridOptions.api, this.generic.gridOptions.columnApi);
        this.generic.select_state.checked = false;
        if (this.gridOptions.api) {
          if (this.nonAdmin) {
            this.gridOptions.api.deselectAll()
          } else {
            this.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.orgChart && this.orgChart.gridOptions.api) {
          if (this.nonAdmin) {
            this.orgChart.gridOptions.api.deselectAll()
          } else {
            this.orgChart.gridOptions.api.clearRangeSelection()
          }
        }
        if(this.nonAdmin && this.recommended?.gridOptions?.api) {
          this.recommended.gridOptions.api.deselectAll()
        }
      } else if (newTabName == 'recommended') {
        this.sizeColumnToFitWhenNewToProfile(newTab, this.recommended.gridOptions.api, this.recommended.gridOptions.columnApi);
        if (this.gridOptions.api) {
          this.gridOptions.api.deselectAll()
        }
        if(this.orgChart && this.orgChart.gridOptions.api) {
          this.orgChart.gridOptions.api.deselectAll()
        }
        if(this.generic && this.generic.gridOptions.api) {
          this.generic.gridOptions.api.deselectAll()
        }
      }
    }
    , onSingleContractsChange() {
      if (this.alertMsg === this.$t('staff.error.not_found')) {
        this.alertMsg = null
        // this.selected = cloneDeep(this.selectedStaff);
        //TODO: do we need to keep the range selection?
      }
      const tab = this.tabList.find(i => i.name == 'list')
      const profileKeyOpts = `${tab.profileKey}_opts`
      if (this.profileSettings[profileKeyOpts] == null) {
        this.profileSettings[profileKeyOpts] = {}
      }
      this.profileSettings[profileKeyOpts].singleContracts = this.singleContracts
      this.profileSettings[profileKeyOpts].dates = this.dates;
      this.profileSettings[profileKeyOpts].startDate = this.startDate;
      this.profileSettings[profileKeyOpts].endDate = this.endDate;
      this.updateViewProfile()
      this.$nextTick(() => {
        let api = this.gridOptions.api
        if(api != null) {
          api.refreshServerSide({ purge: true })
        }
      });
    }
    , applyOrgChartFilter(pattern) {
      this.orgChart.searchFilter = pattern;
      
      this.orgChart.searchFilterTerm = this.profileSettings[`${this.formattedEntity}_orgchart${this.nonAdmin? '' : '_admin'}_selector_search`] = this.orgChart.searchFilter;
      this.updateViewProfile()
      
      this.reloadOrgChart();
    }
    
    , processCellCallbackOrgChart(self) {
      return function(params) {
        if (params.column.colId.indexOf('staff') !== -1) {
          if (params.node.data.staff) {
            return params.node.data.name;
          }
          return null;
        }
        else if (params.column.colId.indexOf('department') !== -1) {
          if (params.node.data.staff) {
            return null;
          }
          return params.node.data.name;
        }
        else if (params.column.colId.indexOf('path') !== -1) {
          const ids = params.node.data.path.split(', ');
          let path = '';
          let parentPath = '';
          for (const id of ids) {
            const parent = self.orgChart.gridOptions.api.getRowNode(parentPath);
            if (parent) {
              path = path !== '' ? `${path}/${parent.data.name}` : parent.data.name;
            }
            parentPath = parentPath === '' ? id : `${parentPath}, ${id}`
          }
          return path;
        }
        return params.value;
      }
    }
    , onOrgChartRealStaffChange() {
      const tab = this.tabList.find(i => i.name == 'orgChart')
      const profileKeyOpts = `${tab.profileKey}_opts`
      if (this.profileSettings[profileKeyOpts] == null) {
        this.profileSettings[profileKeyOpts] = {}
      }
      this.profileSettings[profileKeyOpts].realStaff = this.orgChartRealStaff;
      this.updateViewProfile();
      this.reloadOrgChart();
    }
    , onOrgChartSingleContractsChange() {
      const tab = this.tabList.find(i => i.name == 'orgChart')
      const profileKeyOpts = `${tab.profileKey}_opts`
      if (this.profileSettings[profileKeyOpts] == null) {
        this.profileSettings[profileKeyOpts] = {}
      }
      this.profileSettings[profileKeyOpts].singleContracts = this.orgChart.singleContracts;
      this.updateViewProfile();
      this.reloadOrgChart();
    }
    , onOrgChartGenericStaffChange() {
      const tab = this.tabList.find(i => i.name == 'orgChart')
      const profileKeyOpts = `${tab.profileKey}_opts`
      if (this.profileSettings[profileKeyOpts] == null) {
        this.profileSettings[profileKeyOpts] = {}
      }
      this.profileSettings[profileKeyOpts].genericStaff = this.orgChartGenericStaff;
      this.updateViewProfile();
      this.reloadOrgChart();
    }
    , isEditable(params) {
      if (typeof params.data !== 'undefined' &&
          typeof params.data.name !== 'undefined' &&
          typeof params.data.staff === 'undefined' &&
          typeof params.data.genericStaff === 'undefined') {
        return false; // can't edit departments
      }
      return true;
    }
    , handleAjaxError(feedbackList) {
      const foundResult = feedbackList.find(i => (i.clue == 'Not_unique_key' || i.clue == 'Invalid_value') && 
                                                  Array.isArray(i.args) && i.args.length > 0 && i.args[0] == 'email')
      let alertMsg = null
      if (foundResult != null) {
        if (foundResult.clue == 'Not_unique_key') {
          alertMsg = this.$t('error.not_unique_key_with_arg', [this.$t('user.field.email')])
        } else {
          alertMsg = this.$t('error.invalid_value_with_arg', [this.$t('user.field.email')])
        }
      }
      //  else {
      //   alertMsg = this.$t('error.internal_server')
      // }
      return {
        msg: alertMsg
      }
    }
    , lackOfMandatoryField(mandatoryFields) {
      const _mandatoryFields = mandatoryFields != null? mandatoryFields : this.queryMandatoryFields
      const denyRulesMap = {
        'STAFF': getPermissionDenyProperties('STAFF', 'VIEW')
      }
      for (const field of _mandatoryFields) {
        const tokens = field.split('.')
        if (tokens.length == 1) {
          if (denyRulesMap.STAFF.includes(tokens[tokens.length-1])) {
            return true
          }
        } else {
          let linkedEntity = tokens[tokens.length - 2]
          let properties = [tokens[tokens.length - 1]]
          if (linkedEntity.indexOf('-') > -1) {
            const subTokens = linkedEntity.split('-')
            linkedEntity = subTokens[0]
            properties = [linkedEntity, `${subTokens[1]}.${properties[0]}`]
          }
          
          if (!Object.hasOwn(denyRulesMap, linkedEntity)) {
            denyRulesMap[linkedEntity] = getPermissionDenyProperties(linkedEntity, 'VIEW')
          }
          for (const property of properties) {
            if (denyRulesMap[linkedEntity].includes(property)) {
              return true
            }
          }
        }
      }
      return false
    }
    , getPropertyDeleteHandler() {
      return {
        startDate: () => { 
          return { value: 0, status: 'SUCCESS' }
        }
        , endDate: () => { 
          return { value: 32503680000000, status: 'SUCCESS' }
        }
      }

    }
    , getColumnDefs(c) {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }
    , saveSelectorColumnSettings(columns, tab, skipCheck=true) {
      const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => this.getColumnDefs(c))
      const oldColumns = this.profileSettings[tab.profileKey]
      if (skipCheck) {
        this.profileSettings[tab.profileKey] = newColumns
        this.updateViewProfile()
        return
      }

      let hasChanged = false
      if (oldColumns == null) {
        hasChanged = true
      } else if (oldColumns.length != newColumns.length) {
        hasChanged = true
      } else {
        for (const [index, col] of oldColumns.entries()) {
          if (col.colId != newColumns[index].colId || 
              col.width != newColumns[index].width ||
              col.sort != newColumns[index].sort ||
              col.sortIndex != newColumns[index].sortIndex) {
            hasChanged = true
            break
          }
        }
      }
      if (hasChanged) {
        this.profileSettings[tab.profileKey] = newColumns
        this.updateViewProfile()
      }
    }
    , sizeColumnToFitWhenNewToProfile(tab, api, columnApi) {
      if (tab.newToProfile == true) {
        tab.newToProfile = null
        api.sizeColumnsToFit()
        this.$nextTick(() => {
          const columns = columnApi.getAllDisplayedColumns()
          this.saveSelectorColumnSettings(columns, tab)
        })
      }
    }

    , prepareNoRowsMessage(self) {
      return () => {
          if (self.noRowsMessage != null) {
          return self.noRowsMessage;  
        }
        return this.$t('staff.grid.no_data');
      }
    }
    , showNoRowsOverlay(self, msg=null) {
      self.noRowsMessage = msg
      if (self.gridOptions != null && self.gridOptions.api != null) {
        self.gridOptions.api.hideOverlay()
        setTimeout(() => {
          self.gridOptions.api.showNoRowsOverlay()
        })
      }
    }
    , onCellEditingStarted(/** event **/) {
      this.isCellEditing = true
    }
    , onCellEditingStopped(/** event **/) {
      this.isCellEditing = false
    }
    , getRowColumnDefs(isGeneric=false) {
      const self = this
      let specificColumns = []
      if (isGeneric) {
        specificColumns.push(...[{
          headerName: this.$t('customer.field.name')
          , field: 'uuId'
          , cellRenderer: 'detailLinkCellRenderer'
          , cellRendererParams: {
            label: 'firstName'
            , tabName: 'generic'
          }
          , cellEditor: 'nameEditor'
          , cellEditorParams: {
            customProp: 'firstName'
          }
          , checkboxSelection: this.nonAdmin
          , pinned: 'left'
          , lockPosition: 'left'
          , lockVisible: true
          , minWidth: 200
          , hide: false
          , sort: 'asc'
          , editable: params => this.canEdit(this.permissionName, ['firstName']) && params.data.readOnly != true
          , valueSetter: function(params) {
            const newValue = params.newValue.trim()
            const oldValue = objectClone(params.data.firstName)
            if (newValue !== '' && newValue != oldValue) {
              self.$set(params.data, 'oldName', oldValue)
              params.data.firstName = newValue
              return true
            }
            return false
          }
          , 
          cellStyle: params => {
            if (params.data &&
                params.data.color &&
                self.genericColoring[self.formattedEntity]) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) }
            }
            else if (params.data &&
                params.data.companyColor &&
                self.genericColoring.company) {
                return { background: params.node.data.companyColor, color: invertColor(params.node.data.companyColor, true) }
            }
            else if (params.data &&
                params.data.departmentColor &&
                self.genericColoring.department) {
                return { background: params.node.data.departmentColor, color: invertColor(params.node.data.departmentColor, true) }
            }
            else if (params.data &&
                params.data.locationColor &&
                self.genericColoring.location) {
                return { background: params.node.data.locationColor, color: invertColor(params.node.data.locationColor, true) }
            }
            else if (params.data &&
                params.data.skillColor &&
                self.genericColoring.skill) {
                return { background: params.node.data.skillColor, color: invertColor(params.node.data.skillColor, true) }
            }
            else if (params.data &&
                params.data.resourceColor &&
                self.genericColoring.resource) {
                return { background: params.node.data.resourceColor, color: invertColor(params.node.data.resourceColor, true) }
            }
          }
        }
        , {
          headerName: this.$t('staff.field.company')
          , field: 'companies'
          , cellRenderer: 'genericEntityArrayCellRenderer'
          , cellEditor: 'companiesEditor'
          , editable: params => params.data.readOnly != true
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.resourceQuota')
          , field: 'resourceQuota'
          , cellRenderer: 'genericCellRenderer'
          , cellEditor: 'numericEditor'
          , cellEditorParams: {
            minValueProp: 'minQuotaValue'
            , maxValueProp: 'maxQuotaValue'
          }
          , minWidth: 100
          , hide: true
          , editable: params => params.data.readOnly != true
        }])
      } else {
        specificColumns.push(
          ...[
            {
              headerName: this.$t('staff.field.lastName')
              , field: 'uuId'
              , cellRenderer: 'detailLinkCellRenderer'
              , cellRendererParams: {
                label: 'lastName'
                , tabName: 'list'
              }
              , cellEditor: 'nameEditor'
              , cellEditorParams: {
                customProp: 'lastName'
              }
              , checkboxSelection: this.nonAdmin
              , pinned: 'left'
              , lockPosition: 'left'
              , lockVisible: true
              , minWidth: 150
              , hide: false
              , sort: 'asc'
              , editable: params => this.canEdit(this.permissionName, ['lastName']) && params.data.readOnly != true
              , valueSetter: function(params) {
                const newValue = params.newValue != null? params.newValue.trim() : ''
                const oldValue = objectClone(params.data.lastName)
                if (newValue !== '' && newValue != oldValue) {
                  self.$set(params.data, 'oldName', oldValue)
                  params.data.lastName = newValue
                  return true
                }
                return false
              }
              , 
              cellStyle: params => {
                if (params.data &&
                    params.data.color &&
                    self.coloring[self.formattedEntity]) {
                    return { background: params.node.data.color, color: invertColor(params.node.data.color, true) }
                }
                else if (params.data &&
                    params.data.companyColor &&
                    self.coloring.company) {
                    return { background: params.node.data.companyColor, color: invertColor(params.node.data.companyColor, true) }
                }
                else if (params.data &&
                    params.data.departmentColor &&
                    self.coloring.department) {
                    return { background: params.node.data.departmentColor, color: invertColor(params.node.data.departmentColor, true) }
                }
                else if (params.data &&
                    params.data.locationColor &&
                    self.coloring.location) {
                    return { background: params.node.data.locationColor, color: invertColor(params.node.data.locationColor, true) }
                }
                else if (params.data &&
                    params.data.skillColor &&
                    self.coloring.skill) {
                    return { background: params.node.data.skillColor, color: invertColor(params.node.data.skillColor, true) }
                }
                else if (params.data &&
                    params.data.resourceColor &&
                    self.coloring.resource) {
                    return { background: params.node.data.resourceColor, color: invertColor(params.node.data.resourceColor, true) }
                }
              }
            }
            , {
              headerName: this.$t('staff.field.firstName')
              , field: 'firstName'
              , cellRenderer: 'genericCellRenderer'
              , cellEditor: 'stringEditor'
              , hide: false
              , minWidth: 150
              , editable: params => params.data.readOnly != true
            }
            , { 
              headerName: this.$t('staff.field.email')
              , field: 'email'
              , cellRenderer: 'genericCellRenderer'
              , cellEditor: 'stringEditor'
              , minWidth: 100
              , hide: true
              , editable: params => params.data.readOnly != true
            }
            , { 
              headerName: this.$t('staff.field.position')
              , field: 'position'
              , cellRenderer: 'genericCellRenderer'
              , cellEditor: 'stringEditor'
              , hide: false
              , minWidth: 150
              , editable: params => params.data.readOnly != true
            }
            , {
              headerName: this.$t('staff.field.company')
              , field: 'companies'
              , cellRenderer: 'genericEntityArrayCellRenderer'
              , cellEditor: 'companiesEditor'
              , editable: params => params.data.readOnly != true
              , minWidth: 100
              , hide: true
            }
            , {
              headerName: this.$t('staff.field.phones')
              , field: 'phones'
              , minWidth: 100
              , cellRenderer: 'genericEntityArrayCellRenderer'
              , cellRendererParams: {
                  labelFormatter: (params) => {
                    if (Array.isArray(params.value)) {
                      params.value.forEach(i => {
                        if (i.kind != null && i.data != null) {
                          i.label = `${i.kind}:${i.data}`
                        } else {
                          i.label = ''
                        }
                      })
                    } 
                    return { value: params.value }
                  }
                }
              , cellEditor: 'phonesEditor'
              , cellEditorParams: {
                permissionName: 'STAFF'
              }
              , editable: params => params.data.readOnly != true
              , hide: true
            }
            , {
              headerName: this.$t('staff.field.socials')
              , field: 'socials'
              , minWidth: 100
              , cellRenderer: 'genericEntityArrayCellRenderer'
              , cellRendererParams: {
                  labelFormatter: (params) => {
                    if (Array.isArray(params.value)) {
                      params.value.forEach(i => {
                        if (i.kind != null && i.data != null) {
                          i.label = `${i.kind}:${i.data}`
                        } else {
                          i.label = ''
                        }
                      })
                    } 
                    return { value: params.value }
                  }
                }
              , cellEditor: 'socialsEditor'
              , cellEditorParams: {
                permissionName: 'STAFF'
              }
              , editable: params => params.data.readOnly != true
              , hide: true
            }
            , {
              headerName: this.$t('staff.field.websites')
              , field: 'websites'
              , minWidth: 100
              , cellRenderer: 'genericCellRenderer'
              , cellEditor: 'websitesEditor'
              , cellEditorParams: {
                permissionName: 'CUSTOMER'
              }
              , hide: true
              , editable: params => params.data.readOnly != true
            }
            , {
              headerName: this.$t('staff.field.startDate')
              , field: 'startDate'
              , cellRenderer: 'dateOnlyCellRenderer'
              , cellEditor: 'dateTimeEditor'
              , cellEditorParams: {
                editorMode: 1
                , displayMode: 'date'
                , treatAsNull: [0, 253402214400000] //Order: latest null value is always the first item in the list.
                , optional: true
              }
              , editable: params => params.data.readOnly != true
              , hide: true
            }
            ,  {
              headerName: this.$t('staff.field.endDate')
              , field: 'endDate'
              , cellRenderer: 'dateOnlyCellRenderer'
              , cellEditor: 'dateTimeEditor'
              , cellEditorParams: {
                editorMode: 1
                , displayMode: 'date'
                , treatAsNull: [32503680000000, 253402214400000] //Order: latest null value is always the first item in the list.
                , optional: true
              }
              , editable: params => params.data.readOnly != true
              , hide: true
            }
          ]
        )
      }

      const colDefs = [
        ...specificColumns
        , {
            headerName: this.$t('staff.field.type')
            , field: 'staffType'
            , cellEditor: 'listEditor'
            , cellRenderer: 'enumCellRenderer'
            , cellRendererParams: { options: this.typeOptions, enableReadonlyStyle: true }
            , cellEditorParams: { options: this.typeOptions, isEnumType: true }
            , editable: params => params.data.readOnly != true
            , minWidth: 100
            , hide: true
            
          }
        , {
          headerName: this.$t('staff.field.location')
          , field: 'locations'
          , cellRenderer: 'genericEntityArrayCellRenderer'
          , cellEditor: 'locationsEditor'
          , cellEditorParams: {
            isOptional: false
            , multiple: false
          }
          , editable: params => params.data.readOnly != true
          , hide: true
        }
        
        , {
            headerName: this.$t('staff.field.department')
            , field: 'departments'
            , cellRenderer: 'genericEntityArrayCellRenderer'
            , cellEditor: 'departmentsEditor'
            , cellEditorParams: {
              multiple: true
            }
            , editable: params => params.data.readOnly != true
            , hide: true
            , minWidth: 150
          }
        , {
          headerName: this.$t('staff.field.resources')
          , field: 'resources'
          , cellRenderer: 'genericEntityArrayCellRenderer'
          , cellRendererParams: {
            labelFormatter: (params) => {
              if (params == null || params.value == null || !Array.isArray(params.value)) {
                return { value: [] }
              }
              params.value.forEach(i => {
                if (i.name != null && i.unit != null) {
                  i.label = `${i.name} (${i.unit})`
                }
              })
              return { value: params.value }
            }
          }
          , cellEditor: 'resourcesEditor'
          , cellEditorParams: {
            edgeName: 'STAFF-RESOURCE'
          }
          , editable: params => params.data.readOnly != true
          , hide: true
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.skills')
          , field: 'skills'
          , cellRenderer: 'genericEntityArrayCellRenderer'
          , cellRendererParams: {
            labelFormatter: (params) => {
              if (params == null || params.value == null || !Array.isArray(params.value)) {
                return { value: [] }
              }
              params.value.forEach(i => {
                if (i.name != null && i.level != null) {
                  i.label = `${i.name} (${i.level})`
                }
              })
              return { value: params.value }
            }
          }
          , cellEditor: 'skillsEditor'
          , cellEditorParams: {
            edgeName: 'STAFF-SKILL'
          }
          , editable: params => params.data.readOnly != true
          , hide: isGeneric? false : true
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.payAmount')
          , field: 'payAmount'
          , cellRenderer: 'costCellRenderer'
          , cellEditor: 'costEditor'
          , minWidth: 100
          , hide: isGeneric? false : true
          , editable: params => params.data.readOnly != true
        }
        , {
          headerName: this.$t('staff.field.payCurrency')
          , field: 'payCurrency'
          , cellEditor: 'listEditor'
          , cellRenderer: 'enumCellRenderer'
          , cellRendererParams: { options: this.optionCurrency, enableReadonlyStyle: true }
          , cellEditorParams: { options: this.optionCurrency, isEnumType: true }
          , editable: params => params.data.readOnly != true
        }
        , {
          headerName: this.$t('staff.field.payFrequency')
          , field: 'payFrequency'
          , cellEditor: 'listEditor'
          , cellRenderer: 'enumCellRenderer'
          , cellRendererParams: { options: this.payFrequencyOptions, enableReadonlyStyle: true }
          , cellEditorParams: { options: this.payFrequencyOptions }
          , minWidth: 100
          , hide: true
          , editable: params => params.data.readOnly != true
        }
        , {
          headerName: this.$t('field.tag')
          , field: 'tag'
          , cellRenderer: 'genericCellRenderer'
          , cellEditor: 'tagEditor'
          , minWidth: 100
          , hide: true
          , editable: params => params.data.readOnly != true
        },
        {
          headerName: this.$t('field.color')
          , field: 'color'
          , cellRenderer: 'colorCellRenderer'
          , cellEditor: 'colorEditor'
          , hide: true
          , editable: params => params.data.readOnly != true
        },
        {
          headerName: this.$t('field.identifier_full')
          , field: 'identifier'
          , cellRenderer: 'genericCellRenderer'
          , cellEditor: 'stringEditor'
          , minWidth: 100
          , hide: true
          , editable: params => params.data.readOnly != true
        }
      ]

      prepareCustomFieldColumnDef(colDefs, self.customFields, { self })

      const linkedEntities = [
          { selector: 'STAFF.TAG', field: 'tag', properties: ['name'] }
        , { selector: 'STAFF.LOCATION', field: 'locations', properties: ['name'] }
        , { selector: 'STAFF.SKILL', field: 'skills', properties: ['name'] }
        , { selector: 'STAFF.RESOURCE', field: 'resources', properties: ['name'] }
        , { selector: 'STAFF.DEPARTMENT', field: 'departments', properties: ['name', 'STAFF'] }
        , { selector: 'STAFF.COMPANY', field: 'companies', properties: ['name', 'STAFF'] }
      ]
      
      const viewLinkedEntities = JSON.parse(JSON.stringify(linkedEntities))
      viewLinkedEntities.push({ selector: 'STAFF', field: 'payAmount', properties: ['payCurrency'] })
      //VIEW permission: Remove column from display list
      filterOutViewDenyProperties(colDefs, 'STAFF', viewLinkedEntities)

      //When department is read only, company become read only too. Reason: Changing company also removing department link too.
      linkedEntities.push({ selector: 'STAFF.DEPARTMENT', field: 'companies', properties: ['name', 'STAFF'] })
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'STAFF', linkedEntities)
      
      colDefs.sort(columnDefSortFunc)
      if (!this.nonAdmin) {
        colDefs.unshift(this.getRowSelectorColumn(false, isGeneric?'generic':'list'))
      }
      return colDefs
    }
    , getOrgChartColDefs() {
      const self = this
      const colDefs = [
        {
          headerName: this.$t('staff.field.position')
          , field: 'position'
          , cellRenderer: 'genericCellRenderer'
          , hide: false
          , minWidth: 150
          , editable: (params) => self.isEditable(params) && params.data.readOnly != true
        }
        , {
          headerName: this.$t('field.identifier_full')
          , field: 'identifier'
          , cellRenderer: 'genericCellRenderer'
          , minWidth: 100
          , hide: true
          , editable: (params) => self.isEditable(params) && params.data.readOnly != true
        }
        , {
          field: 'staff'
          , hide: true
          , suppressColumnsToolPanel: true
          , suppressFiltersToolPanel: true
        }
        , {
          field: 'department'
          , hide: true
          , suppressColumnsToolPanel: true
          , suppressFiltersToolPanel: true
          , editable: (params) => self.isEditable(params) && params.data.readOnly != true
        }
        , {
          field: 'path'
          , hide: true
          , suppressColumnsToolPanel: true
          , suppressFiltersToolPanel: true
        }
      ]

      const customDefs = []
      prepareCustomFieldColumnDef(customDefs, self.customFields, { self })

      if (customDefs.length > 0) {
        for (const def of customDefs) {
          //only editable when it is staff row. It is staff row when data contains 'staff' property.
          def.editable = (params) => {
            return params.data?.staff == true
          }
        }
        colDefs.push(...customDefs);
      }

      const linkedEntities = [
        // { permissionName: 'TAG', field: 'tag' }
        // , { permissionName: 'LOCATION', field: 'locations' }
      ]

       //VIEW permission: Remove column from display list
      filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities)
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'STAFF', linkedEntities)

      colDefs.sort(columnDefSortFunc)
      if (!this.nonAdmin) {
        colDefs.unshift(this.getRowSelectorColumn(true, 'orgChart'))
      }
      return colDefs;
    }
    , filterCompany(treeNode, filterIds) {
      if (filterIds.length == 0) {
        this.trimPropertiesForUnrelatedCompany(treeNode);
        if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
          const toRemoveList = [];
          for (const t of treeNode.companyList) {
            this.filterCompany(t, filterIds);
            if (t.keep != true) {
              toRemoveList.push(t.uuId);
            } else {
              treeNode.keep = true;
              delete t.keep;
            }
          }
          while(toRemoveList.length > 0) {
            const toRemovedId = toRemoveList.pop();
            const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
            treeNode.companyList.splice(idx, 1);
          }
        }
        return;
      }
      
      if (treeNode.uuId == null) {
        this.trimPropertiesForUnrelatedCompany(treeNode);
        if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
          const toRemoveList = [];
          for (const t of treeNode.companyList) {
            this.filterCompany(t, filterIds);
            if (t.keep != true) {
              toRemoveList.push(t.uuId);
            } else {
              treeNode.keep = true;
              delete t.keep;
            }
          }
          while(toRemoveList.length > 0) {
            const toRemovedId = toRemoveList.pop();
            const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
            treeNode.companyList.splice(idx, 1);
          }
        }
        return;
      }
      
      const foundIdx = filterIds.findIndex(i => i == treeNode.uuId || (treeNode.companyPath != null && treeNode.companyPath.includes(i)))
      if (foundIdx > -1) {
        treeNode.keep = true;
        filterIds.splice(foundIdx, 1);
        return;
      }
      
      this.trimPropertiesForUnrelatedCompany(treeNode);
      if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
        const toRemoveList = [];
        for (const t of treeNode.companyList) {
          this.filterCompany(t, filterIds);
          if (t.keep != true) {
            toRemoveList.push(t.uuId);
          } else {
            treeNode.keep = true;
            delete t.keep;
          }
        }
        while(toRemoveList.length > 0) {
          const toRemovedId = toRemoveList.pop();
          const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
          treeNode.companyList.splice(idx, 1);
        }
      }
    }
    , trimPropertiesForUnrelatedCompany(treeNode) {
      if (Array.isArray(treeNode.departmentList) && treeNode.departmentList.length > 0) {
        treeNode.departmentList.splice(0, treeNode.departmentList.length);
      }
      if (Array.isArray(treeNode.staffList) && treeNode.staffList.length > 0) {
        treeNode.staffList.splice(0, treeNode.staffList.length);
      }
    }
    , onFilterOver(evt, tabName) {
      const { filterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      this.$refs[filterRefId].visible = true;
    }
    , onFilterLeave(evt, tabName) {
      const { filterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      this.$refs[filterRefId].visible = false;
    }
    , onFilterTextDropdownHide(bvEvent, tabName) {
      const { self } = this.getRespectiveBadgeFilterDetails(tabName);
      if(self.filterTextFocus){
        bvEvent.preventDefault();
      }
    }
    , onFilterSubmit(evt, tabName) {
      const { self, filterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      self.filterTextFocus = false;
      if (this.$refs != null && this.$refs[filterRefId] != null) {
        this.$refs[filterRefId].visible = false;
      }
      self.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.
      
      this.applyFilter(self.filterText, tabName);
    }
    , onFilterClear(evt, tabName) {
      const { self } = this.getRespectiveBadgeFilterDetails(tabName);
      self.filterText = '';
      this.applyFilter(self.filterText, tabName);
    }
    , onBadgeFilterModified(filter, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self, profileKeyBadgeFilters } = this.getRespectiveBadgeFilterDetails(tabName)
      self.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      const removedFilterFields = self.badgeFilters.filter(i => filter.find(j => j.field == i.field) == null).map(i => i.field);
      self.badgeFilters = filter;
      
      for (const f of filter) {
        if (Object.hasOwn(self.badgeFilterFieldValues, f.field)) {
          if (Array.isArray(f.value) && f.value.length > 0) {
            self.badgeFilterFieldValues[f.field].forEach(i => {
              i.checked = f.value.find(j => {
                if (typeof i.text === 'string') {
                  return j.text.localeCompare(i.text, undefined, { sensitivity: 'base' }) == 0
                }
                return j.text == i.text
              }) != null
            });
          } else {
            self.badgeFilterFieldValues[f.field].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
          }
        }
      }
      //reset checked state for the previously selected but now removed filter
      if (removedFilterFields.length > 0) {
        for (const f of removedFilterFields) {
          if (Object.hasOwn(self.badgeFilterFieldValues, f)) {
            self.badgeFilterFieldValues[f].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
           
          }
        }
      }
      self.badgeFilterFieldValues = JSON.parse(JSON.stringify(self.badgeFilterFieldValues)); //Force triggering vue reactivity
      this.profileSettings[profileKeyBadgeFilters] = filter;
      this.updateViewProfile()
      this.changeFilter(tabName);
    }
    , onBadgeFilterDropdownHide(bvEvent, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self } = this.getRespectiveBadgeFilterDetails(tabName)
      if (self.badgeFilterFocus || self.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    }
    , onBadgeFilterEnter(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { badgeFilterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      this.$refs[badgeFilterRefId].visible = true;
    }
    , onBadgeFilterOver(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self, badgeFilterRefId } = this.getRespectiveBadgeFilterDetails(tabName);

      if (this.$refs[badgeFilterRefId] != null 
          && this.$refs[badgeFilterRefId].$el.id != null 
          && evt.target.closest('.dropdown-toggle') != null 
          && self.badgeFilterModalOpened != 'open' 
          && self.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs[badgeFilterRefId].$el.id)) {
          self.badgeFilterFocus = false; 
        }
      }
    }
    , onBadgeFilterLeave(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self, badgeFilterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      if (!self.badgeFilterFocus) {
        this.$refs[badgeFilterRefId].visible = false;
      }
    }
    , onBadgeFilterModalOpened(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self } = this.getRespectiveBadgeFilterDetails(tabName);
      self.badgeFilterModalOpened = 'open';
      self.badgeFilterFocus = true;
    }
    , onBadgeFilterModalClosed(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self } = this.getRespectiveBadgeFilterDetails(tabName);
      self.badgeFilterModalOpened = 'signaled-close';
      self.badgeFilterFieldValues = {}
    }
    , toggleBadgeFilterFocus(evt) {
      //list
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && this.$refs.badgeFilter?.$el?.id != null && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }

      //orgChart
      if (this.orgChart.badgeFilterModalOpened == 'signaled-close') {
        this.orgChart.badgeFilterModalOpened = 'close';
      } else if (this.orgChart.badgeFilterFocus 
                  && this.orgChart.badgeFilterModalOpened == 'close' 
                  && (this.$refs.badgeFilter_orgChart?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter_orgChart.$el.id}`) == null)) {
        this.orgChart.badgeFilterFocus = false;
      } else if (!this.orgChart.badgeFilterFocus && this.$refs.badgeFilter_orgChart?.$el?.id != null && evt.target.closest(`#${this.$refs.badgeFilter_orgChart.$el.id}`) != null) {
        this.orgChart.badgeFilterFocus = true;
      }

      //generic
      if (this.generic.badgeFilterModalOpened == 'signaled-close') {
        this.generic.badgeFilterModalOpened = 'close';
      } else if (this.generic.badgeFilterFocus 
                  && this.generic.badgeFilterModalOpened == 'close' 
                  && (this.$refs.badgeFilter_generic?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter_generic.$el.id}`) == null)) {
        this.generic.badgeFilterFocus = false;
      } else if (!this.generic.badgeFilterFocus && this.$refs.badgeFilter_generic?.$el?.id != null && evt.target.closest(`#${this.$refs.badgeFilter_generic.$el.id}`) != null) {
        this.generic.badgeFilterFocus = true;
      }
    }
    , handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27) {
        //list
        if (this.badgeFilterFocus) {
          this.badgeFilterFocus = false;
          this.badgeFilterModalOpened = 'close';
          this.closePriorityNavDropdown = true;
        }
        //orgChart
        if (this.orgChart.badgeFilterFocus) {
          this.orgChart.badgeFilterFocus = false;
          this.orgChart.badgeFilterModalOpened = 'close';
          this.orgChart.closePriorityNavDropdown = true;
        }
        //generic
        if (this.generic.badgeFilterFocus) {
          this.generic.badgeFilterFocus = false;
          this.generic.badgeFilterModalOpened = 'close';
          this.generic.closePriorityNavDropdown = true;
        }
      }
    }
    , onPriorityNavMouseOverOrTouchEnd(evt, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self, badgeFilterRefId } = this.getRespectiveBadgeFilterDetails(tabName);
      if ((this.$refs[badgeFilterRefId]?.$el.id == null || evt.target.closest(`#${this.$refs[badgeFilterRefId].$el.id}`) == null)) {
        self.badgeFilterFocus = false;
      }
    }
    , onBadgeFilterFetchOptions: debounce(async function(payload, tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return; 
      }
      const { self } = this.getRespectiveBadgeFilterDetails(tabName);
      if (self.badgeFilterOptionFetchFunc != null) {
        const values = await self.badgeFilterOptionFetchFunc(payload.field).then(data => {
          return data;
        })
        .catch(e => {
          console.error(e) //eslint-disable-line no-console
          return [];
        });
        
        this.$set(self.badgeFilterFieldValues, payload.field, values);
      } else {
        self.badgeFilterFieldValues[payload.field] = []
      }
    }, 100)
    , getBadgeFilterFields(tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return []; 
      }
      const self = this;
      const fields = [
        { value: 'firstName', text: self.$t('staff.field.firstName') }
        , { value: 'lastName', text: self.$t('staff.field.lastName') }
        , { value: 'position', text: self.$t('staff.field.position') }
        , { value: 'companyName', text: self.$t('field.company') }
        , { value: 'departmentName', text: self.$t('field.department') }
        , { value: 'skillName', text: self.$t('field.skill') }
        , { value: 'skillLevel', text: self.$t('field.skill_level') }
        , { value: 'locationName', text: self.$t('staff.field.location') }
        , { value: 'resourceName', text: self.$t('field.resource') }
        , { value: 'payCurrency', text: self.$t('staff.field.payCurrency') }
        , { value: 'payFrequency', text: self.$t('staff.field.payFrequency') }
        , { value: 'color', text: self.$t('company.field.color') }
        , { value: 'tagName', text: self.$t('field.tag') }
        , { value: 'identifier', text: self.$t('field.identifier') }
        , { value: 'staffType', text: self.$t('staff.field.type') }
        , { value: 'email', text: self.$t('staff.field.email') }
        
      ];
      if (tabName == 'generic') {
        //Rename firstName's text to name
        fields[0].text = self.$t('field.name')
        //Remove 'lastName', and 'position' options
        fields.splice(1, 2);
      }
      if (Array.isArray(self.customFields) && self.customFields.length > 0) {
        for (const f of self.customFields) {
          if (f.type == 'String' || f.type == 'Enum<String>') {
            fields.push({ value: f.name, text: f.displayName });
          }
        }
      }
      fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
      return fields;
    }
    , getBadgeFilterOptionFetchFunc(tabName) {
      //Defensive code: Stop proceed further because not applicable to recommended
      if (tabName == 'recommended') {
        return []; 
      }
      const { self } = this.getRespectiveBadgeFilterDetails(tabName)
      return (field) => {
        let f = field;
        if (f == 'tagName') {
          f = 'TAG.name'
        } else if (f == 'skillName') {
          f = 'SKILL.name'
        } else if (f == 'skillLevel') {
          f = 'STAFF-SKILL.level'
        } else if (f == 'locationName') {
          f = 'LOCATION.name'
        } else if (f == 'resourceName') {
          f = 'RESOURCE.name'
        } else if (f == 'stageName') {
          f = 'STAGE.name'
        } else if (f == 'companyName') {
          f = 'COMPANY.name'
        } else if (f == 'departmentName') {
          f = 'DEPARTMENT.name'
        }
        
        let startDate = tabName == 'list'? this.startDate : tabName == 'orgChart'? this.orgChart.startDate : null;
        let endDate = tabName == 'list'? this.endDate : tabName == 'orgChart'? this.orgChart.endDate : null;
        let realStaff = tabName == 'orgChart'? this.orgChartRealStaff : null;
        let genericStaff = tabName == 'orgChart'? this.orgChartGenericStaff : null;

        if (tabName == 'orgChart' && realStaff == false && genericStaff == false) {
          return Promise.resolve([]);
        }

        return staffService.listUniqueValuesOfProperty(f, { 
          enableGenericFilter: tabName != 'orgChart' || genericStaff != realStaff
          , isGeneric: tabName == 'generic' || genericStaff == true
          , startDate
          , endDate
        })
        .then(data => {
          if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
            const found = self.badgeFilters.find(i => i.field == field)
            if (found != null && Array.isArray(found.value) && found.value.length > 0) {
              if (field == 'payFrequency') {
                //Additional property 'value' is added to keep the original value.
                const list = [];
                for (const d of data) {
                  const text = !d ? '(Empty)' : this.$t(`payFrequency.${d}`)
                  const value = d ? d : null
                  list.push({
                    text
                    , value
                    , checked: found.value.find(j => j.value != null && j.value == value) != null
                  })
                }
                if (list.find(i => i.text == '(Empty)') == null) {
                  list.unshift({ text: '(Empty)', value: null, checked: false })
                }
                return list
              } else if (field == 'payCurrency') {
                //Additional property 'value' is added to keep the original value.
                const list = [];
                for (const d of data) {
                  let text = d
                  if (!d) {
                    text = '(Empty)'
                  } else {
                    const found = this.optionCurrency.find(j => j.value === d);
                    text = found != null? found.text : '(Empty)'
                  }
                  const value = d ? d : null
                  list.push({
                    text
                    , value
                    , checked: found.value.find(j => j.value != null && j.value == value) != null
                  })
                }
                if (list.find(i => i.text == '(Empty)') == null) {
                  list.unshift({ text: '(Empty)', value: null, checked: false })
                }
                return list
              }

              //Normal handling
              const rList = data.map(i => ({ 
                text: !i ? '(Empty)' : i
                , checked: found.value.find(j => j.text != null 
                                            && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                                || j.text == i) != null
              }))
              if (rList.find(i => i.text == '(Empty)') == null) {
                rList.unshift({ text: '(Empty)', checked: false })
              }
              return rList;
            }
          }
          
          //Normal handling
          const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
          if (rList.find(i => i.text == '(Empty)') == null) {
            rList.unshift({ text: '(Empty)', checked: false })
          }
          return rList;
        });
        
      }
    }
    , changeFilter: debounce(function(tabName) {
      const found = this.tabList.find(i => i.name == tabName);
      if (found == null) {
        return;
      }
      const self = found.self;
      if (self.gridOptions == null || self.gridOptions.api == null) {
        return;
      }
            
      if (self.badgeFilters.length == 0) {
        if (tabName == 'orgChart') {
          delete self.searchData;
          this.reloadOrgChart();
        } else {
          self.gridOptions.api.refreshServerSide({ purge: true });
        }
        return;
      }
      if (tabName == 'orgChart') {
        this.inProgressLabel = this.$t('task.filtering');
        this.inProgressShow = true;
        staffService.listv2({ start: 0, limit: -1, startDate: this.orgChart.startDate, endDate: this.orgChart.endDate, badgeFilters: JSON.parse(JSON.stringify(self.badgeFilters)) })
        .then(response => {
          self.searchData = response.data || [];
          self.gridOptions.api.onFilterChanged();
          this.inProgressShow = false;
        })
        .catch(() => {          
          self.searchData = [];
          self.gridOptions.api.onFilterChanged();
          this.inProgressShow = false;
        });
      } else {
        self.gridOptions.api.refreshServerSide({ purge: true });
      }
      
    }, 100)
    , getRespectiveBadgeFilterDetails(tabName) {
      let self = this;
      let filterRefId = 'filter';
      let badgeFilterRefId = 'badgeFilter';
      let profileKeySearch = `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_search`;
      let profileKeyBadgeFilters = `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_badgeFilters`;
      let profileKeySearchPinned = `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_searchPinned`;
      let profileKeyBadgeFilterPinned = `${this.formattedEntity}${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`;
      if (tabName == 'orgChart') {
        self = this.orgChart;
        filterRefId = 'filter_orgChart';
        badgeFilterRefId = 'badgeFilter_orgChart';
        profileKeySearch = `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_search`;
        profileKeyBadgeFilters = `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_badgeFilters`;
        profileKeySearchPinned = `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_searchPinned`;
        profileKeyBadgeFilterPinned = `${this.formattedEntity}_orgchart${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`;
      } else if (tabName == 'generic') {
        self = this.generic;
        filterRefId = 'filter_generic';
        badgeFilterRefId = 'badgeFilter_generic';
        profileKeySearch = `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_search`;
        profileKeyBadgeFilters = `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_badgeFilters`;
        profileKeySearchPinned = `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_searchPinned`;
        profileKeyBadgeFilterPinned = `${this.formattedEntity}_generic${this.nonAdmin? '':'_admin'}_selector_badgeFilterPinned`;
      } else if (tabName == 'recommended') {
        self = this.recommended
        filterRefId = 'filter_recommended'
        profileKeySearch = `${this.formattedEntity}_recommended_selector_search`;
        profileKeySearchPinned = `${this.formattedEntity}_recommended_selector_searchPinned`;
      }
      const payload = {
        self
        , filterRefId
        , badgeFilterRefId
        , profileKeySearch
        , profileKeyBadgeFilters
        , profileKeySearchPinned
        , profileKeyBadgeFilterPinned
      }
      if (tabName == 'recommended') {
        delete payload.badgeFilterRefId
        delete payload.profileKeyBadgeFilters
      }
      return payload
    }
    , onViewOver(evt, tabName) {
      if (this.$refs[`view_${tabName}`] != null) {
        this.$refs[`view_${tabName}`].visible = true;
      }
    }
    , onViewLeave(evt, tabName) {
      if (typeof evt?.toElement?.className === 'string' 
            && evt.toElement.className !== 'arrow' 
            && evt.toElement.className && !evt.toElement.className.startsWith('popover')) {
        if (this.$refs[`view_${tabName}`] != null) {
          this.$refs[`view_${tabName}`].visible = false;
        }
      }
    }
    , toggleCurrentColumnVisibility(toHide, tabName) {
      const found = this.tabList.find(i => i.name == tabName);
      if (found == null || found.self == null) {
        return;
      }
      const self = found.self;
      const columnDefs = self.gridOptions.api.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector' || (cDef.colId == 'uuId' && (tabName == 'list' || tabName == 'generic'))) {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        cDef.hide = toHide;
      }
      self.gridOptions.api.setColumnDefs(columnDefs);
    }
    , showAllColumns(tabName) {
      this.toggleCurrentColumnVisibility(false, tabName);
    }
    , showNoColumns(tabName) {
      this.toggleCurrentColumnVisibility(true, tabName);
    }
    , savePreset(tabName) {
      const found = this.tabList.find(i => i.name == tabName);
      if (found == null || found.self == null) {
        return;
      }
      const self = found.self;
      this.saveTabName = tabName;
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        name: this.saveName, 
        type: `${this.entity}_admin_selector_${tabName}`, 
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: this.profileSettings[found.profileKey],
        filterText: cloneDeep(self.filterText),
        badgeFilters: JSON.parse(JSON.stringify(self.badgeFilters)),
        searchPinned: self.searchPinned == true,
        badgeFilterPinned: self.badgeFilterPinned == true
      };
            
      if (tabName == 'list') {
        this.saveProfile.coloring = this.coloring;
        this.saveProfile.opts = {
          singleContracts: this.singleContracts
          , dates: this.dates
          , startDate: this.startDate
          , endDate: this.endDate
        }
      }
      if (tabName == 'orgChart') {
        this.saveProfile.opts = {
          singleContracts: this.orgChart.singleContracts
          , dates: this.orgChart.dates
          , startDate: this.orgChart.startDate
          , endDate: this.orgChart.endDate
          , realStaff: this.orgChartRealStaff
          , genericStaff: this.orgChartGenericStaff
        }
      }
      if (tabName == 'generic') {
        this.saveProfile.coloring = this.genericColoring;
      }
    
      this.promptSaveShow = true;
    }
    , async updateUsers(profile, updateUsers, service, nameList) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0][nameList.profileKey] = profile.columns;
            profileData[0][this.saveTabName === 'generic' ? this.profileKeyGenericColoring : this.profileKeyColoring] = profile.coloring;
            profileData[0][nameList.profileKeySearch] = profile.filterText;
            profileData[0][nameList.profileKeyBadgeFilters] = profile.badgeFilters;
            profileData[0][`${nameList.profileKey}_opts`] = profile.opts;
            // save the view name in the profile
            profileData[0][nameList.profileKeyView] = profile.name;

            profileData[0][nameList.profileKeySearchPinned] = profile.searchPinned;
            if (nameList.profileKeyBadgeFilterPinned != null) {
              profileData[0][nameList.profileKeyBadgeFilterPinned] = profile.badgeFilterPinned;
            }
            
            await service.update(profileData, user)
          }
        }
      }
    }
    , confirmSaveOk({ /**name,*/ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.views.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      const found = this.tabList.find(i => i.name == this.saveTabName);
      if (found == null || found.self == null) {
        return;
      }
      const self = found.self;
      if (this.saveIndex !== -1) {
        const matchedIndex = this.views.findIndex(i => i.uuId == profile.uuId);
        if (matchedIndex !== -1) {
          this.views.splice(matchedIndex, 1, profile);
        }
      }
      else {
        this.addViews([profile]);
      }
    
      this.updateUsers(profile, updateUsers, viewProfileService, found);
      
      if (!sharing) {
        self.viewName = profile.name;
        // save the view name in the profile
        
        this.profileSettings[found.profileKeyView] = profile.name;
        this.updateViewProfile({ clearViewName: false });
      }
    }
    , addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type != null && view.type.startsWith(`${this.entity}_admin_selector_`) &&
            this.views.findIndex((i) => i.uuId === view.uuId) === -1) {
          if (view.type === `${this.entity}_admin_selector_list`) {
            this.showInfo.push(false);
          }
          else if (view.type == `${this.entity}_admin_selector_orgChart`) {
            this.orgChart.showInfo.push(false);
          }
          else if (view.type == `${this.entity}_admin_selector_generic`) {
            this.generic.showInfo.push(false);
          }
          
          this.views.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
          else {
            let params = new URL(document.location.toString()).searchParams;
            if (params.has("view")) {
              let uuId = params.get("view");
              if (view.uuId === uuId) {
                if (view.type === 'STAFF_admin_selector_list') {
                  this.loadViewSettings(view, 'list');
                }
                else if (view.type === 'STAFF_admin_selector_orgChart') {
                  this.loadViewSettings(view, 'orgChart');
                  this.activeTab = 1;
                }
                else if (view.type === 'STAFF_admin_selector_generic') {
                  this.loadViewSettings(view, 'generic');
                  this.activeTab = 2;
                }
              }
            }
          }
        }
      }
      
      this.views.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    }
    , editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    }
    , loadViewSettings(view, tabName) {
      const found = this.tabList.find(i => i.name == tabName);
      if (found == null) {
        return;
      }
      const self = found.self;

      //A flag is set to prevent the viewName being removed accidentally from (layout) profile in the onColumnVisible() event.
      //Event:onColumnVisible() is only fired when the previous columns and newly loaded columns are different.
      //Find out if they are different and set flag accordingly.
      let columnsMatched = true;
      let layoutCols = this.profileSettings[found.profileKey];
      if (layoutCols.length > view.columns.length) {
        for (const col of layoutCols) {
          if (view.columns.find(i => i.colId == col.colId) == null) {
            columnsMatched = false;
            break;
          }
        }
      } else {
        for (const col of view.columns) {
          if (layoutCols.find(i => i.colId == col.colId) == null) {
            columnsMatched = false;
            break;
          }
        }
      }      
      if (!columnsMatched) {
        self.skipViewNameReset = true;
      } else {
        self.skipViewNameReset = false;
      }

      this.profileSettings[found.profileKey] = JSON.parse(JSON.stringify(view.columns));
      this.loadColumnSettings(self, this.profileSettings[found.profileKey])

      self.searchPinned = view.searchPinned == true;
      if (tabName != null && tabName != 'recommended') {
        self.badgeFilterPinned = view.badgeFilterPinned == true;
      }

      const profileKeyOpts = view.opts;
      if (profileKeyOpts != null && tabName != 'generic') {
        if (this.profileSettings[`${found.profileKey}_opts`] == null) {
          this.profileSettings[`${found.profileKey}_opts`] = {}
        }
        let profileSettingsOpts = this.profileSettings[`${found.profileKey}_opts`]

        if (tabName == 'list') {
          this.singleContracts = profileSettingsOpts.singleContracts = profileKeyOpts.singleContracts
          if (profileKeyOpts.dates && this.canView(this.permissionName, ['endDate', 'startDate'])) {
            this.dates = profileSettingsOpts.dates = profileKeyOpts.dates;
          }
          if (profileKeyOpts.dates == null) {
            if (profileKeyOpts.startDate) {
              this.startDate = profileSettingsOpts.startDate = profileKeyOpts.startDate
            }
            if (profileKeyOpts.endDate) {
              this.endDate = profileSettingsOpts.endDate = profileKeyOpts.endDate
            }
          } else {
            this.datesChanged(self);
          }
          
        } else if (tabName == 'orgChart') {
          this.orgChart.singleContracts = profileSettingsOpts.singleContracts = profileKeyOpts.singleContracts
          if (this.canView(this.permissionName, ['endDate', 'startDate'])) {
            this.orgChart.dates = profileSettingsOpts.dates = profileKeyOpts.dates
          }
          
          this.orgChartRealStaff = profileSettingsOpts.realStaff = profileKeyOpts.realStaff
          this.orgChartGenericStaff = profileSettingsOpts.genericStaff = profileKeyOpts.genericStaff

          if (profileKeyOpts.dates == null) {
            this.orgChart.startDate = profileSettingsOpts.startDate = profileKeyOpts.startDate
            this.orgChart.endDate = profileSettingsOpts.endDate = profileKeyOpts.endDate
          } else {
            this.datesChanged(self);
          }
        }
      }
console.log('loadViewSettings view', view); //eslint-disable-line no-console
      self.filterText =  typeof view.filterText !== 'undefined' ? view.filterText : '';
      self.searchFilter = self.filterText;
      this.profileSettings[found.profileKeySearch] = self.filterText;

      self.badgeFilters = Array.isArray(view.badgeFilters)? JSON.parse(JSON.stringify(view.badgeFilters)) : [];
      this.profileSettings[found.profileKeyBadgeFilters] = JSON.parse(JSON.stringify(self.badgeFilters));
      this.changeFilter(tabName);
      
      let coloringChanged = false;
      if (tabName == 'list') {
        coloringChanged = JSON.stringify(view.coloring) !== JSON.stringify(this.coloring)
        for (const optName in colorOptions) {
          this.$set(this.coloring, optName, view.coloring? view.coloring[optName] : colorOptions[optName])
        }
        this.profileSettings[this.profileKeyColoring] = this.coloring;
      } else if (tabName == 'generic') {
        coloringChanged = JSON.stringify(view.coloring) !== JSON.stringify(this.genericColoring)
        for (const optName in genericColorOptions) {
          this.$set(this.genericColoring, optName, view.coloring? view.coloring[optName] : genericColorOptions[optName])
        }
        this.profileSettings[this.profileKeyGenericColoring] = this.genericColoring;
      }

      // save the view name in the profile
      this.profileSettings[found.profileKeyView] = self.viewName = view.name;
      
      // Save the new layout after applying it
      this.updateViewProfile({ clearViewName: false });

      if (coloringChanged) {
        self.gridOptions.api.redrawRows();
      }
    }
    , async loadUserProfile() {
      const self = this;
      const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , copyColumnSettings(name , profile, tabName) {
      const columns = profile.columns;
      this.saveTabName = tabName;
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: `${this.entity}_admin_selector_${tabName}`,
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: columns,
        filterText: cloneDeep(profile.filterText),
        badgeFilters: cloneDeep(profile.badgeFilters),
        searchPinned: profile.searchPinned,
        badgeFilterPinned: profile.badgeFilterPinned
        
      };
      if (Object.hasOwn(profile, 'opts')) {
        this.saveProfile.opts = cloneDeep(profile.opts)
      }
      if (Object.hasOwn(profile, 'coloring')) {
        this.saveProfile.coloring = cloneDeep(profile.coloring)
      }
      this.saveIndex = -1;
      this.promptSaveShow = true;
    }
    , shareColumnSettings(index, name, profile, tabName) {
      this.saveTabName = tabName;
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    }
    , updateColumnSettings(index, name, profile, tabName) {
      const found = this.tabList.find(i => i.name === tabName);
      if (found == null) {
        return;
      }
      const self = found.self;
      this.saveTabName = tabName;
      this.saveName = name;
      this.saveProfile = { 
        name: this.saveName, 
        uuId: profile.uuId, 
        type: `${this.entity}_admin_selector_${tabName}`, 
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        columns: this.profileSettings[found.profileKey],
        filterText: cloneDeep(self.filterText),
        badgeFilters: cloneDeep(self.badgeFilters),
        searchPinned: self.searchPinned,
        badgeFilterPinned: self.badgeFilterPinned
      };
      if (tabName == 'list') {
        this.saveProfile.coloring = this.coloring;
        this.saveProfile.opts = {
          singleContracts: this.singleContracts
          , dates: this.dates
          , startDate: this.startDate
          , endDate: this.endDate
        }
      } else if (tabName == 'orgChart') {
        this.saveProfile.opts = {
          singleContracts: this.orgChart.singleContracts
          , dates: this.orgChart.dates
          , startDate: this.orgChart.startDate
          , endDate: this.orgChart.endDate
          , realStaff: this.orgChartRealStaff
          , genericStaff: this.orgChartGenericStaff
        }
      } else if (tabName == 'generic') {
        this.saveProfile.coloring = this.genericColoring;
      }
      this.saveIndex = index;
      this.promptSaveShow = true;
    }
    , removeColumnSettings(index, tabName) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
      this.deleteViewTabName = tabName;
    }
    , confirmDeleteViewOk() {
      const { self } = this.tabList.find(i => i.name == this.deleteViewTabName);
      const toRemove = self.views.splice(this.deleteViewIndex, 1);
      viewProfileService.remove([{ uuId: toRemove[0].uuId }], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , updateDateProfile() {
      const tab = this.tabList[this.activeTab];
      const profileKeyOpts = `${tab.profileKey}_opts`
      if (this.profileSettings[profileKeyOpts] === null) {
        this.profileSettings[profileKeyOpts] = {}
      }      
      
      if (this.profileSettings[profileKeyOpts] == null) {
        this.profileSettings[profileKeyOpts] = {}
      }
      
      this.profileSettings[profileKeyOpts].dates = tab.name === 'list' ? this.dates : this.orgChart.dates;
      this.profileSettings[profileKeyOpts].startDate = tab.name === 'list' ? this.startDate : this.orgChart.startDate;
      this.profileSettings[profileKeyOpts].endDate = tab.name === 'list' ? this.endDate : this.orgChart.endDate;
      this.updateViewProfile()
    
    }
    , rangeSelected() {
      this.datesChanged(this);
      this.updateDateProfile();
      this.updateGrid();
    }
    , orgChartRangeSelected() {
      this.datesChanged(this.orgChart);
      this.updateDateProfile();
      this.reloadOrgChart();
    }
    , updateGrid() {
      this.$nextTick(() => {
        let api = this.gridOptions.api
        if(api != null) {
          api.refreshServerSide({ purge: true })
        }
      });
    }
    , daySelected() {
      this.highlightRefresh = false;
      if (moment(this.endDate).unix() < moment(this.startDate).unix()) {
        this.endDate = moment(this.startDate).add(7, 'days').format('YYYY-MM-DD');
      }
      this.updateDateProfile();
      this.updateGrid();
    }
    , orgChartDaySelected() {
      this.orgChart.highlightRefresh = false;
      if (moment(this.endDate).unix() < moment(this.startDate).unix()) {
        this.endDate = moment(this.startDate).add(7, 'days').format('YYYY-MM-DD');
      }
      this.updateDateProfile();
      this.reloadOrgChart();
    }
    , dateChanged() {
      this.highlightRefresh = true;
      this.dates = null;
      this.updateDateProfile();
    }
    , orgChartDateChanged() {
      this.orgChart.highlightRefresh = true;
      this.orgChart.dates = null;
      this.updateDateProfile();
    }
    , getToday() {
      if (this.$store.state.epoch.value) {
        return new Date(this.$store.state.epoch.value);
      }
      return new Date();
    }
    , datesChanged(self) {
      if (!this.canView(this.permissionName, ['endDate', 'startDate'])) {
        self.dates = "all-time";
        return;
      }
    
      if (self.dates === "today") {
        let today = this.getToday();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        self.startDate = formatDate(today);
        
        // Sunday
        today.setHours(23);
        today.setMinutes(59);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "this-week") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        self.startDate = formatDate(today);
        
        // Sunday
        today.setDate(today.getDate() + 6); 
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "this-week-to-date") {
        let today = getNextWorkingDay(this.getToday());
        self.endDate = formatDate(today);
        
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        self.startDate = formatDate(today);
      } 
      else if (self.dates === "this-month") {
        let today = this.getToday();
        self.endDate = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        
        self.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (self.dates === "this-month-to-date") {
        let today = this.getToday();
        self.endDate = formatDate(today);
        
        self.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (self.dates === "this-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        self.startDate = formatDate(thisq);
        const lastday = new Date(thisq.getFullYear(), thisq.getMonth() + 3, 0);
        self.endDate = formatDate(lastday);
      } 
      else if (self.dates === "this-quarter-to-date") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        self.startDate = formatDate(thisq);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "this-year") {
        let today = this.getToday();
        const year = today.getFullYear();
        self.startDate = [year, '01', '01'].join('-');
        self.endDate = [year, '12', '31'].join('-');
      } 
      else if (self.dates === "this-year-to-date") {
        let today = this.getToday();
        self.endDate = formatDate(today);
        const year = today.getFullYear();
        self.startDate = [year, '01', '01'].join('-');
      } 
      else if (self.dates === "last-week") {
        let today = this.getToday();
             
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        self.endDate = formatDate(today);
        
        today.setDate(today.getDate() - 6); // Monday
        self.startDate = formatDate(today);
      } 
      else if (self.dates === "last-week-to-date") {
        let today = this.getToday();
        self.endDate = formatDate(today);
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        today.setDate(today.getDate() - 6); // Monday
        self.startDate = formatDate(today);
      } 
      else if (self.dates === "last-month") {
        let today = this.getToday();
        self.endDate = formatDate(new Date (today.getFullYear(), today.getMonth(), 0));        
        self.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (self.dates === "last-month-to-date") {
        let today = this.getToday();
        self.endDate = formatDate(today);
            
        self.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (self.dates === "last-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        self.startDate = formatDate(lastq);
        self.endDate = formatDate(new Date (today.getFullYear(), lastq.getMonth() + 3, 0));
      } 
      else if (self.dates === "last-quarter-to-date") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        self.startDate = formatDate(lastq);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "last-year") {
        const b = this.getToday();
        b.setFullYear(b.getFullYear() - 1);
        const year = b.getFullYear();
        self.startDate = [year, '01', '01'].join('-');
        self.endDate = [year, '12', '31'].join('-');
      } 
      else if (self.dates === "next-week") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() + (8 - day)); // Monday
        self.startDate = formatDate(today);
        today.setDate(today.getDate() + 6); // Sunday
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "next-4-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        self.startDate = formatDate(today);
        today.setDate((today.getDate() + 4 * 7) - 1);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "next-8-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        self.startDate = formatDate(today);
        today.setDate((today.getDate() + 8 * 7) - 1);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "next-12-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        self.startDate = formatDate(today);
        today.setDate((today.getDate() + 12 * 7) - 1);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "next-24-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        self.startDate = formatDate(today);
        today.setDate((today.getDate() + 24 * 7) - 1);
        self.endDate = formatDate(today);
      } 
      else if (self.dates === "next-month") {
        let today = this.getToday();
        const nextm = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        self.startDate = formatDate(nextm);
        const lastday = new Date(nextm.getFullYear(), nextm.getMonth() + 1, 0);
        self.endDate = formatDate(lastday);
      } 
      else if (self.dates === "next-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        var nextq;
        if (quarter == 4) {
            nextq = new Date (today.getFullYear() + 1, 1, 1);
        } else {
            nextq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        self.startDate = formatDate(nextq);
        const lastday = new Date(nextq.getFullYear(), nextq.getMonth() + 3, 0);
        self.endDate = formatDate(lastday);
      } 
      else if (self.dates === "next-year") {
        const b = this.getToday();
        b.setFullYear(b.getFullYear() + 1);
        const year = b.getFullYear();
        self.startDate = [year, '01', '01'].join('-');
        self.endDate = [year, '12', '31'].join('-');
      }
    }
    , onInfoOver(index, views, showInfo) {
      profileService.nodeList(views[index].uuId).then((response) => {
        views[index].owner = response.data.resultList.filter(v => views[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(showInfo, index, true);
      });
    }
    , onInfoLeave(index, views, showInfo) {
      this.$set(showInfo, index, false);
    }
    , async onBookings() {
      this.profileSettings['staff_selector_bookings'] = this.bookings;
      this.updateViewProfile();
      
      // reload all lists
      this.gridOptions.api.refreshServerSide({ purge: true });
      this.reloadOrgChart();
      if (this.recommended?.gridOptions?.api) {
        await this.prepareStaffList()
        .catch(e => {
          if (e != null && e.response != null && e.response.status == 403) {
            this.showNoRowsOverlay(this, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
          } else {
            console.error(e); //eslint-disable-line no-console
          }
        });
        this.recommended.gridOptions.api.refreshServerSide({ purge: true });
      }
      if (this.generic?.gridOptions?.api) {
        this.generic.gridOptions.api.refreshServerSide({ purge: true });
      }
    }
    , recommendationSettingsSuccess(result) {
      this.recommendationSettingsShow = false;
      this.recommended.settings = result;
      this.recommended.gridOptions.api.refreshServerSide({ purge: true });
    }
    , showRecommendationSettings() {
      this.recommendationSettingsShow = true;
    }
    , rectifyInvalidRecommendedSettings() {
      for (const prop in this.recommended_settings_template) {
        if (typeof this.recommended.settings[prop] != 'boolean') {
          this.recommended.settings[prop] = this.recommended_settings_template[prop];
        }
      }
    }
    , getRecommendedColumnDefs() {
      const colDefs = [
        {
          headerName: this.$t('staff.field.lastName')
          , field: 'staffUUID'
          , cellRenderer: 'detailLinkCellRenderer'
          , cellRendererParams: {
            label: 'lastName'
            , tabName: 'recommended'
          }
          , checkboxSelection: true
          , pinned: 'left'
          , lockPosition: 'left'
          , lockVisible: true
          , minWidth: 150
          , hide: false
          , sort: 'asc'
          , editable: false
        }
        , {
          headerName: this.$t('staff.field.firstName')
          , field: 'firstName'
          , cellRenderer: 'genericCellRenderer'
          , hide: false
          , minWidth: 150
        }
        , { 
          headerName: this.$t('staff.field.position')
          , field: 'position'
          , hide: true
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.location')
          , field: 'locations'
          , hide: true
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.department')
          , field: 'department'
          , hide: true
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.skills')
          , field: 'skills'
          , hide: false
          , minWidth: 150
        }
        , {
          headerName: this.$t('staff.field.duration')
          , field: 'duration'
          , minWidth: 200
          , hide: false
        }
        , {
          headerName: this.$t('staff.field.payAmount')
          , field: 'payAmount'
          , cellRenderer: 'costCellRenderer'
          , cellRendererParams: {
            customCurrencyProp: 'currencyCode'
          }
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.payCurrency')
          , field: 'payCurrency'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.payFrequency')
          , field: 'payFrequency'
          , cellRenderer: 'payFrequencyRenderer'
          , cellRendererParams: {
            getOptions: () => {
              return self.payFrequencyOptions;
            }
          }
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.type')
          , field: 'staffType'
          , cellRenderer: 'staffTypeCellRenderer'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.company')
          , field: 'company'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('field.identifier_full')
          , field: 'identifier'
          , minWidth: 100
          , hide: true
        }
      ];

      return colDefs;
    }
    , ok() {
      const gridTarget = this.tabList[this.activeTab];
      const api = gridTarget?.self?.gridOptions?.api;
      if (api != null) {
        let details = null
        if (gridTarget.name == 'orgChart') {
          details = api.getSelectedNodes().filter(i => i.data?.staff === true).map(i => ({ 
            uuId: i.data.uuId
            , name: i.data.name
            , genericStaff: false
          }));
        } else if (gridTarget.name == 'recommended') {
          details = api.getSelectedNodes().map(i => ({ 
            uuId: i.data.staffUUID
            , name: i.data? `${i.data.firstName} ${i.data.lastName}` : null
            , genericStaff: false
            , duration: i.data.duration
          }));
        } else {
          details = api.getSelectedNodes().map(i => ({ 
            uuId: i.data.uuId
            , name: i.data.label
            , genericStaff: gridTarget.name == 'generic'
          }));
        }
        
        if (details == null) {
          details = [];
        }
        const ids = details.map(i => i.uuId);
        this.$emit('ok', { ids, details: details });
        this.$emit('input', ids);
      }
    }
    , isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
    , getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
      });
    }
    , onPinBadgeFilter(tabName) {
      const { self, profileKeyBadgeFilterPinned } = this.getRespectiveBadgeFilterDetails(tabName);
      self.badgeFilterPinned = true;
      this.profileSettings[profileKeyBadgeFilterPinned] = self.badgeFilterPinned
      this.updateViewProfile();
    }
    , onUnPinBadgeFilter(tabName) {
      const { self, profileKeyBadgeFilterPinned } = this.getRespectiveBadgeFilterDetails(tabName);
      self.forcePriorityNavRefresh = true;
      self.badgeFilterPinned = false;
      this.profileSettings[profileKeyBadgeFilterPinned] = self.badgeFilterPinned
      this.updateViewProfile();
    }
    , onPinFilter(tabName) {
      const { self, profileKeySearchPinned } = this.getRespectiveBadgeFilterDetails(tabName);
      self.searchPinned = true;
      this.profileSettings[profileKeySearchPinned] = self.searchPinned
      this.updateViewProfile();
    }
    , onUnPinFilter(tabName) {
      const { self, profileKeySearchPinned } = this.getRespectiveBadgeFilterDetails(tabName);
      self.forcePriorityNavRefresh = true;
      self.searchPinned = false;
      this.profileSettings[profileKeySearchPinned] = self.searchPinned
      this.updateViewProfile();
    }
  }
}


</script>

<style lang="scss">
.staff-tab-container ul[role=tablist] {
  display: none; //Hide nav bar generated by bootstrapVue tabs component. 
}

.spreadsheet .ag-row-selected::before,
.spreadsheet .ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}

.spreadsheet .ag-cell-range-selected.ag-cell-focus, .spreadsheet .ag-body-viewport.ag-has-focus .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: var(--ag-range-selection-background-color);
  border: 2px solid  !important;
  border-color: var(--ag-range-selection-border-color) !important; 
  border-style: var(--ag-range-selection-border-style) !important;
}

.tab-content.staff-tabs {
  border: 0;
}
.tab-content.staff-tabs .tab-pane {
  padding: 0;
}

.staff-action-bar, .group-action-bar {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .d-flex {
    align-items: center;
  }
  li > span > label {
    margin: 12px 0;
  }
}

.border-ends {
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
}

.settings-button {
  margin-right: 5px !important;
  margin-left: auto !important;
  display: block !important;
}

.staff-bookings-switch {
  position: absolute !important;
  right: 20px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .selector-nav {
    position: relative;
    top: 5px;
  }
  
  .staff-bookings-switch {
    top: 60px;
  }
  
  .staff-tab-container {
    top: 15px;
    position: relative;
  }
}

.recommended-first-action {
  margin-left: 3px;
}
</style>