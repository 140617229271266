<template>
  <b-alert ref="alertRef" :variant="alertVariant" dismissible :show="showError" @dismissed="dismissAlert">
    <font-awesome-icon :icon="alertIcon"/>&nbsp;&nbsp;{{ msg }}
    <template v-if="showErrorDetail">
    &nbsp;(<a href="#" @click.stop.prevent="detailModalShow=true">{{ $t('alert.link_details') }}</a>)
    </template>
    
    <b-modal :title="$t('alert.title_error_details')"
        v-model="detailModalShow"
        @hidden="detailModalShow=false"
        no-close-on-backdrop
        content-class="shadow"
        scrollable
        >
        <b-row>
        <template v-if="showErrorDetailTitle">
        <b-col cols="12"><h6>{{ detailTitle }}</h6></b-col>
        </template> 
        <template v-for="(item, index) in details">
            <b-col cols="12" :key="index">{{ item }}</b-col>
        </template>
        </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
  </b-alert>
</template>

<script>
import alertStateEnum from '@/enums/alert-state';
import { debounce } from 'lodash';

export default {
  name: 'AlertFeedback',
  props: {
    msg: {
      type: String,
      default: null
    },
    details: {
      type: Array,
      default: () => []
    },
    detailTitle: {
      type: String,
      default: null
    },
    alertState: {
      type: String,
      default: alertStateEnum.SUCCESS
    }
  },
  data: function() {
    return {
      detailModalShow: false
    }
  },
  mounted() {
    this.emitOffsetHeight();
    window.addEventListener('resize', this.emitOffsetHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.emitOffsetHeight);
  },
  computed: {
    showError() {
      return this.msg != null;
    },
    showErrorDetail() {
      return this.details != null && this.details != null && Array.isArray(this.details) && this.details.length > 0;
    },
    alertVariant() {
      return this.alertState == alertStateEnum.SUCCESS || this.alertState === alertStateEnum.INFO ? 'success' : (this.alertState == alertStateEnum.WARNING? 'warning': 'danger');
    },
    alertIcon() {
      return this.alertState == alertStateEnum.SUCCESS? ['far', 'check'] : this.alertState === alertStateEnum.INFO ? ['far', 'circle-info'] : ['fas', 'triangle-exclamation'];
    },
    showErrorDetailTitle() {
      return this.detailTitle != null;
    }
  },
  methods: {
    dismissAlert() {
      this.$emit('resetAlert');
    },
    emitOffsetHeight: debounce(function() {
      this.$emit('offsetHeight', this.$refs.alertRef.$el.offsetHeight);
    }, 100)
  }
}
</script>