<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="title" footer-class="footerClass"
      @hidden="hidden(null)"
      no-close-on-backdrop 
      content-class="shadow"
    >
    
      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-row>
        <b-col cols="12" md="2">
          <b-form-radio-group class="preview-state-toggler"
              v-model="preview"
              :options="[{ text: $t('comment.button.write'), value: false }, { text: $t('comment.button.preview'), value: true }]"
              buttons
              button-variant="outline-secondary"
              size="sm"
            />
        </b-col>
      
        <b-col cols="12" offset-md="4" md="6" class="pl-md-0">
          <b-form-group class="mt-2 mt-md-0" :label="$t('field.identifier')" label-for="identifier" label-align-md="right" label-cols-md="3" content-cols-md="9">
            <b-input-group>
              <b-form-input id="identifier" type="text"
                :data-vv-as="$t('field.identifier')"
                data-vv-name="comment.identifier"
                :maxlength="maxIdentifierLength"
                v-model="comment.identifier" 
                :disabled="isReadOnly"
                size=""
                trim>
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-textarea ref="textarea" v-model="comment.text"  refs="commentInput" name="note" id="message" rows="9"
        :placeholder="$t('comment.placeholder.your_comment')"
        class="comment-textarea rounded-0"
        :class="{ 'd-none': previewState }"
        trim required autofocus/>

      <div class="preview markdown-body" :class="{ 'd-none': !previewState }" v-html="compiledMarkdown">
      </div>

      <div class="markdown-hint">
        <font-awesome-layers class="fa-lg info-icon">
          <font-awesome-icon :icon="['far','circle']" transform="shrink-2" />
          <font-awesome-icon :icon="['far', 'info']" transform="shrink-8" />
        </font-awesome-layers>
        <span><a target="_blank" href="https://projectal.com/resources/markdown">{{ $t('comment.link.markdown') }}</a> {{ $t('comment.link.is_supported') }}</span>
      </div>

      <CommentList class="mt-2" v-if="showList && canList('NOTE')" :id="holderid" :isDialog="true" :comments="comments" @success="commentSuccess" @add="add" @edit="edit" @error="commentError" @reload="commentSuccess" />
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template v-if="allowSelect">
          <b-button v-if="canEdit() || !exists" size="sm" variant="success" @click="ok(false)">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.save_comment')"
        v-model="promptSaveShow"
        @hidden="promptSaveShow = false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <span>{{ $t('comment.save_prompt') }}</span>
      <template v-slot:modal-footer="{}">
        <b-button size="sm" variant="success" @click="confirmSaveOk(true)">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="confirmSaveOk(false)">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { noteService } from '@/services';
import { cloneDeep } from 'lodash';
import * as DOMPurify from 'dompurify';
import * as Marked from 'marked';
const renderer = new Marked.Renderer();
renderer.link = function(href, title, text) {
  const link = Marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a","<a target='_blank' ");
};
Marked.setOptions({
  renderer: renderer,
  gfm: true
});

export default {
  name: 'CommentModal',
  components: {
    CommentList: () => import('@/components/Comment/CommentList.vue')
  },
  props: {
    id:           { type: String,   default: 'COMMENT_NEW' },
    holderid:     { type: String, default: null },
    message:      { type: String,   default: '' },
    title:        { type: String,   default: function() { return this.$t('comment.button.add_comment'); } },
    show:         { type: Boolean, required: true },
    mode:         { type: String, default: 'BOTH' }, //Possible value: ['SELECT', 'MANAGE', 'BOTH'],
    comments:     { type: Array, default: () => { return []; } },
    showList:     { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'NOTE',
      modelInfo: null,
      modalShow: false,
      alertMsg: null,
      comment: {
        text: null,
        identifier: null,
        authorRef: null
      },
      preview: false,
      promptSaveShow: false,
      nextComment: null,
    }
  },
  created() {
    this.getModelInfo();
    this.modalShow = this.show;
    const comment = this.comments.filter(c => c.uuId === this.id);
    if (comment.length > 0) {
      this.comment = cloneDeep(comment[0]);
    }
    this.originComment = cloneDeep(this.comment);
  },
  beforeDestroy() {
    this.originComment = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        this.preview = false;
        this.alertMsg = null;
        if(this.id.indexOf('COMMENT_NEW') === -1) {
          noteService.get([{ uuId: this.id} ], this.holderid).then((response) => {
            this.comment = response.data[response.data.jobCase][0];
            this.originComment = cloneDeep(this.comment);
          }, () => {
            this.$emit('error', { msg: this.$t('comment.error.failed_to_get') });
          });
        }
      }
    },
    comments(newValue) {
      const filtered = newValue.filter(i => i.uuId == this.comment.uuId);
      if(filtered.length > 0) {
        this.$set(this.comment, 'text', filtered[0].text);
        this.$set(this.comment, 'identifier', (filtered[0].identifier != null? filtered[0].identifier : null));
        this.$set(this.comment, 'authorRef', (filtered[0].authorRef != null? filtered[0].authorRef : null))
        this.$nextTick(() => {
          this.originComment = cloneDeep(this.comment);
        });
      }
    }
  },
  computed: {
    exists() {
      return this.id && !this.id.startsWith('COMMENT_NEW');
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    isReadOnly() {
      return this.mode && 'SELECT' === this.mode || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    errorShow() {
      return this.alertMsg != null;
    },
    compiledMarkdown: function () {
      if (this.comment.text === null) {
        return '';
      }
      return DOMPurify.sanitize(Marked(this.comment.text));
    },
    previewState() {
      return this.preview;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    }
  },
  methods: {
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "NOTE"}).then(value => {
        self.modelInfo = value.NOTE.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    add() {
      if (this.comment.uuId == null) {
        if (this.comment.text == null || this.comment.text.trim().length < 1 || this.comment.identifer == null || this.comment.identifier.trim().length < 1) {
          this.comment = { text: null, identifier: null };
        } else {
          this.nextComment = { text: null, identifier: null };
          this.promptSaveShow = true;
        }
      } else {
        const commentFilter = this.comments.filter(c => c.uuId === this.comment.uuId);
        if (commentFilter.length > 0 && 
              (commentFilter[0].text !== this.comment.text || commentFilter[0].identifier != this.comment.identifier)) {
          this.nextComment = { text: null, identifier: null };
          this.promptSaveShow = true;
        } else {
          this.comment = { text: null, identifier: null };
        }
      }
    },
    edit(comment) {
      const _comment = cloneDeep(comment);
      if (this.comment.uuId == null) {
        if (this.comment.text == null || this.comment.text.trim().length < 1 || this.comment.identifer == null || this.comment.identifier.trim().length < 1) {
          this.comment = _comment;
        } else {
          this.nextComment = _comment;
          this.promptSaveShow = true;
        }
      } else {
        const commentFilter = this.comments.filter(c => c.uuId === this.comment.uuId);
        if (commentFilter.length > 0 && 
              (commentFilter[0].text !== this.comment.text || commentFilter[0].identifier != this.comment.identifier)) {
          this.nextComment = _comment;
          this.promptSaveShow = true;
        } else {
          this.comment = _comment;
        }
      }
    },
    confirmSaveOk(toSave=true) {
      if (toSave) {
        this.ok(true);
      } else if (this.nextComment !== null) {
        this.comment = this.nextComment;
        this.nextComment = null;
        this.$refs.textarea.focus();
        this.promptSaveShow = false;
      }
    },
    commentSuccess() {
      this.$emit('reload');
    },
    commentError(err) {
      this.alertMsg = err;
    },
    hidden(comment = null) {
      this.$emit('update:show', false);
      this.$emit('hidden', comment);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    async ok(save = false) {
      if (this.comment.text === '' ||
          this.comment.text === null) {
        this.alertMsg = 'Comment cannot be empty';
        return;
      }

      const data = {
        text: this.comment.text
        , identifier: this.comment.identifier
      }

      let mode = 'create';
      if(this.comment.uuId) {
        mode = 'update';
        data.uuId = this.comment.uuId
      }

      //Skip updating comment if there is no change in comment properties.
      let hasChanged = false;
      if (mode != 'create') {
        hasChanged = this.removeUnchangedCommentProperties(data);
      }

      let result = null;
      if (mode == 'create' || hasChanged) {
        result = await noteService[mode]([data], this.holderid).then(() => {
          return {}
          
        }).catch(e => {
          return { error: e }
        });
      }

      if (result != null && result.error != null) {
        this.$emit('error', { msg: this.$t(`comment.error.failed_to_${mode}`) });
        return;
      }

      // fire event for comment
      this.promptSaveShow = false;
      if (!save) {
        this.$emit('success', { msg: this.$t(`comment.${mode}`), comment: this.comment });
        this.hidden(cloneDeep(this.comment));
        this.comment.text = '';
        this.comment.identifier = null;
      }
      else {
        if (this.nextComment !== null) {
          this.comment = this.nextComment;
          this.nextComment = null;
          this.$refs.textarea.focus();
          this.$emit('reload');
        }
      }
    },
    removeUnchangedCommentProperties(data) {
      //Remove those properties whose value is not changed in provided data against original comment.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalComment = this.originComment;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalComment[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    }
  }
}
</script>

<style lang="scss">
  .preview {
    border: 1px solid var(--form-control-border);
    padding: 6px 12px;
    margin-top: 8px;
    min-height: 203px;
    height: 203px;
    overflow-y: auto;
    resize: vertical;
    background-color: var(--comment-bg);
  }

  .note-content {
    font-family: Arial;
    font-size: 14px;
  }

  .comment-textarea {
    margin-top: 8px;
  }

  .markdown-hint {
    position: relative;
    color: var(--bs-text-muted);
    margin-top: 5px;
    font-size: 0.75rem;

    .info-icon {
      position: absolute;
      left: 0;
      top: 1px;
    }
    span {
      padding-left: 20px;
    }
  }

  .preview-state-toggler {
    .btn.btn-outline-secondary.focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled):active:focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled).active:focus {
      box-shadow: none;
    }
  }
</style>