var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-toolbar grid-toolbar position-relative" },
    [
      _c(
        "PriorityNavigation",
        {
          staticClass: "scheduler-actions pl-1",
          attrs: {
            dropDownStayOpened: _vm.badgeFilterModalOpened != "close",
            closeDropdown: _vm.closePriorityNavDropdown,
            forceRefresh: _vm.forcePriorityNavRefresh,
          },
          on: {
            "update:closeDropdown": function ($event) {
              _vm.closePriorityNavDropdown = $event
            },
            "update:close-dropdown": function ($event) {
              _vm.closePriorityNavDropdown = $event
            },
            "update:forceRefresh": function ($event) {
              _vm.forcePriorityNavRefresh = $event
            },
            "update:force-refresh": function ($event) {
              _vm.forcePriorityNavRefresh = $event
            },
          },
          nativeOn: _vm._d({}, [
            _vm.priorityNavMouseOverEvent,
            function ($event) {
              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(null, arguments)
            },
            _vm.priorityNavTouchEndEvent,
            function ($event) {
              return _vm.onPriorityNavMouseOverOrTouchEnd.apply(null, arguments)
            },
          ]),
        },
        [
          _vm.showAdd
            ? _c(
                "li",
                [
                  _c("b-popover", {
                    attrs: {
                      target: "BTN_ADD_SCHEDULER",
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("button.add"),
                    },
                  }),
                  _c(
                    "b-btn",
                    {
                      staticClass: "add-event action-btn",
                      attrs: { id: "BTN_ADD_SCHEDULER" },
                      on: { click: _vm.eventAdd },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "plus"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.canEdit()
            ? _c(
                "li",
                [
                  _c("b-popover", {
                    attrs: {
                      target: "BTN_EDIT_SCHEDULER",
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("button.edit"),
                    },
                  }),
                  _c(
                    "b-btn",
                    {
                      staticClass: "edit-event action-btn",
                      attrs: {
                        disabled: _vm.disableEdit,
                        id: "BTN_EDIT_SCHEDULER",
                      },
                      on: { click: _vm.eventEdit },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "pen-to-square"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.canDelete("ACTIVITY") ||
          _vm.canDelete("BOOKING") ||
          _vm.canDelete("TASK") ||
          _vm.canDelete("CALENDAR")
            ? _c(
                "li",
                [
                  _c("b-popover", {
                    attrs: {
                      target: "BTN_DELETE_SCHEDULER",
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("button.delete"),
                    },
                  }),
                  _c(
                    "b-btn",
                    {
                      staticClass: "delete-event action-btn",
                      attrs: {
                        disabled: _vm.disableDelete,
                        id: "BTN_DELETE_SCHEDULER",
                      },
                      on: { click: _vm.eventDelete },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "trash-can"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "li",
            [
              _c("b-popover", {
                attrs: {
                  target: "BTN_COPY_SCHEDULER",
                  placement: "top",
                  triggers: "hover",
                  content: _vm.$t("button.copy"),
                },
              }),
              _c(
                "b-btn",
                {
                  staticClass: "copy-event action-btn",
                  attrs: {
                    disabled: _vm.disableCopy,
                    id: "BTN_COPY_SCHEDULER",
                  },
                  on: { click: _vm.eventCopy },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "copy"] } })],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("b-popover", {
                attrs: {
                  target: "BTN_PASTE_SCHEDULER",
                  placement: "top",
                  triggers: "hover",
                  content: _vm.$t("button.paste"),
                },
              }),
              _c(
                "b-btn",
                {
                  staticClass: "paste-event action-btn",
                  attrs: {
                    disabled: _vm.disablePaste,
                    id: "BTN_PASTE_SCHEDULER",
                  },
                  on: { click: _vm.eventPaste },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "paste"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-1 export-event action-btn",
                  attrs: {
                    id: "BTN_IMPORT_SCHEDULER",
                    disabled:
                      !_vm.filter.usage &&
                      !_vm.filter.activity &&
                      !_vm.filter.booking &&
                      !_vm.filter.task &&
                      !_vm.filter.vacation,
                  },
                  on: { click: _vm.fileImport },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "inbox-in"] },
                  }),
                ],
                1
              ),
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "BTN_IMPORT_SCHEDULER",
                    triggers: "hover",
                    placement: "top",
                    boundary: "viewport",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("task.button.import_document")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-1 export-event action-btn",
                  attrs: {
                    id: "BTN_EXPORT_SCHEDULER",
                    disabled:
                      !_vm.filter.usage &&
                      !_vm.filter.activity &&
                      !_vm.filter.booking &&
                      !_vm.filter.task &&
                      !_vm.filter.vacation,
                  },
                  on: { click: _vm.fileExport },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "inbox-out"] },
                  }),
                ],
                1
              ),
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "BTN_EXPORT_SCHEDULER",
                    triggers: "hover",
                    placement: "top",
                    boundary: "viewport",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("task.button.export_document")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                _vm.filterMouseEnterEvent,
                _vm.onFilterOver,
              ]),
            },
            [
              !_vm.searchPinned
                ? _c(
                    "b-dropdown",
                    {
                      ref: "filter",
                      staticClass: "action-bar-dropdown text-filter",
                      attrs: {
                        id: "BTN_FILTER_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      on: { hide: _vm.onFilterTextDropdownHide },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  class: _vm.filterText !== "" ? "active" : "",
                                  attrs: { icon: ["far", "file-search"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3771862229
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-form",
                        {
                          staticClass: "filter-padding",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "search-input" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  value: _vm.filterText,
                                  placeholder: _vm.$t("task.filter"),
                                },
                                on: {
                                  input: _vm.onFilterTextInput,
                                  focus: function ($event) {
                                    _vm.filterTextFocus = true
                                  },
                                  blur: function ($event) {
                                    _vm.filterTextFocus = false
                                  },
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onFilterSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: "BTN_CLEAR_FILTER_" + _vm.id,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onFilterClear },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "times" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_CLEAR_FILTER_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.clear")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: "BTN_SEARCH_FILTER_" + _vm.id,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onFilterSubmit },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "magnifying-glass"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_SEARCH_FILTER_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.search")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: "BTN_PIN_FILTER_" + _vm.id,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onPinSearch },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "thumbtack"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_PIN_FILTER_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("button.pinOnToolbar")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            [
              _vm.searchPinned
                ? _c(
                    "b-input-group",
                    { staticClass: "search-input" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.filterText,
                          placeholder: _vm.$t("task.filter"),
                        },
                        on: {
                          input: _vm.onFilterTextInput,
                          focus: function ($event) {
                            _vm.filterTextFocus = true
                          },
                          blur: function ($event) {
                            _vm.filterTextFocus = false
                          },
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onFilterSubmit.apply(null, arguments)
                          },
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "search-append search-append-bg",
                              attrs: {
                                id: "BTN_CLEAR_FILTER_" + _vm.id,
                                size: "sm",
                              },
                              on: { click: _vm.onFilterClear },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "times" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_CLEAR_FILTER_" + _vm.id,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("button.clear")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "search-append search-append-bg",
                              attrs: {
                                id: "BTN_SEARCH_FILTER_" + _vm.id,
                                size: "sm",
                              },
                              on: { click: _vm.onFilterSubmit },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "magnifying-glass"] },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_SEARCH_FILTER_" + _vm.id,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.search")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "search-append search-append-bg",
                              attrs: {
                                id: "BTN_UNPIN_FILTER_" + _vm.id,
                                size: "sm",
                              },
                              on: { click: _vm.onUnPinSearch },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "thumbtack"] },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_UNPIN_FILTER_" + _vm.id,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("button.unpinFromToolbar")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            {
              on: _vm._d({}, [
                _vm.badgeFilterMouseEnterEvent,
                _vm.onBadgeFilterEnter,
                _vm.badgeFilterMouseOverEvent,
                _vm.onBadgeFilterOver,
                _vm.badgeFilterMouseLeaveEvent,
                _vm.onBadgeFilterLeave,
                _vm.badgeFilterTouchEndEvent,
                _vm.onBadgeFilterOver,
              ]),
            },
            [
              !_vm.filterPinned
                ? _c(
                    "b-dropdown",
                    {
                      ref: "badgeFilter",
                      staticClass: "action-bar-dropdown text-filter",
                      attrs: {
                        id: "BTN_BADGE_FILTER_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      on: { hide: _vm.onBadgeFilterDropdownHide },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  class:
                                    _vm.badgeFilters.length > 0 ? "active" : "",
                                  attrs: { icon: ["far", "filter"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3929189510
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-form",
                        {
                          staticClass: "filter-padding",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("TaskViewBadgeFilter", {
                            attrs: {
                              filters: _vm.badgeFilters,
                              fields: _vm.badgeFilterFields,
                              fieldValues: _vm.badgeFilterFieldValues,
                              canPin: true,
                              filterCheckboxText: _vm.resourceMode
                                ? "resource.filter_resource"
                                : "staff.filter_staff",
                            },
                            on: {
                              modified: _vm.onBadgeFilterModified,
                              filterModalOpened: _vm.onBadgeFilterModalOpened,
                              filterModalClosed: _vm.onBadgeFilterModalClosed,
                              fetchFieldOptions: _vm.onBadgeFilterFetchOptions,
                              pin: _vm.onPinFilter,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            [
              _vm.filterPinned
                ? _c("TaskViewBadgeFilter", {
                    attrs: {
                      filters: _vm.badgeFilters,
                      fields: _vm.badgeFilterFields,
                      fieldValues: _vm.badgeFilterFieldValues,
                      canPin: true,
                      pinned: "",
                      filterCheckboxText: _vm.resourceMode
                        ? "resource.filter_resource"
                        : "staff.filter_staff",
                    },
                    on: {
                      modified: _vm.onBadgeFilterModified,
                      filterModalOpened: _vm.onBadgeFilterModalOpened,
                      filterModalClosed: _vm.onBadgeFilterModalClosed,
                      fetchFieldOptions: _vm.onBadgeFilterFetchOptions,
                      pin: _vm.onUnPinFilter,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "view",
              on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                _vm.viewMouseEnterEvent,
                _vm.onViewOver,
              ]),
            },
            [
              _c(
                "b-dropdown",
                {
                  ref: "view",
                  staticClass: "action-bar-dropdown",
                  attrs: {
                    id: "BTN_VIEW_" + _vm.id,
                    "toggle-class": "text-decoration-none",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "desktop"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" }, on: { click: _vm.savePreset } },
                    [
                      _c("span", { staticClass: "action-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.resourceMode
                                ? "resource.button.save"
                                : _vm.id
                                ? "staff.button.save"
                                : "staff.button.save_planner"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _vm._l(_vm.views, function (item, index) {
                    return [
                      _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          staticClass: "action-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.loadViewSettings(item)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "action-item-label-with-icon",
                              attrs: { id: "viewname" + index },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm.isEllipsisActive(item.name)
                            ? _c("b-popover", {
                                attrs: {
                                  target: "viewname" + index,
                                  placement: "top",
                                  boundary: "viewport",
                                  "custom-class": "popover-margin",
                                  triggers: "hover",
                                  offset: "-100",
                                  content: item.name,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            [
                              !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: { id: "BTN_COPY_" + index },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.copyColumnSettings(
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "copy"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: "BTN_COPY_" + index,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.resourceMode
                                          ? "resource.button.copy"
                                          : _vm.id
                                          ? "staff.button.copy"
                                          : "staff.button.copy_planner"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: "BTN_INFO_" + index },
                                      on: _vm._d(
                                        {
                                          mouseleave: function ($event) {
                                            return _vm.onInfoLeave(index)
                                          },
                                        },
                                        [
                                          _vm.infoMouseEnterEvent,
                                          function ($event) {
                                            return _vm.onInfoOver(index)
                                          },
                                        ]
                                      ),
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "circle-info"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    ref: "BTN_INFO_" + index,
                                    refInFor: true,
                                    attrs: {
                                      target: "BTN_INFO_" + index,
                                      show: _vm.showInfo[index],
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "manual",
                                      content: _vm.$t("owner", [
                                        _vm.views[index].owner,
                                      ]),
                                    },
                                    on: {
                                      "update:show": function ($event) {
                                        return _vm.$set(
                                          _vm.showInfo,
                                          index,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: "BTN_SHARE_" + index },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.shareColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: [
                                            item.defaultView ? "fas" : "far",
                                            "share-nodes",
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: "BTN_SHARE_" + index,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.resourceMode
                                          ? "resource.button.share"
                                          : _vm.id
                                          ? "staff.button.share"
                                          : "staff.button.share_planner"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-second",
                                      attrs: { id: "BTN_UPDATE_" + index },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.updateColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "save"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: "BTN_UPDATE_" + index,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.resourceMode
                                          ? "resource.button.update"
                                          : _vm.id
                                          ? "staff.button.update"
                                          : "staff.button.update_planner"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: { id: "BTN_DELETE_" + index },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeColumnSettings(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-alt"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: "BTN_DELETE_" + index,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.resourceMode
                                          ? "resource.button.delete"
                                          : _vm.id
                                          ? "staff.button.delete"
                                          : "staff.button.delete_planner"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                _vm.colorMouseEnterEvent,
                _vm.onColoringOver,
              ]),
            },
            [
              _c(
                "b-dropdown",
                {
                  ref: "coloring",
                  staticClass: "action-bar-dropdown",
                  attrs: {
                    id: "BTN_COLORING_" + _vm.id,
                    "toggle-class": "text-decoration-none",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "palette"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-group",
                    { attrs: { header: _vm.$t("colorby") } },
                    [
                      _vm.resourceMode
                        ? [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange(
                                      "none",
                                      "resourceplanner_view_coloring"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [_vm._v(_vm._s(_vm.$t("none")))]
                                ),
                                _vm.coloring.none
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange(
                                      "event",
                                      "resourceplanner_view_coloring"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.event"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.event
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange(
                                      "resource",
                                      "resourceplanner_view_coloring"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("activity.coloring.resource")
                                      )
                                    ),
                                  ]
                                ),
                                _vm.coloring.resource
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange(
                                      "stage",
                                      "resourceplanner_view_coloring"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("activity.coloring.stage"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.stage
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange(
                                      "project",
                                      "resourceplanner_view_coloring"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.project"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.project
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "none",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [_vm._v(_vm._s(_vm.$t("none")))]
                                ),
                                _vm.coloring.none
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "company",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.company"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.company
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "location",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.location"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.location
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "stage",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.stage"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.stage
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "project",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.project"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.project
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "department",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("staff.coloring.department")
                                      )
                                    ),
                                  ]
                                ),
                                _vm.coloring.department
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "skill",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.skill"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.skill
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "staff",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.staff"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.staff
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.onColorChange(
                                      "event",
                                      _vm.getProfileEntryName("coloring")
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.coloring.event"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.event
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteEventOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteEventShow,
            callback: function ($$v) {
              _vm.confirmDeleteEventShow = $$v
            },
            expression: "confirmDeleteEventShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length === 1
                      ? "activity.confirmation.delete"
                      : "activity.confirmation.delete_plural"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }